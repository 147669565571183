/**
 * Buttons
 * .btn__secondary
 */

.btn__secondary {
	z-index: 0;
	position: relative;
  background: $btn-secondary-bg-color;
	color: $btn-secondary-text-color;
  border-color: $btn-secondary-bg-color;
  border-width: $btn-secondary-border-width;
  border-style: $btn-secondary-border-style;
}

.btn__secondary--outline {
	z-index: 0;
	position: relative;
	background: transparent;
	border: transparent;
	color: $btn-secondary-outline-text-color;

  &:after,
  &::before {
    z-index: -1;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transition: $transition;
    border-radius: var(--sleeve-button-border-radius);
  }

	&:after {
    border-width: $btn-secondary-border-width;
    border-style: $btn-secondary-border-style;
    border-color: $btn-secondary-border-color;
	}

  &:before {
    opacity: 0;
    background-color: $btn-secondary-bg-color;
  }

	&:hover:enabled,
	&:focus:enabled {
    color: $black;

    &:before {
			opacity: 0.3;
		}
	}
}

.btn__secondary,
.btn__secondary--outline {
	min-width: var(--sleeve-btn-min-width);
	padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x);
	min-height: var(--sleeve-btn-height);
	font-size: $btn-font-size;
}
