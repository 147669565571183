.list__info {
  @include list-unstyled;

  li {
    display: flex;
    flex-direction: column;
    font-size: $small-details-font-size;
    font-weight: $font-weight-heading;
    margin-bottom: 15px;

    span {
      font-weight: $font-weight-body;
    }
  }
}