/**
 * Footer col 3 offset-1 4 4
 */

 .footer__campaign,
 .footer__col-3-offset1-4-4 {
  padding-top: var(--footer-pt-lg);
  padding-bottom: var(--footer-pb-lg);

  @include media-breakpoint-down(sm) {
    padding-top: var(--footer-pt-md);
    padding-bottom: var(--footer-pb-md);
  }

  // Footer grid
  .col-md-12.col-lg-4 {
    &:last-of-type {
      @include media-breakpoint-down(md) {
        margin-top: 80px;
      }
  
      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
    }

    .container--wide .row {
      @include media-breakpoint-up(lg) {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  // Delete after new mark-up implementation
  .col-md-12.col-lg-8 {
    .container .col-md-6 {
      [class*='col-'] {
        padding: 0 !important;
      }
    }
  }
}
