@import 'darkmode';
@import 'link-container';
@import 'search-form--integrated';

.header--left-aligned-container {
	z-index: 21;
	transition: all ease 300ms;
	position: relative;

  @include media-breakpoint-down(sm){
	//Removed 19/11, can be deleted if it doesn't cause sideways scroll on mobile
    //overflow-x: hidden;
  }
}

.header--left-aligned {
	width: 100vw;

	ul {
		list-style: none;
		margin: 0;
	}

	li {
		margin-bottom: 0;
	}

	h1,
  .h1 {
		margin: 0;
	}

	button {
		padding: 0;
	}

	.text-white {
		width: auto;
		height: 30px;
	}

	input {
		padding: 0;
	}
	a {
		font-size: unset;
	}

	.desktop-secondary-list {
		display: flex;
		justify-content: center;
		align-items: center;
	
		&:not(:first-child) {
			margin-left: 15px;
		}

		.desktop-secondary-list-item {
      @include media-breakpoint-up(lg) {
	      position: relative;
      }

      &:after {
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          transform-origin: 100% 50%;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          width: 100%;
          height: 2px;
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        }
      }
      
      &:hover {
        &:after {
          @include media-breakpoint-up(lg) {
            transform: scaleX(100%);
            transform-origin: 0 100%;
          }
        }
      }

      &.list-item--cta {
        .nav-link {
          @include media-breakpoint-up(lg) {
            font-weight: $btn-font-weight;
            font-size: $btn-font-size;
          }
        }

        &::after {
          @include media-breakpoint-up(lg) {
            height: 0;
          }
        }
      }
    }

    li.desktop-secondary-list-item a {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 24px; // Web accessibility
      min-width: 24px; // Web accessibility

      @include media-breakpoint-up(lg) {
        font-size: $header-font-size-2;
      }
    }
	}

  .list-item--cta {
    .nav-link {
      text-align: center;
      font-weight: $btn-font-weight;
      font-size: $btn-link-font-size;
      background-color: $btn-primary-bg-color;
      color: $btn-primary-text-color;
      border-width: $btn-primary-border-width;
      border-style: $btn-primary-border-style;
      border-color: $btn-primary-border-color;
      border-radius: var(--sleeve-button-border-radius);
      transition: $transition;
  
      &:hover,
      &:focus {
        background-color: transparent;
        color: $black;
  
        [class*="icon-"]::before {
          color: $black;
        }
      }
  
      [class*="icon-"]::before {
        color: $btn-primary-text-color;
      }
    }
  }

  //all collapsed menu (mobile) styling
  &.collapsed-menu {
    .header--left-aligned-top-bar {
      padding: 0 40px;

      @include media-breakpoint-down(sm){
        padding: 0 15px;
      }
    }

    .desktop-searchbar {
      display: none;
    }

    .header--left-aligned-mobile-actions {
      display: flex;
    }

    .header--left-aligned-sidemenu {
      height: 100vh;
      min-height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: scroll;
      padding: 0;

      nav {
        width: 100%;
      }

      .list-item-content-heading {
        justify-content: flex-start;
      }

      .header--left-aligned-sidemenu-list {
        overflow-x: hidden;
        flex-direction: column;
        width: 100%;

        .sidemenu-list-item {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-right: 0;
          
          &.list-item--cta {
            margin-top: 15px;
            margin-left: 15px;
          }

          .list-item-content {
            position: relative;
            display: flex;
            flex-direction: column;
            transition: all .2s ease;
            width: 80%;
            padding: 15px;
            height: unset;
          }
        }

        .list-item-left-aligned-toggle {
          width: 55px;
          padding: 15px;
          position: relative;
          right: unset;
          top: unset;
          opacity: 1;

          &:focus {
            margin-right: 0;
            margin-left: 0;
          }

          p {
            right: -400px;
          }
        }
      }
    }

    &.fixed {
      @include media-breakpoint-up(lg){
        position: fixed !important;
      }
    }
  }
  //collapsed styling end
}

.header--left-aligned-top-bar {
	position: relative;
	z-index: 91;
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	padding: 0 15px;
	justify-content: space-between;

  @include media-breakpoint-up(md) {
		padding: 0 40px;
	}

	&.reverse {
		flex-direction: row-reverse;
		justify-content: space-between;

		.header--left-aligned-mobile-actions {
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-left: 0;

			.mobile-actions-burger {
				padding-left: 0;
				padding-right: 15px;
			}

			.mobile-actions-search-icon {
				padding-left: 15px;
				padding-right: 0;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					left: 0;
					height: 32px;
					width: 1px;
				}
			}
		}
	}

	.has-icon .nav-link{
		display: flex;
		align-items: center;
		gap: 10px;
    
		div {
			font-size: 1.6rem;
		}
	}

	.nav-link {
		font-weight: var(--btn-font-weight);
		font-size: var(--btn-font-size);
	}

	.header--left-aligned-divider {
		position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: $black;
    opacity: 0.1;
	}

  .desktop-secondary-list {
    a {
      color: $black;
    }

    &-item::after {
      background-color: $black;
    }
  }

  .mobile-actions-search-icon svg {
    color: $color-icons
  }

  .desktop-searchbar-input {
    border: none !important;
  }

  .burger-cross-icon-right, .burger-icon-elmnt-dash, .burger-cross-icon-left, .mobile-actions-search-icon::after {
    background-color: $color-icons
  }

  // component color theming: top bar
  @include nestedComponentColors($themes) {
		.header--left-aligned-divider,
    .mobile-actions-search-icon {
      border-color: themed('icon');
    }

		.desktop-secondary-list {
			a {
				color: themed('text');
			}

			&-item::after {
				background-color: themed('link');
			}
		}

		.mobile-actions-search-icon svg {
			color: themed('icon');
		}

		.desktop-searchbar-input {
			border-color: themed('icon');
		}

		.burger-cross-icon-right, .burger-icon-elmnt-dash, .burger-cross-icon-left, .mobile-actions-search-icon::after {
			background-color: themed('icon');
		}
    
		.btn__sound-input span {
			color: themed('icon');
		}

    .list-item--cta {
			.nav-link, 
      .nav-link div, 
      .nav-link div::before {
				color: themed('btn-primary-text');
			}

      a.nav-link {
        background-color: themed('btn-primary-background');
        border-color: themed('btn-primary-border');

        &:hover,
        &:focus {
          background-color: themed('btn-primary-background-hover');
          color: themed('btn-primary-text-hover');

          [class*="icon-"] {
            &:before {
              color: themed('btn-primary-text-hover');
            }
          }
        }
      }
		}
  }

	.nav-brand {
		img {
			height: var(--header-brand-height-md);
      		width: auto;
			margin: calc(var(--header-brand-height-md) / 3) 0;
			
			@include media-breakpoint-up(lg) {
				height: var(--header-brand-height-lg);
				width: auto;
				margin: calc(var(--header-brand-height-lg) / 3) 0;

			}
		}

		h1,
    .h1 {
			font-size: calc(.0625em*var(--header-nav-link-font-size) + .375em);
			line-height: 1.125;
			font-weight: var(--heading-menu-font-weight);
			margin: calc(var(--header-brand-height-md)/3) 0;

			@include media-breakpoint-down(md) {
				font-size: $header-nav-link-font-size;
      }
		}
	}
}

.header--left-aligned-desktop-actions {
	justify-content: center;
	align-items: center;
	margin-left: auto;
	display: none;
	padding: 8px 0;

	li {
		&:not(:first-child) {
			margin-left: 15px;
		}
	}
	.suggestions--autocomplete li {
		margin-left: 0;
	}	

	@include media-breakpoint-up(lg) {
		display: flex;

		&.reverse {
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-left: 0;
		}
	}
}

// searhbar
.header--left-aligned {
	.desktop-actions-searchbar-close-btn{
		position: absolute;
		height: 24px;
		width: 24px;
		right: 32px;
		top: 7px;
		z-index: 5;
		span {
			display: block;
			position: absolute;
			width: 80%;
			height: 2px;
			background-color: var(--color-icons);
			&:nth-of-type(1){
				transform: rotate(45deg);
			}
			&:nth-of-type(2){
				transform: rotate(-45deg);
			}
		}
	}

	//search bar font size variables 
	.header--left-aligned-sidemenu .desktop-searchbar-input {
		font-size: $header-font-size-1;
	}

	.header--left-aligned-top-bar .desktop-searchbar-input {
		font-size: $header-font-size-2;
	}

	.reverse .desktop-searchbar {
		margin-left: 0;
		margin-right: 15px;
	}

	.desktop-searchbar {
		margin-left: 15px;

		@include media-breakpoint-down(md){
			display: none;
		}
		
		.desktop-actions-searchbar-form {
			display: flex;
			position: relative;
			min-width: 40px;
			min-height: 40px;
			border-radius: 25px;
			margin-bottom: 0;
		}
	
		.desktop-actions-searchbar-btn,
		.desktop-actions-searchbar-submit {
			width: 40px;
			height: 40px;
			border-radius: 999px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 7px;
			position: absolute;
			right: 0;

			svg {
				background-color: none;
				height: 100%;
				width: 100%;
			}
		}
	
		.desktop-actions-searchbar-label {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 18px;
		}
	
		.desktop-searchbar-input {
			width: 40px;
			border-radius: 25px;
			border-width: 2px;
			border-style: solid;
			height: 40px;
			background-color: transparent;
			padding: 0 15px;
			transition: width 0.25s ease-in-out;
			
			&.active {
				width: 300px;
				z-index: 1;
				padding: 0 40px 0 15px;
	
				&::placeholder {
					visibility: visible;
				}
			}
	
			&::placeholder {
				visibility: hidden;
			}
		}
	}

	// when menu is collapsed on large screen
 	&.header__collapsed {
		.header--left-aligned-mobile-actions {
			display: flex !important;
			margin-left: 15px;
		}

		.mobile-actions-searchbar-container {
			z-index: 1;
		}

		.link-container {
			left: -300px;
			top: -10px;
		}

		.sidemenu-container {
			width: 100%;
			position: absolute;
			right: 0;
			top: 70px;
		}

		.header--left-aligned-sidemenu {
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			padding-top: 100px;
		}

    .header--left-aligned-sidemenu-list {
			flex-direction: column;
			padding: 15px;
			min-width: 350px;
			justify-content: flex-end;
			align-items: flex-start;

			.sidemenu-list-item .list-item-content {
				padding-bottom: 15px;

				&:after {
					bottom: 10px;
				}
			}
		} 

		.desktop-actions-searchbar-form {
			display: none;
		}
	} 
}

.header--left-aligned-top-bar,
.header--left-aligned-sidemenu {
  .desktop-searchbar {
    .desktop-actions-searchbar-btn, .desktop-actions-searchbar-submit {
      color: $color-icons;
      z-index: 1;
    }
  }

  .desktop-searchbar-input {
    border: none;
    color: $black;

    &::placeholder {
      color: $black;
    }
  }

  .desktop-actions-searchbar-form {
    &::after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: $color-icons;
      opacity: 0.1;
      position: absolute;
      border-radius: 25px;
      z-index: 0;
    }
  }

  // component color theming: search bar
  @include nestedComponentColors($themes) {
    .desktop-searchbar {
      .desktop-actions-searchbar-btn, 
      .desktop-actions-searchbar-submit, .btn__sound-input span, .btn__sound-input span::before{
        color: themed('icon');
      }
	  .desktop-actions-searchbar-close-btn span {
		background: themed('icon');
	  }
  
      .desktop-searchbar-input {
        color: themed('icon');
  
        &::placeholder {
          color: themed('text');
        }
      }
    }

    .desktop-actions-searchbar-form {
      &::after {
        background-color: themed('icon');
      }
    }
  }
}

.header--left-aligned-mobile-actions {
	display: flex;
	margin-left: auto;
	padding: 10px 0;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.mobile-actions-search-icon {
		display: flex;
		border-right: rgba(255, 255, 255, 90) 1px solid;
		padding-right: 15px;
		svg {
			height: 30px;
			width: 30px;
		}

		&:not(:first-child) {
			margin-left: 10px;
		}
	}

	.mobile-actions-search-btn {
		display: flex;
		width: 30px;
		height: 30px;
	}

	.mobile-actions-burger {
		display: flex;
		padding-left: 15px;

		.mobile-actions-burger-btn {
			position: relative;
		}

		.mobile-actions-burger-icon {
			position: relative;
			display: flex;
			overflow: hidden;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			transition: all 0.2s ease;
		}

		.burger-icon-elmnt {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: all 0.2s;
			transform-origin: center;
			overflow: hidden;
			width: 30px;
			height: 20px;

			.burger-icon-elmnt-dash {
				transition: all 0.3s ease;
				transform-origin: left;
				animation-delay: 100ms;
				height: 2px;
				width: 30px;

				&.translate-y-6 {
					transform: translateY(24px);
				}
			}
		}

		.burger-cross-icon {
			position: absolute;
			display: flex;
			justify-content: space-between;
			transition: all 0.5s ease;
			top: 15px;

			&.translate-x-0 {
				transform: translateX(0);
				width: 48px;
			}

			&.-translate-x-10 {
				transform: translateX(-40px);
				width: 0;
			}

			.burger-cross-icon-right,
			.burger-cross-icon-left {
				position: absolute;
				transition: all 0.3s ease;
				transition-delay: 300ms;
				height: 2px;
				width: 30px;
			}

			.burger-cross-icon-right {
				transform: rotate(0);

				&.rotate-45 {
					transform: rotate(45deg);
				}
			}

			.burger-cross-icon-left {
				transform: rotate(0);

				&.-rotate-45 {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.mobile-actions-searchbar-container {
	background-color: var(--color-header);
	position: relative;
	opacity: 0.9;
	width: 100%;
	z-index: 2;
	padding: 12px 15px;

	&.alpine-transition-enter {
		transition: all 0.2s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateY(-100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave {
		transition: all 0.2s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateY(-100%);
	}
}

.reverse .mobile-actions-searchbar-container {
	background-color: red;
	position: relative;
	opacity: 0.9;
	width: 100%;
	z-index: -10;
	padding: 12px 15px;

	&.alpine-transition-enter {
		transition: all 0.2s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateY(-100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave {
		transition: all 0.2s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateY(-100%);
	}
}

.mobile-actions-searchbar-form {
	display: flex;
	align-items: center;
	width: 100%;
	border: none;

	&:focus-within {
		outline: none;
	}
}

.mobile-actions-searchbar-input {
	position: relative;
	width: 100%;
	height: 26px;
	margin-right: 24px;
}

.mobile-actions-searchbar-label {
	color: var(--color-header-text);
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	left: 0;
	display: flex;
	align-items: center;
	margin-top: 2px;
	margin-left: 1px;

	&.alpine-transition-enter {
		transition: all 0.1s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(16px);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave {
		transition: all 0.1s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(16px);
	}
}

#input-mobile {
	background-color: transparent;
	color: var(--color-header-text);
	margin-right: 24px;
	transition: all 0.2s ease;
	width: 0;
	border: none;
	border-radius: 0;
	height: 100%;

	&:focus {
		outline: none;
		width: 100%;
		box-shadow: none;
		border-bottom: solid 1px var(--color-header-text);
	}

	&.w-full.border-b {
		width: 100%;
		border-bottom: solid 1px var(--color-header-text);
	}
}

.mobile-actions-searchbar-submit {
	color: var(--color-header-text);
	border: var(--color-header-text) 2px solid;
	border-radius: 9999px;
	margin-left: auto;
	min-height: 46px;
	min-width: 46px;
}

.header--left-aligned-sidemenu {
	position: relative;
	z-index: 90;
	width: 100%;
	min-height: 100vh;
	background-color: $white;
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	box-shadow: var(--header-box-shadow);

	.desktop-searchbar {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	nav:not(.tablet-secondary-nav) {
		@include media-breakpoint-up(lg){
			display: flex;
			align-items: stretch;
		}
	}

	@include media-breakpoint-down(md){
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0;
		min-height: 100vh;
		height: 100vh;
		box-shadow: none;
		overflow-y: scroll;

		nav {
			width: 100%;
		}
	}

  .sidemenu-list-item {
    &:hover, 
    &:active {
      .list-item-content-heading {
        color: $color-link;
      }
    }
  }

  .desktop-searchbar-input {
    border: none;
  }

  .list-item-content {
    color: $black;

    &::after {
      background-color: $color-link;
    }
  }

  .desktop-secondary-list a {
    color: $black;
  }
	
  // component color theming: sidemenu
  @include nestedComponentColors($themes) {
    .list-item-content {
      color: themed('text');

      &::after {
        background-color: themed('link');
      }
    }

    .tablet-secondary-list-item a {
      color: themed('text')
    }

    .desktop-searchbar-input {
      border-color: themed('icon');
    }

    .desktop-secondary-list a {
      color: themed('text');
    }

    &:not(.collapsed-menu) .sidemenu-list-item:not(.list-item--cta) {
      border-bottom: 1px solid themed('text');

      &:hover, 
      &:active {
        .list-item-content-heading {
          color: themed('text');
        }
      }

      .list-item-megamenu-toggle-icon {
        border-color: themed('text');
      }
    }

    .list-item--cta {
      a.nav-link,
      .nav-link, 
      .nav-link div, 
      .nav-link div::before {
        color: themed('btn-primary-text');
      }

      a.nav-link {
        background-color: themed('btn-primary-background');
        border-color: themed('btn-primary-border');

        [class*=icon-]::before {
          color: themed('btn-primary-text');
        }

        &:hover,
        &:focus {
          background-color: themed('btn-primary-background-hover');
          color: themed('btn-primary-text-hover');

          [class*="icon-"] {
            &:before {
              color: themed('btn-primary-text-hover');
            }
          }
        }
      }
    }  
  }

	@include media-breakpoint-up(lg) {
		min-height: fit-content;
		padding: 0 40px 0 40px;
	}

	&.alpine-transition-enter {
		transition: all 0.3s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave {
		transition: all 0.3s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(100%);
	}
}

//reverse animations
.header--left-aligned-sidemenu.animation-reverse {
	&.alpine-transition-enter {
		transition: all 0.3s ease-out;
	}
	
	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(-100%);
	}
	
	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}
	
	&.alpine-transition-leave {
		transition: all 0.3s ease-in;
	}
	
	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}
	
	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(-100%);
	}
}

//collapsed menu specific styles (ie hover)
.header--left-aligned:not(.collapsed-menu) .header--left-aligned-sidemenu-list {

	.sidemenu-list-item:not(.list-item--cta) {
		padding-top: 12px;
		padding-bottom: 12px;
		@include media-breakpoint-up(lg) {
      border-bottom: none !important;
    }
	.sidemenu-list-item.list-item--cta {
		padding-top: 12px;
		padding-bottom: 12px;
	}

    // current site
    &.active {
      .list-item-content:after {
        @include media-breakpoint-up(lg) {
        transform: scaleX(100%);
        transform-origin: 0 100%;
        }
      }
    }

    .list-item-content:focus:after {
      @include media-breakpoint-up(lg) {
        transform: scaleX(100%);
        transform-origin: 0 100%;
      }
    }
		
    // underline
    .list-item-content:after {
      @include media-breakpoint-up(lg) {
        content: "";
        position: absolute;
        transform-origin: 100% 50%;
        bottom: -12px;
        left: 0;
        transform: scaleX(0);
        width: 100%;
        height: 4px;
        transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        z-index: 2;
      }
    }
		
    //hover animation
    &:hover {
      .list-item-content:after {
        @include media-breakpoint-up(lg) {
        transform: scaleX(100%);
        transform-origin: 0 100%;
        }
      }
    }
  }	
}
//end collapsed menu sidemenu

.header--left-aligned-sidemenu-list {
	max-width: 1600px;

	@include media-breakpoint-up(xl){
		max-width: unset;
	}

  @include media-breakpoint-down(md) {
		overflow-x: hidden;
	}

	@include media-breakpoint-up(lg) {
		min-height: fit-content;
		display: flex;
		padding: 0;
		align-items: stretch;
	}

	&.with-search-bar .sidemenu-list-item .list-item-content {
    &::after {
      @include media-breakpoint-up(lg) {
        bottom: -18px;
      }
    }
	}

  .sidemenu-list-item.list-item--cta {
    @include media-breakpoint-down(md) {
      margin-top: 15px;
      margin-left: 15px;
      width: fit-content;
    }
  }

  .sidemenu-list-item:not(.list-item--cta) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

	.sidemenu-list-item {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

    @include media-breakpoint-up(lg) {
			margin-right: 25px;
		}

    &:first-child {
      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }

		&.list-item--cta {
			padding-top: 12px;
			padding-bottom: 12px;
			white-space: nowrap;

			@include media-breakpoint-up(lg){
				margin-top: unset;
				margin-left: unset;
			}

			&::after {
				height: 0;
			}

			.nav-link {
        text-align: center;
        font-weight: $btn-font-weight;
        font-size: $btn-link-font-size;
        background-color: $btn-primary-bg-color;
        color: $btn-primary-text-color;
        border-width: $btn-primary-border-width;
        border-style: $btn-primary-border-style;
        border-color: $btn-primary-border-color;
        border-radius: var(--sleeve-button-border-radius);
        transition: $transition;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $black;

          [class*="icon-"]::before {
            color: $black;
          }
        }

        [class*="icon-"]::before {
          color: $btn-primary-text-color;
        }
			}

			&.has-icon .nav-link{
				display: flex;
				align-items: center;
				gap: 10px;

				div {
					font-size: 1.6rem;
				}
			}
		}

		.list-item-content:after:focus {
      &:after, 
      &:focus:after {
				transform: scaleX(100%);
				transform-origin: 0 100%;
			}

			.list-item-content-heading {
        color: $color-link;
			}
		}

		.list-item-content {
			position: relative;
			display: flex;
			flex-direction: column;
			transition: all 0.2s ease;
			width: 80%;
			padding: 15px;

			@include media-breakpoint-up(lg) {
				width: auto;
				padding: 0;
				height: 100%;
			}
		}

		.list-item-content-heading {
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
			font-weight: $font-weight-heading;
			font-weight: $font-weight-heading-menu;
			transition: all 0.1s ease;
			font-size: $header-font-size-1;
			white-space: nowrap;

			@include media-breakpoint-up(lg){
				display: flex;
        justify-content: center;
				align-items: center;
				height: 100%;
			}
		}
	}
}

.collapsed-menu .tablet-secondary-nav {
	display: block;
}

.tablet-secondary-nav {
  padding: 15px;
  padding-bottom: 150px;
  
  @include media-breakpoint-up(lg) {
    display: none;
  }

  .tablet-secondary-list-item {
    padding-bottom: 15px;
	  font-size: $header-font-size-2;

    &.list-item--cta {
			border-radius: var(--sleeve-button-border-radius);
			transition: opacity ease-in 100ms;
			width: fit-content;
			padding-bottom: 0;
			margin-bottom: 15px;

			&::after {
				height: 0;
			}

			.nav-link {
				font-weight: $btn-font-weight;
				font-size: $btn-font-size;
			}

			&.has-icon .nav-link{
				display: flex;
				align-items: center;
				gap: 10px;

				div {
					font-size: 1.6rem;
				}
			}
		}

    a {
      display: flex;
      align-items: center;
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
    }
  }
}
