/**
 * Darkmode
 * @import into styles/darkmode.scss
 * List timeline
 */

ul.list__timeline {
  border-color: var(--darkmode-800);

  .list__timeline__header {
    &[class*="bg-"] {
      background-color: var(--darkmode-800) !important;
      border: none !important;

      h2,
      h3 {
        color: var(--darkmode-50);
      }
    }
 
    &.has-icon {
      &[class*="bg-"] {
        .title__icon__bg {
          background: var(--darkmode-800);

          &::after {
            background-color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }

    .list__timeline__dot {
      background-color: var(--darkmode-900) !important;
      border-color: var(--darkmode-hightlight-1-200) !important;
    }
  }

  .card:not(.card__list):not(.card__landscape) {
    background-color: transparent !important;

    &[class*="bg-"] {
      background-color: var(--darkmode-700) !important;
    }
  }
}
