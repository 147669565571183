/**
 * <main> 
 * Background color default (body)
 * @import into styles/background-colors/main-body.scss
 * ReadSpeaker - Service menu
 */

 .service-menu__item {
  .rsbtn {
    &.rsexpanded:after {
      background: $white;
    }

    .rsbtn_exp .rsbtn_exp_inner {
      background: $white !important;
    }

    .rsbtn_play {
      .rsbtn_left {
        &:after {
          background: $white;
        }
      }
    }
  }
}
