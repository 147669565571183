.comp-loader {
	position: absolute;
	display: flex;
	display: none;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 100;

	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		& div {
			position: absolute;
			top: 33px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: $color-primary;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);

			&:nth-child(1) {
				left: 8px;
				animation: lds-ellipsis1 0.6s infinite;
			}
			&:nth-child(2) {
				left: 8px;
				animation: lds-ellipsis2 0.6s infinite;
			}
			&:nth-child(3) {
				left: 32px;
				animation: lds-ellipsis2 0.6s infinite;
			}
			&:nth-child(4) {
				left: 56px;
				animation: lds-ellipsis3 0.6s infinite;
			}
		}
	}

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
}

.comp-success {
	position: absolute;
	display: flex;
	display: none;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 100;
	.checkmark {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		display: block;
		stroke-width: 2;
		stroke: #4bb71b;
		stroke-miterlimit: 10;
		box-shadow: inset 0px 0px 0px #4bb71b;
		animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
		position: relative;
		top: 5px;
		right: 5px;
		margin: 0 auto;
	}
	.checkmark__circle {
		stroke-dasharray: 166;
		stroke-dashoffset: 166;
		stroke-width: 2;
		stroke-miterlimit: 10;
		stroke: #4bb71b;
		fill: #fff;
		animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}

	.checkmark__check {
		transform-origin: 50% 50%;
		stroke-dasharray: 48;
		stroke-dashoffset: 48;
		animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}
}
.comp-error {
	position: absolute;
	display: none;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 100;
	.icon {
		width: 100px;
	}
	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;
		&.circle {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}
		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}
		&.check {
			stroke-dashoffset: -100;
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}

	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #4bb71b;
	}
}
@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
