/**
 * Darkmode
 * @import into styles/darkmode.scss
 * ReadSpeaker - Service menu
 */

.service-menu__item {
  // Read Speaker button - override styling
  .rsbtn {
    &.zoom-tooltoggler .rsbtn_tooltoggle {
      &:hover span.rsicn:before,
      &:focus span.rsicn:before {
        color: var(--darkmode-hightlight-1-200);
      }

      span.rsicn:before {
        color: var(--darkmode-50);
      }
    }

    &.rsexpanded:after {
      background: var(--darkmode-800);
    }

    a,
    span {
      color: var(--darkmode-50) !important;
    }

    .rs-player-loadindicator {
      &::after {
        background: var(--darkmode-800);
      }

      .rsbtn-loading {
        background: var(--darkmode-hightlight-1-200) !important;
      }
    }

    .rsbtn_exp .rsbtn_exp_inner {
      background: var(--darkmode-800) !important;

      .rsbtn_pause span.rsicn,
      .rsbtn_pause:focus .rsicn::before,
      .rsbtn_closer:focus .rsicn::before {
        color: var(--darkmode-50) !important;
      }

      .rsbtn_pause {
        &:active span.rsicn,
        &:hover span.rsicn,
        &:focus span.rsicn {
          color: var(--darkmode-hightlight-1-50) !important;
        }
      }
    }

    .rsbtn_tooltoggle:hover span.rsicn {
      color: var(--darkmode-hightlight-1-200);
    }

    .rsbtn_toolpanel .rsbtn_tools {
      .rsbtn_tool:hover .rsicn:before {
        color: var(--darkmode-hightlight-1-200);
      }

      .rsbtn_tools_inner .rsbtn_toolcontainer {
        background: var(--darkmode-700);
        border-color: var(--darkmode-600);
      }
    }

    .rsbtn_play {
      &:focus .rsbtn_left .rsbtn_text span {
        color: var(--darkmode-50) !important;
      }

      .rsbtn_left {
        &:after {
          background: var(--darkmode-800);
        }

        .rsbtn_text {
          &::before,
          &:after {
            color: var(--darkmode-hightlight-1-200);
          }
  
          span {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }
}
