/*
** Search bar small
*/

.search-bar--small {
  position: relative;
  
	form {
		position: relative;
    margin-bottom: 0;

		.form-group {
			position: relative;
			display: flex;
			justify-content: center;
			margin-bottom: 0;

			input,
			label {
				color: $grey-dark;
				font-size: $search-bar-small-font-size-lg;
				font-weight: $font-weight-body;
				cursor: text;

				@include media-breakpoint-down(sm) {
					font-size: $search-bar-small-font-size-sm;
				}
			}

			input {
				z-index: 10;
				width: 100%;
				height: 70px;
				padding: var(--sleeve-search-bar-small-padding);
				transition: all .2s;
				border: none;
				background: transparent;
				background: $white;
				border-radius: $border-radius-search-bar-small;
				padding-left: 20px;

				&:required {
					box-shadow: none; // Removes red border when invalid IE, firefox
				}

				&+label,
				&:invalid+label {
					opacity: 1;
				}

				&:focus+label,
				&:visited+label,
				&:valid+label {
					opacity: 0;
					display: none;
				}

				&::placeholder {
					color: $grey-dark;
          white-space: pre-line; 
          text-overflow: ellipsis;
				}
			}

			label {
				z-index: 10;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				left: var(--sleeve-search-bar-label-left);
				margin-bottom: 0;
				transition: .25s;
        margin-right: 70px;
			}

			button {
				z-index: 15;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				display: flex;
				align-items: center;
				padding: 0;
				font-weight: 600;
				font-size: $btn-font-size;
				// IE
				margin-right: 1px;
				background: none;


				svg {
					height: 25px;
          width: 25px;

					path {
						fill: $color-icons;
					}
				}

				&.btn--filled {
					right: 5px;
					display: flex;
					justify-content: center;
					background-color: $btn-primary-bg-color;
					color: $btn-primary-text-color;
					min-height: 60px;
					min-width: 60px;
					padding: 0 15px;
					border-radius: $border-radius-search-bar-small;
				}
			}

			.button-highlighted {
				background: initial;
				background-color: $btn-primary-bg-color;
				color: $btn-primary-text-color;
				height: 70px;
				width: 70px;
				justify-content: center;
				right: 0px;
				margin: 0px;
				top: 0;
				transform: translateY(0);
				border-top-right-radius: $border-radius-search-bar-small;
				border-bottom-right-radius: $border-radius-search-bar-small;

				svg path {
					fill: $btn-primary-text-color;
				}
			}
		}
	}
}
