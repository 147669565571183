/*
styling on scroll
*/

.nav--transparent {
	&.scroll {
		backdrop-filter: blur(20px);

		.header-items {
			.icon--burger-button {
				width: 22px;
				height: 16px;

				span {
					&:nth-child(2) {
						width: 18px;
						top: 7px;
					}

					&:nth-child(3) {
						top: 14px;
					}
				}
			}

			.icon-search {
				font-size: 16px;
			}

      .list-item--cta {
        a { 
          height: 34px;
        }
      }

			.top-nav__list-item {
				a {
					font-size: 14px;
				}
			}

			.navbar-brand {
				#inverse-logo-id,
				#logo-id {
					margin-top: 5px;
					margin-bottom: 5px;
				}

				img {
					height: var(--header-brand-height-md);

          @include media-breakpoint-down(md) {
            height: calc(var(--header-brand-height-md) - 5px);
          }
				}

				h1,
        .h1 {
					font-size: calc(0.0625em * var(--header-nav-link-font-size));

          @include media-breakpoint-down(md) {
            font-size: calc(0.0625em * var(--header-nav-link-font-size) - 5px);
          }
				}
			}
		}
	}
}

.header-items {
	.top-nav__list-item {
		a {
			&::after {
				background: var(--changing-text-color);
			}
		}
	}

	.submit-input-btn,
	.expand-input-btn,
	.search-button,
	a,
	span,
	svg {
		color: var(--changing-text-color);
	}

	.burger-button {
		.icon--burger-button {
			span {
				background-color: var(--changing-text-color);
			}
		}
	}
}
