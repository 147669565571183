.hero {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

  .tags-container {
    margin-bottom: 10px;

    .tag,
    .label,
    .icon--inline-tag {
      margin-bottom: 0;
    }
  }

  .tag,
  .label,
  .icon--inline-tag {
    margin-bottom: 10px;
  }

	.bg-image {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		top: 0;
		left: 0;

		img {
			position: absolute; // Images are positioned absolute to avoid the browser scaling the images
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			height: 100%;
		}
	}

	.hero-inner {
		background: $white;
		z-index: 1;
		max-width: 600px;
		text-align: left;
		@include nestedComponentColors($themes){
			background: themed(background);
			color: themed(text)
		}

		@include media-breakpoint-down(sm) {
			padding-left: 20px;
			padding-right: 20px;
		}

		h1 {
			color: $white;
			font-weight: 600;
			margin-bottom: 10px;
		}

		p:not(.tag) {
			font-size: $component-font-size;
			line-height: $line-height-secondary;
			width: 100%;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}

    .search-bar--small {
      .form-group {
        margin-bottom: 0;
      }
    }
	}
}
