.filter-list {
  h2 {
    margin-bottom: 40px;
  }
  
  .filter-menu {
    width: 100%;

    .filter-menu__items {
      justify-content: space-between;
      margin-bottom: 25px;

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }

      .select.select-filter {
        width: auto;

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: 30px;
        }

        select {
          height: 50px;
        }
      }
    }

    .btn.reset {
      margin-bottom: 15px;
    }

    .btn.reset,
    #selected-tags {
      display: none;
    }
  }

  .btn a {
    height: 100%;
  }

  .pagination {
    margin-bottom: 30px;
  }

  .loader {
		margin-bottom: 30px;
  }
}

// filter list on article page
.article-wrapper,
[data-creative~="article-content"] {
  .filter-list {
    background-color: transparent;
    padding: 0;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
}
