/**
 * Global
 * Typography
 */

// Fonts
$primary-font: var(--font-primary);
$secondary-font: var(--font-secondary);
$small-font: var(--font-secondary);

// Base font size
$base-font-size: var(--base-font-size);

// Heading font weight
$font-weight-heading: var(--heading-font-weight);
$font-weight-body: var(--body-font-weight);
$font-weight-tag: var(--tag-font-weight);
$font-weight-heading-menu: var(--heading-menu-font-weight);

// Line heights
$line-height-primary: var(--font-primary-line-height);
$line-height-secondary: var(--font-secondary-line-height);
$line-height-article-body: var(--article-body-line-height);

// H1
$h1-font-size-lg: calc(em(1px) * var(--h1-font-size-lg));
$h1-font-size-sm: calc(em(1px) * var(--h1-font-size-sm));

// H2
$h2-font-size-lg: calc(em(1px) * var(--h2-font-size-lg));
$h2-font-size-sm: calc(em(1px) * var(--h2-font-size-sm));

// H3
$h3-font-size-lg: calc(em(1px) * var(--h3-font-size-lg));
$h3-font-size-sm: calc(em(1px) * var(--h3-font-size-sm));

// H4
$h4-font-size-lg: calc(em(1px) * var(--h4-font-size-lg));
$h4-font-size-sm: calc(em(1px) * var(--h4-font-size-sm));

// Article
$article-font-size-lg: calc(em(1px) * var(--article-body-font-size-lg));
$article-font-size-sm: calc(em(1px) * var(--article-body-font-size-sm));

// H5
$h5-font-size-lg: calc(em(1px) * var(--h5-font-size-lg));
$h5-font-size-sm: calc(em(1px) * var(--h5-font-size-sm));

// Buttons
$btn-font-size: calc(em(1px) * var(--btn-font-size));
$btn-link-font-size: calc(em(1px) * var(--btn-link-font-size));

// Small
$small-font-size: calc(em(1px) * var(--small-font-size));
$small-details-font-size: calc(em(1px) * var(--small-details-font-size));
$small-text-transform: var(--small-text-transform);

// Breadcrumb
$breadcrumb-font-size: calc(em(1px) * var(--breadcrumb-font-size));

// Form elements
$form-font-size: calc(em(1px) * var(--form-font-size));
$search-bar-small-font-size-lg: calc($form-font-size + em(4px));
$search-bar-small-font-size-sm: calc($form-font-size + em(2px));

// Components
$component-title-font-size: calc(em(1px) * var(--component-heading-font-size));
$component-font-size: calc(em(1px) * var(--component-body-font-size));

// Hero article
$hero-article-p-font-size-lg: calc(em(1px) * var(--article-subheading-font-size-lg));
$hero-article-p-font-size-sm: calc(em(1px) * var(--article-subheading-font-size-sm));

// Header
$header-nav-link-font-size: calc(em(1px) * var(--header-nav-link-font-size));
$megamenu-link-list-font-size: calc(em(1px) * var(--megamenu-link-list-font-size));

// Header - new variables
$header-font-size-1: calc(em(1px) * var(--header-font-size-1));
$header-font-size-2: calc(em(1px) * var(--header-font-size-2));
$header-font-size-3: calc(em(1px) * var(--header-font-size-3));
$header-font-size-4: calc(em(1px) * var(--header-font-size-4));