.hero__text-box {
  height: auto;
  min-height: 450px;
  align-items: flex-end !important;
  @include responsive-space('mb', var(--section-margin-bottom-sm--large) !important, var(--section-margin-bottom-sm--large) !important, calc(var(--section-margin-bottom-md--large) + 40px) !important, '', '');

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    min-height: auto;
    background: $white;
  }

  &.mb--less {
    @include responsive-space('mb', var(--section-margin-bottom-sm--large) !important, var(--section-margin-bottom-sm--large) !important, var(--section-margin-bottom-md--large) !important, '', '');
  }

  &.has-overlay--md-up:not(.hero__text-box__bg-color) {
    &.hero__text-box--full-height,
    &.hero__text-box--align-center {
      .hero-inner:not([class*="bg-"]) {
        @include media-breakpoint-up(md) {
          background: transparent;
          padding: 0;
        }
      }
    }
  }

  .bg-image,
  .background-video {
    @include media-breakpoint-down(sm) {
      position: relative !important;
      height: 160px !important;
    }

    img {
      @include media-breakpoint-down(sm) {
        position: relative !important;
      }
    }
  }

  .bg-overlay,
  .bg-overlay-45deg {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .hero-inner {
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 40px;
      margin-bottom: -40px;
      width: 600px;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .1);
      border-radius: var(--sleeve-component-border-radius);
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
    }

    h1 {
      color: $black;
    }

    &:not([class*="bg-"]) {
      .search-bar--small {
        form .form-group input {
          background-color: $grey-light;
        }
      }
    }

    .rich-text {
      margin-bottom: 20px;
    }

    .link-container {
      display: flex;
      flex-wrap: wrap;
    }

    // Hero inner - is link
    a:not(.btn__link):not(.btn__component):not(.btn__component--outline) {
      &:hover {
        .arrow {
          right: 45px;
        }
      }

      p:not(.tag) {
        margin-bottom: 30px;
      }

      .arrow {
        transition: $transition;
        bottom: 40px;
        right: 40px;
      }
    }
  }

  //colored bg heros
  &.hero__text-box__bg-color:not(.has-overlay--md-up) {
    .hero-inner {
      @include media-breakpoint-down(md) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      } 
    }
  }

  &.hero__text-box__bg-color.has-overlay--md-up .hero-inner {
    @include nestedComponentColors($themes) {
      padding: 0;

      @include media-breakpoint-down(md) {
        padding: 30px 15px;
      }
    }
  }

  .btn-container {
    margin-top: 0;
    
    .btn {
      width: auto;
      margin-right: 30px;
      margin-top: 15px;
    }
  }

  &.hero__text-box--align-center {
    align-items: center !important;
    margin-bottom: 0 !important;

    .hero-inner {
      margin-bottom: 0;
      box-shadow: none;

      @include media-breakpoint-up(lg) {
        margin-top: 80px;
        margin-bottom: 80px;
      }
    }
  }

  &.hero__text-box--full-height,
  &.height-50vh {
    margin-bottom: 0 !important;
  }

  &.hero__text-box--full-height {
    height: calc(100vh - #{var(--header-height-lg)});

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      height: auto;
      background: $white;
    }

    &.has-overlay--md-up:not(.hero__text-box--align-center) {
      .hero-inner:not([class*="bg-"]) {
        @include media-breakpoint-up(md) {
          margin: 200px 0;
        }
      }
    }

    .bg-image,
    .background-video {
      @include media-breakpoint-down(sm) {
        height: calc(50vh - #{var(--header-height-md)}) !important;
      }
    }

    .hero-inner {
      max-width: 100%;
      width: 100%;
      padding: 70px 50px 60px 50px;
      box-shadow: none;

      @include media-breakpoint-down(md) {
        padding: 50px 30px 40px 30px;
      }
  
      @include media-breakpoint-down(sm) {
        padding: 40px 15px 30px 15px;
      }
    }
  }

  &.hero__text-box--full-height:not(.hero__text-box--align-center),
  &.height-50vh:not(.hero__text-box--align-center) {
    .hero-inner {
      margin-bottom: 100px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

    .container {
      height: 100%;
  
      .row {
        height: 100%;
  
        [class*="col-"] {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
  }
  
  .hero__text-box__bg-color {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @include media-breakpoint-down(sm) {
      position: relative !important;
      height: 160px !important;
    }
  }

  @import 'has-swiper';
}
