/**
 * Link animations: Component titles link styling
 *
 * Usage: <body class="c-title--link--underline">
 * Will overide default Component titles link animations
 *
 */

.info-box {
  &:not([class*="bg--"]),
  &:not([class*="c-background"]),
  &.bg--transparent,
  &.bg--default  {
    a:not(.btn) {  
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          color: $color-link;
        }
      }
    }
  }

  &[class*="bg--"]:not(.bg--transparent):not(.bg--default) {
    a:not(.btn) {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          text-decoration: underline;
        }
      }
    }
  }

  @include nestedComponentColors($themes) {
    &:not(.bg--transparent):not(.bg--default) {
      a:not(.btn) {
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: themed('text');
          }
        }
      }
    }
  }

  a:not(.btn) {
    h3,
    .h3,
    .h4 {
      transition: $transition;
    }
  }
}

a.spot-box {
  &:not([class*="bg--"]),
  &.bg--transparent,
  &.bg--default {
    &:hover, 
    &:focus {
      p:not(.h1) {
        color: $color-link;
      }
    }
  }

  &[class*="bg--"] {
    &:hover, 
    &:focus {
      p:not(.h1) {
        text-decoration: underline;
      }
    }
  }
}

//-  Color on hover: Link color on hover and on colored backgrounds underline on hover
.c-title--link--hover-color {
  a.card,
  a.spot-box {
    &:not([class*="bg--"]):not(.card__image),
    &.bg--transparent,
    &.bg--default {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          color: $color-link;
        }
      }
    }
  
    &[class*="bg--"]:not(.bg--transparent):not(.bg--default),
    &[class*="article-element-bg--"],
    &.card__image {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          text-decoration: underline;
        }
      }
    }

    @include nestedComponentColors($themes) {
      &:not(.bg--transparent):not(.bg--default) {
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: themed('text');
          }
        }
      }
    }
  
    h3,
    .h3,
    .h4 {
      transition: $transition;
    }
  }

  a.link-box {
    transition: $transition;

    &:not([class*="bg--"]) {
      &:hover, 
      &:focus {
        span {
          color: $color-link;
        }
      }
    }

    @include nestedComponentColors($themes) {
      &:hover, 
      &:focus {
        text-decoration: underline;
        color: themed('text');
      }
    }
  }

  .card {
    &:not([class*="bg--"]),
    &.bg--transparent,
    &.bg--default {
      a:not(.btn) {  
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            &:hover, 
            &:focus {
              color: $color-link;
            }
          }
        }
      }
    }

    @include nestedComponentColors($themes) {
      a:not(.btn) {
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            text-decoration: underline;
            color: themed('text');
          }
        }
      }
    }
  }

  .info-box,
  .info-box__links__icon__item {
    @include nestedComponentColors($themes) {
      .list__links ul li a.list__link {
        &:hover, 
        &:focus {
          text-decoration: underline;
          color: themed('text');
        }
      }
    }
  }

  .list__downloads .list__link span,
  .list__news .list__news__item .h3,
  .list__links ul li a.list__link {
    &:hover, 
    &:focus {
      color: $color-link;
    }
  }
}

//-  Underline on hover: Link underline on hover
.c-title--link--hover-underline {
  a.card,
  a.spot-box {
    &:not([class*="bg--"]):not(.card__image),
    &.bg--transparent,
    &.bg--default {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          text-decoration: underline;
          color: $black;
        }
      }
    }

    @include nestedComponentColors($themes) {
      &:not(.bg--transparent):not(.bg--default) {
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            text-decoration: underline;
            color: themed('text');
          }
        }
      }
    }
  }

  .card,
  .info-box {
    &:not([class*="bg--"]),
    &.bg--transparent,
    &.bg--default {
      a:not(.btn) {  
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: $black;
            text-decoration: underline;
          }
        }
      }
    }

    @include nestedComponentColors($themes) {
      a:not(.btn) {
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: themed('text');
          }
        }
      }
    }
  }

  a.card {
    @include nestedComponentColors($themes) {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          color: themed('text');
        }
      }
    }
  }

  a.link-box,
  .breadcrumb .breadcrumb-item a {  
    &:hover, 
    &:focus {
      span {
        text-decoration: underline;
      }
    }
  }

  .breadcrumb .breadcrumb-item a {  
    &:hover, 
    &:focus {
      span:last-of-type {
        text-decoration: none;
      }
    }
  }

  .title__link .btn__link:not(.btn__link-arrow--short),
  .list__news .list__news__item .h3 {  
    &:hover, 
    &:focus {
      text-decoration: underline;
    }
  }

  .list__downloads .list__link span,
  .list__news .list__news__item .h3,
  .list__links ul li a.list__link {
    &:hover, 
    &:focus {
      text-decoration: underline;
    }
  }
}

//-  Fixed underline
.c-title--link--underline {
  a.card,
  a.spot-box {
    &:not([class*="bg--"]):not(.card__image),
    &.bg--transparent,
    &.bg--default {
      h3,
      .h3,
      .h4 {
        text-decoration: underline;
      }
    }

    h3,
    .h3,
    .h4 {
      text-decoration: underline;
    }
  }

  .card,
  .info-box {
    &:not([class*="bg--"]),
    &.bg--transparent,
    &.bg--default  {
      a:not(.btn) {  
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: $black;
          }
        }
      }
    }

    a:not(.btn) {
      h3,
      .h3,
      .h4 {
        text-decoration: underline;
      }
    }
  }

  a.link-box,
  .breadcrumb .breadcrumb-item a {
    span {
      text-decoration: underline;
    }
  }

  .breadcrumb .breadcrumb-item a {  
    span:last-of-type {
      text-decoration: none;
    }
  }

  .list__downloads .list__link span,
  .list__news .list__news__item .h3,
  .list__links ul li a.list__link {
    text-decoration: underline;
  }
}

//-  Fixed underlined & color on hover
.c-title--link--underline-hover-color {
  a.card,
  a.spot-box {
    &:not([class*="bg--"]):not(.card__image),
    &.bg--transparent,
    &.bg--default {
      &:hover, 
      &:focus {
        h3,
        .h3,
        .h4 {
          color: $color-link;
        }
      }
    }
  
    h3,
    .h3,
    .h4 {
      text-decoration: underline;
    }
  }

  .card,
  .info-box {
    &:not([class*="bg--"]),
    &.bg--transparent,
    &.bg--default {
      a:not(.btn) {  
        &:hover, 
        &:focus {
          h3,
          .h3,
          .h4 {
            color: $color-link;
          }
        }
      }
    }

    a:not(.btn) {
      h3,
      .h3,
      .h4 {
        text-decoration: underline;
      }
    }
  }

  a.link-box,
  .breadcrumb .breadcrumb-item a {
    &:hover, 
    &:focus {
      span {
        color: $color-link;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  .breadcrumb .breadcrumb-item a {
    &:hover, 
    &:focus {
      span:last-of-type {
        color: $grey-dark;
      }
    }

    span:last-of-type {
      text-decoration: none;
    }
  }

  .list__downloads .list__link span,
  .title__link .btn__link:not(.btn__link-arrow--short),
  .list__news .list__news__item .h3 {
    text-decoration: underline;
  }

  .info-box,
  .info-box__links__icon__item {
    @include nestedComponentColors($themes) {
      .list__links ul li a.list__link {
        &:hover, 
        &:focus {
          text-decoration: underline;
          color: themed('text');
        }
      }
    }
  }

  .list__downloads .list__link span,
  .list__news .list__news__item .h3,
  .list__links ul li a.list__link {
    text-decoration: underline;

    &:hover, 
    &:focus {
      color: $color-link;
    }
  }
}
