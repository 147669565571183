/**
 * SVG's
 */

svg {
	// IE
	overflow: visible;

	// SVGs from mixin svgs();
	&.svg-date,
	&.svg-time,
	&.svg-location,
	&.svg-link {
		path {
			fill: $color-icons;
		}
	}

	&.svg-date,
	&.svg-grid,
	&.svg-list {
		rect {
			fill: $color-icons;
		}
	}

	&.svg-time {
		path {
			stroke: $color-icons;
		}

		circle {
			stroke: $color-icons;
		}
	}

	&.svg-price {
		path {
			stroke: $color-icons;
		}
	}
}

.play-icon {
	// cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	height: 60px;
	width: 60px;
  background-color: rgba(0,0,0,0.35);
  border-radius: 50%;

	&.play-icon--dark {
    background-color: rgba(255,255,255,0.75);

		.stroke-solid {
			stroke: $black;
		}

		.icon {
			fill: $black;
		}
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}

	.stroke-solid {
		stroke: $white;
		stroke-dashoffset: 0;
		stroke-dasharray: 300;
		stroke-width: 2px;
		transition: stroke-dashoffset 1s ease, opacity 1s ease;
	}

	.icon {
		fill: $white;
		transform: scale(0.8);
		transform-origin: 50% 50%;
		transition: transform 200ms ease-out;
	}

	// Hover
	&:hover {
		.stroke-solid {
			opacity: 1;
			stroke-dashoffset: 300;
		}

		.icon {
			transform: scale(0.9);
		}
	}
}

.arrow {
	svg path {
		fill: $color-arrows;
	}
}
