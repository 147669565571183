/*
** Accordion
*/

.accordion {
	width: 100%;
	margin: 0 auto;
	border-radius: 5px;

	.accordion__title {
		margin-bottom: 20px;

		h2,
    .h2 {
			margin-bottom: 0;
		}

		&.has-btn {
			position: relative;

			h2 {
				padding-right: 135px;

        @include media-breakpoint-down(sm) {
          padding-right: 110px;
        }

        @include media-breakpoint-down(xs) {
          hyphens: var(--hyphens-mobile); /* Web accessibility: Zoom */
        }
			}

			.accordion__toggle-btn {
				position: absolute;
				right: 0;
				bottom: -10px;
			}
		}
	}

	&.accordion__no-title {
		.accordion__toggle-btn {
			width: 100%;
		}
	}

	.accordion__toggle-btn {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: auto;

		button {
			z-index: 1;
			position: relative;
			padding: 9px 38px 9px 15px;
			margin-right: 14px;
      color: $black;

			span {
        font-size:  $small-font-size;
        line-height: $line-height-secondary;
				font-weight: 500;     
			}

			.icon-plus {
				width: 8px;
				height: 8px;
				right: 15px;
			}

			&.active {
				.icon-plus {
					&:after {
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	.accordion-header {
		position: relative;
		display: flex;
		width: 100%;
		align-items: center;
		padding: 1.5em 60px 1.5em 0;
		border-radius: 0;
		transition: $transition;

		@include media-breakpoint-down(sm) {
			padding: 24px 60px 24px 0;
		}

		&::before {
      z-index: 10;
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			border-bottom: 1px solid $divider-color;
		}

		&:hover {
			position: relative;
			z-index: 5;
			background: $hover-bg-color;
			padding-left: 20px;

			@include media-breakpoint-down(sm) {
				padding-left: 15px;
			}
		}

		&.inactive {
			span, h2, h3, .h3 {
				color: $grey-dark
			}
		}

		span,
		h2,
		h3,
    .h3 {
			text-align: initial;
			margin-bottom: 0;
			font-family: $primary-font;
			color: $black;
			font-weight: $font-weight-heading;
			@include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
			line-height: $line-height-article-body;
		}
	}

	.accordion-body {
		display: none;

		.accordion-body__contents {
      position: relative;
			padding: 24px 20px;

			@include media-breakpoint-down(sm) {
				padding: 24px 15px;
      }

      &::after {
        content: "";
        z-index: 10;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid $divider-color;
        left: 50%;
        transform: translate(-50%, 0);
      }

      div:last-of-type {
        margin-bottom: 0;
      }
      
      .rich-text {
        p {
          &:last-child {
            margin-bottom: 0;
          }
  
          img,
          img[width],
          img[height] {
            max-width: 100%;
          }
        }

        strong {
          font-weight: 600;
        }
  
        a {
          font-size: inherit;
          line-height: inherit;
  
          &:not([href]):not([tabindex]) {
            background-size: unset;
            background-image: unset;
            font-weight: $font-weight-body;
          }
        }
      }
		}
	}

  .accordion__items {
    position: relative;

    &::before {
      content: "";
      z-index: 10;
      position: absolute;
      top: -1px;
      width: 100%;
      border-top: 1px solid $divider-color;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

	.accordion__item {
    position: relative;
		width: 100%;
		border-radius: 0;

		&:last-child {
			border-radius: 0;
		}

		&.active {
			& .accordion-header {
				padding-left: 20px;
				background: $hover-bg-color;

				@include media-breakpoint-down(sm) {
					padding-left: 15px;
				}
			}

			&:last-child {
				& .accordion-header {
					border-radius: 0;
				}
			}
		}
	}
}

// Accordion toggle icon
.accordion .icon-plus {
  right: 25px;
}

.js-accordion-item {
  &.active {
    & > .js-accordion-header {
      .icon-plus,
      .toggle--icon-plus {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
}
