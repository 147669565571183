/*
** Spot box
** Article element color on components
**
** Included in spot-box index.scss file
*/

@include nestedComponentColors($themes) {
  background-color: ('background');
  
  h2,
  p,
  p.h1,
  .author,
  [class*="icon-"]  {
    color: themed('text');
  }

  &.flex-center .imagery-text::before {
    background-color: themed('text');
  }
}

@include nestedArticleColors($themes-article) {
  background: themedArticle('background');
  
  h2,
  p,
  p.h1,
  .author,
  [class*="icon-"]  {
    color: themedArticle('text');
  }

  &.flex-center .imagery-text::before {
    background-color: themedArticle('text');
  }
}
