/**
 * Positioning
 */

.position-relative {
  position: relative;
}

.position-right {
  right: 0;
  left: auto !important;
}

.flex-center,
.align--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  flex-direction: row;
}

.flex-right {
  display: flex;
  flex-direction: row-reverse;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align--space-between {
  display: flex;
  justify-content: space-between;
}

.text-center {
	text-align: center;

	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	p,
  span {
		text-align: center;
	}

  .tags-container {
    display: flex;
    justify-content: center;
  }
}

.content-center {
  display: flex;
  justify-content: center;

  [class*="col-"] {
    display: flex;
    align-items: center;
  }
}

.height-100vh--absolute {
  height: calc(100vh - var(--header-height-lg));
  margin-bottom: -100px;

  @include media-breakpoint-down(md) {
    height: calc(100vh - var(--header-height-md));
    margin-bottom: -70px;
  }
}

.height-80vh--absolute {
  min-height: calc(80vh - var(--header-height-lg));
  margin-bottom: -100px;

  @include media-breakpoint-down(md) {
    min-height: calc(80vh - var(--header-height-md));
    margin-bottom: -70px;
  }
}

.height-50vh--absolute {
  min-height: calc(50vh - var(--header-height-lg));
  margin-bottom: -100px;

  @include media-breakpoint-down(md) {
    min-height: calc(50vh - var(--header-height-md));
    margin-bottom: -70px;
  }
}
