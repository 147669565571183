.pagination-kultunaut {
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      width: auto;
      height: 40px;
      padding: 0 12px;

      @include media-breakpoint-down(sm) {
        height: 25px;
        padding: 0 10px;
      }

      &.active-item {
        a {
          span {
            color: $color-icons;
          }
        }
      }

      &.prev,
      &.next {
        padding: 0;
      }

      &.prev {
        svg {
          transform: rotate(180deg);
        }

        &.disible {
          display: none;
        }
      }
      
      a {
        display: flex;
        transition: $transition;

        &:hover {
          span {
            color: $color-icons;
          }
        }

        span {
          display: flex;
          font-size: 1.375rem; // needs fixed size
          line-height: 2.125rem; // needs fixed size
          color: #666;
          padding: 0;

          @include media-breakpoint-down(sm) {
            font-size: 1.25rem;
            line-height: 1.625rem;
          }

          &.dotsleft {
            margin-left: 21px;

            @include media-breakpoint-down(sm) {
              margin-left: 20px;
            }
          }

          &.dotsright {
            margin-right: 21px;

            @include media-breakpoint-down(sm) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

body.dark-theme  {
  color: var(--darkmode-50);

  .pagination-kultunaut {
    ul li {
      &.active-item a span {
        color: var(--darkmode-hightlight-1-200);
      }

      a {
        &:hover span {
          color: var(--darkmode-hightlight-1-200);
        }

        span {
          color: var(--darkmode-50);
        }

        svg path {
          fill: var(--darkmode-hightlight-1-200);
        }
      }
    }
  }
}
