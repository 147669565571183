/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Service menu
 */

.article-wrapper .service-menu .service-menu__item .service-menu__item__icon {
  background: var(--darkmode-800);
}

.service-menu {
  .service-menu__item .service-menu__item__icon svg path {
    fill: var(--darkmode-hightlight-1-200);
  }

  .dropdown-menu {
    background-color: var(--darkmode-800);
  }

  .service-menu__item {
    button,
    a {
      &:hover {
        .service-menu__item__text {
          color: var(--darkmode-hightlight-1-200);
        }
      }
    }
  }

  .dropdown-menu__share {
    svg {
      circle {
        stroke: var(--darkmode-hightlight-1-200);
      }
  
      path {
        fill: var(--darkmode-50);
      }
    }

    .btn-tooltip {
      svg {
 
        path:first-of-type {
          stroke: var(--darkmode-hightlight-1-200);
          fill: transparent;
        }
      }
    }
  }
}
