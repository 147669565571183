.list__group {
  ul {
		list-style-type: none;
		margin-bottom: 0;
		margin-bottom: auto;

		li {
			width: 100%;
			margin-bottom: 15px;

			&:nth-child(n + 2) {
				margin-top: 15px;
			}

      h3 {
        font-size: $component-font-size;
        font-weight: 500;
        margin-bottom: 0;
      }

			p {
				margin-bottom: 0;
				font-size: 87.5%;
				color: $grey-dark;
			}
		}

		a {
			color: $color-link;
			transition: $transition;
			cursor: pointer;
      
			&:first-of-type {
				color: $black;
				font-weight: 500;

				&:hover {
					color: $color-link;
				}
			}
      
			&.active {
				color: $color-link;
			}
		}
	}
}