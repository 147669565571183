/*
** <main>
** Background color default (body)
*/

main:not(.bg-main--white) {
  .article-wrapper {
    .service-menu:not(.service-menu--bg-transparent) .service-menu__item .service-menu__item__icon,
    .card__business:not([class*="article-element-bg--"]),
    .link-boxes__article .link-boxes__article__title {
      background: $white;
    }

    .link-box__icon,
    .link-box__arrow {
      &:not(.article-element-bg--0):not(.article-element-bg--1):not(.article-element-bg--2):not(.article-element-bg--3):not(.article-element-bg--4):not(.article-element-bg--5) {
        background: $white;
      }    
    }

    .link-box__article {
      background: $color-body;

      &:after {
        background: $white;
      }
    }

    .accordion .accordion__item {
      &.active .accordion-header,
      .accordion-header:hover {
        background: $white;
      }
    }

    .accordion__colored-backgrounds {
      background: $white;

      &:not(.article-element-bg--0):not(.article-element-bg--1):not(.article-element-bg--2):not(.article-element-bg--3):not(.article-element-bg--4):not(.article-element-bg--5) {
        &::before,
        &::after {
          background: $white;
          opacity: 1;
        }

        .accordion__title, 
        .accordion__sub-title::after {
          background: $white;
        }

        .accordion-header::after,
        .accordion-body::after {
          background: $white;
        }

        .accordion-body::after {
          background: $color-body;
          opacity: .5;
        }

        .accordion-header.has-icon .accordion-header__icon {
          background: $color-body;
        }
      }
    }
 
    .fact-box {
      &:not(.article-element-bg--0):not(.article-element-bg--1):not(.article-element-bg--2):not(.article-element-bg--3):not(.article-element-bg--4):not(.article-element-bg--5) {
        .fact-box__title,
        .fact-box__content:after {
          background: $white;
        }

        &.fact-box__no-title .fact-box__content:after {
          opacity: 1;
        }
      }
    }
  }

  .bg--inherit-bg-main {
    background-color: $color-body;
  }

  section.bg--invert-bg-main {
    background-color: $white;
  }

  .bg--invert-bg-main {
    &.banner__alert {
      background-color: $white;
    }
  }

  .bg-section--invert-bg-main {
    .form-container {
      background-color: $grey-light;
    }
  }

  // Styles
  @import '../modules/third-party/ReadSpeaker/main--bg-body.scss';
}

[data-pagetype~="creative"]:not(.bg-main--white) {
  section {
    &:not(.bg-section--primary-decorative):not(.bg-section--secondary-decorative):not(.bg-section--invert-bg-main):not(.bg--body):not(.article-wrapper):not(.bg--grey-light) {
      .card__business,
      .link-box__icon:not([class*="article-element-bg--"]),
      .link-box__arrow:not([class*="article-element-bg--"]) {
        background-color: $white;
      }
    }
  }
}

section.bg-section--invert-bg-main {
  .bg--transparent {
    .search-bar--small form:not(#search-form) .form-group input {
      background: $color-body;
    }
  }
}
