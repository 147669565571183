/*Sidemenu specifics*/

.header--transparent {
	.menu-overlay {
		z-index: 1;
	}

	.burger-button {
		margin: 0;
	}

	.sidemenu {
		&__container {
			@include media-breakpoint-up(md) {
				padding: 30px 60px;
			}
		}

    .list-item--cta {
      &:hover,
      &:focus {
        transform: translateX(0);
      }
    
      a {
        display: flex;
        align-items: center;
        background-color: $btn-primary-bg-color;
        color: $btn-primary-text-color;
        border-color: $btn-primary-border-color;
        height: 35px;
    
        &:hover,
        &:focus,
        &.active {
          background-color: transparent;
          color: $black;
    
          [class*="icon-"] {
            color: $color-icons;
          }
        }
    
        [class*="icon-"] {
          color: $btn-primary-text-color;
        }
      }
    }

		.sidemenu__search-bar {
			margin-bottom: 30px;

			svg {
				height: 20px;
				width: 20px;

        @include media-breakpoint-up(md) {
          height: 23px;
          width: 23px;
        }
			}

			input {
				border-radius: 50px;
				height: 45px;
				padding-left: 60px;

        @include media-breakpoint-up(md) {
          height: 60px;
        }
			}

			button {
				left: 20px;
			}

			label {
				left: 60px;
			}
		}

		.sidemenu__primary {
			ul {
				margin: 0;
			}

      .list-item--cta {
        a {
          height: 45px;
        }
      }
		}

		.sidemenu__primary__list-item {
			opacity: 1;

			a {
        font-size: $header-nav-link-font-size;
        min-height: 25px; // Web accessibility
        min-width: 24px; // Web accessibility
				transition: all 0.2s ease;

        @include media-breakpoint-down(md) {
          font-size: $component-font-size;
        }
			}

			a:hover {
				transform: translateX(7px);
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		.sidemenu__secondary {
			opacity: 1;

			.sidemenu__secondary__list-item {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}

      .settings-menu {
        .dropdown-settings__container {
          position: relative;
          top: unset;
          width: 100%;
          box-shadow: unset;
          padding: 10px;
          margin-bottom: 20px;
        }
      }
		}

		#searchbar-small {
			background-color: $grey-light;
		}
	}
}
