.image-gallery {
  margin: -10px;

  &.image-gallery--fixed-grid {
    .image-gallery__item {
      height: 265px;

      @include media-breakpoint-down(sm) {
        height: 145px;
      }

      &:nth-child(even) {
        height: 345px;

        @include media-breakpoint-down(sm) {
          height: 145px;
        }
      }

      &:nth-child(3n-7) {  /* or 4n+1 */
        @include media-breakpoint-down(sm) {
          height: 185px; 
        }  
      }

      img {
        height: 100%;
      }
    }
  }

  .image-gallery__item {
    cursor: zoom-in;
    width: 33.33%;
    height: auto;
    padding: 10px;

    @include media-breakpoint-down(sm) {
      width: 50%;
    }

    img {
      width:  100%;
      height: auto;
      border-radius: $border-radius-image;
    }
  }
}

// On article page
.article-wrapper,
[data-creative~="article-content"] {
  .image-gallery {
    &.image-gallery--fixed-grid {
      .image-gallery__item {
        height: 145px;

        &:nth-child(even) {
          height: 185px;

          @include media-breakpoint-down(sm) {
            height: 145px;
          }
        }

        &:nth-child(3n-7) {
          @include media-breakpoint-down(sm) {
            height: 185px; 
          }  
        }
      }
    }
  }
}

@import 'viewerJS';
