.modal-open {
  padding-right: 0 !important;
}

.modal {
  button.close {
    display: flex;
    justify-content: center;
  }
}
