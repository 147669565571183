/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Tabels
 */

// Tabel
.rich-text tr {
  border-color: var(--darkmode-500);

  td {
    background: var(--darkmode-700);
    color: var(--darkmode-50);
  }
}

// DataTabel
table.dataTable {
  thead tr {
    th {
      border-color: var(--darkmode-hightlight-1-200) !important;

      &.sorting,
      &.sorting_desc,
      &.sorting_asc {
        color: var(--darkmode-50);
      }

      &.sorting_asc:after {
        border-color: var(--darkmode-hightlight-1-200);
      }
    }
  }

  tbody tr {
    background-color: var(--darkmode-900);

    td {
      color: var(--darkmode-50);
    }
  }
}

table.dataTable.no-footer {
  border-color: var(--darkmode-hightlight-1-200) !important;
}

.dataTables_filter label {
  border-color: var(--darkmode-600);
  background: var(--darkmode-700);
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: var(--darkmode-hightlight-1-200);
  border-color: var(--darkmode-hightlight-1-100);
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  color: var(--darkmode-900);
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  color: var(--darkmode-hightlight-2-200);
  border-color: var(--darkmode-hightlight-2-200);
}

.dataTable thead tr th.sorting:after,
.dataTable thead tr th.sorting_desc:after {
  border-color: var(--darkmode-hightlight-1-200);
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: var(--darkmode-700);
}

table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: var(--darkmode-700);
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: var(--darkmode-800);
}

table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: var(--darkmode-900);
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: var(--darkmode-50);
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: var(--darkmode-50) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:after,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:after {
  color: var(--darkmode-hightlight-1-200);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--darkmode-hightlight-1-200) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: var(--darkmode-hightlight-1-200) !important;
}
