.card__landscape {
  border-top: 3px solid $grey-medium;
  background: transparent !important;

  a {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  
  img {
    width: 375px;
    height: 230px;
    margin-right: 40px;
    border-radius: $border-radius-component-image;
    
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  .video {
    position: relative;
    height: 100%;
    overflow: hidden;
    max-width: 100%;
  }

  h3 {
    font-weight: $font-weight-heading;
  }

  p {
    margin-bottom: 0;
  }
}

