.spot-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: $border-radius-component;

  &:not(.c-background):not([class*="bg--"]):not([class*="article-element-bg--"]) {
    padding: 15px 40px;

    @include media-breakpoint-down(sm) {
      padding: 30px;
    }
  }

  &.flex-center {
    .spot-box__imagery {
      margin-bottom: 15px;
    }

    .imagery-text {
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 50%);
        height: 1px;
        width: 40px;
        background-color: $grey-medium;
      }
    }
  }

  &.flex-left {
    .spot-box__imagery {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  &.flex-right {
    .spot-box__imagery {
      margin-left: 15px;
      margin-bottom: 0;
    }
  }

  .spot-box__imagery {
    margin-bottom: 15px;
  }
  
  .image {
    height: auto;
    max-width: 4.0625rem; // should as big as icons (max);
    //width: 30px old styling. Svgs not same size as icons
    width: fit-content; // let icon be smaller, if not 4.0625rem wide inherently

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  [class*="icon-"] {
    font-size: 4.0625rem; // icon size
  }

  .imagery-text {
    p.h1 {
      color: $color-tag;
      margin-bottom: 0;
      line-height: 1.5; // Web accessibility
    }
  }

  .blockquote,
  .blocktext {
    width: 100%;
  }

  .blockquote {
    flex-direction: column;
    margin: 0;

    p:not(.h1) {
      @include responsive-font-size($hero-article-p-font-size-sm, '', $hero-article-p-font-size-sm, '', $hero-article-p-font-size-lg);
      line-height: $line-height-article-body;
      margin-bottom: 18px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }

  @import 'background-colors';
}
