/**
 * Third party
 * Instagram
 */

.instagram-feed {
	display: none;

	.instagram-feed__container {
		display: flex;
		width: 100%;
		flex-wrap: wrap;

		a {
			position: relative;
			width: 25%;
			padding: 0 2px 2px 0;

			&:nth-child(4) {
				padding: 0;
			}

			&:hover {
				text-decoration: none;

				.instagram-feed__overlay {
					opacity: 1;
				}
			}

			img {
				width: 100%;
				height: auto;
			}

			.instagram-feed__overlay {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: calc(100% - 2px);
				width: calc(100% - 2px);
				opacity: 0;
				cursor: pointer;
				transition: $transition;
				background-color: rgba($black, 0.6);
			}

			.instagram-feed__text {
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				width: 100%;
				font-size: $small-details-font-size;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;

				svg {
					width: 20px;
					height: auto;
					margin-right: 15px;

					path {
						fill: $white;
					}
				}
			}
		}
	}
}
