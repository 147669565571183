.search-bar {
	width: 100%;
	margin-top: 80px;
	margin-bottom: 100px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
		margin-top: 40px;
	}

	form {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;

		.form-group {
			width: 100%;
		}

		input {
			border: none;
			border-bottom-style: solid;
			border-bottom-color: $black;
			border-bottom-width: 1px;
			background-color: transparent;
			color: $black;

			&::placeholder {
				color: $black;
			}
		}
	}

	.btn__search {
		z-index: 15;
		position: absolute;
		right: 0;
		bottom: 0;
		margin-bottom: 10px;
		background-color: transparent;

		svg {
			@include media-breakpoint-down(sm) {
				height: 20px;
			}
			path {
				fill: $color-primary;
			}
		}
	}
}
