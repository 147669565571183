.list__news {
  .list__news__item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-medium;

    &:first-child {
      padding-top: 5px;
    }

    &:nth-child(n + 2) {
      padding-top: 15px;
		}

    &:hover {
      h3,
      .h3 {
        color: $color-link;
      }
    }
  }

  .tags-container {
    margin-bottom: 10px;
  }

  h3,
  .h3 {
    font-size: calc(.0625em*var(--component-body-font-size)) !important;
    line-height: var(--font-secondary-line-height);
    font-weight: $font-weight-body;
    margin-bottom: 15px;
    transition: $transition;
  }
}

@include globalComponentColors($themes) {
  .list__news {
    .list__news__item {  
      &:hover {
        h3,
        .h3 {
          text-decoration: underline;
        }
      }
    }
  }
}
