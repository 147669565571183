.search-bar--container {
  padding-top: 60px;
  padding-bottom: 15px;

  @include media-breakpoint-down(sm) {
    padding-top: 40px;
  }

  .title {
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  p,
  .search-bar--small {
    margin-bottom: 15px;
  }

  p.introductory {
    @include responsive-font-size($hero-article-p-font-size-sm, "", $hero-article-p-font-size-sm, "", $hero-article-p-font-size-lg);
    line-height: $line-height-secondary;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  .search-bar--small {
    margin-top: 15px;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }
  }

  form:not(#search-form) .form-group input {
    background-color: $white;
  }
}