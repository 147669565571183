/**
* Titles introductory
*/

.title__introductory {
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: center;

  &[class*="bg--"] {
    margin: 0 -60px;
    padding: 15px 60px 60px 60px;
    width: calc(100% + 120px);

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      padding: 15px 15px 30px 15px;
      width: 100%
    }

    // Breadcrumb with hightlight
    section.bg--invert-bg-main {
      background-color: transparent !important;
      margin-bottom: 0;
    }
  }

  h1,
  .h1 {
    margin-bottom: 15px;

    // When pre-text is set
    strong {
      font-weight: $font-weight-heading;
    }
    
    span {
      font-family: $primary-font;
      font-weight: $font-weight-body;
    }
  }

  p {
    margin-bottom: 0;
    @include responsive-font-size($hero-article-p-font-size-sm, "", $hero-article-p-font-size-sm, "", $hero-article-p-font-size-lg);
    line-height: $line-height-secondary;
  }

  // Breadcrumb with hightlight
  section.bg--invert-bg-main {
    margin-bottom: 40px;
  }
}
