.card.card__business {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: $border-radius-component;

  @include media-breakpoint-down(sm) {
    position: relative;
    padding: 20px;
  }

  .card__business__text {
    display: flex;
    flex-direction: column;
    padding: 30px 50px 40px 30px;

    @include media-breakpoint-down(sm) {
      padding: 0;
      width: calc(100% - 75px);
    }

    p {
      margin-bottom: 0;
    }

    .rich-text {
      p,
      .btn {
        margin-bottom: 20px;
      }
    }

    p.subtitle {
      font-weight: $font-weight-heading;
    }


    .btn__link {
      margin-top: 15px;
    }

    .card__business__text__content {
      &:last-of-type {
        margin-bottom: auto;
      }

      p:last-of-type {
        margin-bottom: 10px;
      }

      .rich-text {
        margin-bottom: 0;
      }
    }
  }

  .card__business__image {
    height: auto;
    margin-right: 15px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 160px;
      min-width: 160px;
    }

    @include media-breakpoint-down(sm) {
      min-width: 80px;
      max-width: 80px;
      height: 100px;
      margin-right: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 220px;
      transition: $transition;

      @include media-breakpoint-down(md) {
        height: 100%;
      }
    }
  }

  .arrow {
    position: absolute;
    bottom: 15px;
    right: 25px;
    transition: all 0.2s ease-in-out;

    &:hover {
      right: 23px;
    }
  }

	.tags-container {
		margin-bottom: 10px;
	}
}

.col-lg-4 {
  .card__business {
    .card__business__text {
      padding: 30px 30px 30px 25px;

      @include media-breakpoint-down(sm) {
        padding: 30px;
      }
    }

    .card__business__image {
      padding-right: 0 !important;
    }
  }
}
