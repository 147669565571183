/**
 * Buttons
 * .btn__color-theme
 * Theme grayscale 
 * Add class .grayscale to body
 */

 // Grayscale on: to move toggle to the right
.grayscale--on {
  .btn__color-theme--grayscale .btn__color-theme__toggle {
    right: 0;
    transition: all 0.25s ease;
  }
}

// Grayscale filter: set on header, main and footer
// * Dont set class on body
.js-grayscale--filter {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.btn__color-theme--grayscale {
  .btn__color-theme__elements {
    align-items: center;
    gap: 2px;
  }

  .icon-color-mode {
    font-size: 16px;
  }
}
