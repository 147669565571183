/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Side boxes
 */

.spot-box {
  &.c-background,
  &[class*="bg--"],
  &[class*="article-element-bg--"] {
    background-color: var(--darkmode-800);

    p.h1 {
      color: var(--darkmode-hightlight-1-200);
    }
  }

  &.flex-center .imagery-text::before {
    background-color: var(--darkmode-50);
  }

  p.h1 {
    color: var(--darkmode-hightlight-1-200);
  }

  &.article-element-bg--0,
  &.article-element-bg--1,
  &.article-element-bg--2,
  &.article-element-bg--3,
  &.article-element-bg--4,
  &.article-element-bg--5 {  
    h2,
    p,
    .author,
    [class*="icon-"]  {
      color: var(--darkmode-50);
    }

    p.h1 {
      color: var(--darkmode-hightlight-1-200);
    }
  
    &.flex-center .imagery-text::before {
      background-color: var(--darkmode-50);
    }
  } 
}
