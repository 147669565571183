.list__icons ul,
ul.list__icons {
  @include list-unstyled();
  margin-bottom: 0;

  li {
    display: flex;
    // align-items: center;
    font-size: $component-font-size;
    line-height: $line-height-secondary;
    padding-bottom: 18px;
    margin-bottom: 0;

    &:last-of-type {
      padding-bottom: 0;
    }

    &.has-ul {
      display: flex;
      // flex-direction: column;
      flex-direction: row;

      div {
        display: flex;
      }

      ul {
        // margin-top: 18px;
        // margin-left: 35px;
        margin-top: 0;
        margin-left: 0;
      }
    }

    span[class*="icon-"],
    svg {
      margin-right: 15px;
    }

    span[class*="icon-"] {
      font-size: inherit;

      &::before {
        font-size: em(25); // icon size
      }
    }

    svg {
      &.svg-date,
      &.svg-time,
      &.svg-location {
        path {
          fill: $color-icons;
        }
      }
    
      &.svg-date {
        rect {
          fill: $color-icons;
        }
      }
    
      &.svg-time {
        path {
          stroke: $color-icons;
        }
    
        circle {
          stroke: $color-icons;
        }
      }
    }
  }
}
