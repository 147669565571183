/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Memberpage
 */

.member-message {
  background-color: var(--darkmode-hightlight-2-200);

  p {
    color: var(--darkmode--900) !important;
  }
}
