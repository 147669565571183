/**
 * Font Face
 */

// Icons
@font-face {
	font-family: 'icomoon';
	src: url('/assets/fonts/icomoon.eot?rfzp4p');
	src: url('/assets/fonts/icomoon.eot?rfzp4p#iefix') format('embedded-opentype'), url('/assets/fonts/icomoon.ttf?rfzp4p') format('truetype'), url('/assets/fonts/icomoon.woff?rfzp4p') format('woff'), url('/assets/fonts/icomoon.svg?rfzp4p#icomoon') format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

// Roboto
@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

// Playfair Display
@font-face {
	font-family: 'Playfair Display';
	src: url('/assets/fonts/Playfair-display/PlayfairDisplay-Regular.ttf') format('truetype');
}

// MaxLF
@font-face {
	font-family: 'MaxLF';
	src: url('/assets/fonts/ikast-brande/MaxLF-Regular/MxRgLf__.ttf') format('truetype');
  font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'MaxLF';
	src: url('/assets/fonts/ikast-brande/MaxLF-SemiBold/MxSbLf__.ttf') format('truetype');
  font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'MaxLF';
	src: url('/assets/fonts/ikast-brande/MaxLF-SemiBold/MxSbLf__.ttf') format('truetype');
  font-weight: 600;
	font-style: normal;
}

// Open Sans
@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

// Exo2
@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Exo2';
	src: url('/assets/fonts/Exo2/Exo2-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

// Fira Sans
@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/FiraSans/FiraSans-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Montserrat
@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Metropolis
@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('/assets/fonts/Metropolis/Metropolis-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Barlow
@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow';
	src: url('/assets/fonts/Barlow/Barlow-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Poppins
@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// ID00 Sans
@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Thin.otf') format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Thin-Italic.otf') format("opentype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Light.otf') format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Light-Italic.otf') format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans.otf') format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Italic.otf') format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-DemiBold.otf') format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-DemiBold-Italic.otf') format("opentype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Bold.otf') format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-BoldItalic.otf') format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-ExtraBold.otf') format("opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-ExtraBold-Italic.otf') format("opentype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Heavy.otf') format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Sans';
	src: url('/assets/fonts/ID00-sans/ID00-Sans-Heavy-Italic.otf') format("opentype");
	font-weight: 900;
	font-style: italic;
}

// ID00 Serif
@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Light.otf') format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Light-Italic.otf') format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Italic.otf') format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Sans-Italic.otf') format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-DemiBold.otf') format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-DemiBold-Italic.otf') format("opentype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Bold.otf') format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-BoldItalic.otf') format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-ExtraBold-Italic.otf') format("opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-ExtraBold.otf') format("opentype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Heavy.otf') format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'ID00 Serif';
	src: url('/assets/fonts/ID00-serif/ID00-Serif-Heavy-Italic.otf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Merriweather
@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('/assets/fonts/Merriweather/Merriweather-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Rubik
@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/assets/fonts/Rubik/Rubik-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Alverata
@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Light.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-LightItalic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Regular.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Italic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Medium.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-MediumItalic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Semibold.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-SemiboldItalic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Bold.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-BoldItalic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-Black.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Alverata';
  src: url('/assets/fonts/Alverata/Alverata-BlackItalic.woff2') format('woff2'),
       url('/assets/fonts/Alverata/Alverata-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

// Resolve

/**
* @license
* MyFonts Webfont Build ID 690550
*
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are
* explicitly restricted from using the Licensed Webfonts(s).
*
* You may obtain a valid license from one of MyFonts official sites.
* http://www.fonts.com
* http://www.myfonts.com
* http://www.linotype.com
*
*/

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Thin.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Thin-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Thin-Italic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Ultralight.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Ultralight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Ultralight-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Ultralight-Italic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Light.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Light-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Light-Italic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Regular.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Regular-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Regular-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Medium.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Medium-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Medium-Italic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Semibold.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Semibold-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Semibold-Italic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Bold.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Bold-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Bold-Italic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Black.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Black.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve';
  src: url('/assets/fonts/Resolve/Resolve-Black-Italic.woff2') format('woff2'),
       url('/assets/fonts/Resolve/Resolve-Black-Italic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

// Resolve Nrw
@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveThinNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveThinNrw.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveThinNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveThinNrwIta.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveUltralightNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveUltralightNrw.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveUltralightNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveUltralightNrwIta.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveLightNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveLightNrw.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveLightNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveLightNrwIta.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveRegularNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveRegularNrw.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveRegularNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveRegularNrwIta.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveSemiboldNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveSemiboldNrw.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveSemiboldNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveSemiboldNrwIta.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveBoldNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveBoldNrw.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveBoldNrwIta.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveBoldNrwIta.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/Resolve-Black.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/Resolve-Black.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Resolve Nrw';
  src: url('/assets/fonts/Resolve-Nrw/ResolveBlackNrw.woff2') format('woff2'),
       url('/assets/fonts/Resolve-Nrw/ResolveBlackNrw.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

// Icecreme slant

/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at https://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2023 Fontspring
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Positype
 * License URL: https://www.fontspring.com/licenses/positype/webfont
 *
 */

 @font-face {
  font-family: 'icecream-slant';
  src: url('/assets/fonts/icecreme-slant/icecream-slant-webfont.woff2') format('woff2'),
       url('/assets/fonts/icecreme-slant/icecream-slant-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'DM Sans';
	src: url('/assets/fonts/dm-sans/DMSans-VariableFont_opsz,wght.ttf') format('truetype');
	font-style: normal;
}
@font-face {
	font-family: 'DM Sans';
	src: url('/assets/fonts/dm-sans/DMSans-Italic-VariableFont_opsz,wght.ttf') format('truetype');
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('/assets/fonts/raleway/Raleway-VariableFont_wght.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('/assets/fonts/raleway/Raleway-Italic-VariableFont_wght') format('truetype');
	font-style: italic;
}

@font-face {
	font-family: 'Bricolage Grotesque';
	src: url('/assets/fonts/Bricolage-Grotesque/BricolageGrotesque-VariableFont_opsz,wdth,wght.ttf') format('truetype');
}
@font-face {
	font-family: 'Sora';
	src: url('/assets/fonts/sora/Sora-VariableFont_wght.ttf') format('truetype')
}

// Tropiline
@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Light.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Light.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-LightItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-LightItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Light.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-LightItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-LightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Semibold.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-SemiboldItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Bold.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-BoldItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-ExtraBold.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-ExtraBoldItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-Black.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Tropiline';
  	src: url('/assets/fonts/Tropiline/Tropiline-BlackItalic.ttf') format('truetype'),
       url('/assets/fonts/Tropiline/Tropiline-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Caveat (google font)
@font-face {
	font-family: 'Caveat';
	src: url('/assets/fonts/Caveat/Caveat-VariableFont_wght.ttf') format('truetype')
}

// Flama 
@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Thin.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Thin.woff') format('woff');
	font-weight: 100;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-ThinItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Ultralight.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Ultralight.woff') format('woff');
	font-weight: 200;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-UltralightItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-UltralightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Light.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Light.woff') format('woff');
	font-weight: 300;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-LightItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Book.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Book.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-BookItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-BookItalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Basic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Basic.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-BasicItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-BasicItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Medium.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Medium.woff') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-MediumItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-MediumItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Semibold.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Semibold.woff') format('woff');
	font-weight: 700;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-SemiboldItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-SemiboldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Bold.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Bold.woff') format('woff');
	font-weight: 800;
}
@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-BoldItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-BoldItalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-Black.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-Black.woff') format('woff');
	font-weight: 900;
}

@font-face {
	font-family: 'Flama';
	src: url('/assets/fonts/Flama/Flama-BlackItalic.woff2') format('woff2'),
	url('/assets/fonts/Flama/Flama-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

// Inter (Google font)
@font-face {
	font-family: 'Inter';
	src: url('/assets/fonts/Inter/Inter-VariableFont_opsz,wght.ttf') format('truetype');
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('/assets/fonts/Inter/Inter-Italic-VariableFont_opsz,wght.ttf') format('truetype');
	font-style: italic;
}

// Lato (google font)
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}