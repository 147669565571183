/**
 * Utils Page Style
 * These classes are only for display purposes
 */

/**
	* Padding top
	*/
.utils-pt {
	padding-top: 200px;

	@include media-breakpoint-down(md) {
		padding-top: 100px;
	}
}

/**
	* Margin bottom
	*/
.utils-mb {
	margin-bottom: 60px;
}

/**
	* Margin bottom small
	*/
.utils-mb--small {
	margin-bottom: 20px;
}

/**
	* color
	*/
.colors {
	display: flex;
	flex-wrap: wrap;

	.color {
		span {
			font-weight: 600;
			text-align: center;
		}

    &.color-levels {
      background: $white; 
      margin-left: 0;

      span {
        margin-bottom: 20px;
      }

      .color-levels-1,
      .color-levels-2,
      .color-levels-3 {
        position: relative; 
        margin: 0;

        span {
          margin: 0;
        }

        span:not(.span-bg-1):not(.span-bg-2):not(.span-bg-3) {
          z-index: 1;
        }
      }

      .span-bg-1,
      .span-bg-2,
      .span-bg-3 {
        position: absolute; 
        top: 0; 
        left: 0;
        width: 100%; 
        height: 100%; 
      }

      .span-bg-2 {
        opacity: .5;
      }

      .span-bg-3 {
        opacity: .2;
      }
    }
	}

	.primary {
		background-color: $color-primary;
		color: $color-primary-text;
	}

	.secondary {
		background-color: $color-secondary;
		color: $color-secondary-text;
	}

  .color-icon {
		border: 1px solid $black;
		color: $color-icons;
	}

  .color-arrow {
		border: 1px solid $black;
		color: $color-arrows;
	}

  .color-btn-primary {
		background-color: var(--color-btn-primary);
		color: var(--color-btn-primary-text);
	}

	.primary-decorative {
		background-color: $color-primary-decorative;
		color: $color-primary-decorative-title;
	}

	.secondary-decorative {
		background-color: $color-secondary-decorative;
		color: $color-secondary-decorative-title;
	}
}

.color {
	display: flex;
	justify-content: center;
  flex-direction: column;
	align-items: center;
  min-width: 100px;
  padding: 20px;
	min-height: 100px;
	margin-right: 20px;
	margin-bottom: 20px;

  .btn {
    margin-top: 20px;

    &::after {
      display: none;
    }
  }
}

.color-opacity {
  position: relative;
	width: 300px;
	height: 300px;
  overflow: hidden;

  span {
    z-index: 2;
    color: var(--color-overlay-text);
  }

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
