.search-bar {
	width: 100%;
	margin-top: 80px;
  margin-bottom: 100px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
		margin-top: 40px;
	}

	form {
		display: flex;
		flex-direction: row;
    position: relative;
    margin: 0;

    .form-group {
      position: relative;
      border: none;
      height: 70px;
      width: 100%;

      @include media-breakpoint-down(md) {
        height: auto;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    label,
    input {
      @include responsive-font-size(em(24px), "", em(40px), "", em(40px));
      font-weight: 500;
      letter-spacing: 0;
    }

    label {
      position: absolute;
      bottom: 2px;
      z-index: 0;
      margin-bottom: 0;
      padding-left: 15px;
      color: rgba($black, .4);
      opacity: 1;
      transition: $transition;

      @include media-breakpoint-down(sm) {
        padding-bottom: 5px;
      }
    }

    input {
      z-index: 10;
      padding: 0;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 15px;
      height: 80px;
      border: none;
			border-bottom-style: solid;
			border-bottom-color: $black;
			border-bottom-width: 1px;
			background-color: transparent;
      color: $black;
      border-radius: unset;

      @include media-breakpoint-down(md) {
        padding-bottom: 5px;
        height: 75px;
      }

      @include media-breakpoint-down(sm) {
        height: 45px;
        padding-top: 7px;
      }
      
      &::placeholder {
				color: $black;
			}

      &:required {
        // Removes red border when invalid IE, firefox
        box-shadow: none;
      }

      &:focus + label,
      &:visited + label,
      &:valid + label {
        color: rgba($black, .4);
        opacity: 0;
        display: none;
      }
    }
	}

	.btn__search {
		z-index: 15;
		position: absolute;
		right: 0;
		bottom: 0;
    margin-bottom: 4px;
    margin-right: 10px;
    padding: 5px;
    background-color: transparent;
    
		@include media-breakpoint-down(sm) {
      display: flex;
      margin-bottom: 5px;
      padding: 5px;
		}

		svg {
			@include media-breakpoint-down(sm) {
        height: 20px;
        width: 20px;
      }
      
			path {
				fill: $color-primary;
			}
		}
	}
}

@include globalComponentColors($themes) {
	.search-bar {
		form {
			input {
				border-bottom-color: themed('text');
				color: themed('text');

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					-webkit-text-fill-color: themed('text');
					-webkit-box-shadow: 0 0 0 1000px themed('background') inset !important;
				}

				&:focus + label,
				&:visited + label,
				&:valid + label {
					color: themed('text');
				}

				&::placeholder {
					color: themed('text');
				}
			}

			label {
				color: themed('text');
			}
		}

		.btn__search {
			svg {
				path {
					fill: themed('text');
				}
			}
		}
	}
}
