// body
body {
  overflow-x: hidden; // Do not remove: use class .overflow--unset if you want to overide
	background-color: $color-body;
	font-size: var(--base-font-size);
	position: relative;

	p,
	a {
		font-size: 1em; // Base font size - don't change this
	}

	&.burger-open {
		position: fixed;
		overflow: hidden;
		width: 100%;
	}
}

// Fix position class
.position-fixed {
	position: fixed;
}

// Focus visible class
*:focus-visible { 
	outline-offset: 1px;
	box-shadow: inset 0 0 0 1px $white;
}
