/**
 * Radio
 */

.form-radio {
	display: flex;
	flex-direction: column;

	.radio {
		position: relative;
		display: inline-block;
		margin-bottom: 15px;
		padding-left: 40px;
	}

  .span-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .tag {
      margin-bottom: 0 !important;
    }
  }

  .span-label {
    font-weight: $font-weight-heading;

    .tag.tag--bg-color {
      margin-left: 5px;
    }
  }

	input[type="radio"] {
		cursor: pointer;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 25px;
		height: 25px;

		&:checked + .radio__indicator {
			background-color: $color-primary;
			background-image: $color-primary;

			&:after {
				opacity: 1;
				transform: scale(1);
				transition: $transition;
			}
		}
	}

	.radio__indicator {
		position: absolute;
		top: 0;
		left: 0;
		width: 25px;
		height: 25px;
		border-radius: 15px;
		background-color: $white;
		border: 1px solid $grey-dark;
		transition: $transition;
		cursor: pointer;

		&:after {
			content: "";
			position: absolute;
			display: flex;
			top: 7px;
			left: 7px;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: var(--color-primary-text);
			opacity: 0;
			transform: scale(0);
		}
	}
}
