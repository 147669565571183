/**
 * Headings
 *
 */

$headings-margin-bottom: 10px;
$headings-font-family: $primary-font !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.2 !default;
$headings-color: $black !default;

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
h2.h2--large {
	margin-top: 0;
	margin-bottom: $headings-margin-bottom;
	font-family: $headings-font-family;
	font-weight: $font-weight-heading;
	line-height: $headings-line-height;
	color: $headings-color;
  line-height: $line-height-primary;
  hyphens: var(--hyphens);
}

h1,
.h1,
h2.h2--large,
.title.title--large h2 {
	@include responsive-font-size($h1-font-size-sm, "", $h1-font-size-sm, "", $h1-font-size-lg);
}

h2,
.h2 {
	@include responsive-font-size($h2-font-size-sm, "", $h2-font-size-sm, "", $h2-font-size-lg);
}

.h2--large {
  @include responsive-font-size(calc($h2-font-size-sm*1.5), "", calc($h2-font-size-sm*1.5), "", calc($h2-font-size-lg*1.5));
}

h3,
.h3 {
	@include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
}

h4,
.h4 {
	@include responsive-font-size($h4-font-size-sm, "", $h4-font-size-sm, "", $h4-font-size-lg);
}

h5,
.h5 {
  @include responsive-font-size($h5-font-size-sm, "", $h5-font-size-sm, "", $h5-font-size-lg);
}
