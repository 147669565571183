.burger-menu {
	.search-form {
		position: relative;
		margin: 0;
	}

	.search-form__input {
		height: 40px;
		width: 40px;
		border-style: none;
		padding-right: 30px;
		padding-left: 10px;
		outline: none;
		border-radius: 25px;
		transition: all 0.5s ease-in-out;
		background-color: $grey-light;

		&::placeholder {
			visibility: hidden;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	// button
	.search-form__btn {
		width: 40px;
		height: 40px;
		border-style: none;
		cursor: pointer;
		border-radius: 50%;
		position: absolute;
		background-color: $grey-light;
		pointer-events: painted;

		@include media-breakpoint-down(sm) {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&:focus {
				outline: 2px solid $color-secondary;
			}
		}

		&:disabled {
			color: black;
		}

		.icon-search {
			font-size: 22px;
			color: $color-icons;
		}

		&:focus {
			outline: 2px solid $color-secondary;
		}
	}

	.submit-input {
		visibility: hidden;

		@include media-breakpoint-down(sm) {
			display: none
		}
	}

	.expand-input {
		visibility: visible;
	}

	.search-form.active {

		.submit-input {
			visibility: visible;
		}

		.expand-input {
			visibility: hidden;
		}

		.search-form__input {
			width: 300px;
			padding-left: 15px;
			transition: width 0.5s ease-in-out, padding 0.25s ease;

			&::placeholder {
				visibility: visible;
			}
		}
	}

	.search-container-mobile {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 15px;
		display: none;

		.search-form--mobile {
			position: relative;
			width: 100%;
		}

		.search-input--mobile {
			box-sizing: border-box;
			width: 100%;
			height: 50px;
			border-style: none;
			padding: 0 30px 0 15px;
			outline: none;
			border-radius: 25px;
			background-color: $grey-light;
		}

		.search-btn--mobile {
			width: 50px;
			height: 50px;
			border-style: none;
			cursor: pointer;
			border-radius: 50%;
			position: absolute;
			right: 0;
			background-color: $grey-light;
			pointer-events: painted;

			.icon-search {
				font-size: 22px;
				color: $color-icons;
			}
		}

		@include media-breakpoint-up(sm) {
			display: none;
		}
	}

	.search-container-mobile.active {
		display: flex;
		animation: delayedShow 0.5s ease forwards;
	}

	@keyframes delayedShow {
		0% {
			opacity: 0;
		}

		20% {
			opacity: 0;
		}

		100% {
			opacity: 100;
		}
	}
}
