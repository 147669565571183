.dark-theme {
	.header--left-aligned {
    [class*="bg-"], 
    .mobile-actions-searchbar-container {
			background-color: var(--darkmode-800) !important;

			&.header--left-aligned-top-bar {
				border-bottom: 1px solid var(--darkmode-600);

				@include media-breakpoint-up(lg){
					border-bottom: none;
				}
      }
    }

    .bg--white, 
    .bg--body, 
    .link-container {
      background-color: var(--darkmode-900) !important;
    }

		.sidemenu-list-item:hover {
			.list-item-content-heading {
				color: var(--darkmode-hightlight-1-200) !important
			}
		}

		.list-item-content, 
    .desktop-secondary-list-item a, 
    .desktop-searchbar-input {
			color: var(--darkmode-50) !important;

			&::placeholder {
				color: var(--darkmode-200);
			}

			&::after {
				background-color: var(--darkmode-hightlight-1-200) !important;
			}

			.desktop-secondary-list-item:after {
				background-color: var(--darkmode-50);
			}
		}

		.burger-icon-elmnt-dash, 
    .burger-cross-icon-left, 
    .burger-cross-icon-right, 
    .mobile-actions-search-icon::after {
			background-color: var(--darkmode-hightlight-1-200) !important;
		}

		.list-item--cta {
			a {
				color: var(--darkmode-50) !important;
			}

      .nav-link {
        background-color: var(--darkmode-hightlight-1-200) !important;
        color: var(--darkmode-900) !important;
        border-color: var(--darkmode-hightlight-1-200) !important;

        &:hover,
        &:focus {
          background-color: transparent !important;
          color: var(--darkmode-50) !important;

          [class*="icon-"]::before {
            color: var(--darkmode-50) !important;
          }
        }

        [class*="icon-"]::before {
          color: var(--darkmode-900) !important;
        }
      }
		}

		.desktop-actions-searchbar-submit, 
    .desktop-actions-searchbar-btn, 
    .mobile-actions-search-icon svg {
			color: var(--darkmode-hightlight-1-200) !important;
		}

		.desktop-searchbar-input {
			border-color: var(--darkmode-hightlight-1-200) !important;
		}

		.sidemenu-list-item:not(.list-item--cta) {
			border-bottom: 1px solid var(--darkmode-600);

			@include media-breakpoint-up(lg){
				border-bottom: none;
			}
		}

		.mobile-actions-search-icon {
			border-right: 1px solid var(--darkmode-hightlight-1-200);
		}

		.reverse .mobile-actions-search-icon {
			border-right: none;
		}

		.list-item-megamenu-toggle-icon {
			border-color: var(--darkmode-hightlight-1-200)
		}

		.link-container .link-container__link:hover {
			color: var(--darkmode-hightlight-1-200)
		}

		.link-container .link-container__link {
			color: var(--darkmode-50);
		}
	}
}
