$bulb-color: rgb(255, 234, 175);
$bulb-color-2: rgb(253, 251, 243);
$bulb-color-3: rgb(255, 250, 236);
$bulb-boxshadow-1: rgba(255, 235, 180, 0.8);
$bulb-boxshadow-2: rgba(253, 251, 243, 0.8);
$bulb-boxshadow-3: rgba(255, 251, 241, 0.8);
$bulb-off: rgba(253, 244, 220, 0.8);

@keyframes flash-1 { 
  0%, 100% { background: $bulb-color;
  box-shadow: 0px 0px 5px 1px $bulb-boxshadow-1;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 5px 4px $bulb-boxshadow-1;}
}
@keyframes flash-1-mobile { 
  0%, 100% { background: $bulb-color;
  box-shadow: 0px 0px 3px 1px $bulb-boxshadow-1;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 3px 2px $bulb-boxshadow-1;}
}
@keyframes flash-2 { 
  0%, 100% { background: $bulb-color-2;
  box-shadow: 0px 0px 6px 2px $bulb-boxshadow-2;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 6px 6px $bulb-boxshadow-2;}
}
@keyframes flash-2-mobile { 
  0%, 100% { background: $bulb-color-2;
  box-shadow: 0px 0px 3px 1px $bulb-boxshadow-2;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 3px 3px $bulb-boxshadow-2;}
}
@keyframes flash-3 { 
  0%, 100% { background: $bulb-color-3;
  box-shadow: 0px 0px 8px 1px $bulb-boxshadow-3;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 8px 8px $bulb-boxshadow-3;}
}
@keyframes flash-3-mobile { 
  0%, 100% { background: $bulb-color-3;
  box-shadow: 0px 0px 3px 1px $bulb-boxshadow-3;} 
  50% { background: $bulb-off;
  box-shadow: 0px 0px 3px 4px $bulb-boxshadow-3;}
}

[class^="christmas-lights"] {
  pointer-events: none;
}

.christmas-lights-var-1__left {
  position: absolute;
  width: 420px;
  top: 0px;
  left: 0;

  @include media-breakpoint-down(md){
    width: 300px;
  }

  @include media-breakpoint-down(sm){
    width: 170px;
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #ffcc5e;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-1;

    @include media-breakpoint-down(sm){
      height: 2px;
      width: 2px;
      animation-name: flash-1-mobile;
    }
  }
  .light-1 {
    top: 5.5%;
    left: 1.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 39%;
    left: 3%;
    animation-duration: 1.7s;
  }
  .light-3 {
    top: 66%;
    left: 3%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 5%;
    left: 18%;
    animation-duration: 2s;
  }
  .light-5 {
    top: 27%;
    left: 12%;
    animation-duration: 4.7s;
  }
  .light-6 {
    top: 9%;
    left: 26.3%;
    animation-duration: 3s;
  }
  .light-7 {
    top: 22%;
    left: 35.8%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 4.8%;
    left: 51.2%;
    animation-duration: 1.4s;
  }
  .light-9 {
    top: 20%;
    left: 68.4%;
    animation-duration: 4.4s;
  }
  .light-10 {
    top: 8.2%;
    left: 84.2%;
    animation-duration: 1.9s;
  }
  .light-11 {
    top: 7%;
    left: 37.4%;
    animation-duration: 3.9s;
  }
} 



.christmas-lights-var-1__right {
  position: absolute;
  width: 420px;
  right: 0;
  top: 0px;

  @include media-breakpoint-down(md){
    width: 300px;
  }
  @include media-breakpoint-down(sm){
    width: 170px;
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #ffcc5e;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-1;

    @include media-breakpoint-down(sm){
      height: 2px;
      width: 2px;
      animation-name: flash-1-mobile;
    }
  }
  .light-1 {
    top: 5.5%;
    right: 1.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 39%;
    right: 3%;
    animation-duration: 1.7s;
  }
  .light-3 {
    top: 66%;
    right: 3%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 5%;
    right: 18%;
    animation-duration: 2.5s;
  }
  .light-5 {
    top: 27%;
    right: 12%;
    animation-duration: 3.7s;
  }
  .light-6 {
    top: 9%;
    right: 26.3%;
    animation-duration: 2s;
  }
  .light-7 {
    top: 22%;
    right: 35.3%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 4.8%;
    right: 51.2%;
    animation-duration: 2.4s;
  }
  .light-9 {
    top: 20%;
    right: 68.4%;
    animation-duration: 3.4s;
  }
  .light-10 {
    top: 8.2%;
    right: 84.2%;
    animation-duration: 1.7s;
  }
  .light-11 {
    top: 7%;
    right: 37.4%;
    animation-duration: 2.9s;
  }
}

.christmas-lights-var-2__left {
  position: absolute;
  width: 450px;
  top: 0px;
  left: 0;

  @include media-breakpoint-down(md){
    width: 400px;
  }

  @include media-breakpoint-down(sm){
    width: 170px;
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #ffcc5e;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-2;

    @include media-breakpoint-down(sm){
      height: 2px;
      width: 2px;
      animation-name: flash-2-mobile;
    }
  }

  .light-1 {
    top: 76.5%;
    left: 72.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 37%;
    left: 4.5%;
    animation-duration: 1.7s;
  }
  .light-3 {
    top: 77%;
    left: 23%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 46%;
    left: 30%;
    animation-duration: 2.5s;
  }
  .light-5 {
    top: 18%;
    left: 83.8%;
    animation-duration: 3.7s;
  }
  .light-6 {
    top: 66%;
    left: 32.3%;
    animation-duration: 2s;
  }
  .light-7 {
    top: 37%;
    left: 54.3%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 18%;
    left: 33.8%;
    animation-duration: 2.4s;
  }
  .light-9 {
    top: 58%;
    left: 55.4%;
    animation-duration: 3.4s;
  }
  .light-10 {
    top: 46.2%;
    left: 80.2%;
    animation-duration: 1.7s;
  }
  .light-11 {
    top: 54%;
    left: 96.4%;
    animation-duration: 2.9s;
  }
}

.christmas-lights-var-2__right {
  position: absolute;
  width: 450px;
  right: 20px;
  top: 0px;

  @include media-breakpoint-down(md){
    width: 400px;
    right: 0;
  }

  @include media-breakpoint-down(sm){
    width: 170px;
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #ffcc5e;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-2;

    @include media-breakpoint-down(sm){
      height: 2px;
      width: 2px;
      animation-name: flash-2-mobile;
    }
  }

  .light-1 {
    top: 76.5%;
    left: 72.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 37%;
    left: 4.5%;
    animation-duration: 1.7s;
  }
  .light-3 {
    top: 77%;
    left: 23%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 46%;
    left: 30%;
    animation-duration: 2.5s;
  }
  .light-5 {
    top: 18%;
    left: 83.8%;
    animation-duration: 3.7s;
  }
  .light-6 {
    top: 66%;
    left: 32.3%;
    animation-duration: 2s;
  }
  .light-7 {
    top: 37%;
    left: 54.3%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 18%;
    left: 33.8%;
    animation-duration: 2.4s;
  }
  .light-9 {
    top: 58%;
    left: 55.4%;
    animation-duration: 3.4s;
  }
  .light-10 {
    top: 46.2%;
    left: 80.2%;
    animation-duration: 1.7s;
  }
  .light-11 {
    top: 54%;
    left: 96.4%;
    animation-duration: 2.9s;
  }
}

.christmas-lights-var-3__left {
  position: absolute;
  width: 290px;
  top: 0px;
  left: 0;

  @include media-breakpoint-down(md){
    width: 290px;
  }

  @include media-breakpoint-down(sm){
    width: 150px;
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #fffce8;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-3;

    @include media-breakpoint-down(sm){
      height: 2px;
      width: 2px;
      animation-name: flash-3-mobile;
    }
  }

  .light-1 {
    top: 37.5%;
    left: 46.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 33%;
    left: 6%;
    animation-duration: 2.7s;
  }
  .light-3 {
    top: 66%;
    left: 3%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 73%;
    left: 18%;
    animation-duration: 2s;
  }
  .light-5 {
    top: 27%;
    left: 12%;
    animation-duration: 4.7s;
  }
  .light-6 {
    top: 9%;
    left: 26.3%;
    animation-duration: 3s;
  }
  .light-7 {
    top: 38%;
    left: 78.8%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 4.8%;
    left: 51.2%;
    animation-duration: 2.1s;
  }
  .light-9 {
    top: 20%;
    left: 68.4%;
    animation-duration: 4.4s;
  }
  .light-10 {
    top: 8.2%;
    left: 84.2%;
    animation-duration: 1.9s;
  }
  .light-11 {
    top: 7%;
    left: 37.4%;
    animation-duration: 3.9s;
  }
}

.christmas-lights-var-3__right {
  position: absolute;
  width: 290px;
  right: 0;
  top: 0px;

  @include media-breakpoint-down(md){
    width: 290px;
  }

  @include media-breakpoint-down(sm) {
    width: 150px;

    @keyframes flash-3 { 
      0%, 100% { background: $bulb-color;
      box-shadow: 0px 0px 3px 1px $bulb-boxshadow-3;} 
      50% { background: $bulb-off;
      box-shadow: 0px 0px 3px 4px $bulb-boxshadow-3;}
    }
  }

  .light {
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #fffce8;
    border-radius: 50%;
    box-shadow: 0px 3px 3px $bulb-color;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    animation-name: flash-3;

    @include media-breakpoint-down(sm) {
      height: 2px;
      width: 2px;
    }
  }

  .light-1 {
    top: 37.5%;
    right: 46.8%;
    animation-duration: 2s;
  }
  .light-2 {
    top: 33%;
    right: 6%;
    animation-duration: 2.7s;
  }
  .light-3 {
    top: 66%;
    right: 3%;
    animation-duration: 2.3s;
  }
  .light-4 {
    top: 73%;
    right: 18%;
    animation-duration: 2s;
  }
  .light-5 {
    top: 27%;
    right: 12%;
    animation-duration: 4.7s;
  }
  .light-6 {
    top: 9%;
    right: 26.3%;
    animation-duration: 3s;
  }
  .light-7 {
    top: 38%;
    right: 78.8%;
    animation-duration: 1.9s;
  }
  .light-8 {
    top: 4.8%;
    right: 51.2%;
    animation-duration: 1.4s;
  }
  .light-9 {
    top: 20%;
    right: 68.4%;
    animation-duration: 4.4s;
  }
  .light-10 {
    top: 8.2%;
    right: 84.2%;
    animation-duration: 1.9s;
  }
  .light-11 {
    top: 7%;
    right: 37.4%;
    animation-duration: 3.9s;
  }
}

.hero,
.hero__multi-boxes {
  &.has-christmas-lights {
    padding-top: 150px !important;
    
    @include media-breakpoint-down(sm) {
      padding-top: 100px !important;
    }
  }
}

.hero__multi-boxes {
  &.has-christmas-lights {
    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
    }
  }
}

.hero__search-bar {
  &.has-christmas-lights {
    padding-bottom: 150px;
    
    @include media-breakpoint-down(sm) {
      padding-bottom: 100px;
    }
  }
}

// .banner__cta.padding {
//   .christmas-lights-var-1__left, .christmas-lights-var-2__left, .christmas-lights-var-3__left {
//     left: 13px
//   }
//   .christmas-lights-var-1__right, .christmas-lights-var-2__right, .christmas-lights-var-3__right {
//     right: 13px
//   }
// }