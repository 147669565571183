/*
** Global variables 
** Colors
*/

/* Primary colors */
$color-primary: var(--color-primary);
$color-primary-text: var(--color-primary-text);
$color-secondary: var(--color-secondary);
$color-secondary-text: var(--color-secondary-text);
$color-primary-decorative: var(--color-primary-decorative);
$color-primary-decorative-title: var(--color-primary-decorative-text);
$color-secondary-decorative: var(--color-secondary-decorative);
$color-secondary-decorative-title: var(--color-secondary-decorative-text);

/* Element colors */
$color-icons: var(--color-icons);
$color-arrows: var(--color-arrows);
$color-element: var(--color-element);
$color-link: var(--color-link);
$color-tag: var(--color-tag);
$color-tag--system: var(--color-tag--system);
$color-tag-bg: var(--color-tag-bg);
$color-tag-bg-text: var(--color-tag-bg-text);
$color-highlight-bg: $color-primary;
$color-highlight-text: $color-primary-text;
$color-border-top--article-components-bg: var(--color-border-top--article-components-bg);
$color-border-top--article-components-text: var(--color-border-top--article-components-text);

/* Basic colors */
$white: var(--color-white);
$black: var(--color-black);
$grey-light: var(--color-grey-light); //- For backgrounds
$grey-medium: var(--color-grey-medium); //- For borders, outlines, dividers
$grey-dark: var(--color-grey-dark); //- For text: test on body an grey light backgrounds
$color-body: var(--color-body);
$color-body-text: var(--color-body-text);
$success-green: var(--color-success);
$error-red: var(--color-danger);
$warning-orange: var(--color-warning);

//Background colors
$hover-bg-color: #f6f6f6;
$background-tooltip: $color-primary;
$header-search-bar-background-color: $color-primary;
$article-element-bg--default: var(--article-element-bg--default);
$bg--white-color: $white;
$bg-section--white-color: var(--section-white-bg);

//Divider color
$divider-color: $grey-medium;

/* Overlay/gradient colors */
$primary-gradient: linear-gradient(to bottom, rgba(var(--color-primary), 0) 0%, rgba(var(--color-primary), 1) 100%);
