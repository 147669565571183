.btn__toggle {
  position: relative;
  height: 24px; // Web Accessibility
	width: 44px;
  padding: 0;
	border-radius: 15px;
	border: 1px solid $btn-primary-bg-color;
  background: $white;
	transition: ease-in 200ms;
	
	.btn__toggle__toggle {
    position: absolute;
    top: 2px;
    left: 2px;
		height: 18px;
		width: 18px;
		border-radius: 15px;
		background: $btn-primary-bg-color;
		transition: ease-in 200ms;
	}

	&.active {
		background: $btn-primary-bg-color;

		.btn__toggle__toggle {
			left: 21px;
			background: $btn-primary-text-color;
		}

    .btn__toggle__elements {
      [class*="icon"]::before {
        color: $btn-primary-text-color;
      }
    }
	}

  .btn__toggle__elements {
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-radius: 15px;

    [class*="icon"] {
      // font-size: 20px;
      font-size: 18px;
    }
  }
}
