.card__meeting {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  width: 100%;
  padding: 30px;
  border-radius: var(--sleeve-component-border-radius);

  @include media-breakpoint-down(sm) {
    min-height: auto;
  }

  &:hover {
    .arrow {
      right: 35px;
    }
  }

  .card__meeting__text {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
    }

    .tags-container {
      justify-content: space-between;
    }

    h3,
    .h3 {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  
  .arrow {
    position: absolute;
    bottom: 30px;
    right: 30px;
    transition: $transition;
  }
}
