/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Footers
 */

footer {
  border-top: 1px solid var(--darkmode-900);
  background-color: var(--darkmode-800) !important;

  .dropdown-toggle {
    color: var(--darkmode-50);

    &:after {
      @media only screen and (max-width: 991.98px) {
        border-color: var(--darkmode-hightlight-1-200) !important;
      }
    }
  }
  
  .footer__content {
    .rich-text p {
      color: var(--darkmode-50) !important;
    }

    .form-subscribe .form-check .checkbox {
      .checkbox__indicator {
        background: var(--darkmode-700);
        border-color: var(--darkmode-600);
  
        &:after {
          border-color: var(--darkmode-50);
        }
      }
  
      & input:checked ~ .checkbox__indicator {
        border-color: var(--darkmode-600);
        background: var(--darkmode-700);
      }
  
      & input:disabled ~ .checkbox__indicator {
        background: var(--darkmode-700);
  
        &:after {
          border-color: var(--darkmode-50);
        }
      }
    }

    .btn__primary {
      color: var(--darkmode-800);
      border-color: var(--darkmode-hightlight-1-200);
      background-color: var(--darkmode-hightlight-1-200);
    
      &:focus,
      &:hover {
        background: var(--darkmode-800);
        color: var(--darkmode-hightlight-1-200);
      }
    
      &:disabled {
        &:hover {
          color: var(--darkmode-800);
          background-color: var(--darkmode-hightlight-1-200);
        }
      }
    }

    .dropdown-settings .dropdown-settings__container {
      background-color: var(--darkmode-800);
      border-color: var(--darkmode-900);
    }
  }
}

// Footer link hightlights
.footer.hightlight-links--arrow {
  li a:not([class^="icon-"]),
  p a:not([class^="icon-"]) {
    color: var(--darkmode-50) !important;

    &:hover,
    &:focus {
      color: var(--darkmode-50) !important;
    }
  }
}
