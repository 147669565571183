// Read Speaker button - override styling
.service-menu__item {
	.rsbtn_toollist li {
		min-height: inherit !important;
		max-height: none !important;
	}

	.rsbtn {
    height: 45px;
		font-size: 16px; // needs fixed size
		padding-bottom: 0 !important;
		margin-bottom: 0px !important;
    margin-right: 10px;

		a {
			font-size: em(16px) !important; // needs fixed size
		}

		span {
			font-size: em(16px); // needs fixed size
		}

		.rsbtn_status_overlay {
			padding-left: 70px;

			.rsbtn_status {
				padding-left: 60px !important;
				font-size: $breadcrumb-font-size !important;
			}
		}

		&.toolsopen:not(.rsexpanded) {
			.rsbtn_toolpanel {
				display: none;
			}
		}

		&.rsexpanded {
			z-index: 10;
			width: 155px !important;

      &:after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        width: 100%;
        border-radius: 30px;
        background: $grey-light;
      }

      .rsbtn_play {
        height: 45px;
      }

			.rsbtn_exp {
				position: absolute;
				transform: translate(0, -50%);
				left: 40px;
				width: 120px;
        top: 50%;
        height: 45px;
			}

			.rsbtn_play .rsbtn_left .rsbtn_text:after {
				width: 160px;
			}

			.rsbtn_tooltoggle {
				display: flex !important;

				&:focus {
					outline: -webkit-focus-ring-color auto 1px !important;
				}
			}
		}

		&.rspaused,
		&.rsplaying,
		&.rsstopped {
			.rs-player-loadindicator {
				display: none !important;
			}
		}

		&.rsstopped {
			background: transparent !important;
			right: 0 !important;
			top: 0 !important;
			left: 0 !important;
		}

		&.zoom-tooltoggler {
			.rsbtn_tooltoggle {
				background: transparent !important;

				&:hover,
				&:focus {
					span.rsicn:before {
						color: $color-link;
					}
				}

				span.rsicn:before {
					color: $black;
				}
			}
		}

		.rs-player-loadindicator {
			background: transparent !important;
			height: 26px !important;
			right: 0 !important;
			left: 0 !important;
      top: 50% !important;
      transform: translateY(-50%);

			&::after {
				z-index: 0;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				background: $grey-dark;
				opacity: 0.5;
			}

			.rsbtn-loading {
				z-index: 1;
				background: $color-link !important;
			}
		}

		.rsbtn_exp {

			.rsbtn_stop.rsbtn_player_item,
			.rsbtn_rewind.rsbtn_player_item,
			.rsbtn_forward.rsbtn_player_item,
			.rsbtn_volume.rsbtn_player_item,
			.rsbtn_volume_container.rsbtn_dropdown,
			.rsbtn_speed.rsbtn_player_item {
				display: none;
			}

			.rsbtn_exp_inner {
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
        display: flex;
        align-items: center;
				height: auto !important;
				background: $grey-light !important;
				border: none !important;

				.rsbtn_player_item {
					border-left: none !important;

					span.rsicn {
						line-height: 26px;
					}
				}

				.rsbtn_pause,
				.rsbtn_closer {
					display: flex;
					justify-content: center;
          align-items: center;
          min-height: 26px;

					&:active,
					&:hover,
					&:focus {
						background: transparent !important;
					}

					&:focus {
						outline: -webkit-focus-ring-color auto 1px !important;

						.rsicn::before {
							color: #000 !important;
						}
					}
				}

				.rsbtn_pause {

					&:active,
					&:hover,
					&:focus {
						background: transparent !important;

						span.rsicn {
							color: #288144 !important;
						}
					}

					span.rsicn {
						color: inherit !important;

            &::before {
              line-height: 26px;
            }
					}
				}
			}
		}

		.rsbtn_tooltoggle {
			display: none !important;
			border: unset !important;
			left: 0 !important;
			padding-right: 1px !important;
			height: 26px !important;
			width: 26px !important;
			width: 34px !important;
			padding: 0 10px !important;

			top: 50% !important;
			right: 10px !important;
			left: auto !important;
			transform: translate(0, -50%) !important;
			transition: $transition;

			&:hover {
				background: transparent !important;

				span.rsicn {
					color: $color-link;
				}
			}

			span.rsicn {
				width: 16px;
				font-size: 16px !important; // needs fixed size
				margin: auto;

        &::before {
          line-height: 25px;
        }
			}
		}

		.rsbtn_toolpanel {
			position: absolute;
			right: -3px;
			top: 0 !important;

			.rsbtn_tools {
				background: transparent;
				border: none;
				border-radius: 0 !important;

				.rsbtn_tool {
					&:hover {
						background: none !important;

						.rsicn:before {
							color: $color-link;
						}
					}

					&:focus {
						outline: -webkit-focus-ring-color auto 1px !important;
					}

					.rsicn-cogs {
						&::before {
							display: flex;
							font-size: em(12px); // needs fixed size
						}
					}
				}

				.rsbtn_tools_inner {

					.rsbtn_toolcontainer {
						margin-top: 0 !important;
						background: $white;
						padding: 2px 0;

						.rsbtn_toollist {
							width: 100% !important;
							padding-left: 0px !important;

							@include media-breakpoint-down(sm) {
								padding-left: 0 !important;
							}

							li {
								& .rsbtn_tool {
									border-right: none !important;

									&:focus {
										background: transparent;

										span.rsicn {
											color: #000;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.rsbtn_play {
			position: relative;
			border: none !important;
      padding: 0 !important;
      height: 45px;

			@include media-breakpoint-down(sm) {
				padding: 0 !important;
				height: 45px !important;
				width: 45px !important;
			}

			&:focus {
				outline: -webkit-focus-ring-color auto 1px !important;
				background: transparent !important;

				.rsbtn_left .rsbtn_text span {
					color: $black !important;
				}
			}

			&:hover {
				background: transparent !important;

        .rsbtn_label {
          color: $color-link;
        }
			}

      .rsbtn_label {
        transition: $transition;
      }

			.rsbtn_right.rsimg.rsplay.rspart {
				display: none;
			}

			.rsbtn_left {
				margin-left: 14px !important;

				@include media-breakpoint-down(sm) {
					height: 45px !important;
					width: 45px !important;
				}

        &:after {
					z-index: -1;
					content: "";
					position: absolute;
					top: 0;
          left: 0;
					height: 45px;
					width: 45px;
					border-radius: 30px;
					background: $grey-light;
				}
			}

			.rsbtn_left .rsbtn_text {
        height: 45px;

				&::before,
        &:after {
					color: $color-link;
					font-size: em(14px) !important; // needs fixed size
          line-height: 46px;
				}

				span {
          display: flex;
          align-items: center;
					font-family: $secondary-font !important;
					font-weight: $font-weight-body !important;
					padding-left: 40px !important;
					font-size: $breadcrumb-font-size !important;
				}
			}
		}
	}
}

.service-menu--bg-transparent {
	.rsbtn {
		&.rsexpanded {
			width: 144px !important;

			.rsbtn_exp {
				left: 28px;
			}
		}

		.rsbtn_play {
			padding: 0 !important;

			.rsbtn_left {
				margin-left: 3px !important;

				.rsbtn_text {
					&:after {
						display: none;
					}

					span {
						padding-left: 24px !important;
					}
				}
			}
		}

		.rsbtn_toolpanel {
			top: 1px;

			.rsbtn_tools {
				background: var(--color-grey-light);
			}
		}
	}
}

#rsbtn_scrollcontrols button:focus.rsbtn_scroll_togglescroll.enabled:focus span.rsbtn_scroll_state,
#rs-controlpanel button.rs-controlpanel-scroll-toggler.enabled:focus span.rs-controlpanel-scroll-state {
  color: #f6f9fc !important;
}

.rs_addtools .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li,
.rsbtn .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li {
	height: auto !important;
	border-top: none !important;
}

.rs_addtools .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li button.rsbtn_tool .rs_minitoggler,
.rsbtn .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li button.rsbtn_tool .rs_minitoggler {
	margin: 0 0.2em 0 2em !important;
}

.rs_addtools .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li button.rsbtn_tool span.rsbtn_btnlabel, 
.rsbtn .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer ul.rsbtn_toollist li button.rsbtn_tool span.rsbtn_btnlabel {
	margin-right: auto;
}

.rs_addtools .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_powered,
.rsbtn .rsbtn_toolpanel.vertical .rsbtn_tools .rsbtn_tools_inner .rsbtn_powered {
	display: none !important;
}

.rs_addtools .rsbtn_toolpanel.vertical,
.rsbtn .rsbtn_toolpanel.vertical {
	left: calc(100% - 42px) !important;

	@include media-breakpoint-down(sm) {
		left: 0 !important;
		top: 2px !important;
	}
}

.rs_addtools .rsbtn_play .rsbtn_left,
.rsbtn .rsbtn_play .rsbtn_left {
	height: auto !important;
}

// Delete after
.rsbtn .rsbtn_exp_inner .rsbtn_status_overlay {
	display: none !important;
}
