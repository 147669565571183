.hero__search-bar {
  height: 450px;
  padding: 30px 0;
  align-items: flex-end;
  background: var(--color-overlay);

  @include media-breakpoint-down(sm) {
    height: 300px;
  }

  .h1,
  h2,
  .h2 {
    margin-bottom: 20px;
  }

  // Small variation
  &.hero__search-bar--small {
    height: auto;
    min-height: 250px;

    @include media-breakpoint-down(sm) {
      padding: 40px 0;
      height: auto;
      min-height: 175px;
    }

    &.hero__search-bar--has-links {
      .search-bar--small {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  .bg-image {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);
      z-index: 0;
    }

    img {
      height: 100%;
    }
  }


  .search-bar--small {
    position: relative;
    margin-bottom: 45px;

    form {
      width: 100%;
    }
  }

  .suggestions {
    position: relative;
    width: 100%;
    margin-top: 10px;

    p,
    a {
      font-size: $component-font-size;
      line-height: $line-height-secondary;
    }

    p {
      // color: var(--color-overlay-text);
      margin-bottom: 0;
    }

    span {
      margin-right: 1rem;
    }

    a {
      cursor: pointer;

      &:focus {
				outline: -webkit-focus-ring-color auto 1px;
			}

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .h1,
  h2,
  .h2 {
    z-index: 20;
    color:  var(--color-overlay-text);
    position: relative;
  }
}

.hero__search-bar--has-links .search-bar--small {
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}
}

.hero__search-bar.has-overlay,
.hero__search-bar--has-title,
.hero__search-bar--has-links {
  .background-video {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .bg-image:after,
  .background-video:after {
    background: var(--color-overlay);
		height: 100%;
	}
}

// Position absolute option
.height-100vh--absolute {
  .hero__search-bar .container{
    margin-bottom: 50px;
  }
}
