/**
 * Third party
 * Citrix/Helpdesk
 */

.helpdesk-popup__list-arrows {
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li button {
      position: relative;
      display: flex;
      margin-left: 20px;
      text-align: left;
  
      &::before {
        content: "";
        font-family: "listicons",sans-serif;
        speak: none;
        font-style: normal;
        font-weight: 500;
        font-variant: normal;
        text-transform: none;
        position: absolute;
        left: -20px;
        top: 0;
        transition: all .2s ease-in-out;
        width: auto;
        height: auto;
        border-style: none;
        border-color: transparent;
        border-width: 0;
        transform: none;
      }
    }
  }

  .tag.tag--free-color {
    margin-right: 5px;
    margin-top: 5px;
  }
}

footer {
  .helpdesk-popup__list-arrows {
    ul li button {
      padding: 0;
      color: var(--color-footer-text);

      &:hover {
        &::before {
          left: -15px;
        }
      }

      &::before {
        color: var(--color-footer-text);
      }

      .tag.tag--free-color .color-dot {
        border: solid $white 1px;
      }
    }
  }
}
