.search-result {
	.filter-menu {
		margin-bottom: 30px;

		h2 {
			margin-top: 30px;
		}
	}

	.list__cards {
		.card {
			position: relative;

			.breadcrumbs_list {
				p {
					text-transform: inherit;
					font-weight: 500 !important;
				}
			}
		}
	}
}
