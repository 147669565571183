[class*="bg-"], 
.mobile-secondary-nav {
  .dropdown-settings {
    .dropdown-settings__toggle {
      color: var(--darkmode-50) !important;

      &::before {
        border-color: var(--darkmode-50) !important;
      }
    }

    .dropdown-settings__container {
      background: var(--darkmode-800);

      li {
        border-color: var(--darkmode-600);
      }
    }
  }
}

.dropdown-settings {
  .dropdown-settings__container {
    background: var(--darkmode-800);

    p {
      color: var(--darkmode-50);
    }
  }

  .btn__toggle {
    background: var(--darkmode-500);
    border-color: var(--darkmode-400);

    .btn__toggle__toggle {
      background-color: var(--darkmode-400);
    }

    &.active {
      background: var(--darkmode-900);

      .btn__toggle__toggle {
        background: var(--darkmode-200);
      }

      .btn__toggle__elements {
        [class*="icon"]::before {
          color: var(--darkmode-200);
        }
      }
    }
  }
}
