/**
 * Error
 */

.error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height: calc(100vh - 100px);

  .error__icon {
    margin-bottom: 30px;
    
    &[class^="icon-"],
    &[class*="icon-"] {
      font-size: initial;

      &:before {
        font-size: em(60px); // icon size
      }
    }
  }

	svg {
		height: 60px;

		path {
			fill: #c1c1c1 !important;
		}
	}

  .rich-text {
    text-align: center;

    h1,
    .h1,
    h2,
    .h2 {
      margin-bottom: 30px;
    }
  }
}
