.accordion__colored-backgrounds {
  position: relative;
  padding-bottom: 40px;

  &:after {
    z-index: 0;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    background: $article-element-bg--default;
    width: 100%;
    height: 40px;
    border-radius: 0 0 $border-radius-component $border-radius-component;
  }

  &:not(.accordion__colored-backgrounds--1-item) {
    border-radius: $border-radius-component;
  }

  .accordion__items::before {
    display: none;
  }

  &.accordion__colored-backgrounds--1-item {
    padding-bottom: 0;

    &:after {
      display: none;
    }

    .accordion__item {
      border-top: 1px solid $black;

      &:first-child:after {
        top: 0;
        width: 100%;
      }

      &:last-child {
        border-bottom: 1px solid $black;
        padding-bottom: 0;

        &::before {
          display: none;
        }
      }

      &.active {
        .accordion-header {
          padding-left: 40px !important;

          @include media-breakpoint-down(sm) {
            padding-left: 30px !important;
          }
        }
      }

      .accordion-header {
        padding: 1.5em 60px 1.5em 20px !important;
        padding-left: 20px !important;

        @include media-breakpoint-down(sm) {
          padding-left: 15px !important;
        }

        &:hover {
          padding-left: 40px !important;

          @include media-breakpoint-down(sm) {
            padding-left: 30px !important;
          }
        }

        .icon-plus {
          right: 25px !important;
        }
      }

      .accordion-body .accordion-body__contents {
        padding-left: 40px;
        padding-right: 40px;

        @include media-breakpoint-down(sm) {
          padding-left: 30px;
          padding-right: 30px;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .accordion__toggle-btn {
    position: absolute;
    right: 0;
    padding-right: 44px;

    @include media-breakpoint-down(sm) {
      padding-right: 19px;
    }

    button {
      margin-right: 0 !important;

      .icon-plus {
        right: 15px;
      }
    }
  }

  &.accordion__no-title {
    padding-top: 40px;

    &:before {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      background: $article-element-bg--default;
      width: 100%;
      height: 40px;
      border-top-left-radius: $border-radius-component;
      border-top-right-radius: $border-radius-component;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .accordion__toggle-btn {  
      top: 0px;

      &::before {
        content: "";
        z-index: 10;
        position: absolute;
        bottom: -3px;
        width: calc(100% - 60px);
        border-bottom: 1px solid $black;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  .accordion__title,
  .accordion__sub-title {
    z-index: 5;
    position: relative;
    width: 100%;
    padding: 30px;
    background: $article-element-bg--default;
    margin-bottom: 0 !important;
    border-radius: $border-radius-component $border-radius-component 0 0;

    .smalltext{
      margin: 10px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 20px 15px;
    }

    .accordion__title--pr {
      padding-right: 125px;

      @include media-breakpoint-down(sm) {
        padding-right: 115px;
      }
    }

    h2,
    .h2 {
      margin-bottom: 0;
    }

    .accordion__toggle-btn {
      bottom: 22px;

      @include media-breakpoint-down(sm) {
        bottom: 12px;
      }
    }
  }

  .accordion__title {
		&.has-btn {
			.accordion__toggle-btn {
        bottom: 21px;

        @include media-breakpoint-down(sm) {
          bottom: 11px;
        }
			}
		}
	}

  .accordion__sub-title {
    background-color: $white;

    &::before {
      content: "";
      z-index: 10;
      position: absolute;
      bottom: 0;
      width: calc(100% - 60px);
      border-bottom: 1px solid $black;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &:after {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      background: $article-element-bg--default;
      width: 100%;
      height: 100%;
      transition: $transition;
    }

    .rich-text {
      z-index: 1;
      position: relative;
      margin-bottom: 0;

      &:first-child{
        p {
          margin-bottom: 0px;
        }
      }
    }
  }

  .accordion__item {
    position: relative;
    background: $white;
    border-top: none;

    &:before {
      content: "";
      z-index: 10;
      position: absolute;
      bottom: -1px;
      width: calc(100% - 60px);
      border-bottom: 1px solid $black;
      left: 50%;
      transform: translate(-50%, 0);
      transition: $transition;

      @include media-breakpoint-down(sm) {
        width: calc(100% - 30px);
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 40px;

      &:before {
        bottom: 40px;
      }
    }

    &.active {
      &:before {
        border-color: transparent;
      }

      .accordion-header {
        padding-left: 50px !important;

        @include media-breakpoint-down(sm) {
          padding-left: 30px !important;
        }
      }

      .accordion-body__contents::after {
        border: none;
      }
    }

    .accordion-header__icon {
      &[class*=icon-]::before {
        color: $black;
      }
    }
  }

  .accordion-header {
    position: relative;
    padding-left: 30px !important;
    padding-right: 90px;
    background: $white !important;

    @include media-breakpoint-down(sm) {
      padding-left: 15px !important;
      padding-right: 60px;
    }

    &:hover {
      padding-left: 50px !important;

      @include media-breakpoint-down(sm) {
        padding-left: 15px !important;
      }
    }

    &:after {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      background: $article-element-bg--default;
      width: 100%;
      height: 100%;
      transition: $transition;
    }

    &:before {
      border-bottom: none;
    }

    &:hover {
      &:after {
        opacity: 0.8;
      }
    }

    span,
    h2,
    h3,
    .icon-plus {
      z-index: 1;
    }

    .icon-plus {
      right: 55px !important;

      @include media-breakpoint-down(sm) {
        right: 30px !important;
      }
    }

    &.has-icon {
      .accordion-header__icon {
        z-index: 1;

        &[class^="icon-"],
        &[class*="icon-"] {
          color: $black;
        }
      }
    }
  }

  .accordion-body {
    position: relative;
    z-index: 0;

    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      transition: $transition;
      background-color: $article-element-bg--default;
    }

    .accordion-body__contents {
      z-index: 1;
      padding-left: 50px;
      padding-right: 50px;

      @include media-breakpoint-down(sm) {
        padding-left: 30px;
        padding-right: 30px;
      }

      &::after {
        border: none;
      }
    }
  }

  .accordion-header.has-icon .accordion-header__icon {
    background-color: $white;
  }

  @include nestedArticleColors($themes-article) {
    .rich-text {
      a:not(.btn),
      li a {
        color: $black;
        background-size: 100% 1px;
        background-image: linear-gradient($black, $black);
      }
    }
  }

  @include nestedComponentColors($themes) {
    .accordion-header .icon-plus {
      &::before,
      &:after {
        background: $black;
      }
    }

    .accordion-header.has-icon .accordion-header__icon {
      &:after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: $transition;
      } 
    }

    &:after {
      background-color: themed('background');
    }

    .accordion__toggle-btn button {
      color: themed('text');

      .icon-plus {
        &::before,
        &:after {
          color: themed('text');
        }
      }
    }

    .accordion__title {
      background-color: themed('background');

      h2,
      .h2 {
        color: themed('text');
      }
    }

    &.accordion__no-title {
      &::before {
        background-color: themed('background');
      }
    }

    .accordion__sub-title {
      &:after {
        background-color: themed('background');
      }
    }

    .accordion-header {
      &:after {
        background-color: themed('background');
      }

      &:hover {
        h2,
        .h2,
        h3,
        .h3 {
          color: themed('text');
        }

        .icon-plus {
          &::before,
          &:after {
            color: themed('text');
          }
        }
      }
    }

    .accordion-body,
    .accordion-header.has-icon .accordion-header__icon {
      &:after {
        background-color: themed('background');
      } 
    }
  }

  @include nestedArticleColors($themes-article) {
    &:after {
      background: themedArticle('background');
    }

    .accordion__toggle-btn button {
      color: themedArticle('text');

      .icon-plus {
        &::before,
        &:after {
          background: themedArticle('text');
        }
      }
    }

    .accordion__title {
      background: themedArticle('background');

      h2,
      .h2 {
        color: themedArticle('text');
      }
    }

    &.accordion__no-title {
      &::before {
        background: themedArticle('background');
      }
    }

    .accordion__sub-title {
      &:after {
        background: themedArticle('background');
      }
    }

    .accordion-header {
      &.has-icon .accordion-header__icon {
        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.2;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: $transition;
        }
      }

      &:after {
        background: themedArticle('background');
      }

      &:hover {
        h2,
        .h2,
        h3,
        .h3 {
          color: themedArticle('text');
        }

        .icon-plus {
          &::before,
          &:after {
            background: themedArticle('text');
          }
        }
      }

      .icon-plus {
        &::before,
        &:after {
          background: $black;
        }
      }
    }

    .accordion-body,
    .accordion-header.has-icon .accordion-header__icon {
      &:after {
        background: themedArticle('background');
      } 
    }

    .fact-box:not([class*="bg-"]) .fact-box__title__icon[class*="icon-"]::before{
      color: $black;
    }
  }
}

.accordion__colored-backgrounds .accordion__items .accordion__item:last-child {
	padding-bottom: 0;

	&::before {
		bottom: 0;
	}
}
