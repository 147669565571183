/**
 * Variables
 * Buttons
 */

$btn-bg: var(--color-btn-primary) !default;
$btn-text-color: var(--color-btn-primary-text) !default;
$btn-font-weight: inherit !default;
$btn-font-size: em(16px) !default;
$btn-border-color: transparent !default;
$btn-hover-border-color: transparent !default;
