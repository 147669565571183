.info-box__links__icon {
	width: 100%;
	margin: 0 auto;
	border-radius: 5px;
  border-radius: var(--sleeve-component-border-radius);

	// Item grid
	.container {
		@include media-breakpoint-up(md) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.row {
      @include media-breakpoint-down(md) {
        margin-top: 0 !important;
      }

			[class*='col-'] {
        @include media-breakpoint-down(md) {
          margin-top: 0;
          margin-bottom: 2px;
        }

				@include media-breakpoint-down(sm) {
					padding: 0 !important;
				}
			}

			.col-lg-6 {
				&:nth-child(n + 3) {
					@include media-breakpoint-up(lg) {
						margin-top: 20px;
					}
				}

        &:last-child {
          .info-box__links__icon__item {
            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }
          }
        }
			}

			.col-lg-4 {
				&:nth-child(n + 4) {
					@include media-breakpoint-up(lg) {
						margin-top: 20px;
					}
				}

        &:first-child {
          .info-box__links__icon__item .info-box__links__icon__item__header {
            @include media-breakpoint-down(md) {
              border-top-left-radius: var(--sleeve-component-border-radius);
              border-top-right-radius: var(--sleeve-component-border-radius);
            }
          }
        }

        &:last-child {
          .info-box__links__icon__item {
            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }

            .info-box__links__icon__item__header {
              @include media-breakpoint-down(md) {
                border-bottom-left-radius: var(--sleeve-component-border-radius);
                border-bottom-right-radius: var(--sleeve-component-border-radius);
              }
            }
          }
        }
			}
		}
	}

	// Item
	.info-box__links__icon__item {
    position: relative;
		height: 100%;
		background: $white;
    border-radius: var(--sleeve-component-border-radius);

		&.active {
			& > .js-toggle {
				&:after {
					transform: translateY(-50%) translateX(-15px) rotate(-45deg);
				}
			}

      .info-box__links__icon__item__header.has-link .js-toggle {
				&:after {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}

    @include nestedComponentColors($themes) {
      color: themed('text');
      background-color: themed('background');
    }

    // Accordion toggle
    button.js-toggle {
      position: relative;
      width: 100%;
      padding: 0;

			&:after {
				@include media-breakpoint-down(md) {
					content: '';
					display: inline-block;
					position: absolute;
					right: 7px;
					top: 50%;
					transition: all 0.2s ease-in-out;
					width: 9px;
					height: 9px;
					border-style: solid;
					border-color: $color-arrows;
					border-width: 2px 2px 0 0;
					transform: translateY(-50%) translateX(-15px) rotate(135deg);
				}
			}
    }

		// Header - title, icon & arrow
    .js-toggle {
      .info-box__links__icon__item__header {
        @include media-breakpoint-up(lg) {
          padding: 30px 30px 15px 20px;
        }
  
        @include media-breakpoint-down(md) {
          padding: 15px 43px 15px 15px;
        }
      }
    }

		.info-box__links__icon__item__header {
			position: relative;
			display: flex;
			align-items: center;
      width: 100%;
			border-radius: 0;
			transition: $transition;
			background-color: $white;
      border-radius: var(--sleeve-component-border-radius);

			@include media-breakpoint-up(lg) {
				padding: 30px 0px 15px 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
			}

			@include media-breakpoint-down(md) {
				cursor: pointer;
				padding: 15px 0px 15px 15px;
			}

      &.has-link {
        @include media-breakpoint-down(md) {
          padding: 15px 15px 15px 20px
        }

        .js-toggle {
          position: relative;
          height: 25px;
          width: 25px;
          min-width: 25px;
  
          &:after {
            right: 10%;
            transform: translate(-50%, -50%) rotate(135deg);
          }
        }
      }

			.info-box__links__icon__item__header__image {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: auto;
				max-width: 40px;
				max-height: 40px;
				margin-right: 20px;
				font-size: 2rem; // icon size

        @include media-breakpoint-down(md) {
					width: 30px;
					max-width: 30px;
					max-height: 30px;
					font-size: 1.875rem; // icon size
				}

        img,
        svg {
          width: 32px;
          height: auto;
          max-height: 32px;

          @include media-breakpoint-down(md) {
            width: 30px;
            max-height: 30px;
          }
        }
      
        img {
          object-fit: contain;
        }
			}

      a {
        width: 100%;
      }

			h3,
			.h3 {
				max-width: 90%;
				font-size: $component-font-size;
				margin-bottom: 0;
        text-align: left;
			}
		}

		// Body - Links
		.info-box__links__icon__item__body {
			background-color: $white;
			padding: 0 30px 30px 80px;
      border-radius: var(--sleeve-component-border-radius);
      
      @include media-breakpoint-up(lg) {
				border-radius: var(--sleeve-component-border-radius);
			}

			@include media-breakpoint-down(md) {
				display: none;
				padding: 0 20px 20px 65px;
        background-color: transparent;
        border-bottom-left-radius: var(--sleeve-component-border-radius);
        border-bottom-right-radius: var(--sleeve-component-border-radius);
			}

			.info-box__links__icon__item__body__contents {
				.list__links {
					padding: 0;

					ul {
            &.no-wrap {
              @include media-breakpoint-up(lg) {
                flex-wrap: unset;
                flex-direction: column;
              }
            }

						li {
							@include media-breakpoint-down(sm) {
								margin-bottom: 15px;
							}

							@include media-breakpoint-up(lg) {
								margin-right: 15px;
								margin-bottom: 7px;
							}

							&:last-child {
								@include media-breakpoint-down(sm) {
									margin-bottom: 0;
								}

								@include media-breakpoint-up(lg) {
									margin-bottom: 0;
								}
							}
						}

						&.list-colum {
							flex-direction: column;
						}
					}
				}

        .btn__link {
          padding-top: 20px;
        }
			}
		}

    // When icon is not filled
    &.no-icon {      
      .info-box__links__icon__item__header {
        @include media-breakpoint-up(lg) {
          padding: 30px 30px 15px 30px;
        }
  
        @include media-breakpoint-down(md) {
          padding: 15px 43px 15px 20px;
        }

        &.has-link {
          @include media-breakpoint-down(md) {
            padding: 15px 15px 15px 20px
          }
        }
      }
  
      .info-box__links__icon__item__body {
        padding: 0 30px 30px 30px;
  
        @include media-breakpoint-down(md) {
          padding: 0 20px 20px 20px;
        }
      }
    }
	}
}

// Framed option
.info-box__links__icon--framed {
  background-color: $grey-light;
  border-radius: var(--sleeve-component-border-radius);

  @include media-breakpoint-up(lg) {
    background-color: $white;
    padding: var(--sleeve-component-padding);
  }

  .info-box__links__icon {
    .info-box__links__icon__item {
      border: none; 
      
      @include media-breakpoint-down(md) {
        border-radius: 0;
      }
    }

    .container .row [class*="col-"] {
      &:first-of-type .info-box__links__icon__item  {
        @include media-breakpoint-down(md) {
          border-top-left-radius: var(--sleeve-component-border-radius);
          border-top-right-radius: var(--sleeve-component-border-radius);
        }
      }
  
      &:last-of-type .info-box__links__icon__item {
        @include media-breakpoint-down(md) {
          border-bottom-left-radius: var(--sleeve-component-border-radius);
          border-bottom-right-radius: var(--sleeve-component-border-radius);
        }

       .info-box__links__icon__item__body {
          @include media-breakpoint-down(md) {
            border-bottom-left-radius: var(--sleeve-component-border-radius);
            border-bottom-right-radius: var(--sleeve-component-border-radius);
          }
        }
      }
    }
  }

  .info-box__links__icon__item__header,
  .info-box__links__icon__item__body {
    @include media-breakpoint-down(md) {
      border-radius: 0;
    }
  }
}

// Grid
.row--grid__col-8-4 .info-box__links__icon {
  .container,
  .row {
    height: 100%;
  }
}
