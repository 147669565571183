/**
 * Classes
 * Aspect ratios
 */

.aspect-ratios--1-1 {
  aspect-ratio: 1 / 1;
}

.aspect-ratios--1-1--sm {
  @include media-breakpoint-down(sm) {
    aspect-ratio: 1 / 1;
  }
}

.aspect-ratios--3-2 {
  aspect-ratio: 3 / 2;
}

.aspect-ratios--3-2--sm  {
  @include media-breakpoint-down(sm) {
    aspect-ratio: 3 / 2;
  }
}

.aspect-ratios--4-5 {
  aspect-ratio: 4 / 5;
}

.aspect-ratios--4-5--sm  {
  @include media-breakpoint-down(sm) {
    aspect-ratio: 4 / 5;
  }
}

.aspect-ratios--16-9 {
  aspect-ratio: 16 / 9;
}

.aspect-ratios--16-9--sm  {
  @include media-breakpoint-down(sm) {
    aspect-ratio: 16 / 9;
  }
}

.aspect-ratios--16-5 {
  aspect-ratio: 16 / 5;
}

.aspect-ratios--16-5--sm {
  @include media-breakpoint-down(sm) {
    aspect-ratio: 16 / 5;
  }
}

