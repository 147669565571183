ul.filter-search__filter__alphabet,
ul.filter__alphabet {
  @include list-unstyled;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
  
  @include media-breakpoint-down(md) {
    position: relative;
    flex-wrap: wrap;
    justify-content: inherit;
    padding: 20px 0 20px 45px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  li:first-of-type {
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 0;
      top: 20px;
    }
  }

  button {
    min-height: 24px; // Web accessibility
    min-width: 24px; // Web accessibility
    padding: 8px 6px 4px 6px;
    color: $black;
    background-color: transparent;
    cursor: auto;
    font-weight: 100;
    transition: $transition;

    &.active,
    &.chosen {
      font-weight: 900;
      cursor: pointer;
    }

    &.chosen {
      color: $color-primary-text;
      background-color: $color-primary;
    }
  }
}

// filter list on article page
.article-wrapper,
[data-creative~="article-content"] {
  .filter-list ul.filter-search__filter__alphabet,
  ul.filter__alphabet {
    @include media-breakpoint-down(lg) {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 20px 0 20px 45px;
    }

    li:first-of-type {
      @include media-breakpoint-down(lg) {
        position: absolute;
        left: 0;
        top: 20px;
      }
    }
  }
}

.article-with-sidebar.article-with-sidemenu {
  .article-wrapper {
    .filter-list ul.filter-search__filter__alphabet,
    ul.filter__alphabet {      
      @include media-breakpoint-down(xl) {
        position: relative;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 20px 0 20px 45px;
      }

      li:first-of-type {
        @include media-breakpoint-down(xl) {
          position: absolute;
          left: 0;
          top: 20px;
        }
      }
    } 
  }
}
