/**
 * Info Boxes
 */

 .info-box {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: $white;
  // background: green;
  padding: var(--sleeve-component-padding);
  box-shadow: unset;
  border-radius: var(--sleeve-component-border-radius);

  h3,
  .h3 {
    padding-bottom: 0;
    text-transform: unset;
    letter-spacing: 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  .list__links {
    margin-bottom: auto;
  }

  .btn__link {
    margin-top: 20px;
  }
}

.col-md-8 {
  .info-box {
    ul {
      flex-wrap: wrap;
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      li {
        width: calc(50% - 20px);

        @include media-breakpoint-down(sm) {
          width: auto;
        }

        &:nth-child(odd) {
          margin-right: 20px;

          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }

        &:nth-child(even) {
          margin-left: 20px;
          
          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
