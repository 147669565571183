// Icons for CTA arrows

@font-face {
	font-family: 'ctaicons';
	src: url('/assets/fonts/cta-icons/icomoon.eot?rfzp4p');
	src:
	url('/assets/fonts/cta-icons/icomoon.eot?rfzp4p#iefix') format('embedded-opentype'),
	url('/assets/fonts/cta-icons/icomoon.ttf?rfzp4p') format('truetype'),
	url('/assets/fonts/cta-icons/icomoon.woff?rfzp4p') format('woff'),
	url('/assets/fonts/cta-icons/icomoon.svg?rfzp4p#icomoon') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

main,
body {

	&[class^="cta-icon-"],
	&[class*="cta-icon-"] {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'ctaicons', sans-serif !important;
		speak: none;
		font-style: normal;
		font-weight: 500;
		font-variant: normal;
		text-transform: none;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		.arrow {
			position: absolute;
			height: 24px;
			width: 26px;
      min-width: 26px;

			&:before {
				/* use !important to prevent issues with browser extensions that change fonts */
				font-family: 'ctaicons', sans-serif !important;
				speak: none;
				font-style: normal;
				font-weight: 500;
				font-variant: normal;
				text-transform: none;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				position: absolute;
				left: 0;
				top: 50%;
				color: $color-arrows;
				transition: all 0.2s ease-in-out;
				font-size: 26px; // icon size (Intitial size - don't change)

				// START - Delete after old arrow is removed
				width: auto;
				height: auto;
				border-style: none;
				border-color: transparent;
				border-width: 0;
				transform: none;
				// END - Delete after old arrow is removed

				transform: translate(0, -50%);
			}
		}

		.btn__link.btn__link-arrow--long,
		.span__link.span__link-arrow--long,
		.link-box.link-box__article,
		.pagination {
			.arrow {
				position: relative;
			}
		}

		.link-box.link-box__article {
			.arrow {
				margin-left: auto;
			}
		}

    .btn__primary-arrow--long {
      &:hover,
      &:focus {
        .arrow::before {
          color: $btn-primary-bg-color;
        }
      }

      .arrow::before {
        color: $btn-primary-text-color;
      }
    }

    @include globalComponentColors($themes) {
      .btn__primary-arrow--long {
        &:hover,
        &:focus {
          .arrow::before {
            color: themed('text');
          }
        }
  
        .arrow::before {
          color: themed('btn-primary-text');
        }
      }
    }
	}

	&.cta-icon-arrow-long-thin {
		.arrow {
			&:before {
				content: "\e200";
			}
		}
	}

	&.cta-icon-arrow-medium {
		.arrow {
			&:before {
				content: "\e201";
			}
		}
	}

	&.cta-icon-arrow-short-circle-outline {
		.arrow {
			&:before {
				content: "\e202";
			}
		}
	}

	&.cta-icon-arrow-long-circle-outline {
		.arrow {
			&:before {
				content: "\e203";
			}
		}
	}

	&.cta-icon-arrow-sharp-thick {
		.arrow {
			&:before {
				content: "\e204";
			}
		}
	}

	&.cta-icon-arrow-medium-circle-outline {
		.arrow {
			&:before {
				content: "\e205";
			}
		}
	}

	&.cta-icon-arrow-filled-circle {
		.arrow {
			&:before {
				content: "\e900";
			}
		}
	}

	&.cta-icon-arrow-short-circle-outline,
	&.cta-icon-arrow-medium-circle-outline,
	&.cta-icon-arrow-long-circle-outline {
		.arrow {
			height: 30px;
			width: 30px;
      min-width: 30px;

			&:before {
				font-size: 30px; // icon size
			}
		}

    .btn__link-arrow--long {
      span {
        margin-top: 3px;
      }
    }
	}
}
