/**
 * Footer link hightlights
 *
 * Usage: <div class="footer hightlight-links">
 * Will overide default link highlights
 */

.footer.hightlight-links {
  p a,
  li a {
    background-size: 100% 1px;
  }
}

.footer.hightlight-links--arrow {
  p a {
    padding: 0;
  }

  span a:not(.list__link):not(.btn):not([class^="icon-"]),
  li a:not(.list__link):not(.btn):not([class^="icon-"]),
  p a:not(.list__link):not(.btn):not([class^="icon-"]) {
    position: relative;
    display: flex;
    margin-left: 20px;
    background-size: 100% 0;
    background-image: unset;
    border-bottom: none;

    &:hover,
    &:focus {
      background-size: 100% 0;
      color: var(--color-footer-arrows);

      &::before {
        left: -15px !important;
      }
    }
  }

  li a:not(.list__link):not(.btn):not([class^="icon-"])::before,
  p a:not(.list__link):not(.btn):not([class^="icon-"])::before {
    color: var(--color-footer-arrows) !important;
    left: -20px !important;
  }

  .list__icons ul li a:not(.list__link):not(.btn),
  ul.list__icons li a:not(.list__link):not(.btn) {
    margin-left: 0;

    &:hover,
    &:focus {
      &::before {
        content: unset !important;
      }
    }

    &::before {
      content: unset;
    }
  }
}
