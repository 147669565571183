// Filter list form
.filter-list__form {
	background: $white;
	padding: 30px;
  margin-bottom: 30px;
  
  @include media-breakpoint-down(sm) {
    padding: 30px 15px;
  }

  form,
  .form {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -15px 0;

		.form-group {
      width: calc(100% - 31px);
			margin: 0 15px;

      &:first-child:nth-last-child(n + 2),
      &:first-child:nth-last-child(n + 2) ~ .form-group {
        width: calc(100%/2 - 31px);
      }

      &:first-child:nth-last-child(n + 3),
      &:first-child:nth-last-child(n + 3) ~ .form-group {
        width: calc(100%/3 - 31px);
      }

      &:first-child:nth-last-child(n + 2),
      &:first-child:nth-last-child(n + 2) ~ .form-group,
      &:first-child:nth-last-child(n + 3),
      &:first-child:nth-last-child(n + 3) ~ .form-group {
        @include media-breakpoint-down(lg) {
          width: calc(100%/2 - 31px);
        }
  
        @include media-breakpoint-down(sm) {
          width: calc(100% - 30px);
        }
      }

      &:nth-child(n+4) {
        @include media-breakpoint-up(xl) {
          margin-top: 20px;
        }
      }

      &:nth-child(n+3) {
        @include media-breakpoint-down(lg) {
          margin-top: 20px;
        }
      }


      &:nth-child(n+2) {
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }

			label {
				font-weight: $font-weight-heading;
			}

      .select {
        select {
          background: $grey-light;
        }
			}
		}

		.filter-menu__items {
			.select {
				width: 100%;
				position: relative;

				&:hover {
					span.border-left {
						width: 15px;
					}
				}
			}
    }

    .select {
      &:hover {
        span.border-left {
          width: 15px;
        }
      }

      span.border-left {
        width: 10px;
        height: 100%;
        background: #ccc;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transition: width 50ms cubic-bezier(.455, .03, .515, .955);
      
        &:hover {
          &:before {
            width: 15px;
          }
        }
      }

      select {
        background: $white;
        padding-left: 25px;
      }
    } 
	}
}

// Tags block
.tag-block {
	display: block;
	padding-top: 15px;
	padding-bottom: 10px;

	.tag {
		display: inline-block;
		background-color: $white;
		padding: 8px 15px 8px 20px;
		border: none;
		transition: all .3s cubic-bezier(.455, .03, .515, .955);
		position: relative;
		margin-right: 5px;
		margin-bottom: 5px;
    
    span {
      font-family: $secondary-font;
      font-size: inherit;
      line-height: $line-height-secondary;
      font-weight: $font-weight-heading;
      color: $grey-dark;
      
      &.border-left {
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background: #ccc;
        transition: width 50ms cubic-bezier(.455, .03, .515, .955);
      }
    }

		&:hover {
			span.border-left {
				width: 10px;
			}
		}
	}
}
