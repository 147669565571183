/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Breadcrumbs
 */

.breadcrumb svg path {
  fill: var(--darkmode-50);
}

.breadcrumb-item span {
  color: var(--darkmode-50);
}

.breadcrumb-item.active {
  color: var(--darkmode-50);
}

.breadcrumb-item a:hover {
  color: var(--darkmode-hightlight-1-200);
}
