/**
 * Buttons
 * .btn__selfservice
 */

.btn__selfservice {
	z-index: 0;
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	width: 100%;
  padding: var(--sleeve-btn-padding-y) 60px var(--sleeve-btn-padding-y) 30px;
	background-color: $btn-primary-bg-color;
	color: $btn-primary-text-color;
  border-color: $btn-primary-bg-color;
  border-width: $btn-primary-border-width;
  border-style: $btn-primary-border-style;

	&::before {
    content: "\ebf7";
		position: absolute;
		top: 53%;
		transform: translate(0, -50%);
		right: 15px;
    font-family: "icomoon" !important;
    font-size: em(25); // icon size
	}

	a {
		background-size: 0;
	}
}

// When .btn__selfservice is used in articles
[class^="link--"] span.btn__selfservice,
[class*="link--"] span.btn__selfservice,
span.btn__selfservice {
	display: inline-flex;
	width: auto;
	padding: 0;

	a {
		width: 100%;
    padding: var(--sleeve-btn-padding-y) 90px var(--sleeve-btn-padding-y) 30px !important;
		color: $btn-primary-text-color;
		font-weight: $btn-font-weight;
		background-size: 100% 0 !important;
		border-bottom: unset;

		&:hover,
    &:focus {
			background-size: unset !important;
			background-image: unset !important;
		}
	}
}

a span.btn__selfservice {
  padding: var(--sleeve-btn-padding-y) 90px var(--sleeve-btn-padding-y) 30px !important;
	padding-right: 100px;
}

// Rich editor combination
span.btn {
	.btn__selfservice {
		min-height: auto;
		padding: 0 80px 0 15px !important;
		font-size: inherit !important;

		&.btn__selfservice::before {
			right: 5px;
		}
	}
}
