/**
 * Links
 */

$link-decoration: none !default;
$link-hover-decoration: underline !default;

a {
	color: $color-link;
	text-decoration: $link-decoration;
	background-color: transparent; // Remove the gray background on active links in IE 10.
	-webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

	&:hover {
		text-decoration: $link-hover-decoration;
	}
}

body:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {
  // Link animation, transtion & general styling
  p,
  h2,
  h3,
  h4,
  .list__bullets li,
  .rich-text li,
  .article-wrapper .rich-text li,
  .accordion .rich-text li,
  .article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
  .info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext {
    a:not(.list__link):not(.btn) {
      text-decoration: none;
      background-position: 0% 99%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: $transition;
      font-weight: $font-weight-body;

      &:hover,
      &:focus {
        background-size: 100% 1px;
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    p a:not(.list__link):not(.btn) {
      color: $black;
			background-size: 100% 1px;
			background-image: linear-gradient($black, $black);

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }

  // Links on light backgrounds
  p,
  h2,
  h3,
  h4,
  .rich-text li,
  .article-wrapper .rich-text li,
  .accordion .rich-text li,
  .article-wrapper table,
  .info-box-article .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext {
    a:not(.list__link):not(.btn) {
      color: $color-link;
      background-image: linear-gradient($color-link, $color-link);

      &:hover,
      &:focus {
        color: $color-link;
      }
    }
  }

  p span.btn__primary,
  p span.btn__selfservice {
    a:not(.list__link) {
      color: $btn-primary-text-color;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.btn),
        li a {
          color: $black;
          background-size: 100% 1px;
          background-image: linear-gradient($black, $black);

          &:hover,
          &:focus {
            background-size: 100% 1px;
            color: $black;
          }
        }
      }
    }
  }

  // Footer links
  .footer p {
		a:not(.list__link):not(.btn) {
			background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
			color: var(--color-footer-text);

      &:hover,
      &:focus {
        color: var(--color-footer-text);
      }
		}
	}

  .footer.hightlight-links--arrow {
    p a:not(.list__link):not(.btn) {
      background-image: unset;
    }
  }

  .footer.hightlight-links {
    p a:not(.list__link):not(.btn) {
      background-size: 100% 1px;
    }
  }
}

// Links with icons
.link--icon:not(.new) {
  position: relative;
  margin-bottom: 10px;
  padding-left: calc($component-font-size + em(18px)); // icon dynamic padding - font-size+(icon-size + spacing-size)

  a[class^="icon-"],
  a[class*="icon-"] {
    font-family: inherit !important;
    font-size: inherit;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      font-size: calc($component-font-size + em(6px)); // icon size
      line-height: 1.1;
      transition: $transition;
    }
  }
}

.link--icon.new {
  position: relative;
  display: flex;
  margin-bottom: 10px;

  // Link needs a container beacuse link-animations dont work if the link is displayed flex
  .link {
    margin-left: em(15px);

    a {
      font-size: inherit;
    }
  }

  [class^="icon-"],
  [class*="icon-"] {
    font-family: inherit !important;
    font-size: inherit;

    &::before {
      position: relative;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      display: inline-block;
      font-size: calc($component-font-size + em(6px)); // icon size
      line-height: 1.1;
      transition: $transition;
    }
  }
}
