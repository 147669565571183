main:not(.bg-main--white) .subscribe.bg-section--invert-bg-main {
  .form-container {
    h3,
    .h3,
    p,
    label {
      color: $black;
    }

    .form-group {
      input,
      textarea {
        background-color: $white;
      }
    }
  }
}

.subscribe {
  @include nestedComponentColors($themes) {
    .form-container {
      background-color: $white;
    }
  
    .form-container form:not(#search-form):not(#subscribForm) {
      h3,
      .h3,
      p,
      label {
        color: $black;
      }
  
      .form-group {
        input,
        textarea {
          background-color: $grey-light;
        }
      }
    }
  }
}

main.bg-main--white .subscribe.bg-section--invert-bg-main {
  .form-container .form-group {
    input,
    textarea {
      background-color: $grey-light !important;
    }
  }
}

.form,
.form-container {
  // Component background color mixin
  @include nestedComponentColors($themes) {
    h3,
    .h3,
    p,
    label {
      color: themed('text');
    }

    .form-group {
      input,
      textarea {
        background-color: themed('background');
        color: themed('text');
        border-width: 1px;
        border-style: solid;
        border-color: themed('text');
  
        &::placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder {
          color: themed('text');
        }
      }

      input {
        &::after {
          border-color: themed('text');
        }
      }
    }

    input[type="date"] {
      background-color: themed('background');
    
      &::-webkit-input-placeholder {
        color: themed('text');
      }
    
    
      &::-webkit-datetime-edit-text,
      &::-webkit-date-and-time-value,
      &::-webkit-datetime-placeholder,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        color: themed('text');
      }
    
      &::-webkit-calendar-picker-indicator {
        color: themed('text');
      }
    
      &:not(.has-value):before {
        @include media-breakpoint-down(sm) {
          color: themed('text');
        }
      }
    }

    .select select {
      background: themed('background');
      color: themed('text');
      border-width: 1px;
      border-style: solid;
      border-color: themed('text');
    
      &::placeholder {
        color: themed('text');
      }
    
      option {
        color: themed('text');
        
        &:checked {
          background-color: themed('btn-primary-background');
        }
      }
    }

    .select,
    .select-checkboxes {
      &:after {
        border-color: themed('text');
      }
    }

    .select-checkboxes {
      background: themed('background');
      color: themed('text');
      border-width: 1px;
      border-style: solid;
      border-color: themed('text');

      button {
        color: themed('text');
      }
    
      .dropdown-menu {
        background: themed('background');
        border-color: themed('text');
    
        li {
          background-color: themed('background');
    
          &:hover {
            // Same color as standard HTML select hover blue
            background-color: #1e90ff;
            color: themed('background');
          }
    
        }
      }
    
      .divider {
        border-top-color: themed('text');
      }
    }

    .form-check {
      .checkbox {
        .checkbox__indicator {
          background: themed('background');
          border-color: themed('text');
    
          &:after {
            border-color: themed('text');
          }
        }
    
        & input:checked ~ .checkbox__indicator {
          border-color: themed('text');
          background: themed('background');
        }
    
        & input:disabled ~ .checkbox__indicator {
          background: themed('background');
    
          &:after {
            border-color: themed('text');
          }
        }
      }
    }

    .form-radio {
      input[type="radio"] {
        &:checked + .radio__indicator {
          background-color: themed('background');
          background-image: themed('background');
        }
      }
    
      .radio__indicator {
        background-color: themed('background');
        border-color: themed('text');
    
        &:after {
          background: themed('text');
        }
      }
    }

    .form-file {
      input[type=file] {
        border-color: themed('text');
        color: themed('text');
      }
    
      .thumb {
        button {
          color: $black;
        }
      }
    }
    
    .btn__submit,
    .btn__primary {
      background-color: themed('btn-primary-background');
      color: themed('btn-primary-text');
      border-color: themed('btn-primary-border');
  
      &:hover,
      &:focus,
      &.active {
        background-color: themed('btn-primary-background-hover');
        color: themed('btn-primary-text-hover');
      }
    }
  }
}
