.filter-menu {
	display: flex;
	flex-direction: column;
	justify-content: unset;
	align-items: unset;
	flex-wrap: wrap;
	margin-top: 0;
  margin-bottom: var(--sleeve-filter-menu-margin-bottom);
  
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  h2,
  p {
		font-size: $component-font-size;
    line-height: $line-height-secondary;
		font-weight: $font-weight-body;
		align-items: center;
		margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
		}
	}

	.filter-menu__items {
		display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;

		@include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 0;
		}

    .select,
		.select-checkboxes,
    .btn,
    .form-check {
			margin-right: 20px;
      margin-bottom: 10px;

      @include media-breakpoint-down(sm) {
				margin-right: 0;
			}
    }

		.select,
		.select-checkboxes {
			width: 290px;

			@media only screen and (min-width: 992px) and (max-width: 1143px) {
				width: 185px;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

			&:nth-child(n + 2) {
        width: auto;
				min-width: 185px;

				@media only screen and (min-width: 992px) and (max-width: 1143px) {
          width: auto;
          min-width: 185px;
				}

				@include media-breakpoint-down(sm) {
					width: 100%;
          min-width: auto;
				}
			}

			&:last-child {
				margin-right: 0;
			}

			select {
				font-weight: $font-weight-body;

				&:focus {
					box-shadow: unset;
					outline-offset: -1px;
				}
			}

			&.select-filter {
				display: flex;
				width: 20%;
				justify-content: flex-end;
				align-items: center;
				margin-left: auto;

        p,
        label {
					margin-right: 10px;
          margin-bottom: 0;
          line-height: $line-height-secondary;
          font-weight: $font-weight-body;
				}

				select {
					padding: 5px 20px;
					width: 120px;
					height: 46px;

					&:after {
						top: 35%;
					}
				}
			}
		}

    .select-checkboxes .dropdown-menu li {
      background-color: $grey-light;
    }

		.btn.reset {
			a {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

  // Has .service-menu
  .service-menu {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
		}

    .service-menu__item {
      margin: 0;

      .service-menu__item__icon {
        background: $white;
      }
    }
  }

  // Grid
  &[data-items="4"],
  &[data-items="3"],
  &[data-items="2"] {
    .select,
		.select-checkboxes {
      &:nth-child(n + 2) {
        @include media-breakpoint-down(sm) {
          width: calc(50% - 5px);
        }
      }
    }
  }

  &[data-items="4"],
  &[data-items="2"] {
    .select,
		.select-checkboxes {
      &:first-child {
        @include media-breakpoint-down(sm) {
          width: calc(50% - 5px);
          margin-right: 5px;
        }
      }

      &:nth-child(2n-7) {
        @include media-breakpoint-down(sm) {
					margin-right: 5px;
				}  
      }

      &:nth-child(2n-8) {
        @include media-breakpoint-down(sm) {
					margin-left: 5px;
				} 
      }
    }
  }

  &[data-items="3"] {
    .select,
		.select-checkboxes {
      &:nth-child(even) {
        @include media-breakpoint-down(sm) {
					margin-right: 5px;
				}
      }

      &:nth-child(odd) {
        @include media-breakpoint-down(sm) {
					margin-left: 5px;
				} 
      }

      &:first-child {
				@include media-breakpoint-down(sm) {
					margin-left: 0;
				} 
			}
    }
  }
}
