/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Info boxes article
 */

.info-box-article {
  border-top-color: var(--darkmode-hightlight-2-200);
}

.info-box-article__icon__bg {
  background: var(--darkmode-800) !important;
}

.info-box-article__contact .info-box-article__content .info-box-article__content__text .tag {
  color: var(--darkmode-hightlight-2-200) !important;
}

.info-box-article .rich-text-article__references {
  ul li {
    &::before {
      color: var(--darkmode-hightlight-1-200);
    }

    a {
      color: var(--darkmode-50) !important;
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50)) !important;
      background-size: 100% 1px !important;
    }
  }
}
