.modal {
	&__subscription,
	&__login {
		.modal-dialog {
			max-width: 600px;
		}

		.modal-content {
			border-radius: 0;
			border: unset;
      border-top-width: var(--component-border-top-width);
      border-top-style: solid;
      border-top-color: $color-secondary;
			background-clip: unset;

			.modal-header {
				border-bottom: unset;
				padding: 20px 20px 0 20px;

        h2 {
          padding-left: 20px;
        }

				button {
					padding: 20px;

					svg {
						path {
							fill: $black;
						}
					}
				}
			}

			.modal-body {
				padding: 20px 40px 40px 40px;

				&__hidden {
					left: -9999px;
					position: absolute;
					top: -9999px;
          width: 100%;
          display: none;
        }

        h4,
        h2 {
					@include responsive-font-size($h2-font-size-sm, "", $h2-font-size-sm, "", $h2-font-size-lg);
          line-height: $line-height-primary;
					font-weight: 600;
					margin-bottom: 40px;
				}

        p {
          font-size: $component-font-size;
          line-height: $line-height-secondary;
        }
			}

			.subscription-body,
			.login-body {
				margin-top: 30px;

				form {
          margin-bottom: 40px;

          @include media-breakpoint-down(sm) {
            flex-direction: column;
					  align-items: flex-start;
          }

          .has-error {
            .with-errors {
              position: absolute;
              left: 0;
              bottom: -21px;
            }
          }

          .form-check {
            &:last-of-type {
              margin-bottom: 20px;
            }
          }

					.form-group {
            position: relative;
						width: 100%;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
              margin-bottom: 20px;
            }

            label {
              font-weight: $font-weight-body;
            }

						input {
							height: 50px;
							border-color: $grey-dark;
            }
					}

					.btn__primary {
            width: 140px;
					}
				}
			}
		}
	}
}

.modal__subscription {
  .message-body__error,
  .message-body__conformation,
  .message-body__isMember {
    .checkmark-icon,
    .error-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .checkmark-icon {
      svg {
        path {
          fill: $success-green;
        }
      }
    }
  }

  .message-body__isMember {
    h2,
    p {
      text-align: center;
    }

    .btn-container {
      display: flex;
      justify-content: center;
    }
  }
}

.modal-popup {
  .message-body {
    &__error,
    &__conformation,
    &__isMember {
      .checkmark-icon,
      .error-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }

      h2,
      h3,
      h4 {
        color: $black;
      }

      h4,
      h2 {
        margin-bottom: 15px;
        text-align: center;
      }

      p {
        margin: 0 auto 30px auto;
        text-align: center;
        max-width: 400px;
      }

      .btn-container {
        display: flex;
        justify-content: center;
      }
    }

    &__error {
      display: none;
    }
  }
}
