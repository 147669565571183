.card__cta {
  border-radius: var(--sleeve-component-border-radius);
  overflow: hidden;

  .card__cta__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: var(--sleeve-component-padding);

    .card__cta__content__text {
      margin-bottom: var(--sleeve-component-spacing);

      p {
        margin-bottom: 10px;
      }
    }
  }

  .card__cta__image {
    img {
      width: 100%;
      height: 100%;
    }

    img:not([class*="aspect-ratios--"]) {
      height: 222px;

      @include media-breakpoint-down(sm) {
        height: 195px;
      }
		}
  }

  &.card__cta--landscape {
    height: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .card__cta__content,
    .card__cta__image {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    .card__cta__content {
      @include media-breakpoint-up(lg) {
        padding-left: 15px;
      }

      .btn__primary {
        width: fit-content;
      }
    }

    .card__cta__image {
      @include media-breakpoint-up(lg) {
        height: 100%;
        min-height: 100%;
        padding-right: 15px;
      }
    }
  }
}
