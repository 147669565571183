/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Progressbar
 */

progress {
  color: var(--darkmode-hightlight-1-200);

  &::-webkit-progress-value {
    background-color: var(--darkmode-hightlight-1-200);
  }

  &::-moz-progress-bar {
    background-color: var(--darkmode-hightlight-2-200);
  }
}

progress.progressbar__value {
  background-color: var(--darkmode-800);
}
