/*
** Global variables 
** Border-radius
*/

//- Component border-radius
$border-radius-component: var(--sleeve-component-border-radius);
$border-radius-component-image: var(--sleeve-component-image-radius);
$border-radius-image: var(--sleeve-image-radius);

//- Search-bar border-radius
$border-radius-search-bar-small: var(--sleeve-search-bar-small-border-radius);
$border-radius-form-elements: var(--border-radius-form-elements);
