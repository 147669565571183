.hero__event {
  position: relative;
  background: $color-body;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }

  .hero__event__content {
    padding: 80px 50px 60px 0;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 40px 0;
    }

    .hero__event__content__text {
      p {
        margin-bottom: 30px;
        @include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
        line-height: $line-height-article-body;
      }
    }

    .hero__event__content__info {
      margin-top: 30px;
      
      ul {
        @include list-unstyled();
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
  
        li {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
          padding-right: 20px;
          font-size: $component-font-size;
          line-height: $line-height-secondary;
          font-weight: 500;

          @include media-breakpoint-up(md) {
            flex: 0 0 50%;
          }

          a {
            font-weight: 500;
          }
  
          svg {
            margin-right: 15px;

            &.svg-date,
            &.svg-time,
            &.svg-location,
            &.svg-link {
              path {
                fill: $color-icons;
              }
            }
          
            &.svg-date {
              rect {
                fill: $color-icons;
              }
            }
          
            &.svg-time {
              path {
                stroke: $color-icons;
              }
          
              circle {
                stroke: $color-icons;
              }
            }
          
            &.svg-price {
              path {
                stroke: $color-icons;
              }
            }
          }
        }
      }
    }
  }

  .hero__event__media {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    padding-left: 15px;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      padding-left: 0;
    }

    img {
      height: 100%;
      object-fit: contain;
      object-position: right;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
