.media-box {
  position: relative;
  display: flex;
  height: auto;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.bg--transparent {
    .media-box__text {
      @include media-breakpoint-down(md) {
        padding: 40px 0 0 0;
      }
    }
  }

  .tags-container {
    margin-bottom: 15px;

    .tag,
    .label,
    .icon--inline-tag {
      margin-bottom: 0;
    }
  }

  .tag,
  .label,
  .icon--inline-tag {
    margin-bottom: 15px;
  }

  .media-box__text {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 5;
    padding: 100px 60px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 40px;
    }

    h2,
    h3 {
      margin-bottom: 30px;
      @include responsive-font-size($h1-font-size-sm, "", $h1-font-size-sm, "", $h1-font-size-lg);
      line-height: $line-height-primary;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }

    // When pre-text is set
    h2,
    h3 {
      strong {
        font-weight: $font-weight-heading;
      }

      span {
        font-family: $primary-font;
        font-weight: $font-weight-body;
      }
    }

    p {
      @include responsive-font-size($component-font-size, "", $component-font-size, "", calc($component-font-size + em(2px)));
      line-height: $line-height-secondary;
    }

    .link-container {
      margin-top: auto;
    }

    .btn__primary {
      align-self: flex-start;
    }

    .btn__link {
      margin-top: 15px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .btn-container {
      margin-top: 0;

      .btn {
        margin-top: 15px;
      }
    }

    //- Media box with/with out rich-text field
    .rich-text, 
    p {
      margin-bottom: 30px;
    }

    .rich-text {
      h3 {
        margin-bottom: 10px;
    
        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }
    
      h3 {
        @include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &.has-searchbar {
    //- bg--defualt does not work well with colored background, find new naming convention (AM comment)
    &:not([class*="bg-"]),
    &.bg--default {
      .search-bar--small {
        form .form-group input {
          background: $grey-light;
        }
      }
    }

    .search-bar--small {
      margin-top: 70px;

      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
    }
  }

  &.has-searchbar:not(.bg--transparent) {
    .media-box__text {
      padding: 100px 60px 60px 60px;

      @include media-breakpoint-down(md) {
        padding: 40px;
      }
    }
  }

  @import 'has-swiper';
}
