.hero__search-bar.hero__search-bar--variant-center {
  align-items: center;

	&.hero__search-bar--has-links {
		.search-bar--small {
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
	}

	.container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.row {
		flex-grow: 1;
		justify-content: center;
	}

	.h1,
  h2,
  .h2 {
		display: flex;
		justify-content: center;
    text-align: center;
	}

	.search-bar--small {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
	}

  .suggestions {
    justify-content: center;
    
    p {
      text-align: center;
    }
  }
}
