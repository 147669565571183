/**
 * Buttons
 * .btn__feedback
 */

.btn__feedback {
  display: flex;
  justify-content: center;
  align-items: center;
	background-color: transparent;
  padding: $btn-font-size;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50px;

	&:nth-child(n + 2) {
		margin-left: 30px;
	}

  [class*="icon-"] {
    font-size: em(25); // icon size
  }

  span {
    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }

	&--filled {
    border-width: $btn-primary-border-width;
    border-style: $btn-primary-border-style;
    border-color: $btn-primary-border-color;
		color: $btn-primary-text-color;
		background-color: $btn-primary-bg-color;

		&:hover {
			background-color: transparent;
			color: $black;

      span,
      [class*="icon-"]::before {
        color: $black;
      }
		}

    span,
    [class*="icon-"]::before {
      color: $btn-primary-text-color;
    }
	}

	&--border {
		color: $black;
    border-width: $btn-primary-border-width;
    border-style: $btn-primary-border-style;
		border-color: $btn-primary-border-color;

		&:hover {
			background-color: $btn-primary-bg-color;
			color: $btn-primary-text-color;

      span,
      [class*="icon-"]::before {
        color: $btn-primary-text-color;
      }
		}

    span,
    [class*="icon-"]::before {
      color: $black;
    }
	}
}

@include globalComponentColors($themes) {
  .btn__feedback {
    &--filled {
      border-color: themed('btn-primary-background');
      color: themed('btn-primary-text');
      background-color: themed('btn-primary-background');
      
      &:hover {
        color: themed('text');
        background-color: transparent;

        span,
        [class*="icon-"]::before {
          color: themed('text');
        }
      }

      span,
      [class*="icon-"]::before {
        color: themed('btn-primary-text');
      }
    }

    &--border {
      color: themed('text');
      border: 1px solid themed('btn-primary-background');

      &:hover {
        color: themed('btn-primary-text');
        background-color: themed('btn-primary-background');

        span,
        [class*="icon-"]::before {
          color: themed('btn-primary-text');
        }
      }

      span,
      [class*="icon-"]::before {
        color: themed('text');
      }
    }
  }
}

@include sectionColors($themes-section) {
  .btn__feedback {
    &--filled {
      border-color: themedSections('btn-primary-background');
      color: themedSections('btn-primary-text');
      background-color: themedSections('btn-primary-background');
      
      &:hover {
        color: themedSections('text');
        background-color: transparent;

        span,
        [class*="icon-"]::before {
          color: themedSections('text');
        }
      }

      span,
      [class*="icon-"]::before {
        color: themedSections('btn-primary-text');
      }
    }

    &--border {
      color: themedSections('text');
      border: 1px solid themedSections('btn-primary-background');

      &:hover {
        color: themedSections('btn-primary-text');
        background-color: themedSections('btn-primary-background');

        span,
        [class*="icon-"]::before {
          color: themedSections('btn-primary-text');
        }
      }

      span,
      [class*="icon-"]::before {
        color: themedSections('text');
      }
    }
  }
}
