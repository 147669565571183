// Icons for lists
@font-face {
	font-family: 'listicons';
	src: url('/assets/fonts/list-icons/icomoon.eot?rfzp4p');
	src:
	url('/assets/fonts/list-icons/icomoon.eot?rfzp4p#iefix') format('embedded-opentype'),
	url('/assets/fonts/list-icons/icomoon.ttf?rfzp4p') format('truetype'),
	url('/assets/fonts/list-icons/icomoon.woff?rfzp4p') format('woff'),
	url('/assets/fonts/list-icons/icomoon.svg?rfzp4p#icomoon') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

// Default - no class
body:not([class^="list-icon-"]) {

  .intext-arrow-link,
  .list__link,
  .btn__link-arrow--short {
    &::before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'listicons', sans-serif !important;
      speak: none;
      font-style: normal;
      font-weight: 500;
      font-variant: normal;
      text-transform: none;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    
      content: "\e101";
      position: absolute;
      left: -10px;
      top: 0;
      color: $color-arrows;
      transition: all 0.2s ease-in-out;
    }
  }

  .list__link {
    &:hover {
      &:before {
        left: -6px;
      }
    }
  }

  .list__links li,
  .side-box p span.intext-arrow-link,
  .side-box .list__links li {
    padding-left: em(10px) !important;
    padding-left: calc($component-font-size - em(6px)) !important;
  }

  .list__links li,
  .side-box p span.intext-arrow-link {
    margin-left: 10px;
  }

  .btn__link-arrow--short {
    &:hover::before {
      right: -5px;
    }

    &::before {
      left: auto;
      right: 0;
    }
  }

  .intext-arrow-link {
    margin-left: 10px;
    padding-left: 12px !important;

    &:before {
      left: -10px;
    }

    &.onHoverJS {
      &:hover {
        &::before {
          left: -5px;
        }
      }
    }
  }
}

// With link-icon classes
body[class^="list-icon-"],
body[class*="list-icon-"] {
  .list-icon,
  a.list__link,
  button.list__link,
  .side-menu__btn-back .arrow__back,
  .breadcrumb .arrow__back,
  .intext-arrow-link,
  .title__link .btn__link-arrow--short,
  .title__link .btn__link-arrow--short,
  .hightlight-links--arrow span a:not([class^="icon-"]),
  .hightlight-links--arrow p a:not([class^="icon-"]),
  .hightlight-links--arrow li a:not([class^="icon-"]) {
    &:before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'listicons', sans-serif !important;
      speak: none;
      font-style: normal;
      font-weight: 500;
      font-variant: normal;
      text-transform: none;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      position: absolute;
      left: -10px;
      top: 0;
      color: $color-arrows;
      transition: all 0.2s ease-in-out;

      // START - Delete after old arrow is removed
      width: auto;
      height: auto;
      border-style: none;
      border-color: transparent;
      border-width: 0;
      transform: none;
      // END - Delete after old arrow is removed
    }
  }

  .breadcrumb .arrow__back {
    width: 20px;

    &::before {
      left: 0;
      top: 50%;
      transform: rotate(180deg) translateY(55%);
    }
  }

  .has-overlay {
    .breadcrumb .arrow__back {
      &:before {
        color: var(--color-overlay-text);
      }
    }
  }

  a.list__link,
  button.list__link,
  .side-menu__btn-back .arrow__back {
    &:hover {
      &:before {
        left: -6px;
      }
    }
  }

  .btn__link-arrow--short {
    &::before,
    &:hover::before {
      left: auto !important;
    }

    &:hover::before {
      right: -5px;
    }

    &::before {
      right: 0;
    }
  }
}

body {
	// List icon arrow short
	&.list-icon-arrow-short {
		.list__links li {
			padding-left: em(10px) !important;
		}

		.list__links li,
		.side-box p span.intext-arrow-link {
			margin-left: 10px;
		}

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(10px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e101";
			}
		}

		.intext-arrow-link {
			margin-left: 10px;
			padding-left: 12px !important;

			&:before {
				content: "\e101";
				left: -10px;
			}

			&.onHoverJS {
				&:hover {
					&::before {
						left: -5px;
					}
				}
			}
		}
	}

	// List icon arrow long
	&.list-icon-arrow-long {
		.breadcrumb .arrow__back {
			width: 25px;
		}

		.list__links li {
			padding-left: em(19px) !important;
			margin-left: 10px;
		}

    .side-box .list__links li,
		.side-box p span.intext-arrow-link {
			margin-left: 19px;
		}

    .side-box .list__links li a.list__link {
      &:hover {
        &::before {
          left: -16px;
        }
      }
    }

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(19px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e102";
			}
		}

		.intext-arrow-link {
			margin-left: 19px;
			padding-left: 12px !important;

			&:before {
				content: "\e102";
				left: -21px;
			}

			&.onHoverJS {
				&:hover {
					&::before {
						left: -16px;
					}
				}
			}
		}

    .side-box .list__links li a.list__link,
    .intext-arrow-link {
      &:before {
				left: -21px;
			}
    }

    .title__link .btn__link-arrow--short {
			padding-right: 30px;
		}

    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			margin-left: 29px;

			&::before {
				left: -31px !important;
			}

			&:hover {
				&::before {
					left: -26px !important;
				}
			}
		}
	}

	// List icon arrow medium
	&.list-icon-arrow-medium {
		.list__links li {
			padding-left: em(15px) !important;
			margin-left: 10px;
		}

    .side-box .list__links li,
		.side-box p span.intext-arrow-link {
			margin-left: 15px;
		}

    .side-box .list__links li a.list__link {
      &:hover {
        &::before {
          left: -12px;
        }
      }
    }

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(15px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e103";
			}
		}

		.intext-arrow-link {
			margin-left: 17px;
			padding-left: 12px;

			&:before {
				content: "\e103";
				left: -17px;
			}

			&.onHoverJS {
				&:hover {
					&::before {
						left: -12px;
					}
				}
			}
		}

    .side-box .list__links li a.list__link,
    .intext-arrow-link {
      &:before {
				left: -16px;
			}
    }

    .title__link .btn__link-arrow--short {
			padding-right: 25px;
		}

    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			margin-left: 25px;

			&::before {
				left: -26px !important;
			}

			&:hover {
				&::before {
					left: -21px !important;
				}
			}
		}
	}

	// List icon arrow sharp regular & thick
	&.list-icon-arrow-sharp,
	&.list-icon-arrow-sharp-thick {
		.list__links li,
		.side-box p span.intext-arrow-link {
			margin-left: 10px;
		}

		.intext-arrow-link {
			margin-left: 10px;
			padding-left: 12px;

			&:before {
				left: -10px;
			}

			&.onHoverJS {
				&:hover {
					&::before {
						left: -5px;
					}
				}
			}
		}
	}

	// List icon arrow sharp
	&.list-icon-arrow-sharp {
		.list__links li {
			padding-left: em(10px) !important;
		}

		.header__megamenu-extended {

			.list__links li {
        padding-left: em(10px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e104";
			}
		}
	}

	// List icon arrow sharp thick
	&.list-icon-arrow-sharp-thick {
		.list__links li {
			padding-left: em(10px) !important;
		}

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(10px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e105";
			}
		}
	}

	// List icon arrow short circle outline & arrow medium circle outline
	&.list-icon-arrow-short-circle-outline,
	&.list-icon-arrow-medium-circle-outline {

		.list__links li {
			margin-left: 10px;
		}

		.side-box p span.intext-arrow-link {
			margin-left: 18px;
		}

		.intext-arrow-link,
    .side-box .list__links li {
			margin-left: 20px;
			padding-left: 12px;
		}

    .intext-arrow-link {
			&:before {
				left: -18px;
			}

			&.onHoverJS {
				&:hover {
					&::before {
						left: -13px;
					}
				}
			}
		}

    .side-box .list__links li a.list__link {
      &:before {
				left: -18px;
			}
      
      &:hover {
        &::before {
          left: -13px;
        }
      }
    }

    .title__link .btn__link-arrow--short {
			padding-right: 25px;
		}

    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			margin-left: 25px;

			&::before {
				left: -26px !important;
			}

			&:hover {
				&::before {
					left: -21px !important;
				}
			}
		}
	}

	// List icon arrow short circle outline
	&.list-icon-arrow-short-circle-outline {
		.list__links li {
			padding-left: em(15px) !important;
		}

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(15px) !important;
			}
		}

    .list-icon,
		a.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e106";
			}
		}
	}

	// List icon arrow medium circle outline
	&.list-icon-arrow-medium-circle-outline {
		.list__links li {
			padding-left: em(15px) !important;
		}

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(15px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e107";
			}
		}
	}

	&.list-icon-arrow-short,
	&.list-icon-arrow-long,
	&.list-icon-arrow-medium,
	&.list-icon-arrow-sharp,
	&.list-icon-arrow-sharp-thick,
	&.list-icon-arrow-short-circle-outline,
	&.list-icon-arrow-medium-circle-outline {
		.header__megamenu-extended {
			.list__links li {
        margin-left: 10px;

        @include media-breakpoint-down(md) {
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
			}
		}
	}

	// List icon arrow filled circle
	&.list-icon-arrow-filled-circle {
		.list__links li {
			padding-left: em(15px) !important;
		}

		.header__megamenu-extended {
			.list__links li {
        padding-left: em(15px) !important;
			}
		}

    .list-icon,
		a.list__link,
    button.list__link,
		.arrow__back,
		.intext-arrow-link,
    .title__link .btn__link-arrow--short,
    .hightlight-links--arrow span a:not([class^="icon-"]),
		.hightlight-links--arrow p a:not([class^="icon-"]),
		.hightlight-links--arrow li a:not([class^="icon-"]) {
			&:before {
				content: "\e900";
			}
		}
	}

	&.list-icon-arrow-short,
	&.list-icon-arrow-long,
	&.list-icon-arrow-medium,
	&.list-icon-arrow-sharp,
	&.list-icon-arrow-sharp-thick,
	&.list-icon-arrow-short-circle-outline,
	&.list-icon-arrow-medium-circle-outline {
		.header__megamenu-extended {
			.list__links li {
        margin-left: 10px;

        @include media-breakpoint-down(md) {
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
			}
		}
	}
}
