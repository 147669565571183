.card__comment {
  position: relative;
  border-radius: var(--sleeve-component-border-radius);
  padding: 30px;

  p {
    margin-bottom: 10px;
  }

  .btn__like {
    margin-left: auto;
  }
}

.card__comment.js-card__comment {
  .card__comment__content {
    order: 1;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    &.closed {
			-webkit-mask-image: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 0.9)),
				to(rgba(0, 0, 0, 0))
			);
    }
  }

  button.btn-toggle {
    order: 2;
    position: relative;
    width: fit-content;
    height: 30px;
    background: $grey-light;
    text-align: left;
    padding: 0;
    margin-top: 15px;
    padding-right: 20px;
    font-size: $btn-link-font-size;
    font-weight: $btn-font-weight;

    &:hover,
    &:focus {
      color: $color-link;
    }

    &.open {
      .toggle--icon-plus {
        &:after {
          transform: rotate(90deg);
        }
      }
    }

    &.hidden {
      display: none;
    }

    .toggle--icon-plus {
      width: 12px;
      height: 12px;
    }
  }
}