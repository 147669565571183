// Space allocation on the burger-menu version
.burger-menu {
	.sidemenu {
		visibility: hidden;
		animation: overlayFadeOut 0.5s ease forwards;
		transition: visibility 0.5s;

		.sidemenu__primary,
		.sidemenu__secondary {
			opacity: 0;
		}
	}

	// left ------------------ left
	&.burger-menu--left {
		.search-form__btn {
			left: 0;
		}

		.search-form__input.active,
		.search-form__input:focus {
			padding-left: 45px;
		}

		.sidemenu.active {
			.sidemenu__secondary,
			.sidemenu__primary {
				animation: itemFadeInLeft 0.7s ease forwards;
			}

			.sidemenu__secondary {
				animation-delay: 0.2s;
			}
		}

		.sidemenu.inactive {
			.sidemenu__secondary,
			.sidemenu__primary {
				animation: itemFadeOutLeft 0.5s ease forwards;
			}
		}
	}

	// right ----------------------- right
	&.burger-menu--right {
		.search-form__btn {
			right: 0;
		}

		.sidemenu.active {
			.sidemenu__secondary,
			.sidemenu__primary {
				animation: itemFadeInRight 0.7s ease forwards;
			}

			.sidemenu__secondary {
				animation-delay: 0.2s;
			}
		}

		.sidemenu.inactive {
			.sidemenu__secondary,
			.sidemenu__primary {
				animation: itemFadeOutRight 0.5s ease forwards;
			}
		}
	}
}
