/**
 * Footers
 */

.footer {
  position: relative;
  overflow: hidden;
  background-color: var(--color-footer);

  .footer__container {
    padding-top: var(--footer-pt-lg);
    padding-bottom: var(--footer-pb-lg);
  
    @include media-breakpoint-down(sm) {
      padding-top: var(--footer-pt-md);
      padding-bottom: var(--footer-pb-md);
    }

    .row:nth-child(2) {
      margin-top: 30px;
    }
  }

	section {
		&:first-of-type {
			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
		}
	}

	& .logo {
		display: flex;
		margin-bottom: 30px;
		height: auto;
		width: auto;

		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}

		img {
			width: auto;
			height: var(--footer-brand-height-lg);
			object-fit: contain !important;
			background-size: contain !important; // IE fix
			background-position-x: left !important; // IE fix

			@include media-breakpoint-down(md) {
				height: var(--footer-brand-height-md);
			}
		}

		h2 {
			font-family: $primary-font;
			font-size: calc($header-nav-link-font-size + em(6px));
			line-height: 1.15;
			font-weight: $font-weight-body;
			margin-bottom: 0;
      color: var(--color-footer-text);

			strong {
				font-family: $primary-font;
			}

			@include media-breakpoint-down(md) {
				font-size: $header-nav-link-font-size;
			}
		}
	}

  .footer__content {
    @include media-breakpoint-down(md) {
      margin-bottom: var(--section-margin-bottom-sm--medium);
		}

    &.dropdown-mobile {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

    .rich-text {
      margin-bottom: 0;

      h2,
      .h3 h4,
      h3,
      p,
      li,
      li::marker,
      a,
      .dropdown-toggle {
        color: var(--color-footer-text);
      }
    }

    h2,
    .h3 h4,
    h3,
    p,
    li,
    a,
    .dropdown-toggle,
    .dropdown-settings__toggle,
    .dropdown-settings__toggle [class*="icon-"]::before,
    .tag {
      color: var(--color-footer-text);
    }

    .list__icons ul li span[class*="icon-"]::before,
    ul.list__icons li span[class*="icon-"]::before {
      color: var(--color-footer-icons);
    }

    .dropdown-settings {
      .dropdown-settings__container {
        background-color: var(--color-footer);
        border: 1px solid var(--color-footer-text);

        .dropdown-settings__heading {
          margin-bottom: 10px !important;
        }

        ul li {
          border-color: var(--color-footer-text);
          margin-bottom: 1em !important;

          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }

      .dropdown-settings__toggle::before {
        border-color: var(--color-footer-text);
      }
    }

    .btn__toggle {
      border-color: var(--color-footer);
      background: var(--color-footer-text);
      
      .btn__toggle__toggle {
        background: var(--color-footer);
      }

      .btn__toggle__elements {
        [class*="icon"]::before {
          color: var(--color-footer);
        }
      }

      &.active {    
        .btn__toggle__elements {
          [class*="icon"]::before {
            color: var(--color-footer);
          }
        }
      }
    }

    h2,
    .h3 h4,
    h3,
    .dropdown-toggle {
      line-height: $line-height-primary;
    }
  
    p,
    a {
      line-height: $line-height-secondary;
    }

    li,
    p,
    .dropdown-settings__toggle {
      font-size: $component-font-size;
    }
  
    li a {
      font-size: inherit;
    }
  
    p a {
      display: initial;
    }
  
    p,
    li {
      a:not(.list__link):not(.btn) {
        color: var(--color-footer-text);
        text-decoration: none;
        background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
        background-position: 0% 99%;
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition: $transition;
        font-weight: $font-weight-body;
  
        &:hover,
        &:visited,
        &:focus {
          color: var(--color-footer-text);
          background-size: 100% 1px;
        }
      }
    }
  
    ul {
      @include list-unstyled();

      &.mb--xsmall {
        margin-bottom: 0;
      }
  
      li {
        width: fit-content;
        height: auto;
        margin-bottom: 5px;
        font-size: $component-font-size;
        line-height: $line-height-secondary;
      }
    }
  
    .btn__primary{
      color: var(--color-btn-bg--footer-text);
      background-color: var(--color-btn-bg--footer);
      border-color: var(--color-btn-bg--footer);
  
      &:hover,
      &:focus {
        color: var(--color-footer-text);
        background-color: transparent;
      }
    }

    .btn__primary--outline {
      color: var(--color-footer-text);
      background-color: transparent;
      border-color: var(--color-btn-bg--footer);
  
      &:hover,
      &:focus {
        color: var(--color-btn-bg--footer-text);
        background-color: var(--color-btn-bg--footer);
  
        &::before {
          opacity: 1;
        }
      }
    }

    .form-subscribe {
      label {
        color: var(--color-footer-text);
      }

      .form-group input {
        background-color: transparent;
        color: var(--color-footer-text);
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-footer-text);
  
        &::placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder {
          color: var(--color-footer-text);
        }
      }

      .form-check {
        .checkbox {
          .checkbox__indicator {
            background: var(--color-footer);
            border-color: var(--color-footer-text);
      
            &:after {
              border-color: var(--color-footer-text);
            }
          }
      
          & input:checked ~ .checkbox__indicator {
            border-color: var(--color-footer-text);
            background: var(--color-footer);
          }
      
          & input:disabled ~ .checkbox__indicator {
            background: var(--color-footer);
      
            &:after {
              border-color: var(--color-footer-text);
            }
          }
        }
      }

      .flex-row {
        .btn {
          margin-top: 0;
        }
      }
    }
  }

	.dropdown-toggle {
		padding: 0;
		text-align: left;

		&[disabled] {
			cursor: default;
		}
	}

  .row--align-right {
    justify-content: flex-end;
  }

  .row--align-right,
  .col--align-right {
    .logo {
      justify-content: flex-end;
    }
  }

  .col--align-right {
    .footer__content {
      width: fit-content;
      margin-left: auto;
    }
  }

  .col--align-center {
    .footer__content {
      width: fit-content;
      margin: 0 auto;
    }
  }

	.dropdown-toggle {
		white-space: unset;

		@include media-breakpoint-down(sm) {
			position: relative;
			cursor: pointer;
			width: 100%;
			padding-right: 20px;
		}

    &::before,
    &::after {
      border-color: var(--color-footer-text);
    }

		&:after {
			display: none;

			@include media-breakpoint-down(sm) {
				position: absolute;
				content: "";
				display: flex;
				right: 2px;
				top: 45%;
				transition: all .2s ease-in-out;
				width: 9px;
				height: 9px;
				border-style: solid;
				border-width: 2px 2px 0 0;
				transform: translate(0, -50%) rotate(135deg);
			}
		}

		&.show {
			&:after {
				@include media-breakpoint-down(sm) {
					top: 55%;
					transform: translate(0, -50%) rotate(-45deg);
				}
			}
		}
	}

	.dropdown-menu {
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		display: block;
		min-width: auto;
		width: 100%;
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;
		font-size: inherit;
		color: unset;
		background-color: transparent;
		background-clip: unset;
		border: none;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

  .footer__container {
    .row-reverse {
      [class*="col-"]:first-of-type {
        .logo {
          flex-direction: row-reverse;
        }

        .footer__content {
          width: fit-content;
          margin-left: auto;
        }
      }
    }

    [class*="col-"]:first-of-type {
      .btn {
        margin-top: 25px;
      }
    }

    .footer__content {
      display: flex;
      flex-direction: column;

      .btn {
        width: fit-content;
      }
    }
  }

  // Footer spacing mobile
  .col--footer {
    @include media-breakpoint-down(sm) {
      margin-bottom: calc(var(--section-margin-bottom-sm--medium) / 2);
    }

    &:not(.order-mobile-1) {
      &:last-of-type {
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
  
        .footer__content:not(.dropdown-mobile) {
          @include media-breakpoint-down(sm) {
            margin-bottom: 0;
          }
        }
      }    
    }

    .footer__content:not(.dropdown-mobile) {
      @include media-breakpoint-down(sm) {
        margin-bottom: calc(var(--section-margin-bottom-sm--medium) / 2);
      }
    }

    [class*=col-].mb--xsmall,
    .mb--medium {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }

      .footer__content {
        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }
      }
    }

    // Two column footer grid option
    .col-md-12.col-lg-6 {
      &:last-of-type {
        .footer__content:not(.dropdown-mobile) {
          @include media-breakpoint-down(sm) {
            margin-bottom: 0;
          }
        }
      }

      .footer__content:not(.dropdown-mobile) {
        @include media-breakpoint-down(sm) {
          margin-bottom: var(--section-margin-bottom-sm--medium) ;
        }
      }
    }
  }
}

// Delete after new mark-up implementation
.footer	{
  .footer__campaign,
  .footer__extended {
    p,
    ul {
      &:last-child {
        margin-bottom: 80px;
    
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .footer__extended {
    p,
    ul {
      &:last-child {
        margin-bottom: 80px;
    
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }
    }
  
    // Footer grid
    .col-md-12.col-lg-6 .col-md-6 {
      padding: 0;
    }
  }
}

body:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {
  .footer .list__icons li {
    a:not(.list__link):not(.btn) {
      color: var(--color-footer-text);
      text-decoration: none;
      background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
      background-position: 0% 99%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: $transition;
      font-weight: $font-weight-body;

      &:hover,
      &:visited,
      &:focus {
        color: var(--color-footer-text);
        background-size: 100% 1px;
      }
    }
  }
}
