/**
 * Buttons
 * .btn__link-arrow--long
 */

.btn__link-arrow--long {
  display: flex;
  color: $black;
  line-height: 24px;

  &:hover,
  &:focus {
    color: $color-arrows;

    .arrow {
      margin-left: 5px;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    margin-right: 10px;
    transition: $transition;
  }
}

.span__link-arrow--long {
	display: -webkit-box;
	align-items: center;
	color: $black;
	line-height: 1.5;

	.arrow {
		display: flex;
		margin-right: 10px;
		transition: $transition;
		margin-bottom: auto;
	}
}
