.member-message {
	background-color: $color-primary;
	padding: 10px 15px;
	font-weight: 700;
	margin: 0 0 20px 0;

	p {
		color: $color-primary-text;
		margin-bottom: 0;
    font-size: $article-font-size-sm;

		a {
			color: $color-primary-text;
			font-weight: 800;
			background-image: linear-gradient($color-primary-text, $color-primary-text);

			&:hover {
				color: $color-primary-text;
			}
		}
	}
}
