/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Side menu
 */
.side-menu__dropdowns,
.side-menu__no-dropdowns {
  .side-menu__toggle-mobile {
    @media only screen and (max-width: 991.98px) {
      background-color: var(--darkmode-800);
      color: var(--darkmode-50);
    }

    &::before {
      @media only screen and (max-width: 991.98px) {
        background: var(--darkmode-600);
      }
    }

    &::after {
      @media only screen and (max-width: 991.98px) {
        border-color: var(--darkmode-hightlight-1-200);
      } 
    }
  }

  ul.sidemenu__dropdown {
    @media only screen and (max-width: 991.98px) {
      background:var(--darkmode-700);
    }
  }
}

.side-menu__no-dropdowns {
  button.side-menu__btn-back {
    color: var(--darkmode-hightlight-1-200);

    .arrow__back:after {
      background-color: var(--darkmode-hightlight-1-200);
    }
  }
}

.side-menu.side-menu__dropdowns {
  ul {
    li {
      &.side-menu__level-one {
        &:first-of-type {
          border-top: 3px solid var(--darkmode-600);
        }

        .dropdown-menu {
          background: transparent;
        }

        .side-menu__level-one__toggle, 
        .side-menu__level-one__link {
          border-bottom: 1px solid var(--darkmode-600);
        }
      }

      .side-menu__link.active::after {
        background-color: var(--darkmode-700);
      }
    }

    li.side-menu__level-one.show:before, 
    li.side-menu__level-one .active:before {
      background: var(--darkmode-600);
    }
  }
}

.dropdown-toggle.show .icon-toggle:after {
  border-color: var(--darkmode-hightlight-1-200) !important;
}

.dropdown-toggle .icon-toggle:after {
  border-color: var(--darkmode-hightlight-1-200) !important;
}
