/**
 * Buttons
 */

@use "sass:math";

button:enabled,
.btn:enabled {
	cursor: pointer;
}

button:disabled {
  cursor: default;
}

.btn-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin-top: 30px;
  gap: 30px;

	@include media-breakpoint-down(sm) {
		margin-top: 15px;
	}

	.btn {
		@include media-breakpoint-down(sm) {
			margin-top: 15px;
		}
	}

  form {
    margin-bottom: 0;
  }
}

.side-box {
  .btn-container {
    margin-top: 0;
    justify-content: center;
  
    .btn {
      width: 100%;
    }
  
    .btn__link {
      margin-top: 25px;
    }
  }

  form {
    .btn-container {
      justify-content: left;
    }
  }
}

// Mixed button/links
.link-container {
  .btn__link-arrow--long {
    width: fit-content;
  }

  .btn__primary,
  .btn__component,
  .btn__component--outline {
    margin-right: 30px;
    margin-top: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.btn {
	font-family: $btn-font-family;
	display: inline-block;
	min-width: var(--sleeve-btn-min-width);
	padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x);
	border: 1px solid transparent;
	background-color: $btn-bg;
	color: $btn-text-color;
	vertical-align: middle;
	text-align: center;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	transition: $transition-base;
	user-select: none;
	text-decoration: none;
  line-height: 1.2;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	a {
		font-family: $btn-font-family;
	}

	&.btn__primary,
	&.btn__primary--outline,
	&.btn__primary-arrow--long,
	&.btn__submit,
	&.btn__secondary,
	&.btn__secondary--outline,
	&.btn__selfservice {
		border-radius: var(--sleeve-button-border-radius);

		&:after {
			border-radius: var(--sleeve-button-border-radius);
		}
	}
}

.btn--filled,
.btn__primary,
.btn__primary--outline,
.btn__secondary,
.btn__submit,
.btn__feedback {
	text-decoration: none;
  font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	font-family: $btn-font-family;
	transition: $transition-base;
}

.btn__primary,
.btn__primary-arrow--long,
.btn__submit,
.btn__selfservice,
.btn__secondary {
  &:hover,
  &:focus {
    background: $transparent;
    color: $black;
  }
}

.btn__primary,
.btn__primary--outline,
.btn__primary-arrow--long,
.btn__submit,
.btn__selfservice,
.btn__secondary,
.btn__secondary--outline,
.btn__component {
	display: block;
	border: none;
	width: auto;
	margin-left: 0;
	margin-right: 0;
	text-decoration: none;
	font-weight: $btn-font-weight;
	font-family: $btn-font-family;
	transition: $transition-base;

	&:after {
		transition: $transition-base;
	}
}

.btn__primary,
.btn__primary--outline,
.btn__submit,
.btn__selfservice {
	padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x);
	min-height: var(--sleeve-btn-height);
	@include responsive-font-size($btn-font-size, "", "", "", $btn-font-size);
}

// Primary buttons
.btn__primary:not(input),
.btn__submit:not(input),
.btn__component,
.btn__primary,
.btn__submit {
  display: inline-flex;
  justify-content: center;
  border-radius: var(--sleeve-button-border-radius);
	background: $btn-primary-bg-color;
	color: $btn-primary-text-color;
  border-color: $btn-primary-bg-color;
  border-width: $btn-primary-border-width;
  border-style: $btn-primary-border-style;

  &:disabled {
    &:hover {
      background: $btn-primary-bg-color;
      color: $btn-primary-text-color;
    }
  }
}

.btn__component {
  .btn__component__text,
  [class*="icon-"]::before,
  .arrow::before {
    color: $btn-primary-text-color;
  }

  &:hover,
  &:focus {
    .btn__component__text,
    [class*="icon-"]::before,
    .arrow::before {
      color: $black;
    }
  }

  &.has-arrow {
    .arrow::before {
      color: $btn-primary-text-color;
    }
  }
}

.btn__primary,
.btn__submit {
	z-index: 0;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;

  &:disabled {
    cursor: default;
  }
}

.btn__secondary--outline {
  &:hover,
  &:focus {
    background: $btn-secondary-bg-color;
    color: $btn-secondary-text-color;
  }
}

.btn__component--outline,
.btn__primary--outline,
.btn__primary-arrow--long--outline {
  color: $btn-primary-outline-text-color;
	background: transparent;
  border-width: $btn-primary-border-width;
  border-style: $btn-primary-border-style;
  border-color: $btn-primary-border-color;

  &:hover,
	&:focus {
    background: $btn-primary-bg-color;
    color: $btn-primary-text-color;
	}

  &:disabled {
    &:hover,
    &:focus {
      color: $btn-primary-outline-text-color;
	    background: transparent;
    }
  }
}

.btn__component--outline {
  .btn__component__text,
  [class*="icon-"]::before {
    color: $btn-primary-outline-text-color;
  }

  &.has-arrow .arrow::before {
    color: $color-arrows;
  }

  &:hover,
  &:focus {
    .btn__component__text,
    [class*="icon-"]::before,
    &.has-arrow .arrow::before {
      color: $btn-primary-text-color;
    }
  }
}

.btn__component--outline,
.btn__primary--outline {
  &.active {
    color: $btn-primary-text-color;
    background-color: $btn-primary-bg-color;
  }
}
