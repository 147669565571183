.hero__multi-boxes:not(.height-auto) {
	@include media-breakpoint-up(md) {
		min-height: calc(100vh - #{var(--header-height-lg)}) !important;
		height: 100% !important;
	}

  .swiper {
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{var(--header-height-lg)}) !important;
    }
  }
}

.hero__multi-boxes {
	@include media-breakpoint-up(md) {
		position: relative;
		padding: 100px 0;
		display: flex;
		align-items: flex-end;
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;
		height: auto;
		min-height: auto;
		padding: 0;
	}

  .tags-container {
    margin-bottom: 10px;

    .tag,
    .label,
    .icon--inline-tag {
      margin-bottom: 0;
    }
  }

  .tag,
  .label,
  .icon--inline-tag {
    margin-bottom: 10px;
  }

  .hero__multi-boxes__media,
  .background-video,
  .hero__multi-boxes__bg-color {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@include media-breakpoint-down(sm) {
			position: relative;
			height: 160px;
		}

		img {
			height: 100%;
			width: 100%;
		}
	}

	/* ---- grid ----- */
	.hero__multi-boxes__grid {
		margin: 0 -15px;

		/* clearfix */
		&:after {
			content: "";
			display: block;
			clear: both;
		}

		.grid-column-two {
			width: 50%;
		}

		.grid-column-one {
			width: 100%;
		}
	}

	/* ---- grid-item ---- */
  a.hero__multi-boxes__box {
    cursor: pointer;
  }
  
	.hero__multi-boxes__box {
		height: auto;
		width: 100%;
		float: left;

		@include media-breakpoint-up(md) {
			padding: 15px;
		}

		@include media-breakpoint-down(sm) {
      margin-top: 0px;
      border-bottom: 2px solid $color-body;
		}

		&:first-of-type {
			@include media-breakpoint-down(sm) {
				margin-top: 0;
			}
    }

    &:last-of-type {
      @include media-breakpoint-down(sm) {
        border-bottom: unset;
      }
    }

		&:hover {
			.hero__multi-boxes__box__text {
				.hero__multi-boxes__box__overlay {
					opacity: 1;
				}

				.span__link .arrow {
					margin-left: 5px;
				}
			}
		}

		&.hero__multi-boxes__box--narrow {
			@include media-breakpoint-up(md) {
				width: 50%;
			}
		}

    .span__link-arrow--long {
      span {
        display: flex;
        width: calc(100% - 40px);
      }
    }

		.hero__multi-boxes__box__text {
			position: relative;
			margin-bottom: 0;
			padding: 40px 30px;

			@include media-breakpoint-down(sm) {
        z-index: 1;
        padding: 15px;
      }

      /* When pre-text is set */
      h2 {
        strong {
          font-weight: $font-weight-heading;
        }

        span {
          font-family: $primary-font;
          font-weight: $font-weight-body;
        }
      }

      .rich-text {
        margin-bottom: 20px;
      }

      .search-bar--small {
        margin-top: 15px;

        form .form-group input {
          background-color: $grey-light;
        }
      }

      @include nestedComponentColors($themes) {
        h2,
				h2 p,
				p,
				span,
        .label,
        .tag,
        .arrow::before {
          color: themed('text');
        }
      }

      @include nestedArticleColors($themes-article) {
        h2,
				h2 p,
				p,
				span,
        .label,
        .tag,
        .arrow::before {
					color: themedArticle('text');
				}
      }

			.hero__multi-boxes__box__overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 0;
				opacity: 0.85;
				background-color: $white;
        transition: $transition;
        border-radius: var(--sleeve-component-border-radius);

        @include media-breakpoint-down(sm) {
          opacity: 1;
          border-radius: 0;
        }

        @include nestedComponentColors($themes) {
          background-color: themed('background');
        }

        @include nestedArticleColors($themes-article) {
          background-color: themedArticle('background');
        }
			}

			h2,
			p,
      .tags-container,
      .tag,
      .label,
      .icon--inline-tag {
				position: relative;
			}

      h2 {
        @include media-breakpoint-down(sm) {
          font-size: $component-font-size;
          line-height: $line-height-primary;
        }
      }

      p {
        font-size: $component-font-size;
        line-height: $line-height-secondary;
      }

      h2 p {
        @include media-breakpoint-down(sm) {
          display: inline;
        }
      }
		}
	}

  // Grid
  .container {
    @include media-breakpoint-down(md) {
			max-width: 100%;
		}
  }

  @import 'has-carousel';

  //Specific styles for hero multi-boxes with carousel
  &.has-carousel {
    @include media-breakpoint-down(sm){
      .swiper {
        position: relative;

        img {
          height: 160px;
          min-height: 100%;
        }
      }
    }
  }
}
