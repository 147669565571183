/**
 * Select
 */

select {
  appearance: auto;
}

select,
.select select {
	padding: 5px 35px 5px 15px;
  cursor: pointer;
}

select,
.select select,
.select-checkboxes {
  height: 50px;
  border-radius: $border-radius-form-elements;
}

.select select {
  position: relative;
	width: 100%;
	border: none;
	font-weight: 400;
	background: $white;
	appearance: none;
	transition: $transition;
  color: $grey-dark;

  &::placeholder {
    color: $grey-dark;
  }

  /* CAUTION: IE hackery ahead */
	&::-ms-expand {
		/* remove default arrow in IE 10 and 11 */
		display: none;
	}

	option {
		transition: $transition;
		color: $black;
	}

	@media screen and (min-width: 0\0) {
		/* target Internet Explorer 9 to undo the custom arrow */
		background: none\9;
		padding: 5px\9;
	}
}

.select button {
  color: $grey-dark;
}

.form-select,
.select {
  width: 100%;
  margin-bottom: 20px;
}

.form-group {
  .select {
    margin-bottom: 0;
  }
}

.select,
.select-checkboxes {
	position: relative;

	&:after {
		position: absolute;
		content: "";
		right: 15px;
		top: 49%;
		width: 9px;
		height: 9px;
		border: 2px solid $black;
		border-width: 2px 2px 0 0;
		transform: translate(0, -50%) rotate(135deg);
		pointer-events: none;
	}
}

.select-multiple {
  &::after {
    display: none
  }

  select[multiple] {
    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }

    option {
      position: relative;
      padding: 5px 0;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
}

select[multiple] {
  height: 60px;
  padding: 0;

  option {
    padding: 5px 15px;
  }
}

.select-checkboxes {
	border: none;
	background: $white;

	button {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 5px 35px 5px 15px;
		text-align: left;
		font-weight: $font-weight-body;

		&.dropdown-toggle:after {
			display: none;
		}
	}

	.dropdown-menu {
		z-index: 1;
		top: 3px;
		left: -15px;
		width: 100%;
		background: $white;
		border-radius: 0;
		border-color: $black;
		margin: 0;
		padding: 0;

		li {
      background-color: $white;
			padding: 10px 15px;
			margin: 0;

			&:hover {
				// Same color as standard HTML select hover blue
				background-color: #1e90ff;
				color: $white;
			}

			.form-check {
				margin-bottom: 0;

				.checkbox input {
					top: -2px;
          padding: 0;
				}

				.checkbox__indicator {
					top: -2px;
				}
			}
		}
	}

	.divider {
		position: absolute;
		top: 45px;
		left: 0;
		height: auto;
		width: 100%;
		padding: 0;
		border-top: 1px solid $black;
	}
}
