/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Info boxes
 */

.info-box__links-extended .info-box__links-extended__list li:first-of-type a,
.info-box__links-extended .info-box__links-extended__list li:first-of-type p,
.info-box__links-extended .info-box__links-extended__list__text a,
.info-box__links-extended .info-box__links-extended__list__text p {
  color: var(--darkmode-50);
}

.info-box__links-extended ul.info-box__links-extended__list li {
  border-color: var(--darkmode-600);
}

.info-box__links__icon {
  .container .row [class*="col-"] {
    @media only screen and (max-width: 991.98px) {
      margin-bottom: 2px;
    }

    &:last-child {
      @media only screen and (max-width: 991.98px) {
        margin-bottom: 0;
      }
    }
  }

  button.js-toggle:after {
    @media only screen and (max-width: 991.98px) {
      border-color: var(--darkmode-hightlight-1-200) !important;
    }
  }
}

.info-box__links-extended
  .info-box__links-extended__title {
  @media only screen and (max-width: 991.98px) {
    border-color: var(--darkmode-hightlight-1-200) !important;
  }
}

// Framed option
.framed--components,
.info-box__links__icon--framed {
  border: var(--darkmode-700);
  background-color: var(--darkmode-800) !important;

  @media only screen and (max-width: 991.98px) {
    background-color: var(--darkmode-600) !important;
  }
}
