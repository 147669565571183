/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Tab panel
 */

.tab-panel--container {
  .tab-controls {
    .tab-control--container {
      @media only screen and (min-width: 992px) {
				border: 1px solid var(--darkmode-600);
			}
      
      &:last-of-type {
        @media only screen and (min-width: 992px) {
          border-right: 1px solid var(--darkmode-600);
        }
      }
    }

    .tab-control {
      background-color: var(--darkmode-800);

      @media only screen and (max-width: 991.98px) {
				background-color: var(--darkmode-800);
        outline: 1px solid var(--darkmode-600);
			}

      &:after {
        @media only screen and (max-width: 991.98px) {
          border-color: var(--darkmode-hightlight-1-200);
        }
      }
    }

    .tab-control.active,
    .tab-control.active::after,
    .tab-control--container.active .tab-control {
      background-color: var(--darkmode-700);
      border-bottom-color: var(--darkmode-hightlight-1-200);
    }

    .tab-control:not(.active) {
      &::after {
        background-color: var(--darkmode-hightlight-1-200);
      }

      &:hover {
        span:not(.icon) {
          color: var(--darkmode-hightlight-1-200);
        }
      }
    }
  }
}
