.dropdown--md-down {
  @include media-breakpoint-down(md) {
    position: relative;
  }

  .dropdown--md-down-toggle {
    @include media-breakpoint-down(md) {
      z-index: 2;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 20px 35px 20px 15px;
      text-align: left;
      font-weight: 500;
      background-color: $grey-light;
      border-style: solid;
      border-color: $grey-medium;
      border-width: 1px;
      transition: background-color .2s ease-in-out;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:after {
      position: absolute;
      content: "";
      display: flex;
      right: 17px;
      top: 45%;
      transition: all .2s ease-in-out;
      width: 9px;
      height: 9px;
      border-style: solid;
      border-color: $black;
      border-width: 2px 2px 0 0;
      transform: translate(0, -50%) rotate(135deg);
		}

    &.show {
      @include media-breakpoint-down(md) {
        background-color: $white;
      }
    
      &:after {
        top: 50%;
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }

  .dropdown--md-down-menu {
    @include media-breakpoint-down(md) {
      z-index: 1;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      display: none;
      width: 100%;
      padding: 20px 15px;
      background-color: $white;
      border-style: solid;
      border-color: $grey-medium;
      border-width: 1px;
    }
  }
}
