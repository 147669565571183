/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Elements forms
 */

.form-container {
  background: var(--darkmode-800) !important;
  border-top-color: var(--darkmode-hightlight-2-200);
}

form .form-group input,
form .form-group textarea,
.select,
.form-container select,
.bg-main--white .select select {
  border-color: var(--darkmode-600) !important;
  background: var(--darkmode-700) !important;
}

select {
  background: var(--darkmode-700);
}

.select {
  &:after {
    border-color: var(--darkmode-hightlight-1-200);
  }
}

.form-container .select select,
.bg-main--white .select select {
  color: var(--darkmode-50);
}

.form-group {
  input {
    color: var(--darkmode-50);
    
    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder {
      color: var(--darkmode-50);
    }
  }

  input[type="date"] {
    background-color: var(--darkmode-700);

    &:after {
      border-color: var(--darkmode-50);
    }
  
    &::-webkit-input-placeholder {
      color: var(--darkmode-50);
    }
  
    &::-webkit-datetime-edit-text,
    &::-webkit-date-and-time-value,
    &::-webkit-datetime-placeholder,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field,
    &::-webkit-calendar-picker-indicator {
      color: var(--darkmode-50);
    }
  
    &:not(.has-value):before {
      @media only screen and (max-width: 767.98px) {
        color: var(--darkmode-50);
      }
    }
  }
}

.select-checkboxes .dropdown-menu li {
  background: var(--darkmode-700) !important;
}

.umbraco-forms-form {
  background: var(--darkmode-800);
  border-top-color: var(--darkmode-hightlight-2-200);

  .umbraco-forms-page .form-group select {
    background: var(--darkmode-700);
  }

  input {
    color: var(--darkmode-50);
  }
}

/**
 * Radio
 */
.form-radio {
	input[type="radio"] {
		&:checked + .radio__indicator {
			background-color: var(--darkmode-hightlight-1-200);
			background-image: var(--darkmode-hightlight-1-200);
		}
	}

	.radio__indicator {
		background-color: var(--darkmode-700);
		border-color: var(--darkmode-600);

		&:after {
			background: var(--darkmode-900);
		}
	}
}

/**
 * Checkbox
 */
.form-check {
	// Checkbox
	.checkbox {
		.checkbox__indicator {
			background: var(--darkmode-700);
			border-color: var(--darkmode-600);

			&:after {
				border-color: var(--darkmode-900);
			}
		}

		& input:checked ~ .checkbox__indicator {
			border-color: var(--darkmode-900);
			background: var(--darkmode-hightlight-1-200);
		}

		& input:disabled ~ .checkbox__indicator {
			background: var(--darkmode-600);

			&:after {
				border-color: var(--darkmode-500);
			}
		}
	}
}

.checkbox-toggle {
  input {
    &[disabled] {
      + label {
        > .status-switch {
          background-color: var(--darkmode-700) !important;

          &:before {
            background-color: var(--darkmode-500);
          }
        }
      }
    }

    // Unchecked State
    + label {
      > .status-switch {
        background-color: var(--darkmode-700);
        color: var(--darkmode-hightlight-1-200);

        &:before {
          background-color: var(--darkmode-900);
        }
      }
    }

    // Checked state
    &:checked + label > .status-switch {
      background-color: var(--darkmode-hightlight-1-200);

      &:before {
        color: var(--darkmode-hightlight-1-200);
      }
    }
  }
}

.form,
.form-container {
  // Component background color mixin
  &[class*="bg-"] {
    h3,
    .h3,
    p,
    label {
      color: var(--darkmode-50);
    }

    .form-group {
      input,
      textarea {
        background-color: var(--darkmode-700);
        color: var(--darkmode-50);
        border-color: var(--darkmode-600);
  
        &::placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder {
          color: var(--darkmode-50);
        }
      }

      input {
        &::after {
          border-color: var(--darkmode-600);
        }
      }
    }

    input[type="date"] {
      background-color: var(--darkmode-700);
    
      &::-webkit-input-placeholder {
        color: var(--darkmode-50);
      }
    
    
      &::-webkit-datetime-edit-text,
      &::-webkit-date-and-time-value,
      &::-webkit-datetime-placeholder,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        color: var(--darkmode-50);
      }
    
      &::-webkit-calendar-picker-indicator {
        color: var(--darkmode-50);
      }
    
      &:not(.has-value):before {
        @media only screen and (max-width: 767.98px) {
          color: var(--darkmode-50);
        }
      }
    }

    .select select {
      background: var(--darkmode-700);
      color: var(--darkmode-50);
      border-color: var(--darkmode-600);
    
      &::placeholder {
        color: var(--darkmode-50);
      }
    
      option {
        color: var(--darkmode-50);
        
        &:checked {
          background-color: themed('btn-primary-background');
        }
      }
    }

    .select,
    .select-checkboxes {
      &:after {
        border-color: var(--darkmode-50);
      }
    }

    .select-checkboxes {
      background: var(--darkmode-700);
      color: var(--darkmode-50);
      border-color: var(--darkmode-600);

      button {
        color: var(--darkmode-50);
      }
    
      .dropdown-menu {
        background: var(--darkmode-700);
        border-color: var(--darkmode-600);
    
        li {
          background-color: var(--darkmode-700);
    
          &:hover {
            // Same color as standard HTML select hover blue
            background-color: #1e90ff;
            color: var(--darkmode-700);
          }
        }
      }
    
      .divider {
        border-top-color: var(--darkmode-50);
      }
    }

    .form-check {
      .checkbox {
        .checkbox__indicator {
          background: var(--darkmode-700);
          border-color: var(--darkmode-600);
    
          &:after {
            border-color: var(--darkmode-50);
          }
        }
    
        & input:checked ~ .checkbox__indicator {
          border-color: var(--darkmode-600);
          background: var(--darkmode-700);
        }
    
        & input:disabled ~ .checkbox__indicator {
          background: var(--darkmode-700);
    
          &:after {
            border-color: var(--darkmode-50);
          }
        }
      }
    }

    .form-radio {
      input[type="radio"] {
        &:checked + .radio__indicator {
          background-color: var(--darkmode-700);
          background-image: var(--darkmode-700);
        }
      }
    
      .radio__indicator {
        background-color: var(--darkmode-700);
        border-color: var(--darkmode-50);
    
        &:after {
          background: var(--darkmode-50);
        }
      }
    }

    .form-file {
      input[type=file] {
        border-color: var(--darkmode-50);
        color: var(--darkmode-50);
      }
    }
  }
}

input[type=file] {
  color: var(--darkmode-50);

  &:hover {
    &::-webkit-file-upload-button {
      background-color: transparent;
      color: var(--darkmode-50);
    }
  }

	&::-webkit-file-upload-button {
    color: var(--darkmode-900);
    border-color: var(--darkmode-hightlight-1-200);
    background-color: var(--darkmode-hightlight-1-200);
	}
}
