a.card__hours {
  .card__hours__content__text {
    margin-bottom: 30px;
  }
}

.card__hours {
	border-radius: var(--sleeve-component-border-radius);
    
	&:not(.has-image) {
		border-radius: var(--sleeve-component-border-radius);
  }

  .hidden {
	  display: none !important;
  }

  // Icon
  .card__hours__image__icon,
  .card__hours__content__icon {
    height: 55px;
    min-width: 55px;
    
    &[class*="icon-"] {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        background: $color-icons;
        opacity: 0.1;
        border-radius: 30px;
      }
    }

    img,
    svg {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 55px;
      height: auto;
      max-height: 55px;
    }

    img {
      transform: unset;
      object-fit: contain;
    }
	}

	.card__hours__content__text {
    width: 100%;

    .subtitle {
			margin-top: 30px;
    }

    .btn__link {
			margin-top: 20px;
    	}
	}

	.card__hours__image {
    width: 100%;
    overflow: hidden;
    border-top-left-radius: var(--sleeve-component-border-radius);
    border-top-right-radius: var(--sleeve-component-border-radius);
    
    // Icon (with image)
    .card__hours__image__icon {
			z-index: 1;
			position: absolute;
			top: 15px;
			left: 15px;

      &[class*="icon-"] {
        background-color: $white;
        border-radius: 30px;
      }
    }
    
    img {
			width: 100%;
			height: 100%;
			transition: $transition;
    }

    img:not([class*="aspect-ratios--"]) {
      height: 222px;

      @include media-breakpoint-down(sm) {
        height: 195px;
      }
		}
	}

	.card__hours__content {
    display: flex;
    padding: 30px;
    
    // Icon (no image)
    .card__hours__content__icon {
			position: relative;
			margin-right: 20px;
			margin-top: -15px;
			margin-left: -15px;
    }
	}

	.container--wide .row {
		margin-right: -12px;
		margin-left: -12px;
	}

  .arrow {
		position: absolute;
		bottom: 30px;
		right: 30px;
		display: flex;
		transition: $transition;
	}
  
	&--divider {
		margin: 0 0.3em;
	  }
}

// If card link
a.card__hours {
	&:hover {
		.arrow {
			right: 35px;
		}
        
		img {
			transform: scale(1.05);
		}
	}
}

// No image in .col-lg-4
.col-lg-4 .card__hours.card__hours--no-image.has-icon {
  .subtitle,
  table,
  p.status {
		@include media-breakpoint-down(lg) {
			margin-left: -70px;
		}
		
		@include media-breakpoint-down(md) {
			margin-left: 0;
		}
	}
}

//Negative margin if with icon and .col-lg-4 
.row--grid__col-8-4 .col-md-12.col-lg-4 .has-icon:not(.has-image) .card__hours__content__icon ~ .card__hours__content__text,
.row--grid .col-md-6.col-lg-4 .has-icon:not(.has-image) .card__hours__content__icon ~ .card__hours__content__text {     
	.subtitle, table, p, .btn__link {
    @include media-breakpoint-down(lg){
      margin-left: -60px;
    }

    @include media-breakpoint-down(md){
      margin-left: 0;
    }

    @include media-breakpoint-down(xs){
      margin-left: -60px;
    }
	}   
}

//Negative margin if wide container .col-lg-8
.row--grid__col-8-4 .col-lg-8 .has-icon:not(.has-image) .container--wide {
	@include media-breakpoint-down(xs){
		margin-left: -60px;
		width: calc(100% + 60px);
	}
}
