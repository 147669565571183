/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Bypass block
 */

.bypass-block:focus,
.bypass-block:active {
  background-color: var(--darkmode-900);
  border: 2px solid var(--darkmode-50);
}
