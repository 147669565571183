.banner__form {
  position: relative;
	display: flex;
	flex-direction: column;
  padding: 30px 0;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: center;
	}

	.banner__form--container {
		display: flex;
		flex-direction: row;
    justify-content: space-between;
    width: 100%;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: center;
		}

    .banner__form__content {
      display: flex;
      align-items: center;
      height: 100%;

      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
    }

		.banner__form__content__icon {
			height: 70px;
			width: 70px;
      margin-right: 35px;
      font-size: 4.0625rem; // icon size
      
			@include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-right: 0;
			}
		}

		.banner__form__content__text {
      display: flex;
      flex-direction: column;
			max-width: 600px;
			margin-right: 30px;

			@include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-right: 0;
			}

			h2 {
				margin-bottom: 0;

				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}

      p {
        margin-top: 20px;
        margin-bottom: 0;
      }
		}

    .form {
      padding: 30px 0;

      @include media-breakpoint-down(sm) {
        padding-top: 60px;
      }
    }
    
    .umbraco-forms-form {
      background-color: unset;
    }
	}

  .btn-container {
    align-items: center;
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    .btn {
      min-width: auto;
      margin-top: 0;
    }
  }
}

// accordion--desktop-down
.banner__form.accordion--desktop-down {
  // Body - Hidden content
  .accordion--desktop-down__body {
    display: none;
  }
}

@include globalComponentColors($themes) {
  .banner__form {
    .banner__form--container {
      .banner__form__content__text h2 {
        color: themed('text');
      }

      .banner__form__content__icon {
        color: themed('icon');
      }
    }
  }
}

@include sectionColors($themes-section) {
  .banner__form {
    .banner__form--container {
      .banner__form__content__text h2 {
        color: themedSections('text');
      }

      .banner__form__content__icon {
        color: themedSections('icon');
      }

      .umbraco-forms-form {
        legend,
        label {
          color: themedSections('text');
        }

        .btn__primary {
          border-color: themedSections('btn-primary-border');
          background-color: themedSections('btn-primary-background');
          color: themedSections('btn-primary-text');
      
          &:focus,
          &:hover {
            color: themedSections('text');
            background-color: $transparent;
          }
        }
      }
    }
  }
}
