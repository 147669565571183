.service-menu {
  @include list-unstyled;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
  background: transparent;

  .service-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    margin-bottom: 10px;

    &:nth-child(n+1) {
			margin-right: 20px;
    }
    
    &:hover {
      transition: $transition;
    }

    .service-menu__item__text {
      display: inline-block;
      transition: $transition;
    }

    .inactive {
      .service-menu__item__icon svg path {
        fill: none;
      }
      &:hover .service-menu__item__icon svg path {
        fill: $btn-icon-color
      }
    }

    .service-menu__item__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      margin-right: 10px;
      border-radius: 30px;
      background: $white;

      &[class^="icon-"],
      &[class*="icon-"] {
        &:before {
          font-size: $breadcrumb-font-size;
          color: $btn-icon-color;
        }
      }

      svg {
        display: flex;
        transition: $transition;

        path {
          fill: $btn-icon-color;
        }
      }
    }

    button,
    a {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: $breadcrumb-font-size;
      font-weight: $font-weight-body;
      background-color: transparent;
      cursor: pointer;
      color: $black;
      white-space: nowrap;
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
      
      &:hover {
        .service-menu__item__text {
          color: $color-link;
        }
      }
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      font-size: $breadcrumb-font-size;
      
      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      z-index: 11;
      min-width: 75px;
      border-radius: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .15);
      border: unset;
      padding: 10px 20px;
      margin-bottom: 0;

      &__enlarge-text {
        padding: 15px 20px;

        ul {
          &.fontsize {
            margin-bottom: 0;
            @include list-unstyled;

            li {
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .selected-size {
              button {
                font-weight: $btn-font-weight;
              }
            }
  
            button {
              background-color: transparent;
            }
          }
        }
      }

      &__share {
        &.show {
          @include media-breakpoint-up(md) {
            display: flex;
            left: auto !important;
            right: -10px;
          }
        }

        [class*="icon-"] {
          position: relative;
          width: 22px;

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 22px; // icon size
            color: $btn-icon-color;
          }
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          margin-bottom: 10px;
          background: $grey-light;
          border-radius: 50%;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-right: 10px;
          }

          &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
              margin-right: 0;
            }
          }
        }

        .btn-tooltip button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  #copiedText {
    display: none;
  }
}
