/**
 * Cards
 * Background colors
 */

 .card {
  &.bg--transparent {
		background: none;
	}

  &.c-background {
    background-color: $white;
  }

  @include nestedComponentColors($themes) {
    &:not(.bg--transparent) {
      .tag,
      .label,
      h3,
      .h3,
      p:not(.status),
      [class*="icon-"],
      .arrow::before {
        color: themed('text');
      }
    }
    
    .divider::before {
      background-color: themed('text');
    }
  }

  @include nestedArticleColors($themes-article) {
    .tag,
    .label,
    h4,
    .h4,
    h3,
    .h3,
    a,
    a.list__link:before,
    p:not(.status),
    [class*="icon-"],
    .arrow::before {
      color: themedArticle('text');
    }

    .divider::before {
      background-color: themedArticle('text');
    }
  }
}
