/**
 * Buttons
 * .btn__link
 */

.btn__link,
.span__link {
	display: inline-block;
	color: $color-link;
	font-weight: $btn-font-weight;
	font-family: $primary-font;
	position: relative;
	box-sizing: border-box;
	transition: $transition;
	font-size: $btn-link-font-size;
	text-transform: $small-text-transform;

  span {
    font-family: $primary-font;
  }

  &.not-active {
    color: $black;

    &:hover {
      color: $color-link;
    }
  }
}
