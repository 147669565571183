/**
 * Side menu - with dropdowns
 * Is used on: Sleeve default
 */

// Mobile - Sidemenu cloned into header__megamenu
// .side-menu__dropdowns
header {
  .side-menu.side-menu__dropdowns,
  .side-menu.side-menu__no-dropdowns {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      background: transparent;
    }

    ul.dropdown-menu {
      position: initial !important;
    }
  }
}

//Only margin bottom on sidemenu on article colored hero
.article-colored-hero .side-menu.side-menu__dropdowns {
  @include media-breakpoint-down(md) {
    margin-bottom: var(--article-wrapper-c-mb);
  }
}

// Side menu - .side-menu__dropdowns
.side-menu.side-menu__dropdowns {
  margin-top: 65px;

  @include media-breakpoint-down(md) {
    z-index: 200;
    margin-top: 0;
    background: $white;
    margin-bottom: 0;
    .sidemenu__dropdown {
      display: none;
    }
    // Hide sidemenu, that is not cloned on mobile
    //display: none;
  }

  @include media-breakpoint-up(lg) {
		position: relative;
  }
  
  // Show mobile side menu
  &.show {
    .sidemenu__dropdown {
      @include media-breakpoint-down(md) {
        display: block;
        transform: translateY(0);
      }
    }

    ul {
      margin-bottom: 0;
    }
  }
  
  // Mobile toggle button
  .side-menu__toggle-mobile {
    display: none;

    @include media-breakpoint-down(md) {
      //z-index: 1000;
      position: relative;
      left: 0;
      top: auto;
      display: flex;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 55px;
      //margin-left: 15px;
      //margin-right: 15px;
      padding: 10px 55px 10px 15px;
      color: $black;
      background-color: transparent;
      font-size: $component-title-font-size;
      font-weight: $font-weight-heading;
      //box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
      text-align: left;
      border-bottom: 1px solid #e3e3e3;
    }

    &::before {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: #e3e3e3;
      }
    }

    &::after {
      @include media-breakpoint-down(md) {
        position: absolute;
        display: flex;
        content: "";
        right: 18px;
        top: 50%;
        transition: $transition;
        width: 9px;
        height: 9px;
        border-style: solid;
        border-color: $color-arrows;
        border-width: 2px 2px 0 0;
        transform: translate(0, -50%) rotate(135deg);
        top: 49%;
      }
    }

    &.show {
      &:after {
        @include media-breakpoint-down(md) {
          top: 55%;
          transform: translate(0, -50%) rotate(-45deg);
        }
      }
    }
  }

	.side-menu__title {
    margin-bottom: 12px;
    font-size: $component-title-font-size;
    line-height: $line-height-secondary;
    
    @include media-breakpoint-down(md) {
      display: none;
    }
	}

	ul {
		@include list-unstyled();

		li {
			padding-left: 0 !important;
		}

		&.dropdown {
			z-index: 0;
			//display: flex;
			flex-direction: column;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
		}

		&.sidemenu__dropdown {
      overflow: hidden;
      padding-left: 0 !important;
  
  
      @include media-breakpoint-down(md) {
        //z-index: 200;
        //position: absolute;
        //top: initial;
        //left: 0;
        transform: translateY(100%);
        //height: 100vh;
        //overflow-y: scroll;
        background: $white;
        //width: 100vw;
        //padding-bottom: 400px;
      }
    }
    
    .dropdown__togglers {
      position: relative;

      .dropdown-toggle {
        z-index: 1001;
        position: absolute;
        top: 0;
        height: 100%;

        @include media-breakpoint-down(lg) {
          right: 0;
          width: 44px;
          padding: 0 15px;
        }

        @include media-breakpoint-up(lg) {
          left: 1px;
          width: 29px;
          height: calc(100% - 1px);
        }

        &:after {
          border: none;
        }
    
        .icon-toggle {
          position: absolute;
          width: 13px;
          height: 13px;
          right: 50%;
          top: 45%;
          transform: translate(50%, -50%);

          @include media-breakpoint-down(md) {
            top: 50%;
          }

          &:after {
            content: '';
            position: absolute;
            display: flex;
            right: 4px;
            top: 65%;
            transition: $transition;
            width: 9px;
            height: 9px;
            border-style: solid;
            border-color: $color-arrows;
            border-width: 2px 2px 0 0;

            @include media-breakpoint-down(md) {
              right: 2px;
            } 

            @include media-breakpoint-down(lg) {
              transform: translate(0, -50%) rotate(135deg);
            }
    
            @include media-breakpoint-up(lg) {
              transform: translate(0, -50%) rotate(45deg);
            }
          }
        }
    
        &.show .icon-toggle {
          &:after {
            right: 2px;

            @include media-breakpoint-down(lg) {
              top: 75%;
              transform: translate(0, -50%) rotate(-45deg);
            }
    
            @include media-breakpoint-up(lg) {
              top: 55%;
              transform: translate(0, -50%) rotate(135deg);
            }
          }
        }
      }
    }

		// Colored background on .show
		&.dropdown,
		&.dropdown-submenu {
			.side-menu__toggle,
			.side-menu__link {
				position: relative;
			}

			.side-menu__level-two__toggle,
			.side-menu__level-three__toggle,
			.side-menu__level-four__toggle,
			.side-menu__link {
				&.active {
					font-weight: $font-weight-heading-menu;
				}
      }

			.dropdown-submenu {
        .side-menu__level-two__toggle,
				.side-menu__level-three__toggle,
				.side-menu__level-four__toggle {
          &:before {
            z-index: 1001;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background: $grey-medium;
            opacity: 0;
            transition: $transition;
          }

					&.show {
						&:before {
              opacity: 1;
            }
					}
        }
        
        .dropdown-toggle.show {
          .icon-toggle {
            &::after {
              @include media-breakpoint-down(lg) {
                top: 75%;
              }
      
              @include media-breakpoint-up(lg) {
                top: 55%;
              }
            }
          }
        }
			}
		}

		li {
			.side-menu__toggle,
			.side-menu__link {
				position: relative;
        // display: flex;
        display: -webkit-box;
				width: 100%;
				font-family: $secondary-font;
        
        text-align: left;

        @include media-breakpoint-down(lg) {
          padding: 6px 25px 6px 0;
        }

        @include media-breakpoint-up(lg) {
          padding: 6px 15px 6px 25px;
        }

        &:after {
          transition: $transition;
        }

        &:hover {
          &:after {
            opacity: 0.05;
          }
        }
        
        &:hover,
        &.active {
          &::after {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            width: 150%;
            height: 100%;
            background-color: $color-secondary;
          }
        }

        &.active {
          &:after {
            opacity: 0;
          }
        }

        &.show.active {
          &::after {
            opacity: 0.1;
          }
        }
			}

			// Side menu - Level one
			&.side-menu__level-one {
        margin: 0;
        overflow: hidden;

        // When level one has dropdown
        &.dropdown__list-item {
          .dropdown__togglers {
            .dropdown-toggle {
              .icon-toggle:after {
                top: 70%;

                @include media-breakpoint-down(md) {
                  top: 35%;
                }
              }

              &.show {
                .icon-toggle:after {
                  top: 55%;
                }
              }
            }
          }
        }

        .dropdown__list-item.dropdown-submenu {
          .dropdown__togglers {
            .dropdown-toggle {
              .icon-toggle {
                @include media-breakpoint-down(md) {
                  top: 40%;
                }
                
                &:after {
                  top: 65%;

                  @include media-breakpoint-down(md) {
                    top: 50%;
                  } 
                }
              }
              
              &.show {
                .icon-toggle {
                  @include media-breakpoint-down(md) {
                    top: 50%;
                  }

                  &:after {
                    top: 55%;
                  }
                }
              }
            }
          }
        }

        &.show,
        .active {
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            height: 100%;
            background: $color-secondary;
            opacity: 0.1;
          }
        }
        
        &.show {
          &:before {
            left: 0;
            width: 100%;
          }
        }

        .active:not(.show) {
          &:before {
            right: 0;
            width: 150%;
          }
        }

				&:first-of-type {
          @include media-breakpoint-up(lg) {
            border-top: 3px solid $color-secondary;
          }
				}

				.side-menu__level-one__toggle,
				.side-menu__level-one__link {
          display: inline-block;
					border-bottom: 1px solid $grey-medium;
					margin-bottom: 0;
          font-size: calc($component-font-size + em(2px));
          font-weight: $font-weight-heading-menu;

          @include media-breakpoint-down(lg) {
            padding: 12px 44px 12px 15px;
          }
  
          @include media-breakpoint-up(lg) {
            padding: 12px 32px 12px 32px;
          }
				}
        
        .dropdown-menu {
          padding-left: 30px !important;
          border-bottom: 1px solid $grey-medium;
          font-size: inherit; // For change font-size function

          .dropdown-submenu .dropdown-menu {
            padding-left: 20px !important;
            border-bottom: none;
          }
        }
			}
		}

		// Side menu - Level two & down
		&.dropdown-menu {
			position: relative !important;
			transform: translate3d(0, 0, 0) !important;
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
      border: 0;
      background-clip: border-box;

			&.show {
        position: relative;
        top: 0;
        left: 0;
        padding-left: 20px !important;
			}

			li {
        display: flex;
        flex-direction: column;
				margin-bottom: 0;

				.side-menu__toggle,
				.side-menu__link {
          display: inline-block;
					font-size: $component-font-size;
          line-height: $line-height-secondary;
          hyphens: auto;
				}
			}

			.dropdown-submenu {
				// Side menu - Level two & down
				.side-menu__toggle {
          &.show {
            @include media-breakpoint-down(lg) {
              padding: 6px 44px 6px 0;
            }
    
            @include media-breakpoint-up(lg) {
              padding: 6px 15px 6px 25px;
            }
          }
        }
        
        .dropdown-toggle {
          @include media-breakpoint-down(lg) {
            right: 0;
          }
  
          @include media-breakpoint-up(lg) {
            left: -6px;
            width: 25px;
          }
        }

				.dropdown-menu {
          padding-left: 30px;
          
          // Side menu - Level three & down
          .dropdown-menu {
            padding-bottom: 0;
          }
				}
			}
		}
	}
}
