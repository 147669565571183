.rich-text ul,
.info-box-article .rich-text-article__references ul,
.list__bullets ul {
  list-style: none;
  list-style-type: disc;
  padding-left: 18px;

	li {
    ul {
      margin-left: 0;

      li {
        font-size: inherit;
        line-height: inherit;
      }
    }

		p,
		a {
			font-size: inherit;
			line-height: inherit;
		}

		a:hover,
		a:focus {
			background-size: 100% 1px;
			color: $color-link;
		}
	}
}
