/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Pagination
 */

.pagination__item button,
.pagination__item a {
  color: var(--darkmode-50);
}

.pagination__item:hover button,
.pagination__item:hover a {
  color: var(--darkmode-hightlight-1-200);
}

.pagination__item .arrow--right svg path,
.pagination__item .arrow--left svg path {
  fill: var(--darkmode-hightlight-1-200);
}

@for $i from 1 through 80 {
  .pagination[actpage="#{$i}"] {
    // 3 before
    .pagination__item:nth-child(#{$i - 2}):not(:first-child):not(:nth-child(2)),
    .pagination__item:nth-child(#{$i + 4}):not(:last-child):not(:nth-last-child(2)) {
      button,
      a {
        &:after {
          color: var(--darkmode-50);
        }
      }
    }

    // active
    .pagination__item:nth-child(#{$i + 1}) {
      button,
      a {
        color: var(--darkmode-hightlight-1-200);
      }
    }
  }
}
