/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Link boxes
 */

.link-boxes__article {
  .link-boxes__article__title {
    background: var(--darkmode-800);
  }

  .link-box__article {
    background: var(--darkmode-700);
  }
}

.link-box__article {
  border-top: 1px solid var(--darkmode-600);

  &:last-child {
    border-bottom: 1px solid var(--darkmode-600);
  }
}

.link-boxes .link-box__icon.link-box__arrow[class*="article-element-bg--"] {
  color: var(--darkmode-50);

  .arrow::before {
    color: var(--darkmode-hightlight-1-200);
  }
}

main:not(.bg-main--white) .link-boxes--framed.framed--components {
  background-color: var(--darkmode-800);
  &::after {
    background: var(--darkmode-800);
  }
}