.side-box {
  border-radius: var(--sleeve-component-border-radius);
  margin-bottom: 30px;

  //make all emails break in side boxes
  a[href^="mailto:"] {
    word-break: break-all;
  }

  p:not(.h3),
  ul li,
  .list__link,
  .rich-text p,
  .rich-text ul li {
    font-size: $component-font-size;
    line-height: $line-height-secondary;
  }

  .tags-container {
    margin-bottom: 10px;

    .tag {
      margin-bottom: 0;
    }
  }

  .tag {
    margin-bottom: 10px;
  }

  .list__icons {
    margin-bottom: 15px;
  }

  .rich-text {
    margin-bottom: 15px;

    h2 {
      @include responsive-font-size($h3-font-size-sm,"",$h3-font-size-sm,"",$h3-font-size-lg);
    }

    h3 {
      @include responsive-font-size($h4-font-size-sm,"", $h4-font-size-sm,"",$h4-font-size-lg);
    }
  }

  .divider {
    position: relative;
    padding-top: 15px;
    margin-top: 5px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 40px;
      background-color: $grey-medium;
    }
  }

  .list__downloads ul li {
    margin-bottom: 0;
  }

  .list__links {
    ul {
      li {
        // Remove when - Single arrow link has been outfased
        a.list__link {
          position: unset;
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
  
  // Single arrow link needs to be outfased
  a.list__link {
    position: relative; // Option of single link in BO
    display: inline-block;
    padding-left: 10px;
    margin-left: 15px;
  }
  
  .btn {
    width: fit-content;

    &:first-of-type {
      margin-top: var(--sleeve-component-spacing);
    }
  }

  .side-box__media__image img,
  .side-box__media__icon {
    border-radius: var(--sleeve-component-border-radius) var(--sleeve-component-border-radius) 0 0;
  }

  .side-box__media__icon {
    position: relative;
    min-height: 30px;
    max-height: 45px;
    width: 100%;
    border-radius: var(--sleeve-component-border-radius) var(--sleeve-component-border-radius) 0 0;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px;

    .logo {
      display: flex;

    }

    [class*="icon-"] {
      font-size: em(25); // icon size

      &::before {
        opacity: 1;
      }
    }

    img {
      object-fit: contain;
      height: 25px;
    }

    .tag {
      margin-bottom: 0;
    }
  }

  form {
    .list__info {
      margin-bottom: 0;
    }

    .divider {
      position: relative;
      padding-top: 20px;
      margin-top: 5px;
  
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $grey-medium;
      }
    }

    .btn {
      margin-top: 0;
    }
  }

  @import 'form-container';
  @import 'background-colors';
}
