.rich-text {
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  h2, h3, h4 {
    color: $black;
  }

  p {
    img, 
    img[width], 
    img[height] {
      max-width: 100%;
    }
  }

  p,
  ul {
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  h2 a,
  h3 a,
  h4 a,
  h5 a {
    &:not([href]):not([tabindex]) {
      font-weight: $font-weight-heading;
    }
  }

  p a,
  a {
    &:not([href]):not([tabindex]) {
      font-weight: $font-weight-body;
    }
  }

  h2 a,
  h3 a,
  h4 a,
  h5 a,
  p a,
  a {
    &:not([href]):not([tabindex]) {
      background-size: unset;
      background-image: unset !important;
      padding: 0;
      color: inherit !important;
      border-bottom: unset;

      &:hover,
      &:focus {
        color: inherit !important;
      }
    }
  }
  
  a {
    font-size: inherit;
    line-height: inherit;
  }

  span a {
    font-size: inherit;
    line-height: initial;
  }

  ul {
    @include unordered-list-styled;
    margin-bottom: 12px; // 20px - 8px (li margin-bottom)

    li {
      ul {
        margin-left: 0;

        li {
          font-size: inherit;
          line-height: inherit;
        }
      } 
    }
    
    a {
      font-size: $btn-font-size;
    }
  }

  img {
    width: auto;
    max-width: 100%;
  }

  &.column-count-2 {
    @include media-breakpoint-up(md) {
      column-count: 2;
      column-gap: 40px;
    }
  }
  
  &_locked {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  }
}
