&.has-carousel {
  .container {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &:not(.hero__text-box--full-height) {
    @include media-breakpoint-up(md) {
      height: calc(50vh - var(--header-height-lg) + 140px); // A height is neede for carousels: Screen - menu + padding
    }
  }

  &.hero__text-box--align-center {
    .container {
      @include media-breakpoint-up(md) {
        bottom: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }

  &.hero__text-box--full-height {
    .container {
      @include media-breakpoint-down(sm) {
        display: flex;
        height: auto;
      }
    }
  }

  .swiper {
    height: 100%;
    width: 100%;
    overflow: visible;

    img {
      max-height: calc(100vh - #{var(--header-height-lg)});

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }
  }

  .swiper-slide {
    background-color: $white; // Default background color
  }

  .swiper-pagination {
    &::after {
      z-index: 2;
      position: absolute;
      bottom: 0;
      height: 100px;
      width: 100%;
      background: linear-gradient(transparent, rgba(#000, .3));
    }

    @include media-breakpoint-down(md) {
      top: 0;
      bottom: auto !important;
      margin-top: var(--col-padding);
    }
  }

  .gradient {
    @include media-breakpoint-down(md) {
      top: 0;
      transform: rotate(180deg);
    }
  }

  .container {
    @include media-breakpoint-down(sm) {
      position: relative;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
    
    @include media-breakpoint-up(md) {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .row {
      width: 100%;

      @include media-breakpoint-down(sm) {
        position: relative;
        left: var(--col-padding);
      }

      [class*=col-]:not([class*=__col-]) {
        @include media-breakpoint-down(sm) {
          padding-right: 0;
          padding-left: 0;
        }
        
      }
    }

    .hero-inner {
      width: calc(100% - var(--col-padding)* 5);

      @include media-breakpoint-down(sm) {
        // width: calc(100% - (var(--col-padding) * 4));
        width: 100%;
        max-width: 100%;
        padding-left: calc(var(--col-padding) * 2);
        padding-right: calc(var(--col-padding) * 2);
        box-shadow: unset;
        border-radius: 0;
        margin-bottom: 0;
      }
    }
  }
}
