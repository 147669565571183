/**
 * Third party
 * SBSYS
 */

/*
** Accordion - SBSYS
*/

.accordion--sbsys {
  .label {
    margin-bottom: 10px;
  }

	.accordion__title {
		p {
			margin-bottom: 0;
		}
	}

  .list__downloads {
    ul {
      margin-bottom: 0;
    }
  }

  .accordion--sbsys__btns-print {
    display: flex;
    flex-direction: row-reverse;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
    }

    .btn-container {
      margin-top: 0;
    }
  }

  .card {
    border-radius: $border-radius-component;

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
