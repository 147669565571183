progress.progressbar__value {
  /* Positioning */
	position: absolute;

	/* Dimensions */
	height: 10px;

	/* Progress bar container for Firefox/IE10+ */
	background-color: #d0d0d0;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  
	&::-webkit-progress-value {
		background-color: $color-icons;
	}
  
	&::-moz-progress-bar {
		background-color: $color-icons;
	}
}
