.card__event {
  border-radius: var(--sleeve-component-border-radius) !important;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  &:not(.no-image) {
    .card__tag {
      max-width: 60%;
    }
  }

  .card__tag {
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .tag {
      margin-bottom: 0;

      span {
        white-space: initial;
      }
    }
  }

  //- No Image
  &.no-image {
    .card__event__image-content {
      padding-bottom: 0;
      padding-left: 30px;
      padding-top: 30px;
    }

    .card__event__image-content__tag.card__tag {
      position: relative;
      top: unset;
      bottom: unset;
      width: fit-content;
      margin-bottom: 15px;
    }
  }

  &.no-image.card__event--date--top-left,
  &.no-image.card__event--date--top-right {
    .card__event__image-content {
      padding-bottom: 0;
      padding-left: 30px;
      padding-top: 30px;
      margin: 0;
    }

    .card__event__image-content__tag.card__tag {
      right: unset;
      left: unset;
    }

    .card__badge {
      right: 10px;
    }

    .card__event__content {
      padding-top: 0;
    }
  }

  &.no-image.card__event--date--top-left {
    .card__event__image-content {
      .card__event__image-content__tag.card__tag {
        top: unset !important;
      }
    }
  }

  .card__event__image-content {
    position: relative;
    padding-bottom: 30px;

    .card__event__image-content__image {
      overflow: hidden;
      height: 100%;
    }

    // For theme styling
    .card__event__image-content--container {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      transition: $transition;
      border-top-left-radius: var(--sleeve-component-border-radius);
      border-top-right-radius: var(--sleeve-component-border-radius);
    }

    img:not([class*="aspect-ratios--"]) {
      height: 222px;

      @include media-breakpoint-down(sm) {
        height: 195px;
      }
		}

    .card__event__image-content__date {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 25px;
      background: $white;
      margin-bottom: 28px;
      border-top-right-radius: var(--sleeve-component-border-radius);

      // Accessibility
      overflow: auto;

      p {
        font-weight: $font-weight-heading;
        margin-bottom: 0;

        &.day {
          font-size: calc($component-font-size + em(8px));
        }

        &.month {
          color: $black;
        }
      }
    }
  }

  .card__event__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 30px 30px 30px;

    .card__event__content__text {
      p {
        margin-bottom: 30px;
      }
    }
  }

  .card__event__image-content__badge.card__badge {
    top: unset;
    bottom: 10px;
  }

  .card__event__image-content__tag.card__tag {
    align-items: flex-start;
    justify-content: flex-end;
    bottom: 40px;
    background: transparent;
    gap: 10px;

    .tag {
      background: $white;
    }

    .tag--default {
      padding: 5px 10px;
      border-radius: var(--sleeve-component-border-radius);
    }
  }
}

.card__event:not(.card__event--date--bottom-left):not(.card__event--date--top-left):not(.card__event--date--top-right) {
  .card__event__content {
    ul {
      @include list-unstyled();
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
        font-size: $component-font-size;
        line-height: $line-height-secondary;
        border-top: 1px solid $grey-medium;
        padding-top: 18px;
        padding-bottom: 18px;
        margin-bottom: 0;

        &:last-of-type {
          padding-bottom: 0;
        }

        svg {
          margin-right: 15px;

          &.svg-date,
          &.svg-time,
          &.svg-location {
            path {
              fill: $color-icons;
            }
          }
        
          &.svg-date {
            rect {
              fill: $color-icons;
            }
          }
        
          &.svg-time {
            path {
              stroke: $color-icons;
            }
        
            circle {
              stroke: $color-icons;
            }
          }
        }
      }
    }
  }
}