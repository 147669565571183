/**
 * Darkmode
 * @import into styles/darkmode.scss
 * MedicinDk
 */

.medicindk {
  background-color: var(--darkmode-800) !important;

  .medicindk__filtering ul.filter-search__filter__alphabet button {
    color: var(--darkmode-50);

    &.chosen {
      color: var(--darkmode-900);
      background-color: var(--darkmode-hightlight-2-200);
    }
  }

  .card__list {
    border-color: var(--darkmode-700) !important;
  }
}

.article-wrapper .medicindk {
  background: transparent !important;

  .medicindk__filtering {
    background: var(--darkmode-800) !important;
  }
}
