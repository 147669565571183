/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Banners
 */

// Banner alert
.banner__alert {
  button.banner__alert__close:before,
  button.banner__alert__close:after {
    background-color: var(--darkmode-hightlight-1-200) !important;
  }

  &:not([class*="bg-"]) {
    background-color: var(--darkmode-hightlight-1-50);

    h2,
    h3,
    h4,
    h5,
    p {
      color: var(--darkmode-900) !important;
    }

    .banner__alert__content__icon {
      &[class^="icon-"],
      &[class*="icon-"] {
        &:before {
          color: var(--darkmode-900) !important;
        }
      }
    }

    button.banner__alert__close:before,
    button.banner__alert__close:after {
      background-color: var(--darkmode-900) !important;
    }
  }
}

// Banner hours
.banner__hours {
  &.bg--white {
    background: var(--darkmode-800) !important;
  }

  .banner__hours__content__info {
    .info-status-busy.closed, .closed, .info-status-text, .info-status-busy, .info-status.busy {
      color: var(--darkmode-50)
    }
  }

  .banner__hours__content__icon::after {
    background: var(--darkmode-hightlight-1-200);
  }

  .banner__hours__content__text {
    .info-contact {
      color: var(--darkmode-50);
    }
  }
}

// Banner feedback
.feedback-banner,
.feedback-response,
.feedback-success {
  h2,
  label {
    color: var(--darkmode-50);
  }

  .textarea-counter .textarea-counter__maxlength::after {
    color: var(--darkmode-50);
  }

  &__message {
    p {
      a {
        color: var(--darkmode-50);
      }
    }

    button.btn__secondary--outline {
      color: var(--darkmode-50);

      &:hover:enabled,
      &:focus:enabled {
        background: var(--darkmode-hightlight-1-200);
        color: var(--darkmode-900);
      }
    }
  }

  p,
  p a {
    color: var(--darkmode-50);
  }
}
