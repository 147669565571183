/*
** Heros article - Displaced
*/

.hero__article__displaced {
  margin-bottom: 0;

  &.no-media {
    .hero__article__text {
      &[class*="bg-"] {
        margin-top: 20px;

        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
    }
  }

	.hero__article__media {
    position: relative;
    height: calc(55vh - #{var(--header-height-md)});
		overflow: hidden;

    &.postion-absolute {
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 460px);
      width: 100%;

      @include media-breakpoint-down(md) {
        height: calc(100% - 220px);
      }

      &.height-80vh {
        height: calc(100% - 140px);

        @include media-breakpoint-down(md) {
          min-height: calc(100% - 80px);
        }
      }

      .image {
        @include media-breakpoint-down(md) {
          padding-right: calc(var(--col-padding-md-down) - 2 * var(--col-padding-md-down));
          padding-left: calc(var(--col-padding-md-down));
        }
      }
    }

    &.height-80vh {
      min-height: calc(80vh - #{var(--header-height-md)});
    }
	}

  .hero__article__text {
    padding: 140px 0 60px 0;

    @include media-breakpoint-down(md) {
      padding: 80px 0 40px 0;
    }

    &[class*="bg-"] {
      width: 100%;
      padding: 60px 0;
      margin-top: -140px;
      border-top-left-radius: var(--sleeve-component-border-radius);
      border-top-right-radius: var(--sleeve-component-border-radius);

      @include media-breakpoint-down(md) {
        padding: 40px 0;
        margin-top: -80px;
      }
    }
  }

  .image {
    height: 600px;

    @include media-breakpoint-down(md) {
      height: 300px;
    }

    img,
    picture {
      height: 100%;
    }
  }

  .video {
    width: 100%;
    margin-bottom: 0;
  }

  .container {
    [class*="col-"] {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
