// Breadcrumb in card
.card {
  .breadcrumb-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 15px;

    p {
      display: flex;
      align-items: center;
      white-space: pre;
      margin-top: 0;
    }

    p:not(.status),
    ol li {
      font-size: $small-font-size;
      line-height: $line-height-secondary;
      text-transform: $small-text-transform;
      color: $grey-dark;
    }
    
    .breadcrumb {
      padding: 0;

      &.breadcrumb--divider-arrow {
        display: flex;

        .breadcrumb-item {
          a svg, 
          span svg {
            height: 8px;
            width: 5px;
          }
        } 
      }
    }
  }
}