.top-link__container {
  position: relative;
  position: sticky;
  bottom: 35px;
  display: flex;
  justify-content: right;
  z-index: 20;

  a.top-link {
    width: auto;
    min-width: 44px; // Web accessibility
    min-height: 44px; // Web accessibility
    position: absolute;
    top: -25px;
    right: 15px;

    &:after {
      content: '';
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 46px;
      width: 46px;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 30px;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
      transition: ease-in 200ms;
    }

    &:hover:after {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3);
    }

    &.inverted {
      &::after {
        background-color: $color-icons;
        border: 2px solid $white;
        opacity: 0.75;
      }

      &:hover:after {
        opacity: 1;
      }

      .top-link__icon.arrow {
        &::before {
          color: $white;
        }
      }
    }

    .top-link__icon.arrow {
      z-index: 1;
      top: 10px;
      left: 50%;
      transform: rotate(-90deg) translateY(-55%);

      &::after {
        display: none;
      }
    }
  }
}

//unique positions for different arrow icons (they all need to be positioned slightly different to be centered in the circle)
.cta-icon-arrow-long-thin {
  .top-link__container .top-link .top-link__icon.arrow {
    top: 8px;
    left: 52%;
  }
}

.cta-icon-arrow-medium {
  .top-link__container .top-link .top-link__icon.arrow {
    top: 8px;
    left: 52%;
  }
}

.cta-icon-arrow-short-circle-outline{
  .top-link__container .top-link .top-link__icon.arrow {
    top: 6px;
    left: 54%;
  }
}

.cta-icon-arrow-long-circle-outline {
  .top-link__container .top-link .top-link__icon.arrow {
    top: 6px;
    left: 54%;
  }
}
.cta-icon-arrow-sharp-thick {
  .top-link__container .top-link .top-link__icon.arrow {
    top: 7px;
    left: 54%;
  }
}

.cta-icon-arrow-medium-circle-outline {
  .top-link__container .top-link .top-link__icon.arrow {
    top: 6px;
    left: 54%;
  }
}