.progressbar__value-cta {
  position: relative;
  padding-top: 30px;

  &.archived {
    progress.progressbar__value {
      &::-webkit-progress-value {
        background-color: $grey-dark;
      }
      
      &::-moz-progress-bar {
        background-color: $grey-dark;
      }
    }
  }

  &.side-box {
    background-color: transparent !important;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    .progressbar__value-cta__data {
      display: flex;
      flex-direction: row;

      .progressbar__value-cta__data__item {
        margin-right: 40px;

        p:not(.category) {
          margin-bottom: 0;
          font-size: $component-font-size;
          font-weight: $font-weight-heading;
        }
  
        p.category,
        .tag {
          margin-bottom: 0;
          color: $grey-dark;
          font-weight: 500;
        }
      }
    }
  }

  .btn-container {
    margin-top: 0;

    .btn__primary.disabled::after {
      background: $grey-dark;
    }
  }

  &.side-box {
    .container {
      flex-direction: column;

      .progressbar__value-cta__data {
        flex-direction: column;

        .progressbar__value-cta__data__item {
          margin-right: 0;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .btn-container {
        margin-top: 30px;
      }
    }
  }
}
