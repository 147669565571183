/**
 * Overlays
 */

.has-overlay,
.has-overlay .bg--transparent {
  &.reverse {
    .bg-overlay-45deg {
      @include media-breakpoint-up(md) {
        transform: scaleX(-1);
      }
    }
  }

  .container,
  .title,
  .rich-text {
    z-index: 4;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  p,
  span,
  .tag span,
  .btn__link {
    color: var(--color-overlay-text);
  }

  .btn__link .arrow::before {
    color: var(--color-overlay-text) !important;
  }

  .title,
  .rich-text {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    p,
    span {
      color: var(--color-overlay-text);
    }
  }
  
  .tag,
  .label,
  .tag--with-icon:not(.color-red) span:not([class*=icon-]),
  p.tag.tag--with-icon span:not([class*=icon-]) {
    color: var(--color-overlay-text);
  }

  .tag--bg-color::before,
  .tag--bg-color--system::before {
    background-color: var(--color-overlay-text);
  }

  .label--outlined,
  .tag--outlined,
  .tag.tag--border {
    border-color: var(--color-overlay-text);
  }

  .tags-container.with-divider {
    & > *:not(:first-child)::after {
      background: var(--color-overlay-text);
    }
  }

  .image {
    z-index: -1;
  }

  .breadcrumb {
    a, 
    span,
    li {
      color: var(--color-overlay-text);
    }

    .breadcrumb-item svg path {
      fill: var(--color-overlay-text);
    }
  }

  &:not([class*="bg-"]) {  
    .btn__primary,
    .btn__component:not(.btn__component--outline) {
      background: var(--color-overlay-button);
      color: var(--color-overlay-button-text);
      border: none;

       &::after {
        background: var(--color-overlay-button);
      }

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: var(--color-overlay-button-text);
      }

      &:hover,
      &:focus {
        background: var(--color-overlay-button-text);
        color: var(--color-overlay-button);

        .btn__component__text,
        [class*="icon-"]:before,
        .arrow:before {
          color: var(--color-overlay-button);
        }
      }
    }

    .btn__component--outline {
      border-color: var(--color-overlay-button);

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: var(--color-overlay-button);
      }

      &:hover,
      &:focus {
        background: var(--color-overlay-button); 
        color: var(--color-overlay-button-text);

        .btn__component__text,
        [class*="icon-"]:before,
        .arrow:before {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }
}

.has-overlay--md-up:not(.hero__text-box__bg-color) {
  .container,
  .title,
  .rich-text {
    @include media-breakpoint-up(md) {
      z-index: 2;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  p,
  span,
  .btn__link,
  .btn__link .arrow::before {
    @include media-breakpoint-up(md) {
      color: var(--color-overlay-text);
    }
  }

  .title,
  .rich-text {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    p,
    span {
      @include media-breakpoint-up(md) {
        color: var(--color-overlay-text);
      }
    }
  }
  
  .tag,
  .label,
  p.tag.tag--with-icon span:not([class*=icon-]) {
    @include media-breakpoint-up(md) {
      color: var(--color-overlay-text);
    }
  }

  .tag.tag--border {
    @include media-breakpoint-up(md) {
      border-color: var(--color-overlay-text);
    }
  }

  .image {
    @include media-breakpoint-up(md) {
      z-index: -1;
    }
  }

  .btn__primary,
  .btn__component:not(.btn__component--outline) {
    @include media-breakpoint-up(md) {
      background: var(--color-overlay-button);
      color: var(--color-overlay-button-text);
      border: none;
    }

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      @include media-breakpoint-up(md) {
        color: var(--color-overlay-button-text);
      }
    }

     &::after {
      @include media-breakpoint-up(md) {
        background: var(--color-overlay-button);
      }
    }

    &:hover,
    &:focus {
      @include media-breakpoint-up(md) {
        background: var(--color-overlay-button-text);
        color: var(--color-overlay-button);
      }

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-button);
        }
      }
    }
  }

  .btn__component--outline {
    @include media-breakpoint-up(md) {
      border-color: var(--color-overlay-button);
    }

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      @include media-breakpoint-up(md) {
        color: var(--color-overlay-button);
      }
    }

    &:hover,
    &:focus {
      @include media-breakpoint-up(md) {
        background: var(--color-overlay-button); 
        color: var(--color-overlay-button-text);
      }

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }
}

.bg-overlay,
.bg-overlay-0deg,
.bg-overlay-45deg {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .container,
  .title,
  .rich-text {
    z-index: 2;
  }

  .title,
  .rich-text {
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    p {
      color: var(--color-overlay-text);
    }
  }
  
  .tag,
  .label {
    color: var(--color-overlay-text);
  }

  .image {
    z-index: -1;
  }
}

.bg-overlay {
  background: var(--color-overlay);
}

.bg-overlay-0deg {
  background: var(--color-overlay-0deg);
}

.bg-overlay-45deg {
  background: var(--color-overlay-45deg);
}
