/**
 * Buttons
 * .btn__primary-arrow--long
 */

.btn__primary-arrow--long {
	background: $btn-primary-bg-color;
  color: $btn-primary-text-color;
  border-color: $btn-primary-bg-color;
  border-width: $btn-primary-border-width;
  border-style: $btn-primary-border-style;

	.arrow::before {
		color: $btn-primary-text-color;
	}

  span {
    width: 100%;
  }
}

.btn__primary-arrow--long--outline {
  &:hover,
  &:focus {
    .arrow::before {
      color: $btn-primary-text-color;
    }
  }
}

.btn__primary-arrow--long,
.btn__primary-arrow--long--outline {
	z-index: 0;
	position: relative;
	display: flex;
	align-items: center;
	height: auto;
	width: auto;
	min-width: 185px;
	padding: var(--sleeve-btn-padding-y) 60px var(--sleeve-btn-padding-x) 30px;
	text-align: left;
	border-radius: var(--sleeve-button-border-radius);

	&:hover,
	&:focus {
		.arrow {
			right: 20px;
		}
	}

	.arrow {
		position: absolute;
		display: flex;
		align-items: center;
		right: 15px;
		top: 50%;
		transform: translate(0, -50%);
		transition: $transition;
	}
}
