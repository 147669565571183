.btn__component,
.btn__component--outline {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: var(--sleeve-button-border-radius);
  transition: $transition;

  &:focus,
  &:hover {
    .arrow {
      margin-left: 5px;
      right: 20px;
    }
  }

  &.has-icon {
    padding: var(--sleeve-btn-padding-y) 15px var(--sleeve-btn-padding-y) 30px;
    
    [class*="icon-"] {
      margin-left: 30px;
    }
  }

  &.has-arrow {
    padding: var(--sleeve-btn-padding-y) 60px var(--sleeve-btn-padding-y) 30px;

    .arrow {
      margin-left: 20px;
    }
  }

  &.has-icon.has-arrow {
    padding: var(--sleeve-btn-padding-y) 60px var(--sleeve-btn-padding-y) 15px;

    [class*="icon-"] {
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .btn__component__text,
  .btn__component__icon {
    display: flex;
    align-items: center;
  }

  .btn__component__icon {
    font-size: em(25); // icon size
    margin-left: auto;
  }

  .arrow {
    display: flex;
    align-items: center;
    transition: $transition;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.btn__component:not(.btn__component--outline) {
  &:focus,
  &:hover {
    color: $black;
    background-color: $transparent;

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      color: $black;
    }
  }

  @include nestedArticleColors($themes-article) {
    border-color: themedArticle('btn-primary-border');
    background-color: themedArticle('btn-primary-background');
    color: themedArticle('btn-primary-text');

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      color: themedArticle('btn-primary-text');
    }

    &:focus,
    &:hover {
      color: $black;
      background-color: $transparent;

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: $black;
      }
    }
  }
}

.btn__component--outline {
  &:focus,
  &:hover {
    background: $btn-primary-bg-color;
    color: $btn-primary-text-color;

    [class*="icon-"]:before,
    .arrow:before {
      color: $btn-primary-text-color;
    }
  }

  @include nestedComponentColors($themes) {
    border-color: themed('btn-primary-border');
    background-color: transparent;
    color: themed('text');

    [class*="icon-"]:before,
    .arrow:before {
      color: themed('text');
    }
  }

  &:focus,
  &:hover {
    color: themed('--color-btn-primary');
    background-color: themed('--color-btn-primary-text');

    [class*="icon-"]:before,
    .arrow:before {
      color: themed('--color-btn-primary');
    }
  }
}

button.btn__component {
  display: flex;
  align-items: center;
}

@include globalComponentColors($themes) {
  .btn__component:not(.btn__component--outline) {
    border-color: themed('btn-primary-border');
    background-color: themed('btn-primary-background');
    color: themed('btn-primary-text');

    &.has-arrow {
      .arrow:before {
        color: themed('btn-primary-text');
      }
    }

    [class*="icon-"]::before,
    .arrow:before {
      color: themed('btn-primary-text');
    }

    &:focus,
    &:hover {
      color: themed('text');
      background-color: $transparent;

      [class*="icon-"]::before,
      .arrow:before {
        color: themed('text');
      }
    } 
  }

  .btn__component--outline {
    border-color: themed('btn-primary-border');
    background-color: transparent;
    color: themed('text');

    &.has-arrow {
      .arrow:before {
        color: themed('text');
      }
    }

    [class*="icon-"]::before,
    .arrow:before {
      color: themed('text');
    }
    
    &:focus,
    &:hover {
      color: themed('btn-primary-text');
      background-color: themed('btn-primary-background');

      [class*="icon-"]:before,
      .arrow:before {
        color: themed('btn-primary-text');
      }
    }
  } 
}

@include globalArticleColors($themes-article) {
  .btn__component:not(.btn__component--outline) {
    &:focus,
    &:hover {
      color: themedArticle('text');
      background-color: $transparent;

      [class*="icon-"]:before,
      .arrow:before {
        color: $black;
      }
    }
  }

  .btn__component--outline {
    border-color: themedArticle('btn-primary-border');
    background-color: transparent;
    color: themedArticle('text');

    [class*="icon-"]:before,
    .arrow:before {
      color: themedArticle('text');
    }
    
    &:focus,
    &:hover {
      @include nestedComponentColors($themes-article) {
        color: themedArticle('btn-primary-text');
        background-color: themedArticle('btn-primary-background');

        [class*="icon-"]:before,
        .arrow:before {
          color: themedArticle('btn-primary-text');
        }
      }
    }
  }
}

@include sectionColors($themes-section) {
  .bg--transparent .btn__component:not(.btn__component--outline),
  .btn__component:not(.btn__component--outline) {
    border-color: themedSections('text');
    background-color: themedSections('text');
    color: themedSections('background');

    .btn__component__text,
    [class*="icon-"]:before,
    &.has-arrow .arrow:before {
      color: themedSections('background');
    }

    &:focus,
    &:hover {
      color: themedSections('text');
      background-color: $transparent;

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: themedSections('text');
      }
    }
  }

  .bg--transparent .btn__component--outline, 
  .btn__component--outline {
    border-color: themedSections('text');
    background-color: transparent;
    color: themedSections('text');

    .btn__component__text,
    [class*="icon-"]:before,
    &.has-arrow .arrow:before {
      color: themedSections('text');
    }
    
    &:focus,
    &:hover {
      color: themedSections('background');
      background-color: themedSections('text');

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: themedSections('background');
      }
    }
  }
}
