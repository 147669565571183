header {
  h1 {
    &.hidden {
      z-index: 0;
      position: absolute;
      top: 50%;
      right: 10000px;
      display: block;
      transform: translate(-50%, 0);
      font-size: em(14px); // Is off screen, not allowed to be smaller for accessibility
      margin: 0;
    }
  }  
}

.headroom {
	will-change: transform;
	transition: transform 200ms linear;
}

.headroom--pinned {
	transform: translateY(0%);
}

.headroom--unpinned {
	transform: translateY(-100%);
}
