$theme-section-map: null;

$themes-section: (
  "primary-decorative": (
    background: var(--color-primary-decorative),
    text: var(--color-primary-decorative-text),
    link: var(--color-primary-decorative-text),
    icon: var(--color-primary-decorative-text),
    btn-primary-background: var(--color-primary-decorative-text),
    btn-primary-border: var(--color-primary-decorative-text),
    btn-primary-text: var(--color-primary-decorative),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-primary-decorative),
  ),
  "secondary-decorative": (
    background: var(--color-secondary-decorative),
    text: var(--color-secondary-decorative-text),
    link: var(--color-secondary-decorative-text),
    icon: var(--color-secondary-decorative-text),
    btn-primary-background: var(--color-secondary-decorative-text),
    btn-primary-border: var(--color-secondary-decorative-text),
    btn-primary-text: var(--color-secondary-decorative),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-secondary-decorative),
  ),
);

@mixin sectionColors($themes-section) {
  @each $theme, $map in $themes-section {
    .bg-section--#{$theme} {
      $theme-section-map: map-get($themes-section, $theme) !global;
      @content;
    }
  }
}

@mixin nestedSectionColors($themes-section) {
  @each $theme, $map in $themes-section {
    &.bg-section--#{$theme} {
      $theme-section-map: map-get($themes-section, $theme) !global;
      @content;
    }
  }
}

@function themedSections($property) {
  $property: unquote($property);
  @return map-get($theme-section-map, $property);
}

