/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Link animation
 */

// Underline on hover - left to right
/**
 * Link animation
 *
 * Usage: <body class="link--underline--lr">
 * Will overide default link animations
 *
 * Link animations don't work with display: flex;
 */

// Default
body:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {
  p,
  h2,
  h3,
  h4,
  .list__bullets li,
  .rich-text li,
  .article-wrapper .rich-text li,
  .accordion .rich-text li,
  .article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
  .info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .label {
    a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
    }
  }

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        border-bottom: 1px solid var(--darkmode-50);
    
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
      
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        border-bottom: 1px solid var(--darkmode-50);

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }
  
  [class*="bg-"] {
    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
    
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          &:hover,
          &:focus {
            color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }
  }

  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
    
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--darkmode-50);

      a:not(.list__link) {
        color: var(--darkmode-50);
        border-bottom: 1px solid var(--darkmode-50);
    
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .hero {
    &[class*="bg-"] {
      .suggestions p {
        color: var(--darkmode-50);
  
        a:not(.list__link) {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
      
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    &[class^="article-element-bg"],
    &[class*="article-element-bg"] {
      .rich-text a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
    
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .footer.hightlight-links p,
	.footer.hightlight-links li {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

			&:hover,
			&:focus {
				color: var(--darkmode-50);
			}
		}
	}
}

// All link animations
&.link--underline--lr,
&.link--underline-fat-lr,
&.link--underline-bg-bt,
&.link--underline-bg-gradient-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .label {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {

          color: var(--darkmode-50);
        
      }
    }
  }

  &[class*="bg-"] {
    p,
    .label {
      a:not(.list__link) {
        color: var(--darkmode-50);
  
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
  
        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }
}

// Underline on hover - left to right
&.link--underline--lr {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
			color: var(--darkmode-50);
			background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        border-bottom: 1px solid var(--darkmode-50);

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
  
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
        

        &:hover,
        &:focus {
    
            color: var(--darkmode-50);
          
        }
      }
    }
  }

  [class*="bg-"] {
    p,
    .label {
      a:not(.list__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          &:hover,
          &:focus {
            color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }
  }

  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    p,
    .label {
      a:not(.list__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--darkmode-50);

      a:not(.list__link) {
        color: var(--darkmode-50);
      }
    }
  }

  .hero {
    &[class*="bg-"] {
      .suggestions p {
        color: var(--darkmode-50);
  
        a:not(.list__link) {
          color: var(--darkmode-50);
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    } 
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
			background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
			color: var(--darkmode-50);

      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    &[class^="article-element-bg"],
    &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }  
  }
}

// Underline - fat on hover - left to right
&.link--underline-fat-lr {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li
  .form-richtext,
  .list__icons li,
  .label {
		a:not(.list__link):not(.btn) {
			color: var(--darkmode-50);
			background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
			border-bottom: 1px solid var(--darkmode-50);

			&:hover,
			&:focus {
				color: var(--darkmode-50);
			}
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        border-bottom: 1px solid var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {

          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        

        &:hover,
        &:focus {

            color: var(--darkmode-50);
          
        }
      }
    }
  }

  [class*="bg-"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        border-bottom: 1px solid var(--darkmode-50);

      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        border-bottom: 1px solid var(--darkmode-50);
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          &:hover,
          &:focus {
            color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }
  }

  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        border-bottom: 1px solid var(--darkmode-50);
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        border-bottom: 1px solid var(--darkmode-50);
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient( var(--darkmode-50),  var(--darkmode-50));
      border-bottom: 1px solid var(--darkmode-50);
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--darkmode-50);

      a:not(.list__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        border-bottom: 1px solid var(--darkmode-50);
      }
    }
  }

  .hero {
    &[class*="bg-"] {
      .suggestions p {
        color: var(--darkmode-50);
  
        a:not(.list__link) {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
          border-bottom: 1px solid var(--darkmode-50);
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    &[class^="article-element-bg"],
    &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    } 
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);

      &:hover,
      &:focus {
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        color: var(--darkmode-50);
      } 
		}
	}

	.footer.hightlight-links p,
	.footer.hightlight-links li {
		a:not(.list__link):not(.btn) {
			background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
			border-color: var(--darkmode-50);
			border-bottom: 1px solid var(--darkmode-50);
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    &[class^="article-element-bg"],
    &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          border-bottom: 1px solid var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }  
  }
}

// Underline - background color on hover - bottom to top
&.link--underline-bg-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

      &:hover,
      &:focus {
        color: var(--color-link-colored-bg);
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));

        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));
  
          &:hover,
          &:focus {
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
  
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));
        

        &:hover,
        &:focus {

            color: var(--color-overlay-button-text);
          
        }
      }
    }
  }

  [class*="bg-"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
        color: var(--darkmode-50);
  
        &:hover,
        &:focus {
          color: themed('link-background-text');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
        &:hover,
        &:focus {
          color: themed('background');
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          &:hover,
          &:focus {
            color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }
  }

  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
  
        &:hover,
        &:focus {
          color: themedArticle('background');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
        &:hover,
        &:focus {
          color: themedArticle('background');
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--darkmode-50);

      a:not(.list__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }

  .hero {
    &[class*="bg-"] {
      .suggestions p {
        color: var(--darkmode-50);
  
        a:not(.list__link) {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
    
          &:hover,
          &:focus {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
          color: var(--darkmode-50);

          &:hover,
          &:focus {
            color: var(--darkmode-50);
          }
        }
      }
    }
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
			color: var(--darkmode-50);

      &:hover,
      &:focus {
        color: var(--color-footer);

        &::before {
          color: var(--darkmode-50);
        }
      }
		}
	}

	.footer.hightlight-links {
		p,
		li {
			a:not(.list__link):not(.btn) {
        &:hover,
        &:focus {
          color: var(--color-footer);
        }
			}
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
          &:hover,
          &:focus {
            color: themedArticle('background');
          }
        }
      }
    }  
  }
}

&.link--underline-bg-gradient-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
			background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--color-link) 100%);

      &:hover,
      &:focus {
        color: var(--color-link-colored-bg);
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);

        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--darkmode-50);
          background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);
  
          &:hover,
          &:focus {
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        color: var(--darkmode-50);
        background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);

        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }

  [class*="bg-"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
      
        &:hover,
        &:focus {
          color: themed('link-background-text');
        }
      }
    }

    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
      
        &:hover,
        &:focus {
          color: themed('link-background-text');
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          &:hover,
          &:focus {
            color: var(--darkmode-hightlight-1-200);
          }
        }
      }
    }
  }

  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
      
        &:hover,
        &:focus {
          color: themedArticle('background');
        }
      }
    }

    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
      
        &:hover,
        &:focus {
          color: themedArticle('background');
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
      background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);

      &:hover,
      &:focus {
        color: var(--darkmode-50);
      }
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--darkmode-50);

      a:not(.list__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
  
        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }

  .hero {
    &[class*="bg-"] {
      .suggestions p {
        color: var(--darkmode-50);
  
        a:not(.list__link) {
          color: var(--darkmode-50);
          background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);
    
          &:hover,
          &:focus {
            color: themed('background');
          }
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);

          &:hover,
          &:focus {
            color: var(--color-link-colored-bg);
          }
        }
      }
    }
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
      color: var(--darkmode-50);
			background-image: linear-gradient(120deg, var(--darkmode-50) 0%, var(--darkmode-50) 100%);

      &:hover,
      &:focus {
        color: var(--color-footer);
  
        &::before {
          color: var(--darkmode-50);
        }
      }
		}
	}

	.footer.hightlight-links {
		p,
		li {
			a:not(.list__link):not(.btn) {
        &:hover,
        &:focus {
          color: var(--color-footer);
        }
			}
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: var(--darkmode-50);
          background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));
  
          &:hover,
          &:focus {
            color: themedArticle('background');
          }
        }
      }
    }  
  }
}
