.search-form--integrated {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
  padding-bottom: 6px;

  &.fixed-width {
    width: 292px;
  }

  &.active {
    .input--transparent {
      width: 220px;
      height: 35px;
      padding: 0 10px;
      opacity: 1;
    }

    .submit-input {
      display: flex;
      position: absolute;
      padding-left: 10px;
      right: 0;
      color: $black;
    }
  }

  &.not-showing {
    display: none;
  }

  .input-expandable {
    border-radius: 25px;
    transition: all 0.2s ease-in;
    width: 0;
    padding: 0;
    border: none;

    &.active {
      padding: 10px 15px;
      margin-right: 6px;
      width: 250px;
    }
  }

  .expand-input-btn {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .submit-input-btn {
    position: relative;
    color: var(--color-primary-text);
  }

  .btn-empty-input {
    position: absolute;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .w-6 {
    width: 1.5rem;
  }

  .h-6 {
    height: 1.5rem;
  }
}