@include nestedComponentColors($themes) {
  .list-item--cta {
    a {
      background-color: themed('btn-primary-background');
      color: themed('btn-primary-text');
      border-color: themed('btn-primary-border');

      &:hover,
      &:focus,
      &.active {
        background-color: transparent;
        color: themed('text') ;

        [class*=icon-]::before {
          color: themed('text');
        }
      }
  
      [class*="icon-"]::before {
        color: themed('btn-primary-text');
      }
    }
  }
}