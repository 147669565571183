/**
 * Form container
 */

.form-container {
  width: 100%;
	padding: 30px 40px 55px 40px;
  background-color: $white;
	border-top-width: var(--component-border-top-width);
	border-top-style: solid;
  border-top: $color-border-top--article-components-bg;
  border-radius: $border-radius-component;

	@include media-breakpoint-down(md) {
		padding: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 15px;
	}

  &.side-box {
    padding: 30px;
  }

  &--error {
    border-top-color: #a13030;

    .title .title__icon__bg {
      background-color: #a13030;

      &::before {
        color: $white;
      }
    }
  }

	h3,
	.h3,
  p {
    color: $black;
		margin-bottom: 30px;
	}

  h4,
	.h4 {
    @include responsive-font-size($h5-font-size-sm, "", $h5-font-size-sm, "", $h5-font-size-lg);
		margin-bottom: 15px;
	}

  .form-group {
    // Input and Textarea
    input,
    input[type="date"],
    textarea {
      background-color: $grey-light;
      border: none;
    }

    .select select,
    .select-checkboxes {
      background: $grey-light;
      color: $grey-dark;
      border: none;
    }
  }

  .form-sub-response {
    .checkmark-icon,
    .error-icon {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

// Form in three column grid
.col-md-6.col-lg-4 .form-container {
  padding: var(--sleeve-component-padding);
}
