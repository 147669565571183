/*
** Background color on colored components on article pages
**
** .article-element-bg--? is used to give components a diiferent background color
**
*/

/***** Background highlight color *****/
@include globalArticleColors($themes-article) {
  [class*="icon-"]::before{
		color: themedArticle('text');
	}

  .btn__link,
  .ellipsis .dropdown-toggle,
  .tag,
  .label,
  .tag--with-icon:not(.color-red) span:not([class*=icon-]) {
		color: themedArticle('text');
	}

  .tag span {
		color: themedArticle('tag');
	}

  .tag.tag--outlined {
    border-color: themedArticle('tag');
  }

  .label.label--outlined,
  .tag.tag--outlined {
    border-color: themedArticle('text');
  }

  .tag--bg-color::before,
  .tag--bg-color--system::before {
    background-color: themedArticle('text');
  }

  .rich-text {
    // In text tooltip
    .intext-tooltip {
      border-bottom-color: var(--color-article-element-text);
      border-bottom-color: $black;
    }

    ul li::marker,
    .intext-arrow-link:before {
      color: $black;
    }
  }

  @include nestedArticleColors($themes-article) {
    .btn__primary,
    .btn__primary-arrow--long,
    .btn__submit,
    .btn__selfservice,
    .btn__secondary {
      &:hover,
      &:focus {
        color: $black;
      }
    }

    .btn__component {
      &:hover,
      &:focus {
        .btn__component__text,
        [class*="icon-"]:before,
        .arrow:before {
          color: $black;
        }
      }

      @include nestedArticleColors($themes-article) {
        &:hover,
        &:focus {
          .btn__component__text,
          [class*="icon-"]:before,
          .arrow:before {
            color: $black;
          }
        }
      }
    }
  }
}
