/*
** Global
** Typography variables
*/

// Base font size
$base-font-size: var(--base-font-size);

// Fonts
$primary-font: var(--font-primary);
$secondary-font: var(--font-secondary);
$small-font: var(--font-secondary);

// Heading font weight
$font-weight-heading: 600;
$font-weight-body: 400;
$font-weight-tag: 600;
$font-weight-heading-menu: 600;

// H1
$h1-font-size-lg: em(40px);
$h1-font-size-sm: em(26px);
$h1-line-height-lg: em(20px);
$h1-line-height-sm: em(20px);

// H2
$h2-font-size-lg: em(26px);
$h2-font-size-sm: em(22px);
$h2-line-height-lg: em(20px);
$h2-line-height-sm: em(20px);

// H3
$h3-font-size-lg: em(20px);
$h3-font-size-sm: em(18px);
$h3-line-height-lg: em(21px);
$h3-line-height-sm: em(22px);

// H4
$h4-font-size-lg: em(20px);
$h4-font-size-sm: em(18px);
$h4-line-height-lg: em(21px);
$h4-line-height-sm: em(22px);

// Components
$component-title-font-size: $h3-font-size-lg;
$component-title-line-height: $h3-line-height-lg;
$component-font-size: em(16px);
$component-line-height: em(26px);

// Article
$article-font-size-lg: em(18px);
$article-font-size-sm: em(16px);
$article-line-height-lg: em(25px);
$article-line-height-sm: em(26px);
$article-bullet-font-size: em(22px);
$article-bullet-line-height-lg: inherit !important;
$article-bullet-line-height-sm: inherit !important;

$article-link-font-size-lg: inherit;
$article-link-font-size-sm: inherit;
$article-link-line-height-lg: inherit;
$article-link-line-height-sm: inherit;

// Small
$small-font-size: em(12px);
$small-line-height: em(30px);
$small-text-transform: initial;
$small-category-font-weight: 600;

// Form elements
$form-font-size: em(16px);

// Mega menu
$header-nav-link-font-size: em(18px);
$header-nav-link-line-height: em(18px);
$megamenu-link-list-font-size: em(14px);
$megamenu-link-list-line-height: em(18px);

// Breadcrumb
$breadcrumb-font-size: em(14px);
$breadcrumb-line-height: em(18px);

// Hero article
$hero-article-p-font-size-lg: em(24px);
$hero-article-p-font-size-sm: em(22px);
$hero-article-p-line-height-lg: em(21px);
$hero-article-p-line-height-sm: em(21px);

// Search-bar small
$search-bar-small-font-size-lg: 1.25rem;
$search-bar-small-font-size-sm: 1.125rem;

// Page title icon
$page-title-icon-bg: $grey-medium;
$page-title-icon-color: var(--color-icons);

// Pageination
$pagination-a-font-size-lg: 1.875rem;
$pagination-a-font-size-sm: 1.25rem;
$pagination-a-line-height-lg: 2.625rem;
$pagination-a-line-height-sm: 1.625rem;

// Link-box
$link-box-font-size: em(16px);
$link-box-line-height: em(24px);

// List link
$list-link-font-size: $component-font-size;
$list-link-line-height: em(20px);
$list-link-li-margin-bottom: 20px;

// Link arrows
$list-link-arrow-top: 5px;
$lisk-link-menu-arrow-top: 6px;
$intext-arrow-top: 9px;
$intext-arrow-top-sm: 8px;

// Footer
$footer-line-height: em(28px);
$footer-p-font-size-lg: $component-title-font-size;
$footer-p-font-size-sm: $component-title-font-size;
$footer-p-line-height-lg: $footer-line-height;
$footer-p-line-height-sm: $footer-line-height;

// Quote
$blockquote-font-size-lg: 1.3em;
$blockquote-font-size-sm: 1.1em;
$blockquote-line-height-lg: 1.4em;
$blockquote-line-height-sm: 1.3em;
