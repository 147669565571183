/**
 * Shadows
 */

.box-shadow {
  box-shadow: $shadow;
}

.box-shadow--lg {
  box-shadow: $shadow--lg;
}
