/**
 * Margin
 * Classes that can be used to create margin
 */

//- Margin bottom - none
.mb--none {
	margin-bottom: 5px !important;
}

//- Margin bottom - large
.mb--large {
	@include responsive-space("mb", var(--section-margin-bottom-sm--large), var(--section-margin-bottom-sm--large), var(--section-margin-bottom-md--large), "", "");
}

//- Margin bottom - medium
.mb--medium {
	@include responsive-space("mb", var(--section-margin-bottom-sm--medium), var(--section-margin-bottom-sm--medium), var(--section-margin-bottom-md--medium), "", "");
}

//- Margin bottom - small
.mb--small {
	@include responsive-space("mb", var(--section-margin-bottom-sm--small), var(--section-margin-bottom-sm--small), var(--section-margin-bottom-md--small), "", "");
}

//- Margin bottom - xtra small
.mb--xsmall {
	margin-bottom: var(--section-margin-bottom--xsmall);
}

.col-mb--md-down {
  @include media-breakpoint-down(md) {
    margin-bottom: var(--section-margin-bottom--xsmall);
  }
}

.col-mb--sm-down {
  @include media-breakpoint-down(sm) {
    margin-bottom: var(--section-margin-bottom--xsmall);
  }
}

.col-mb--md-up {
  @include media-breakpoint-up(md) {
    margin-bottom: var(--section-margin-bottom--xsmall);
  }
}

// Margin top - large
.mt--large {
  @include responsive-space("mt", var(--section-margin-bottom-sm--large), var(--section-margin-bottom-sm--large), var(--section-margin-bottom-md--large), "", "");
}

// Margin top - medium
.mt--medium {
	@include responsive-space("mt", var(--section-margin-bottom-sm--medium), var(--section-margin-bottom-sm--medium), var(--section-margin-bottom-md--medium), "", "");
}

// Margin top - small
.mt--small {
	@include responsive-space("mt", 30px, 30px, 60px, "", "");
}

// Margin top - xsmall
.mt--xsmall {
	margin-top: var(--section-margin-bottom--xsmall);
}
