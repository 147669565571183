/*
** Creative
** Aside
*/

.aside {
  border-top: solid 2px $color-primary;

  .rich-text {
    margin-top: 15px;
  }
}
