/*
** <section>
** Background color white
*/

.bg-section--white:not(svg),
main:not(.bg-main--white) .bg-section--invert-bg-main {
	background: var(--section-white-bg);

  .form-container.bg--white,
  .info-box__links:not([class*="bg-"]),
  .info-box__links__icon--large:not([class*="bg-"]),
  .info-box__links__icon .info-box__links__icon__item,
  // .framed--components,
  .info-box__links__icon--framed,
  .owl-card-carousel .card__portrait,
  .card__portrait:not([class*="bg-"]),
  .card__portrait--no-image:not([class*="bg-"]),
  .card__meeting,
  .card__cta:not([class*="bg-"]),
  .card__event,
  .media-box.media-box--text-box:not([class*="bg-"]),
  .media-box--displaced .media-box__text,
  .media-box--equal-heights:not([class*="bg-"]),
  .rich-text__text-box.framed,
  .link-box:not(.link-box__article):not([class*="article-element-bg--"]) {
    border: var(--section-white-bg-component-border);
    background-color: var(--section-white-bg-component-bg);
  }

  /***** Section dividers *****/
  &.line {
    background-color: var(--section-white-bg);
  }

  .card__event .card__event__image-content .card__event__image-content__date {
    background-color: var(--section-white-bg-component-bg);
  }

	.owl-carousel {
    .owl-dots {
      .owl-dot {
        &::after {
          border: 2px solid var(--color-btn-primary);
        }
  
        &.active {
          &::after {
            background: var(--color-btn-primary);
          }
        }
      }
    }
	}

  &.hero__article__displaced {
    background: unset;

    .hero__article__media.postion-absolute {
      background: var(--section-white-bg);
    }
  }
}

main:not(.bg-main--white) {
  .link-boxes--framed.framed--components {
    z-index: 3;
    position: relative;
    background: $color-body;

    &::after {
      z-index: -1;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: var(--section-white-bg);
      border-radius: $border-radius-component;
    }

    .link-box:not(.link-box__article):not(.article-element-bg--0):not(.article-element-bg--1):not(.article-element-bg--2):not(.article-element-bg--3):not(.article-element-bg--4):not(.article-element-bg--5) {
      border: var(--section-white-bg-component-border);
      background-color: var(--section-white-bg-component-bg);
    }
  }
}

svg.bg-section--white {
  path {
    fill: var(--section-white-bg);
  }
}