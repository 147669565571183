/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Text
 */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p,
a,
ul li,
ol li,
.list__links .list__link,
label,
legend,
input::placeholder,
textarea::placeholder,
.service-menu button,
.span__link-arrow--long,
select,
select option,
.search-bar--small form .form-group label,
.service-menu .service-menu__item a,
.article-anchor-menu ol li a,
.select-checkboxes .dropdown-toggle,
.btn__color-theme__text,
.btn__feedback--border,
.hero__text-box .hero-inner p,
.article-wrapper p.caption,
// .bg-section--primary .feedback-banner h2,
.info-box .list__text .label,
.ellipsis.dropdown .dropdown-menu ul li,
.side-menu__no-dropdowns a.active,
.side-menu__no-dropdowns a:hover,
.author,
.label,
.label span,
.tag.tag--free-color span,
.tag.tag--with-icon span:not([class*="icon-"]) {
  color: var(--darkmode-50);
}

[class*="bg-"] {  
  .label {
    color: var(--darkmode-50) !important;
  }
}

.rich-text p {
  color: var(--darkmode-50) !important;
}

.label--outlined,
.tag--border {
  border-color: var(--darkmode-50);
}

.tag--outlined {
  border-color: var(--darkmode-hightlight-2-200);
}

.tag--bg-color::before {
  background-color: var(--darkmode-hightlight-2-200);
}

.tag.tag--bg-color--system {
  &::before {
    background-color: var(--darkmode-hightlight-1-200);
  }

  &.tag--outlined {
    border-color: var(--darkmode-hightlight-1-200);
 }

 span {
  color: var(--darkmode-hightlight-1-200) !important;
 }
}

.icon--inline-tag,
.tag--icon {
  background: var(--darkmode-900);

  [class*="icon-"] {
    color: var(--darkmode-hightlight-1-200);
  }
}

.icon--inline-tag {
  &::before {
    background-color: var(--darkmode-hightlight-1-200);
  }
}

.tag--icon {
  &::after {
    background-color: var(--darkmode-hightlight-1-200);
  }
}

.hero__article .hero__article__text .hero__tags--over p:before {
  background: var(--darkmode-600);
}

.tags-container.with-divider .tag:before, 
.tags-container.with-divider .label:before, 
.tags--inline.with-divider p:before, 
.dates--inline.with-divider p:before {
 background-color: var(--darkmode-500);
}

.tags-container.with-divider>*:not(:first-child)::after {
  background-color: var(--darkmode-50);
}

.intext-tooltip {
  border-color: var(--darkmode-50);
}

[class*=article-element-bg] .rich-text .intext-tooltip {
  border-bottom-color: var(--darkmode-50);
}

p.month {
  color: var(--darkmode-50) !important;
}

h2 a,
h3 a,
h4 a,
h5 a,
p a,
a:not(.btn) {
  &:not([href]):not([tabindex]) {
    &:hover,
    &:focus {
      color: var(--darkmode-50) !important;
    }
  }
}

.tag,
.tag span,
.ellipsis.dropdown .dropdown-toggle {
  color: var(--darkmode-hightlight-2-200) !important;
}

// Tag block
.tag-block .tag,
.card__portrait .tag-block .tag {
  background: var(--darkmode-600);

  span {
    color: var(--darkmode-50);

    &.border-left {
      background: var(--darkmode-hightlight-2-200) !important;
    }
  }
}

// Ellipsis
.ellipsis.dropdown .dropdown-menu {
  background: var(--darkmode-700);
  border-color: var(--darkmode-600);
}

.tag:not(.tag--free-color):not(.tag--bg-color--system),
.ellipsis.dropdown .dropdown-toggle {
  color: var(--darkmode-hightlight-2-200) !important;
}

.tag--free-color .color-dot {
  border: 4px solid  var(--darkmode-hightlight-2-200);
}

// Lists
.list ul li {
  border-color: var(--darkmode-600);
}

.list__link::before {
  border-color: var(--darkmode-hightlight-1-200) !important;
}

.list__bullets ul li {
  &::before {
    color: var(--darkmode-hightlight-1-200);
  }

  a {
    color: var(--darkmode-50) !important;
    background-size: 100% 1px;
    background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50)) !important;
  }
}

.list__news {
  .list__news__item:hover .h3 {
    color: var(--darkmode-50);
  }
}

.rich-text ul li::marker, 
.info-box-article .rich-text-article__references ul li::marker, 
.list__bullets li::marker {
  color: var(--darkmode-hightlight-1-200);
}

.rich-text a:not([href]):not([tabindex]):not(.btn) {
  color: var(--darkmode-50) !important;
}
