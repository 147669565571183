.card__image {
	position: relative;
	display: flex;
	justify-content: flex-end;
	height: 100%;
	min-height: 520px;
	border-radius: $border-radius-component-image;
  background-color: var(--color-overlay-start); // Default background color

	@include media-breakpoint-down(md) {
		min-height: 400px !important;
	}

	.tags-container {
		margin-bottom: 10px;
	}

	&.card__image--small {
		min-height: 240px;

		@include media-breakpoint-down(md) {
			min-height: 240px !important;
		}

		.card__image__content {
      padding-top: 120px;
			min-height: 100%;
			border-top-left-radius: $border-radius-component-image;
			border-top-right-radius: $border-radius-component-image;
		}
	}

  &:hover {
		.arrow {
			right: 35px;
		}

		img:not(.watermark__img) {
			transform: scale(1.05);
		}
	}


  &:not([class*="bg-"]) {
    .card__image__content {
      h2,
      h3,
      .h3,
      p,
      span:not([class^="icon-"]),
      .arrow:before {
        color: $white;
      }
  
      .arrow {
        color: var(--color-overlay-text);
      }
    }
  }


	.arrow {
		position: absolute;
		bottom: 40px;
		right: 30px;
		transition: $transition;
	}

	.card__image__media {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		border-radius: $border-radius-component-image;
	}

	img:not(.watermark__img) {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transition: $transition;
	}

	.card__image__content {
		z-index: 1;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		background: var(--color-overlay-0deg);
		min-height: 50%;
		height: auto;
    padding: 160px 40px 30px 40px;
		border-bottom-left-radius: $border-radius-component-image;
		border-bottom-right-radius: $border-radius-component-image;

		@include media-breakpoint-down(md) {
			height: 100%;
      padding: 30px;
      padding-top: 50%;
		}

		.card__image__title {
			padding-right: 24px;
      width: calc(100% - 24px);
		}

		h2,
		h3,
		.h3 {
			margin-bottom: 0;
		}

		p:not(.tag) {
			margin-top: 0.5rem;
			margin-bottom: 0;
		}
	}
}

// .card__image in .article-wrapper
.article-wrapper,
[data-creative~="article-content"] {
	.card__image {
		.arrow {
			height: 23px;
			bottom: 30px;

			svg {
				width: 20px;
			}
		}

		.card__image__content {
      padding: 160px 30px 30px;
			height: 80%;

			.h3 {
				@include responsive-font-size(
					$article-font-size-sm,
					"",
					$article-font-size-sm,
					"",
					$article-font-size-lg
				);
			}

			p {
				font-size: $small-details-font-size;
			}
		}
	}
}

// In Article-warpper
.article-wrapper,
[data-creative~="article-content"] {
	.card__image.card__image--small {
		height: calc(100% - 30px);
	}
}
