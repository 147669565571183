.header--tagged-sidemenu {
	width: 100%;
  min-height: 100vh;
	background-color: white;
	padding: 0 15px;

  @include media-breakpoint-down(md) {
    height: 100vh;
    padding-bottom: 150px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
	}

	@include media-breakpoint-up(lg) {
		min-height: fit-content;
		padding: 10px 40px 0 40px;
		border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
	}

	&.alpine-transition-enter {
		transition: all 0.3s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave {
		transition: all 0.3s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(100%);
	}
}

.header--tagged-sidemenu-list {
	max-width: 1600px;

	@include media-breakpoint-up(lg) {
		min-height: fit-content;
		align-items: stretch;
		display: flex;
		padding: 0;
	}

	.sidemenu-list-item {
		position: relative;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 15px 0;

		&:active {
			a {
				color: var(--color-link);
			}
		}

		a:focus {
			outline: none;

			.list-item-content-heading {
				color: var(--color-link);
			}

			&:after {
				transform: scaleX(100%);
				transform-origin: 0 100%;
			}
		}

		@include media-breakpoint-up(lg) {
			border-bottom: none;
			flex: 1;
			margin-right: 20px;
			margin-left: 20px;
			padding: 0;

			&:first-child {
				margin-left: 0;
			}

			// current site
			&.active {
				a:after {
					transform: scaleX(100%);
					transform-origin: 0 100%;
				}
			}

			// divider
			&:not(:first-child):before {
				content: "";
				position: absolute;
				left: -20px;
				top: 0;
        height: calc(100% - 10px);
				width: 1px;
				background-color: rgba(0, 0, 0, 0.2);
			}

			// underline
			a:after {
				content: "";
				position: absolute;
				transform-origin: 100% 50%;
				bottom: 0;
				left: 0;
				transform: scaleX(0);
				width: 100%;
				background-color: var(--color-link);
				height: 4px;
				transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
			}

			//hover animation
			&:hover {
				.list-item-content-heading {
					color: var(--color-link);
				}

				a:after {
					transform: scaleX(100%);
					transform-origin: 0 100%;
				}
			}
		}

		.list-item-content {
			display: flex;
			flex-direction: column;
			transition: all 0.2s ease;
      line-height: 1.5;
			min-height: 44px; // Web accessibility
			min-width: 24px;
			justify-content: center;
      font-size: $base-font-size; // Reset font-size
			
			@include media-breakpoint-up(lg) {
        margin: 5px 0 9px 0;
			}
		}

		.list-item-content-heading {
      font-size: $header-font-size-1;
			font-weight: $font-weight-heading;
			transition: all 0.1s ease;
		}

		.list-item-content-tags {
			opacity: 0.7;
      font-size: $header-font-size-3;

			@include media-breakpoint-up(lg) {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}
}
