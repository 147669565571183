.card__event.card__event--date--top-left {
	.card__event__image-content {
		padding: 15px;
		margin: -15px;
		padding-bottom: 30px;

		.card__event__image-content__date {
			bottom: auto;
			top: 0;
			padding: 12px 15px;
      background-color: var(--color-btn-primary);
      border-radius: var(--sleeve-component-border-radius);
      margin-bottom: 0;

			p.day,
			p.month {
        color: var(--color-btn-primary-text);
			}
		}
	}

	.card__event__content {
		padding: 10px 30px 30px 30px;
	}

  .card__tag {
    top: 30px;
    right: 30px;
  }

  .card__badge {
    right: 25px;
  }
}