/*
** Creative
** Parallax
*/

.parallax {
	position: relative;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

  @include media-breakpoint-down(sm) {
    background-attachment: inherit;
  }

	&::-webkit-scrollbar {
		display: none; /* Chrome and Safari */
	}

  // Quick fix for meeting
  .link-boxes {
    margin-bottom: 0 !important;

    .link-box {
      background-color: $white;
      margin-bottom: 0 !important;
    }
  }
}
