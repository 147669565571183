/**
 * Info Box Publications
 */

 .events {
   .headline {
    h2,
    .h2,
    h3,
    .h3 {
      margin-bottom: 30px;
     }
   }
 }

 .info-box__publications {
  a {
    @media screen and (min-width: 768px) and (max-width: 1024px) { 
      flex-direction: column;
    }
  }

  h2,
  .h2,
  h3,
  .h3 {
    margin-bottom: 30px;
  }

  .btn__link {
    margin-bottom: 0;
  }

  .list {
    li {
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 0;

      a {
        display: flex;

        &:hover {
          .arrow {
            right: 5px;
          }
        }
      }

      .label,
      p.date {
        margin-top: 10px;
        margin-bottom: 0;
        margin-right: 50px;
      }

      h3,
      .h3,
      h4,
      .h4 {
        margin-top: 10px;
        margin-bottom: 0;
        margin-right: 50px;
      }

      .arrow {
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        right: 0;
        transition: $transition;
      }
    }

    &__image {
      min-width: 80px;
      max-width: 100px;
      width: 80px;
      height: 115px;
      margin-right: 18px;
      @media screen and (min-width: 768px) and (max-width: 1024px) { 
        margin-bottom: 20px;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__image {
    .arrow {
      @media screen and (min-width: 768px) and (max-width: 1024px) { 
        top: 185px !important;
      }
    }
  }
}

.col-md-8 {
  .info-box__publications {
    ul {
      li {
        &:last-child {
          border-bottom: 1px solid $grey-light;
          @include media-breakpoint-down(sm) {
            border-bottom: none;
          }
        }
      }
    }
  }
}
