.card__event.card__event--date--top-right {
  margin-top: 25px; // This is so date can be placed centered on top of the image

  &:not(.no-image) {
    .card__tag {
      max-width: 50%;
    }
  }

  .card__event__image-content {
    padding: 15px;
    margin: -15px;
    padding-bottom: 30px;

    .card__event__image-content__date {
      bottom: auto;
      top: -30px;
      left: unset;
      right: 30px;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      min-height: 90px;
      padding: 10px;
      border-radius: 50%;
      margin-bottom: 0;
      overflow: hidden;
      background-color: var(--color-btn-primary);
     
      p.day,
      p.month {
        color: var(--color-btn-primary-text);
      }

      p.day {
        font-weight: 700;
        font-size: $component-font-size;
      }

      p.month {
        font-weight: 400;
        font-size: $small-font-size;
      }
    }
  }

  .card__event__content {
    padding: 10px 30px 30px 30px;
  }

  .card__tag {
    justify-content: flex-start;
    top: 30px;
    right: unset;
    left: 30px;
  }

  .card__badge {
    right: 30px;
  }
}