/**
 * Classes
 * Global
 */

.overflow--unset {
  overflow: unset !important;
}

.overflow-wrap {
	overflow-wrap: break-word;
}
