/**
 * Footer col-3-3-3
 */

.footer__col-3-3-3-3 {
  padding-top: var(--footer-pt-lg);
  padding-bottom: var(--footer-pb-lg);

	@include media-breakpoint-down(sm) {
    padding-top: var(--footer-pt-md);
    padding-bottom: var(--footer-pb-md);
	}

	.footer__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}

    h2 {
			font-size: $component-font-size;
		}

		p {
			margin-bottom: 15px !important;
		}

    p.h2 {
      font-weight: $font-weight-body;
    }

		ul {
			li {
				margin-bottom: 5px;
			}
		}

		.btn {
			margin-bottom: 40px;
		}

    // Delete after new footer options are implemented
    &.footer__content--address {
      p  {
        font-size: $component-title-font-size;
      }
    }
	}

  // Footer grid
  .row:nth-child(2) {
    margin-top: 30px;
  }

  .col-sm-12.col-md-6.col-lg-3 {
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}
