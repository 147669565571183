

.burger-menu {
  z-index: 200;
  position: relative; // position is only 'relative' until the height has been calculated and padding bottom set via JS. Then position is switched to 'fixed' via JS function.
  top: 0;
  left: 0;
  width: 100%;
}

.burger-menu__nav {
  background: $white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  z-index: 100;
  padding: 0;
}

.container--wide {
  .burger-menu__nav {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.logoimg {
  width: auto;
  height: var(--header-brand-height-lg);
}

.menu-bar {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.burger-menu .nav-brand {
  h1,
  .h1 {
    font-family: $primary-font;
    font-size: calc($header-nav-link-font-size + em(6px));
    line-height: 1.125;
    font-weight: $font-weight-heading;
    color: $grey-dark;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-size: $header-nav-link-font-size;
    }

    strong {
      font-family: $primary-font;
      color: $black;
    }
  }
}

.menu-bar__keys {
  display: flex;
  align-items: center;
}


.burger-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 30px;

	@include media-breakpoint-down(sm) {
		margin-right: 15px;
	}

	.icon--burger-button {
    width: 36px;
    height: 16px;
    position: relative;
    cursor: pointer;
    background-image: none;

    span {
      background-color: $black;
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 7px;
      }

      &:nth-child(3) {
        top: 14px;
      }
    }
  }
}

.burger-button__text {
  margin-right: 7px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
