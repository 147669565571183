/**
 * Buttons
 * .owl-dots
 */


.owl-carousel {
  ul.owl-dots {
    list-style: none;

    @include media-breakpoint-down(md) {
      padding-bottom: 30px;
    }
  }
  
	.owl-dots {
		display: flex;
		justify-content: center;
		margin: 40px auto 0;
		padding: 0;
		height: 12px;

		@include media-breakpoint-down(md) {
			margin-top: 30px;
		}

		.owl-dot {
      position: relative;
			padding: 0;
      min-width: 24px; // Web accessibility
      min-height: 24px; // Web accessibility

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 12px;
        border: 2px solid $color-body-text;
      }

			@include media-breakpoint-down(md) {
        &::after {
          height: 10px;
          width: 10px;
        }
			}

			&.active {
        &::after {
          background: $color-body-text;
        }
			}
		}
	}
}

@import 'background-colors';
