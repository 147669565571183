/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Filter menu
 */

.filter-menu {
  .form-group input[type=date] {
    color: var(--darkmode-50);

    &::placeholder {
      color: var(--darkmode-50) !important;
    }

    &:after {
      border-color: var(--darkmode-hightlight-1-200) !important;
    }
  }

  .svg-location path {
    fill: var(--darkmode-700) !important;
  }
}

.filter-menu.filter-menu__events .filter-menu__items .filter-menu__items__display button svg.svg-grid.active rect, 
.filter-menu.filter-menu__events .filter-menu__items .filter-menu__items__display button svg.svg-list.active rect {
  fill: var(--darkmode-hightlight-1-200);
}
