/**
 * Tags
 */

.tag--icon {
	position: relative;
	display: inline-flex;
	padding: 5px;
	font-size: $base-font-size; // Reset font-size
	margin-bottom: 10px;
	background: $white;
	border-radius: 50px;
	width: fit-content;

	[class*="icon-"] {
		z-index: 1;
		font-size: em(20); // icon size
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $color-icons;
		opacity: 0.1;
		border-radius: 50px;
	}
}

.label {
	font-size: $small-font-size;
	color: $grey-dark;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
  gap: 5px;

	[class^="icon-"] {
		font-size: em(16); // icon size

		display: flex;
		align-items: center;
		font-weight: $font-weight-tag;
		margin-right: 5px;
	}

	&--outlined {
		border: 1px solid $grey-dark;
		padding: 5px 10px;
	}
}

.label--warning {
	color: $warning-orange;

	[class^="icon-"] {
		color: $warning-orange;
	}

	&.label--outlined {
		border-color: $warning-orange;
	}
}

.label--danger {
	color: $error-red;

	[class^="icon-"] {
		color: $error-red;
	}

	&.label--outlined {
		border-color: $error-red;
	}
}

.label--success {
	color: $success-green;

	[class^="icon-"] {
		color: $success-green;
	}

	&.label--outlined {
		border-color: $success-green;
	}
}

.label--outlined,
.tag--outlined {
  border-radius: var(--sleeve-component-border-radius);
}

.tag {
	font-size: $small-font-size;
	color: $color-tag;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: $font-weight-tag;
  text-transform: $small-text-transform;
  white-space: nowrap;

	&--outlined {
		border: 1px solid $color-tag;
		padding: 5px 10px;
	}
  span {
    white-space: initial;
  }
}

button.tag {
  text-decoration: none;
  transition: $transition;
   
  &:hover {
    text-decoration: underline;
  }
}

.tag--free-color {
	color: $black;

	&.tag--outlined {
		border: 1px solid $black;
	}

	.color-dot {
		position: relative;
		width: 8px;
		height: 8px;
		margin-right: 5px;
		border-radius: 50%;
		background-color: #000;
	}
}

button.tag--free-color {
  min-width: 24px; // Web accessibility
  min-height: 24px; // Web accessibility
}

.tag--bg-color,
.tag--bg-color--system {
  position: relative;
	padding: 5px 10px;
  border-radius: var(--sleeve-component-border-radius);

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0.15; // Web accessibility: check contrast to text
    border-radius: var(--sleeve-component-border-radius);
	}
}

.tag--bg-color {
	&::before {
		background-color: $color-tag;
	}
}

.tag--bg-color--system {
  color: $color-tag--system;

	&::before {
		background-color: $color-tag--system;
	}

  &.tag--outlined {
    border: 1px solid $color-tag--system;
  }
}

.tag-containers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;

	&.with-divider {
		gap: 20px;

		& > * {
			position: relative;
		}

		& > *:not(:first-child)::after {
			content: "";
			position: absolute;
			top: 50%;
			left: -10px;
			transform: translate(0, -50%);
			height: 12px;
			width: 1px;
			background: $grey-medium;
		}
	}
}

/*@TODO separate icon styles*/
//- AM - Had do change this styling beacuse it was not testet with all components that can take an icon.
.icon.icon--inline-tag {
	width: fit-content;
	height: fit-content;
	// padding: 8px;
  padding: 5px;
	display: flex;
	border-radius: 50px;
	overflow: hidden;
	position: relative;
	font-size: $small-font-size;
	background-color: $white;

	[class^="icon-"] {
    font-size: 1.25em;
    z-index: 1;
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: $color-icons;
		opacity: 0.1;
	}
}

@include globalComponentColors($themes) {
	.icon::before {
		opacity: 0.5;
	}
}
