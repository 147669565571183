@import 'darkmode';
@import 'link-container';

.header--double-logo-container {
	z-index: 21;
	transition: top ease 300ms;
	position: relative;
}

.header--double-logo {
	width: 100vw;

  @include media-breakpoint-down(md){
		position: relative;
		height: fit-content;
	}

	div, 
  ul, 
  li, 
  nav {
		background-color: inherit;
	}

  ul {
		list-style: none;
		margin: 0;
	}

	li {
		margin-bottom: 0;
	}

	h1,
  .h1 {
		margin: 0;
	}

	button {
		padding: 0;
	}

	.text-white {
		width: auto;
		height: 30px;
	}

	input {
		padding: 0;
	}

  [class*="icon-"] {
    background-color: unset;
  }

	.nav-brand {
		img {
			height: var(--header-brand-height-md);
			margin: calc(var(--header-brand-height-md) / 3) 0;
			width: auto;
			
			@include media-breakpoint-up(lg) {
				height: var(--header-brand-height-lg);
				margin: calc(var(--header-brand-height-lg) / 3) 0;
				width: auto
			}
		}

		h1, 
    .h1 {
			font-size: calc(.0625em*var(--header-nav-link-font-size) + .375em);
			line-height: 1.125;
			font-weight: var(--heading-menu-font-weight);
			margin: calc(var(--header-brand-height-md)/3) 0;

			@include media-breakpoint-down(md) {
				font-size: $header-nav-link-font-size;
      }
		}
	}

	.header--double-logo-top-bar {
		position: relative;
		z-index: 91;
		width: 100%;
		height: fit-content;
		max-height: 400px;
		display: flex;
		align-items: center;
		padding: 0 15px;
		transition: all ease 200ms;

    @include media-breakpoint-up(md) {
			padding: 0 40px;
		}

		&.hide-top-bar {
			transition: all ease 200ms;
			max-height: 0;
			overflow: hidden;
		}

		&.reverse {
			flex-direction: row-reverse;
			justify-content: space-between;
		}

		.has-icon .nav-link{
			display: flex;
			align-items: center;
			gap: 10px;

			div {
				font-size: 1.6rem;
			}
		}

    // smaller top bar logo
    .nav-brand {
      img {
        height: calc(var(--header-brand-height-md) * 0.75);
        margin: calc((var(--header-brand-height-md) / 3 )* 0.75) 0;
		
        @include media-breakpoint-up(lg){
			height: calc(var(--header-brand-height-lg) * 0.75);
			margin: calc((var(--header-brand-height-lg) / 3 )* 0.75) 0;
          width: auto
        }
      }
    }
	
		.header--double-logo-divider {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			margin: 0;
      border-width: 1px;
      border-style: solid;
      border-color: $black;
      opacity: 0.2;
		}

    // component color theming: top bar
    @include nestedComponentColors($themes) {
		background-color: themed('background');
		color: themed('text');
      	.header--double-logo-divider {
				border-color: themed('text');
			}
    }
	}

	.header--double-logo-desktop-actions {
		justify-content: center;
		align-items: center;
		margin-left: auto;
		display: none;

    @include media-breakpoint-up(lg) {
			display: flex;
		}
	
		li {
			&:not(:first-child) {
				margin-left: 15px;
			}
		}

    &.reverse {
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-left: 0;
      }
    }
	}

	.desktop-secondary-list {
		display: flex;
		justify-content: center;
		align-items: center;
	
		&:not(:first-child) {
			margin-left: 15px;
		}

    .desktop-secondary-list-item {
      @include media-breakpoint-up(lg) {
        position: relative;
        font-size: $header-font-size-2;
      }

      &:after {
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          transform-origin: 100% 50%;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          width: 100%;
          height: 2px;
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        }
      }

      &:hover {
        &:after {
          @include media-breakpoint-up(lg) {
            transform: scaleX(100%);
            transform-origin: 0 100%;
          }
        }
      }

      &.list-item--cta {
        @include media-breakpoint-up(lg) {
          border-radius: var(--sleeve-button-border-radius);
        }

        .nav-link {
          @include media-breakpoint-up(lg) {
            transition: $transition;
          }
        }

        &::after {
          @include media-breakpoint-up(lg) {
            height: 0;
          }
        }
      }
    }
	}

	.mobile-actions-searchbar-container {
		background-color: var(--color-header);
		position: relative;
		opacity: 0.9;
		width: 100%;
		z-index: 2;
		padding: 12px 15px;
	
		&.alpine-transition-enter {
			transition: all 0.2s ease-out;
		}
	
		&.alpine-transition-enter-start {
			opacity: 0;
			transform: translateY(-100%);
		}
	
		&.alpine-transition-enter-end {
			opacity: 0.9;
			transform: translateY(0);
		}
	
		&.alpine-transition-leave {
			transition: all 0.2s ease-in;
		}
	
		&.alpine-transition-leave-start {
			opacity: 0.9;
			transform: translateY(0);
		}
	
		&.alpine-transition-leave-end {
			opacity: 0;
			transform: translateY(-100%);
		}
	}

	.reverse .mobile-actions-searchbar-container {
		background-color: $error-red;
		position: relative;
		opacity: 0.9;
		width: 100%;
		z-index: -10;
		padding: 12px 15px;
	
		&.alpine-transition-enter {
			transition: all 0.2s ease-out;
		}
	
		&.alpine-transition-enter-start {
			opacity: 0;
			transform: translateY(-100%);
		}
	
		&.alpine-transition-enter-end {
			opacity: 0.9;
			transform: translateY(0);
		}
	
		&.alpine-transition-leave {
			transition: all 0.2s ease-in;
		}
	
		&.alpine-transition-leave-start {
			opacity: 0.9;
			transform: translateY(0);
		}
	
		&.alpine-transition-leave-end {
			opacity: 0;
			transform: translateY(-100%);
		}
	}
	
	.mobile-actions-searchbar-form {
		display: flex;
		align-items: center;
		width: 100%;
		border: none;
	
		&:focus-within {
			outline: none;
		}
	}
	
	.mobile-actions-searchbar-input {
		position: relative;
		width: 100%;
		height: 26px;
		margin-right: 24px;
	}
	
	.mobile-actions-searchbar-label {
		color: var(--color-header-text);
		position: absolute;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		left: 0;
		display: flex;
		align-items: center;
		margin-top: 2px;
		margin-left: 1px;
	
		&.alpine-transition-enter {
			transition: all 0.1s ease-out;
		}
	
		&.alpine-transition-enter-start {
			opacity: 0;
			transform: translateX(16px);
		}
	
		&.alpine-transition-enter-end {
			opacity: 0.9;
			transform: translateX(0);
		}
	
		&.alpine-transition-leave {
			transition: all 0.1s ease-in;
		}
	
		&.alpine-transition-leave-start {
			opacity: 0.9;
			transform: translateX(0);
		}
	
		&.alpine-transition-leave-end {
			opacity: 0;
			transform: translateX(16px);
		}
	}
	
	#input-mobile {
		background-color: transparent;
		color: var(--color-header-text);
		margin-right: 24px;
		transition: all 0.2s ease;
		width: 0;
		border: none;
		border-radius: 0;
		height: 100%;
		-webkit-appearance: none;
	
		&:focus {
			outline: none;
			width: 100%;
			box-shadow: none;
			border-bottom: solid 1px var(--color-header-text);
		}
	
		&.w-full.border-b {
			width: 100%;
			border-bottom: solid 1px var(--color-header-text);
		}
	}
	
	.mobile-actions-searchbar-submit {
		color: var(--color-header-text);
		border: var(--color-header-text) 2px solid;
		border-radius: 9999px;
		margin-left: auto;
		min-height: 46px;
		min-width: 46px;
	}

	// when menu is collapsed on large screen
	.header--double-logo-sidemenu.collapsed-menu {
		position: sticky;
		top: 0;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		min-height: auto;
		height: auto;
    min-height: 70px;

		@include media-breakpoint-down(md){
			overflow: hidden;
		}

		&.reverse {
			flex-direction: row-reverse;

			.header--double-logo-mobile-actions {
				margin-right: auto;
			}
		}

		.sidemenu-container, nav {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}

    .sidemenu-container {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 100%;
        right: 0;
        padding: 0 40px;
        width: auto;
      }
    }

		// HERE
		.header--double-logo-sidemenu-list{
			overflow-x: hidden;
			height: 100vh;
			padding-bottom: 60vh;
			width: 100%;

			@include media-breakpoint-up(lg){
				width: 450px;
			}

			.list-item--cta {
				margin-top: 15px;
			}

			.list-item-content {
				align-items: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 10px;
			}
		}

    .dropdown-settings {
      padding: 15px 0;

      .dropdown-settings__toggle {
        margin-right: 25px;
        padding: 0;
        font-weight: var(--heading-menu-font-weight);

        &::before {
          top: 42%;
          right: 3px;
        }
      }

      .dropdown-settings__container {
        position: relative;
        width: 100%;
        box-shadow: unset;
        padding: 10px;
        background: none;

        @include media-breakpoint-down(md) {
          top: unset !important;
          bottom: unset !important;
        }

        .btn__toggle {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

		.desktop-searchbar {
			display: none;
		}
	}
}

// searhbar
.header--double-logo {
	//search bar font size variables 
	.header--double-logo-sidemenu .desktop-searchbar-input {
		font-size: $header-font-size-1;
	}

	.reverse .desktop-searchbar {
		margin-left: 0;
		margin-right: 25px;
	}

	.desktop-searchbar {
		@include media-breakpoint-down(md){
			display: none;
		}

		.desktop-actions-searchbar-close-btn {
			position: absolute;
			height: 24px;
			width: 24px;
			right: 32px;
			top: 7px;
			z-index: 5;

			span {
				display: block;
				position: absolute;
				width: 80%;
				height: 2px;
				background-color: $color-icons;

				&:nth-of-type(1){
					transform: rotate(45deg);
				}

				&:nth-of-type(2){
					transform: rotate(-45deg);
				}
			}
		}
		
		.desktop-actions-searchbar-form {
			display: flex;
			position: relative;
			min-width: 40px;
			min-height: 40px;
			border-radius: 25px;
      margin-bottom: 0;
		}
	
		.desktop-actions-searchbar-btn,
		.desktop-actions-searchbar-submit {
			width: 40px;
			height: 40px;
			border-radius: 999px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 7px;
			position: absolute;
			right: 0;

			svg {
				background-color: none;
				height: 100%;
				width: 100%;
			}
		}
	
		.desktop-actions-searchbar-label {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 18px;
		}
	
		.desktop-searchbar-input {
			width: 40px;
			border-radius: 25px;
			height: 40px;
			background-color: transparent;
			padding: 0 15px;
			transition: width 0.25s ease-in-out;
			
			&.active {
				width: 300px;
				z-index: 1;
				padding: 0 40px 0 15px;
	
				&::placeholder {
					visibility: visible;
				}
			}
	
			&::placeholder {
				visibility: hidden;
			}
		}
	}

  .header--double-logo-sidemenu {
    .mobile-actions-search-icon {
      border-color: $color-icons;

      svg {
        color: $color-icons
      }
    } 

    .burger-cross-icon-right, 
    .burger-icon-elmnt-dash, 
    .burger-cross-icon-left, 
    .mobile-actions-search-icon::after {
      background-color: $color-icons
    }

    .desktop-searchbar {
      .desktop-actions-searchbar-btn, 
      .desktop-actions-searchbar-submit {
        color: $color-icons;
        z-index: 1;
      }
    }

    .desktop-searchbar-input {
      border: none;
      color: $black;

      &::placeholder {
        color: $black;
      }
    }

    .desktop-actions-searchbar-form {
      &::after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: $color-icons;
        opacity: 0.1;
        position: absolute;
        border-radius: 25px;
        z-index: 0;
      }
    }

    .title-color {
      h1,
      .h1 {
        color: var(--color-link);
      }
    }

    // component color theming: search bar
    @include nestedComponentColors($themes) {
      .mobile-actions-search-icon {
        border-color: themed('text');

        svg {
          color: themed('icon');
        }
      } 

      .burger-cross-icon-right, 
      .burger-icon-elmnt-dash, 
      .burger-cross-icon-left, 
      .mobile-actions-search-icon::after {
        background-color: themed('icon');
      }

      .desktop-searchbar {
        .desktop-actions-searchbar-btn, 
        .desktop-actions-searchbar-submit,
        .btn__sound-input span, .btn__sound-input span::before{
          color: themed('icon');
        }

        .desktop-actions-searchbar-close-btn span {
          background-color: themed('icon');
        }

        .desktop-searchbar-input {
          color: themed('text');
          border: 2px solid themed('icon');

          &::placeholder {
            color: themed('text');
          }
        }

        .desktop-actions-searchbar-form::after {
          background-color: themed('icon');
        }
      }
    }
  }
}

.header--double-logo-mobile-actions {
	display: flex;
	margin-left: auto;

	.mobile-actions-search-icon {
		display: flex;
		border-right-width: 1px;
		border-right-style: solid;
		padding-right: 15px;

		svg {
			height: 30px;
			width: 30px;
		}

		&:not(:first-child) {
			margin-left: 10px;
		}
	}

	.mobile-actions-search-btn {
		display: flex;
		width: 30px;
		height: 30px;
	}

	.mobile-actions-burger {
		display: flex;
		padding-left: 15px;

		.mobile-actions-burger-btn {
			position: relative;
		}

		.mobile-actions-burger-icon {
			position: relative;
			display: flex;
			overflow: hidden;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			transition: all 0.2s ease;
		}

		.burger-icon-elmnt {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: all 0.2s;
			transform-origin: center;
			overflow: hidden;
			width: 30px;
			height: 20px;

			.burger-icon-elmnt-dash {
				transition: all 0.3s ease;
				transform-origin: left;
				animation-delay: 100ms;
				height: 2px;
				width: 30px;

				&.translate-y-6 {
					transform: translateY(24px);
				}
			}
		}

		.burger-cross-icon {
			position: absolute;
			display: flex;
			justify-content: space-between;
			transition: all 0.5s ease;
			top: 15px;

			&.translate-x-0 {
				transform: translateX(0);
				width: 48px;
			}

			&.-translate-x-10 {
				transform: translateX(-40px);
				width: 0;
			}

			.burger-cross-icon-right,
			.burger-cross-icon-left {
				position: absolute;
				transition: all 0.3s ease;
				transition-delay: 300ms;
				height: 2px;
				width: 30px;
			}

			.burger-cross-icon-right {
				transform: rotate(0);

				&.rotate-45 {
					transform: rotate(45deg);
				}
			}

			.burger-cross-icon-left {
				transform: rotate(0);

				&.-rotate-45 {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.header--double-logo-sidemenu {
	position: relative;
	z-index: 90;
	width: 100%;
	min-height: 100vh;
	background-color: $white;
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: var(--header-box-shadow);

  @include media-breakpoint-down(md){
		position: sticky;
		top: 0;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		padding: 0 40px;
		min-height: auto;
		height: auto;
		overflow-y: scroll;
    min-height: 70px;
	}

	@include media-breakpoint-down(sm){
		padding: 0 15px;
	}

  @include media-breakpoint-up(lg) {
    min-height: 80px;
		padding: 0px 40px 0px 40px;
	}

  nav {
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }

	&.reverse { 
    justify-content: space-between;
  }

	&.reverse, 
  &.reverse 
  .sidemenu-container {
		flex-direction: row-reverse;

		.header--double-logo-mobile-actions {
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-left: 0;

			.mobile-actions-burger {
				padding-left: 0;
				padding-right: 15px;
			}

			.mobile-actions-search-icon {
				padding-left: 15px;
				padding-right: 0;
				position: relative;
				border-right: none;

				&::after {
					content: '';
					position: absolute;
					left: 0;
					height: 32px;
					width: 1px;
				}
			}
		}
	}

	.sidemenu-container {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(md){
			width: 100%;

			&.alpine-transition-enter {
				transition: all 0.1s ease-out;
			}
		
			&.alpine-transition-enter-start {
				opacity: 0;
				transform: translateX(16px);
			}
		
			&.alpine-transition-enter-end {
				opacity: 0.9;
				transform: translateX(0);
			}
		
			&.alpine-transition-leave {
				transition: all 0.1s ease-in;
			}
		
			&.alpine-transition-leave-start {
				opacity: 0.9;
				transform: translateX(0);
			}
		
			&.alpine-transition-leave-end {
				opacity: 0;
				transform: translateX(16px);
			}
		}
	}

	& :last-child:not(.desktop-searchbar) {
		nav {
			&:last-child:not(.list-item--cta) {
				margin-right: 50px;
			}
		}
	}

  .list-item-content {
    color: $black;

    &::after {
      background-color: var(--color-link);
    }
  }

  .desktop-secondary-list a {
    color: $black;
  }

  .list-item--cta {
    .nav-link, 
    .nav-link div, 
    .nav-link div::before {
      color: $btn-primary-text-color;
    }

    a.nav-link {
      background-color: $btn-primary-bg-color;
      border-color: $btn-primary-border-color;
  
      &:hover,
      &:focus {
        background-color: transparent;
        color: $black;
  
        [class*="icon-"] {
          &:before {
            color: $black;
          }
        }
      }
    }
  }

  .title-color {
    h1,
    .h1 {
      color: var(--color-link);
    }
  }
	
	// component color theming: sidemenu
  @include nestedComponentColors($themes) {
	  background-color: themed('background');

    a.list-item-content {
			color: themed('text');

			&::after {
				background-color: themed('link');
			}

      &:hover,
      &:focus {
        .list-item-content-heading {
          color: themed('link');
        }
      }
		}

		.desktop-secondary-list a {
			color: themed('text');
		}

    .list-item--cta {
			.nav-link, 
      .nav-link div, 
      .nav-link div::before {
				color: themed('btn-primary-text');
			}

      a.nav-link {
        background-color: themed('btn-primary-background');
        border-color: themed('btn-primary-border');

        &:hover,
        &:focus {
          background-color: themed('btn-primary-background-hover');
          color: themed('btn-primary-text-hover');

          [class*="icon-"] {
            &:before {
              color: themed('btn-primary-text-hover');
            }
          }
        }
      }
		}
  }

	&.alpine-transition-enter {
		transition: all 0.3s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave {
		transition: all 0.3s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(100%);
	}
}

//reverse animations
.header--double-logo-sidemenu.animation-reverse .sidemenu-container {
	&.alpine-transition-enter {
		transition: all 0.3s ease-out;
	}
	
	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(-100%);
	}
	
	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}
	
	&.alpine-transition-leave {
		transition: all 0.2s ease-in;
	}
	
 	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}
	
	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(100%);
	}
}

//desktop view when not in collapsed-menu mode
.header--double-logo-sidemenu-list:not(.collapsed-menu){
	@include media-breakpoint-up(xl){
		max-width: unset;
	}
	
  @include media-breakpoint-down(md){
		overflow-x: hidden;
		height: 100vh;
		padding-bottom: 15px;
	}
	
	@include media-breakpoint-up(lg) {
		min-height: fit-content;
		display: flex;
		padding: 0;
		align-items: center;
	}
	
	&.with-search-bar .sidemenu-list-item .list-item-content{
		@include media-breakpoint-up(lg) {
			&::after {
				bottom: -18px;
			}
		}
	}

	.sidemenu-list-item:not(.list-item--cta) {
		@include media-breakpoint-up(lg) {
			border-bottom: none;
		}
	}

	.sidemenu-list-item {
		@include media-breakpoint-up(lg) {
      margin-right: 25px;
      padding: 10px 0 10px 0;
    }

		&:first-child {
			@include media-breakpoint-up(lg) {
				margin-left: 0;
			}
		}

		// current site
		&.active {
		.list-item-content:after {
			@include media-breakpoint-up(lg) {
			transform: scaleX(100%);
			transform-origin: 0 100%;
				}
			}
		}

		.list-item-content:focus:after {
		@include media-breakpoint-up(lg) {
			transform: scaleX(100%);
			transform-origin: 0 100%;
			}
		}

		// underline
		.list-item-content:after {
			@include media-breakpoint-up(lg) {
				content: "";
				position: absolute;
				transform-origin: 100% 50%;
				bottom: -15px;
				left: 0;
				transform: scaleX(0);
				width: 100%;
				height: 4px;
				transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
				z-index: 2;
			}
		}

		//hover animation
		&:hover {
		  .list-item-content:after {
			  @include media-breakpoint-up(lg) {
          transform: scaleX(100%);
          transform-origin: 0 100%;
				}
			}
		}

		.list-item-content {
			@include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        gap: 10px;
				margin: 5px 0;
				width: auto;
				padding: 0;
			}
		}

		.sidemenu-list-item {
			@include media-breakpoint-up(lg) {
				margin-right: 25px;
				padding: 10px 0 10px 0;
			}
	
			&:first-child {
				@include media-breakpoint-up(lg) {
					margin-left: 0;
				}
			}
	
			// current site
			&.active {
			.list-item-content:after {
				@include media-breakpoint-up(lg) {
				transform: scaleX(100%);
				transform-origin: 0 100%;
					}
				}
			}
	
			.list-item-content:focus:after {
        @include media-breakpoint-up(lg) {
          transform: scaleX(100%);
          transform-origin: 0 100%;
				}
			}
	
			// underline
			.list-item-content:after {
				@include media-breakpoint-up(lg) {
					content: "";
					position: absolute;
					transform-origin: 100% 50%;
					bottom: -15px;
					left: 0;
					transform: scaleX(0);
					width: 100%;
					height: 4px;
					transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
					z-index: 2;
				}
			}
	
			//hover animation
			&:hover {
			.list-item-content:after {
				@include media-breakpoint-up(lg) {
					transform: scaleX(100%);
					transform-origin: 0 100%;
					}
				}
			}		
	
			&.list-item--cta {
				@include media-breakpoint-up(lg){
					margin-top: unset;
					margin-left: unset;
				}
			}

			.list-item-content {
				@include media-breakpoint-up(lg) {
					margin: 5px 0;
					width: auto;
					padding: 0;
				}
			}
		}
	}	
}

//collapsed mode end
.header--double-logo-sidemenu-list {
	max-width: 1600px;

	.sidemenu-list-item {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center; 

		&.list-item--cta {
			border-radius: var(--sleeve-button-border-radius);
			transition: opacity ease-in 100ms;
			padding: 0;

			@include media-breakpoint-down(md){
				margin-top: 15px;
				margin-left: 0;
				width: fit-content;
			}

			&::after {
				height: 0;
			}

			&.has-icon .nav-link{
				display: flex;
				align-items: center;
				gap: 10px;

				div {
					font-size: 1.6rem;
				}
			}

			.nav-link {
				text-align: center;
				font-weight: $btn-font-weight;
				font-size: $btn-link-font-size;
				background-color: $btn-primary-bg-color;
				color: $btn-primary-text-color;
				border-width: $btn-primary-border-width;
				border-style: $btn-primary-border-style;
				border-color: $btn-primary-border-color;
				border-radius: var(--sleeve-button-border-radius);
				transition: $transition;

				&:hover,
				&:focus {
					background-color: transparent;
					color: $black;

          [class*="icon-"]::before {
            color: $black;
          }
				}

				[class*="icon-"]::before {
					color: $btn-primary-text-color;
				}
			}
		}

		.list-item-content:after:focus {
			.list-item-content-heading {
				color: var(--color-link);
			}

			&:after, 
			&:focus:after {
				transform: scaleX(100%);
				transform-origin: 0 100%;
			}
		}

		.list-item-content {
			position: relative;
			display: flex;
			flex-direction: column;
			transition: all 0.2s ease;
			width: 80%;
			padding: 15px;
			padding-left: 0;
		}

    &.dropdown-settings {
      margin-right: 20px;

      .dropdown-settings__toggle {
        min-height: 25px; // Web accessibility
        min-width: 24px; // Web accessibility
        font-weight: var(--heading-menu-font-weight);
        font-size: $header-font-size-1;
      }

      .dropdown-settings__container {
        top: 40px !important;

        .btn__toggle {
          margin: 0;
        }
      }
    }

    .btn__toggle {
      margin: 15px 0;
    }

		.list-item-content-heading {
      display: flex;
      align-items: center;
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
			font-weight: var(--heading-menu-font-weight);
			transition: all 0.1s ease;
			font-size: $header-font-size-1;
			white-space: nowrap;

      @include media-breakpoint-up(lg) {
        justify-content: center;
      }
		}

    .btn__toggle,
		.btn__color-theme {
			@include media-breakpoint-down(md) {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}
}
