/*
** Accordion borger.dk
*/

.borgerdk--accordion {
  .rich-text {
    margin-bottom: 20px;
  }

  .label {
    margin-bottom: 10px;
  }

	.accordion__title {
		p {
			margin-bottom: 0;
		}
	}

	.importantinfo {
		background: $grey-light;
		padding: 15px;
		font-size: $small-details-font-size;
		margin-top: 10px;
	}

	.accordion-header__icon {
		margin-left: 15px;
	}

  .accordion {
    margin-bottom: 20px;
  }

	.accordion__colored-backgrounds {
		.importantinfo {
			background: $white;
		}
	}

	.accordion-body__contents {
		table {
			border-collapse: collapse;

			tr {
				vertical-align: top;

				&:nth-child(odd) {
					background: $grey-light;
				}

				td {
					border: 1px solid $divider-color;
					border-spacing: 0px;
					padding: 5px;

					img {
						object-fit: none;
						width: auto;
					}
				}
			}
		}
	}
}
