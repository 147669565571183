.subscribe {
  &[class*="bg-"] {
    @include responsive-space("pt", var(--bg-section-padding-sm-top), var(--bg-section-padding-sm-top), var(--bg-section-padding-md-top), "", "");
    @include responsive-space("pb", var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), "", "");
  }

  &.align-center {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2,
    .rich-text,
    p {
      text-align: center;
    }

    .form-container {
      h2,
      .rich-text,
      p {
        text-align: left;
      }
    }

    .form-sub-response {
      .checkmark-icon,
      .error-icon {
        display: flex;
        justify-content: center;
      }
    }
  }

  .rich-text {
    margin-bottom: 40px;
  }

  .form-subscribe .form-sub-response {    
    .checkmark-icon,
    .error-icon {
      svg circle {
        fill: $white;
      }
    }
  }

  // Component background colors
  @include nestedComponentColors($themes) {
    .form-container {
      border: none;
    }

    .form-subscribe .form-sub-response {    
      .checkmark-icon,
      .error-icon {
        svg circle {
          fill: themed('text');
        }
      }
    }
  }
}