.accordion .icon-plus,
.toggle--icon-plus {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: 16px;
  height: 16px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: $color-icons;
    transition: $transition;
  }

  &:before {
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    transform: translate(0, -50%);
  }

  &:after {
    top: 0;
    left: 50%;
    bottom: 0;
    width: 2px;
    transform: translate(-50%, 0);
  }
}