/**
 * Buttons
 * .btn--toggle
 */

.btn--toggle {
  position: relative;

  button.btn__secondary,
  button.btn__secondary--outline {
    min-width: 100%;
    margin-top: 0 !important;

    &:disabled {
      cursor: auto;

      [class^="icon-"],
      [class*="icon-"] {
        cursor: auto;
      }
    }

    &.inactive {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &.has-icon--left {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 15px;
      }

      [class^="icon-"],
      [class*="icon-"] {
        font-size: em(20px); // icon size
        font-weight: 700;
      }
    }
  }
}