/*
** Heros article - Centered
*/

.hero__article__centered {
  padding: 140px 0;
  margin-bottom: 0;

	@include media-breakpoint-down(md) {
		padding: 60px 0;
	}

	.hero__article__media,
	.background-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			height: 100%;
			min-width: 100%;
		}
	}

	.hero__article__text {
		display: flex;
    flex-direction: column;
    align-items: center;
	}

	.container {
    position: relative;
		display: flex;
    align-items: center;
		height: auto;
    width: 100%;

    .row {
      width: 100%;
    }
	}
}
