.link-boxes {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	margin-bottom: -25px;

  h2 {
    margin-bottom: 20px;
  }
}

.link-boxes__article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .link-boxes__article__title {
    padding: 20px;
    background: $article-element-bg--default;
    border-top-left-radius: var(--sleeve-component-border-radius);
    border-top-right-radius: var(--sleeve-component-border-radius);

    @include media-breakpoint-down(sm) {
      padding: 20px 15px;
    }

    h2 {
      margin-bottom: 0;
    }

    .link-box__article:first-of-type {
      margin-top: 15px;
    }
  }

  // When pre-text is set
  h2,
  .h3 {
    strong {
      font-weight: $font-weight-heading;
    }
    
    span {
      font-family: $primary-font;
      font-weight: $font-weight-body;
    }
  }
}

.link-boxes--article-wrapper {
  .link-box {
    width: 100%;
    margin-bottom: 15px;

    @include media-breakpoint-down(xs) {
      hyphens: var(--hyphens-mobile); /* Web accessibility: Zoom */
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.link-box__icon,
.link-box__arrow {
  display: flex;
  align-items: center;
  background: $white;
  margin-bottom: 25px;
  transition: $transition;
  font-family: $primary-font;
  font-size: $component-font-size;
  line-height: $line-height-secondary;
  font-weight: $font-weight-heading;
  border-radius: var(--sleeve-component-border-radius);

  &:hover {
    box-shadow: 0 4px 4px 0 rgba($black, .05);
  }
}

.link-box__icon {
  padding: 20px;

  [class*="icon-"],
  .link-box__icon__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    max-width: calc($component-font-size + em(24px)); // icon dynamic width (initial 40px)
    max-height: calc($component-font-size + em(24px)); // icon dynamic height (initial 40px)
    margin-right: 20px;
    font-size: calc($component-font-size + em(16px)); // icon size (initial 32px)
    
    &:before {
      color: $color-icons;
    }
  }

  img,
  svg {
    width: 32px;
    height: auto;
    max-height: 32px;
  }

  img {
    object-fit: contain;
  }
}

.link-box__arrow:not(.link-box__icon) {
  padding: 20px 60px 20px 30px;
}

.link-box__arrow {
  position: relative;

  &:hover {
    .arrow,
    .icon-new-window-arrow:not(.link-box__icon__image) {
      right: 25px;
    }

    .icon-download {
      top: 60%;
    }
  }

  .arrow,
  .icon-download,
  .icon-new-window-arrow:not(.link-box__icon__image) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: $transition;
  }

  .icon-new-window-arrow:not(.link-box__icon__image),
  .icon-download {
    color: $color-arrows;
    font-size: em(26px); // icon size
    margin: 0;
  }

  .icon-download {
    font-weight: $font-weight-heading;
  }
}

.link-box__icon.link-box__arrow {
  padding-right: 65px;
}

.link-box__article {
  z-index: 0;
  position: relative;
  background-color: $white;
  padding: 30px 20px 20px 20px;
  border-top: 1px solid $divider-color;
  
  @include media-breakpoint-down(sm) {
    padding: 30px 15px 20px 15px;
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    background: $article-element-bg--default;
    width: 100%;
    height: 100%;
  }

  &:last-child {
    border-bottom: 1px solid $divider-color;
  }

  &:hover {
    .arrow {
      margin-right: 5px;
      
      &:before {
        right: 5px;
      }
    }
  }

  h3,
  .h3 {
    margin-bottom: 15px;
  }
  
  p {
    font-size: $component-font-size;
    line-height: $line-height-secondary;
    margin-bottom: 20px;
  }

  .link-boxes__article__title h2 {
    margin-bottom: 15px;
  }

  .arrow {
    display: flex;
    justify-content: flex-end;
    transition: $transition;
    width: 100%;

    &:before {
      right: 0;
      left: unset;
    }
  }
}

a.link-box__article {
  display: flex;
  flex-direction: column;
}

// In row--grid
.row--grid {
  .link-box {
    width: 100%;

    &.link-box__icon, 
    &.link-box__arrow {
      margin-bottom: 0;
    }
  }
}

// Framed option
.link-boxes--framed {
  background-color: var(--color-body);

  //- Grid 
  .row.row--grid {
    @include media-breakpoint-down(sm) {
      margin-top: calc(var(--col-padding-md-down) - (var(--col-padding-md-down) * 2));
    }

    [class*="col-"] {
      @include media-breakpoint-down(sm) {
        margin-top: var(--col-padding-md-down);
      }
    }
  }
}
//Collapsible option
.link-boxes--framed.accordion--mobile {
  position: relative;
  z-index: 3;
  .accordion--mobile__body {
    height: unset;
  }
  .link-boxes--framed__toggle{
    @include media-breakpoint-down(md){
      width: 100%;
      align-items: center;
      cursor: pointer;
      h3 {
        font-size: $component-font-size;
      }
      .link-boxes--framed__header__image {
        width: 30px;
        max-width: 30px;
        max-height: 30px;
        font-size: 1.875rem; // icon size
        margin-right: 20px;
      }
    }
  }
}

.link-box__collapsed.accordion--mobile {
  @include media-breakpoint-down(sm){
    .title {
      //display: none;
    }
  }
  .accordion--mobile__body {
    padding: 0;
  }
  .accordion--mobile__toggle {
    @include media-breakpoint-up(md){
      display: none;
    }
    padding: 0;
    width: 100%;
    .title {
      margin-bottom: 0;
    }
  }
}