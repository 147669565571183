.dropdown-settings {
	position: relative;
	display: flex;
  flex-direction: column;
  align-items: flex-start;

	@include media-breakpoint-down(md) {
		padding-left: 0;
		flex-direction: column;
	}

	.dropdown-settings__toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
		font-size: $header-font-size-2;
		padding-right: 20px;
    padding-left: 0;

		@include media-breakpoint-down(md) {
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 0;
			width: 100%;
			text-align: left;
		}

    &::after {
      right: 0;
      top: 3px;

			@include media-breakpoint-down(md) {
				top: 21px;
			}

			@include media-breakpoint-up(lg) {
				top: 37%;
			}
    }

		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			right: 1px;
			transition: all .2s ease-in-out;
			width: 9px;
			height: 9px;
			border-style: solid;
			border-width: 2px 2px 0 0;
			transform: translateY(-50%) rotate(135deg);
			border-color: $black;

			@include media-breakpoint-down(md) {
				top: 21px;
			}

			@include media-breakpoint-up(lg) {
				top: 37%;
			}
		}

		&.active::before {
			transform: translateY(0) rotate(315deg);
		}

    [class*="icon-"] {
      margin-right: 5px;
      font-size: em(24); // icon size
    }
	}

	.dropdown-settings__container {
		z-index: 200;
		background: $white;
		height: auto;

		@include media-breakpoint-down(md) {
			position: relative;
			width: 100vw;
			margin-left: -15px;
			top: 0;
			box-shadow: none;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: var(--sleeve-component-padding);
			padding-bottom: var(--sleeve-component-padding);
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 30px;
			box-shadow: 0px 0px 25px 0px #00000025;
			right: 0;
			padding: var(--sleeve-component-padding);
			border-radius: var(--sleeve-component-border-radius);
		}
		
		//transition: ease-in 200ms;
		&.hidden {
			display: none;
			height: 0;
		}

		ul {
			margin-bottom: 0;

      li {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
		}

		p {
			font-size: $header-font-size-2;
		}

		.dropdown-settings__heading {
			font-weight: var(--heading-menu-font-weight);
		}
	}

	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 2px solid $grey-light;
		padding-bottom: 1em;
		margin-bottom: 1em !important;

		&:last-of-type {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0 !important;
		}

		p {
			margin-bottom: 0;
		}
	}
}
//color options
@include globalComponentColors($themes) {
	.dropdown-settings:not(.tablet-secondary-nav) {
		.dropdown-settings__toggle {
			color: themed('text');

			&::before {
				border-color: themed('text');
			}
		}
	}
}

// color options for secondary nav in default menu
.navbar-nav__secondary-container {
	@include nestedComponentColors($themes) {
		.navbar-nav.navbar-nav__secondary {
			.dropdown-settings:not(.tablet-secondary-nav) {
				.dropdown-settings__toggle {
					color: themed('text') !important;
	
					&::before {
						border-color: themed('text') !important;
					}
					[class*="icon-"]::before {
						color: themed('icon') !important;
					}
				}
			}
		}
	}
}

// padding in megamenu
.header__megamenu-extended {
	@include media-breakpoint-up(lg){
		.dropdown-settings {
			padding-left: 20px;
		}
	}

  .dropdown-settings .dropdown-settings__container {
    @include media-breakpoint-down(md) {
      top: unset !important;
      bottom: unset !important;
    }
	}
}


