/**
 * Footer col-3-3-6
 */

 .footer__col-3-3-6 {
  border-top: 1px solid $color-body;
  padding-top: var(--footer-pt-lg);
  padding-bottom: var(--footer-pb-lg);

  @include media-breakpoint-down(sm) {
    padding-top: var(--footer-pt-md);
    padding-bottom: var(--footer-pb-md);
  }

	.footer__content {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    // Delete after new mark-up implementaion
    h2 {
      font-size: $component-font-size;
     }
     
    .list__links {
      padding: 0;

      ul {
        margin-bottom: 0;
      }

      a.list__link {
        &::before {
          color: var(--color-footer-text);
        }

        &:hover, 
        &:visited, 
        &:focus {
          background-size: 0;
        }
      }
    }
	}

  // Footer grid
  .col-md-12.col-lg-6 {
    @include media-breakpoint-down(md) {
      margin-top: 80px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    .container--wide .row {
      @include media-breakpoint-up(lg) {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  // Delete after new mark-up implementation
  .col-md-12.col-lg-6 {
    .container  {
      [class*='col-'] {
        padding: 0 !important;
      }
    }
  }
}
