//Link box col grid
.col {
	&-md-12 {
		&.col-lg-8 {
			.link-boxes:not(.link-boxes--article-wrapper) {
				.link-box,
				.info-box__links {
					&__icon {
						@include media-breakpoint-down(sm) {
							flex-direction: column;
							text-align: center;
						}

						&__image {
							@include media-breakpoint-down(sm) {
								margin-right: 0;
								margin-bottom: 20px;
							}
						}
					}

					&__icon,
					&__arrow {
						width: calc(50% - 12.5px);

						&:nth-child(3n+2),
						&:nth-child(3n+3) {
							margin-left: 0;
						}

						&:nth-child(2n+2) {
							margin-left: 12px;
						}

						&:nth-child(2n+1) {
							margin-right: 12.5px;
						}
					}
				}
			}
		}

		.link-boxes:not(.link-boxes--article-wrapper) {
			.link-box,
			.info-box__links {
				&__icon,
				&__arrow {
					width: calc(33.33% - 16.66px);

					&:nth-child(3n+2),
					&:nth-child(3n+3) {
						margin-left: 25px;

						@include media-breakpoint-down(md) {
							margin-left: 0;
						}
					}

					&:nth-child(2n+2) {
						@include media-breakpoint-down(md) {
							margin-left: 12.5px;
						}

						@include media-breakpoint-down(sm) {
							margin-left: 0;
						}
					}

					&:nth-child(2n+1) {
						@include media-breakpoint-down(md) {
							margin-right: 12.5px;
						}

						@include media-breakpoint-down(sm) {
							margin-right: 0;
						}
					}

					@include media-breakpoint-down(md) {
						width: calc(50% - 12.5px);
					}

					@include media-breakpoint-down(sm) {
						width: 100%;
					}
				}
			}
		}
	}

	&-md-8 {
		.link-boxes {
			.link-box,
			.info-box__links {
				&__icon,
				&__arrow {
					width: calc(50% - 12.5px);

					&:nth-of-type(2n) {
						margin-left: 25px;

						@include media-breakpoint-down(sm) {
							margin-left: 0;
						}
					}

					@include media-breakpoint-down(sm) {
						width: 100%;
					}
				}
			}
		}
	}

	&-md-4 {
		.link-boxes {
			.link-box,
			.info-box__links {
				&__icon,
				&__arrow {
					width: 100%;
				}
			}
		}
	}
}
