/*
** Creative
** Section dividers
*/

.section-divider {
	width: 100vw;
  position: relative;
  z-index: 2;
  pointer-events: none;
  background-color: transparent;
  
  svg,
  .section-divider__custom {
    width: 100vw;
    height: auto;
    margin-bottom: -1px;
  }
  svg {
    background-color: transparent;
  }

  .section-divider__custom img {
    z-index: 1;
    object-fit: contain;
  }

	.reverse {
		transform: scaleX(-1);
	}

  .rotate {
    transform: rotate(180deg);
    margin-top: -2px;
    margin-bottom: 0;
  }

  .reverse.rotate {
    transform: scaleX(-1) rotate(180deg);
  }

  .wave,
  .wave-subtle,
  .tilt,
  .triangle,
  .section-divider__custom {
    display: flex;
  }

  .absolute.rotate {
    position: relative;
    z-index: 10;

    &.wave {
      margin-bottom: -7vw;
    }

    &.wave-subtle {
      margin-bottom: calc(-4.3vw + 1px);
    }

    &.tilt {
      margin-bottom: -2.9vw;
    }

    &.triangle {
      margin-bottom: -3.9vw;
    }
  }

  .absolute:not(.rotate) {
    position: relative;
    z-index: 10;

    &.wave {
      margin-top: -7vw;
    }

    &.wave-subtle {
      margin-top: calc(-4.3vw);
    }

    &.tilt {
      margin-top: -2.9vw;
    }

    &.triangle {
      margin-top: -4vw;
      margin-bottom: -2px;
    }
  }

  .slope {
    height: 20vh;
		width: 100%;
    margin-top: -1px;
	  margin-bottom: -1px;
		clip-path: polygon(100% 0%, 0% 100.2%, 100% 100.2%);

    @include media-breakpoint-down(sm) {
      clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
    }

		@include media-breakpoint-down(md) {
      height: 10vh;
    }

    &.absolute.rotate {
      margin-bottom: calc(-20vh + 1px);

			@include media-breakpoint-down(md) {
				margin-bottom: calc(-10vh + 1px);
			}
    }

    &.absolute:not(.rotate){
      margin-top: calc(-20vh + 2px);
      margin-bottom: -2px;

			@include media-breakpoint-down(md) {
				margin-top: calc(-10vh + 2px);
				margin-bottom: -2px;
			}
    }
	}

  .line {
    height: 2px;
    width: 100%;
    background-color: $grey-medium;
  }

  //Should be replaced with new color mixins, but added because of issues I couldn't fix fast (Magnus 3/10 2024)
  &.bg-section--primary-decorative {
    background-color: var(--color-primary-decorative);
  }
  &.bg-section--secondary-decorative {
    background-color: var(--color-secondary-decorative);
  }
  &.bg--footer {
    background-color: var(--color-footer);
  }
  svg {
    &.bg-section--primary-decorative {
      path {
        fill: var(--color-primary-decorative);
      }
    }
    &.bg-section--secondary-decorative {
      path {
        fill: var(--color-secondary-decorative);
      }
    }
    &.bg--footer {
      path {
        fill: var(--color-footer);
      }
    }
  }
  
}

