a.card__portrait,
.card__portrait a {
	&:hover {
		.arrow {
			right: 35px;
		}

		img:not(.watermark__img) {
			transform: scale(1.05);
		}
	}
}

.card__portrait {
	border-radius: var(--sleeve-component-border-radius);
	overflow: hidden;

  &.no-arrow {
    @include nestedComponentColors($themes) {
      &:hover,
      &:focus {
        h3,
        .h3 {
          color: themed('text');
          text-decoration: underline;
        }
      }
    }

    .card__portrait__text p:not(.tag) {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      h3,
      .h3 {
        color: $color-link;
      }
    }
  }

  h3,
  .h3 {
    transition: $transition;
  }

  a {
    .card__portrait__text {
      p:not(.tag) {
        margin-bottom: 0;
      }
    }
  }
  
	.tags-container {
		margin-bottom: 10px;
	}

  .tags-container--under {
    padding: 0 var(--sleeve-component-padding);
    margin-top: 0;
    margin-bottom: 30px;
  }

	&.bg--transparent {
		background: none;

		.card__portrait__text {
			padding: 15px 0 0 0;
		}

    .tags-container--under {
      padding: 0;
    }

		.arrow {
			display: none;
		}
	}

	.arrow {
		position: absolute;
		bottom: var(--sleeve-component-padding);
		right: var(--sleeve-component-padding);
		display: flex;
		transition: $transition;

		svg {
			z-index: 1;
			position: relative;
		}
	}

	& .card__icon--wrapper {
		padding: 30px 0 0 0;
		text-align: center;

		& .card__icon--large {
      display: flex;
      justify-content: center;

			&:before {
				font-size: 4.4rem; // icon size
			}

      &.flex-left {
        justify-content: left;
        padding: 0 var(--sleeve-component-padding);
      }

      img:not(.watermark__img),
      svg {
        width: 70px;
        height: auto;
        max-height: 70px;
      }
    
      img:not(.watermark__img) {
        transform: unset;
        object-fit: contain;
      }
		}
	}

	.card__portrait__text {
		display: flex;
		flex-direction: column;
		padding: var(--sleeve-component-padding);

		p:not(.tag) {
			margin-bottom: 30px;
		}
	}

	.card__portrait__image {
    position: relative;
		height: auto;
		height: -webkit-fit-content; //for safari
		width: 100%;
		overflow: hidden;

		img:not(.watermark__img) {
			width: 100%;
			height: auto;
			transition: $transition;
		}

		img:not([class*="aspect-ratios--"]):not(.watermark__img) {
			height: 222px;

			@include media-breakpoint-down(sm) {
				height: 195px;
			}
		}

    // Icon (with image)
    .card__portrait__image__icon {
      z-index: 1;
      position: absolute;
      top: 15px;
      left: 15px;
      height: 55px;
      min-width: 55px;

      &[class*="icon-"] {
        background-color: $white;
        border-radius: 30px;
        
        &::before {
          z-index: 10;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
    
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 100%;
          background: $color-icons;
          opacity: 0.1;
          border-radius: 30px;
        }
      }

      img:not(.watermark__img),
      svg {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 55px;
        height: auto;
        max-height: 55px;
      }

      img:not(.watermark__img) {
        transform: unset;
        object-fit: contain;
      }
    }
	}

	.video {
		position: relative;
		height: 100%;
		overflow: hidden;
		max-width: 100%;
	}

	.card__portrait__badge.card__badge {
		top: 182px;
	}

	&.card__portrait--no-image {
		border-radius: var(--sleeve-component-border-radius);
	}
}
