/**
 * Third party
 * Cludo
 */

@import 'cludo-404';

ul.autocomplete {
	display: none;
	width: 100%;
	z-index: 989;
	margin-top: 10px;
	background: white;
	list-style: none;
	box-shadow: 0 13px 17px -10px rgba(0, 0, 0, 0.75);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;

	li {
		margin-bottom: 0;
		padding: 15px 20px;
		cursor: pointer;

		&:hover,
		&.active {
			background: $grey-light;
		}
	}

	&.global-autocomplete {
		display: inline-block;
		position: absolute;
		max-width: 500px;
		width: 90%;
		margin-top: -15px;

		li {
			margin-bottom: 0;
			padding: 15px 20px;
		}
	}
}

.cludo-search {
	.pagination-wrapper {
		display: none;
	}

	.search-bar {
		position: relative;
	}

	.result-info {
		display: none;
		justify-content: space-between;
		padding: 15px 0;
		margin-bottom: 10px;
		flex-wrap: wrap;
		white-space: nowrap;

		p {
			font-size: calc($h3-font-size-lg - em(2px));
			line-height: $line-height-secondary;
			margin-bottom: 0;
		}

		p span {
			font-size: inherit;
			line-height: inherit;
		}

		.result-filter {
			display: none;
		}

		&-light {
			justify-content: flex-start;
			& strong {
				margin: 0 5px;
			}
			& span:not(.result-word) {
				margin: 0 0 0 5px;
			}
			.result-word {
				margin: 0;
			}
			.result-filter {
				margin: 0;
				white-space: pre-wrap;
			}
		}
	}

	.cludo__filter {
		display: none;
		width: 100%;
	}

	.cludo__filter-list {
		display: none;
		background-color: $grey-light;
		width: 100%;
		margin-bottom: 40px;

		&.active {
			button.cludo__filter-list__title__toggle {
				&:after {
					transform: translateY(-50%) translateX(-15px) rotate(-45deg);
					top: 60%;
				}
			}
		}

		.cludo__filter-list__title {
			background-color: $color-primary;
			padding: 15px 30px;

			@include media-breakpoint-down(md) {
				display: flex;
				justify-content: space-between;
				padding: 15px 11px 15px 30px;
			}

			h2 {
				margin-bottom: 0;
				color: $color-primary-text;
			}

			button.cludo__filter-list__title__toggle {
				position: relative;
				width: 30px;
				height: 26px;

				@include media-breakpoint-up(lg) {
					display: none;
				}

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					right: -4px;
					top: 45%;
					transition: $transition;
					width: 9px;
					height: 9px;
					border-style: solid;
					border-color: $color-primary-text;
					border-width: 2px 2px 0 0;
					transform: translateY(-50%) translateX(-15px) rotate(135deg);
				}
			}
		}

		.cludo__filter-list__content {
			padding: 30px;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		h2 {
			font-size: calc($h3-font-size-lg - em(2px));
		}

		ul {
			list-style: none;
			margin-bottom: 30px;

			&.hasTitle {
				padding-left: 15px;
			}

			&:first-of-type {
				margin-bottom: 15px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			li {
				margin-bottom: 20px;
				font-weight: 600;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	.card {
		&:last-of-type {
			border-bottom: 1px solid $grey-medium !important;
		}

		.breadcrumbs_list {
			margin-top: 15px;
			color: $grey-dark;

			p {
				display: inline-block;
				line-height: $line-height-secondary;
				color: $grey-dark;
				font-family: $secondary-font;
				text-transform: inherit;
			}

			.breadcrumb_list {
				font-size: inherit;
				display: flex;
				flex-wrap: wrap;

				.breadcrumblink {
					font-size: inherit;
					font-family: $secondary-font;

					&:after {
						position: relative;
						content: "";
						display: inline-block;
						height: 5px;
						width: 5px;
						vertical-align: middle;
						border-style: solid;
						border-width: 1px 1px 0 0;
						border-color: $grey-medium;
						transform: rotate(45deg);
						transition: margin-right 0.15s
							cubic-bezier(0.455, 0.03, 0.515, 0.955);
						margin-top: -1px;
						margin-right: 10px;
						margin-left: 10px;
					}

					&:last-of-type {
						&:after {
							display: none;
						}
					}

					&:first-of-type {
						margin-left: 10px;
					}
				}
			}
		}

		.breadcrumbs_list {
			.p {
				font-size: $breadcrumb-font-size;
			}
		}
	}

  .rich-text {
    @include nestedSectionColors($themes-section) {
      h2,
      h3,
      h4,
      p {
        color: themedSections('text');
      }
    }
  }
}
