.article-wrapper {
	.col-md-12.col-lg-7.offset-xl-1 {
		margin-right: auto !important;
	}

	//margin bottom between components in articles
  .hero__article--article-wrapper {
		@include responsive-space("mb", var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb));
	}

	// Article with image
	.article-wrapper__top-image {
		position: relative;
		overflow: hidden;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
      margin-left: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
      margin-right: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
		}

    img {
      border-radius: $border-radius-image;

      &:not(.aspect-ratios--1-1):not(.aspect-ratios--3-2):not(.aspect-ratios--16-9):not(.aspect-ratios--16-5) {
        display: inline-block;
        width: 100%;
        min-height: 100%;
  
        @include media-breakpoint-up(md) {
          min-height: 400px;
        }

        @include media-breakpoint-down(sm) {
          height: 250px;
        }
      }
		}

    figcaption {
      @include media-breakpoint-down(sm) {
        padding-left: var(--col-padding-md-down);
        padding-right: var(--col-padding-md-down);
      }
    }
	}
}

.article-wrapper,
[data-creative~="article-content"] {
  //margin bottom between components in articles
  .rich-text,
  .link-boxes__article,
  .link-boxes,
  .accordion,
  .accordion__borgerdk,
  .borgerdk--accordion,
  .image,
  .article-anchor-menu,
  blockquote,
  .spot-box,
  .swiper--image,
  .service-menu,
  .video,
  .dataTables_wrapper,
  .form-container,
  .fact-box,
  .side-menu,
  .image-gallery,
  .html-comp,
  .aside
  p,
  ul,
  ol,
  .tag-containers,
  .list__cards,
  .card__business,
  .medicindk,
  .card__image,
  .btn__component-container,
  .progressbar__value-cta,
  .nemid,
  .gis-map {
    @include responsive-space("mb", var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb));
  }

  // Global components on article page
  .card__business,
  .link-box__icon, 
  .link-box__arrow {
    background: $grey-light;
  }

  .media__container {
    height: auto;
  }

  .spot-box:not(.c-background):not([class*="bg-"]):not([class*="article-element-bg--"]) {
    margin: 15px 0 30px;
    padding: 40px;
  }
}

main[data-pagetype~="article"] {
  //margin bottom between components in articles
  .accordion__form {
    @include responsive-space("mb", var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb), var(--article-wrapper-c-mb));
  }
  
  .search-bar--container {
    padding-top: 0;
  }
}

main[data-pagetype~="creative"] {
  .article-wrapper {
    .image,
    .html-comp {
      margin-bottom: 0;
    }

    .col-md-12.col-lg-6,
    .col-md-12.col-lg-5 {
      //margin bottom between components in article columns
      .image,
      .html-comp {
        @include responsive-space("mb", 30px, 30px, 30px, 30px, 30px);
      }

      .swiper.swiper--image {
        .image {
          margin-bottom: 0;
        }
      }
    }
  }

  [data-creative~="article-content"] {
    .image {
      @include responsive-space("mb", 30px, 30px, 30px, 30px, 30px);
    }

    .swiper.swiper--image {
      .image {
        margin-bottom: 0;
      }
    }
  }
}

.article-container {
  margin: 0 -40px;
  padding: 40px;
  width: calc(100% + 80px);
  border-top-left-radius: var(--sleeve-component-border-radius);
  border-top-right-radius: var(--sleeve-component-border-radius);

  .hero__article .hero__article__text {
    padding-top: 0;
  }
}
