/**
 * Buttons
 * .btn__primary
 */

// When .btn__primary is used in articles
[class^="link--"] span.btn__primary,
[class*="link--"] span.btn__primary,
span.btn__primary,
span.btn__selfservice {
	padding: 0;

	a:not(.list__link) {
		width: 100%;
		min-height: var(--sleeve-btn-height);
		color: $btn-primary-text-color !important;
		font-weight: $btn-font-weight !important;
		background-size: 0 !important;
		border-radius: var(--sleeve-button-border-radius);
    border-bottom: unset !important;
    background-image: unset !important;
    background-position: unset !important;
    background-repeat: unset !important;

		&:hover,
    &:focus {
			background-size: unset !important;
			background-image: unset !important;
      background: $transparent;
			color: $black !important;
		}
	}

  &:has(> a:focus) {
    background: $btn-primary-text-color;
    color: $btn-primary-bg-color !important;
  }
}

span.btn__primary {
	a:not(.list__link) {
		padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x) !important;
	}
}

a span.btn__primary {
	padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x);
}

.btn__primary--outline {
	position: relative;
	z-index: 0;
}

.btn__primary__icon {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 30px;
	padding-left: 50px;

	object,
	svg {
		position: absolute;
		top: 50%;
		left: 16px;
		transform: translate(0, -50%);
	}

	[class^="icon-"],
	[class*=" icon-"] {
		position: absolute;
		top: 50%;
		left: 16px;
		transform: translate(0, -50%);
		font-size: 20px; // icon size
	}
}