/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Blockquote
 */

.blockquote__image svg path {
  fill: var(--darkmode-hightlight-1-200);
}

.blockquote__text span {
  color: var(--darkmode-100);
}
