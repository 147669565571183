.header--left-aligned {
  //collapsed menu specific styles
  &.collapsed-menu {
    .link-container {
      position: relative;
      top: unset;
      left: unset
    }
  }

  //end collapsed menu styles
  .list-item-left-aligned-toggle {
    width: 55px;
    padding: 15px;

    @include media-breakpoint-up(lg) {
      right: -18px;
      top: 10px;
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;
    }

    &:focus {
      @include media-breakpoint-up(lg) {
        opacity: 1;
        height: 11px;
        width: 11px;
        margin-right: -16px;
        margin-left: 5px;
        padding-bottom: 20px;
      }
    }

    &.active div {
      @include media-breakpoint-up(lg) {
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    p {
      position: absolute;
      top: -1000px;
      right: -35px;
      width: 20px;

      @include media-breakpoint-up(lg) {
        top: -300px;
        right: 0;
      }
    }
  }

  .list-item-left-aligned-toggle.active {
    .list-item-megamenu-toggle-icon {
      transform: translateY(0%) rotate(315deg);
    }
  }

  .list-item-megamenu-toggle-icon {
    content: "";
    display: inline-block;
    transition: all .2s ease-in-out;
    width: 9px;
    height: 9px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(135deg);
  }

  &:not(.collapsed-menu){
    .list-item-megamenu-toggle-icon {      
      @include media-breakpoint-up(lg){
        border-width: 4px 4px 0 0;
      }
    }

    .link-container {
      @include media-breakpoint-up(lg) {
        position: absolute; 
        min-width: 300px;
        top: 100%;
        z-index: -1;
        background-color: $white;
        border-radius: var(--sleeve-component-border-radius);
        box-shadow: var(--header-box-shadow);
      } 
    }
  }

  .link-container {
    background-color: var(--color-body);
    padding: 15px 30px 15px 30px;
    width: 100%;
    z-index: 1;
    left: -30px;

    li {
      margin-bottom: 0.6em;
    }

    .link-container__links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    a.link-container__link {
      display: flex;
      align-items: center;
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
      color: var(--color-body-text);

      @include media-breakpoint-up(lg) {
        transition: ease 200ms;
        font-size: $header-font-size-3;
        color: $black;
      }

      &:hover, 
      &:focus {
        @include media-breakpoint-up(lg) {
          color: $color-link;
        }
      }
    }
  }

  .sidemenu-list-item {
    position: unset;

    @include media-breakpoint-up(lg) {
      position: relative;
    }

    .link-container {
      display: none;

      &.visible {
        display: block;
        z-index: 1;
      }
    }

    &.visible {
      .link-container {
        @include media-breakpoint-up(lg) {
          display: block;
          z-index: 1;
        }
      }
    }
  }

  .list-item-megamenu-toggle-icon {
    border-color: $color-arrows;
  }

  .header--double-logo-sidemenu {
  	// component color theming: link container
    @include nestedComponentColors($themes) {
      .sidemenu-list-item:not(.list-item--cta) {
        border-color: themed('text');
      }
  
      .list-item-megamenu-toggle-icon {
        border-color: themed('text');
      }

      .link-container {
        @include media-breakpoint-up(lg) {
          background-color: themed('background');
        }
      }

      .link-container__link {
        @include media-breakpoint-up(lg) {
          color: themed('text');
        }

        &:hover {
          @include media-breakpoint-up(lg) {
            color: themed('text');
          }
        }
      }
    }
  }
}
