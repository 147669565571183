.banner__cta {
  position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 50px 0;
	min-height: 180px;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: center;
		padding: 60px 0;
	}

  &.has-image {
    background: var(--color-overlay); // Web accessibility

    h2,
    .h2,
    p,
    [class*="icon-"] {
      color: var(--color-overlay-text);
    }

    .banner__cta--container {
      z-index: 1;

      [class*="icon-"] {
        color: var(--color-overlay-text);
      }
    }

    .btn__primary-arrow--long {
      .arrow::before {
        color: $btn-primary-text-color;
      }
    }

    .btn__primary-arrow--long {
			background: var(--color-overlay-button);
			color: var(--color-overlay-button-text);
      border-color: var(--color-overlay-button);

			&:after {
				background: var(--color-overlay-button);
			}

      &:hover {
        background-color: var(--color-overlay-button-text);
        border-color: var(--color-overlay-button-text);
        color: var(--color-overlay-button);

        .arrow::before {
          color: var(--color-overlay-button);
        }
      }

      .arrow::before {
        color: var(--color-overlay-button-text);
      }
		} 

    .btn__primary-arrow--long--outline {
			color: var(--color-overlay-button);
      border-color: var(--color-overlay-button);


      &:hover {
        background-color: var(--color-overlay-button);
        color: var(--color-overlay-button-text);

        .arrow::before {
          color: var(--color-overlay-button-text);
        }
      }

      .arrow::before {
        color: var(--color-overlay-button);
      }
    }
  }

  &.padding {
    padding: 50px;
    border-radius: $border-radius-component;

    .banner__cta--background-image img:not(.watermark__img),
    .banner__cta--background-image::after {
      border-radius: $border-radius-component;
    }

    &.has-christmas-lights {
      padding-top: 150px;
      
      @include media-breakpoint-down(sm) {
        padding-top: 75px;
      }
    }
  }

  .banner__cta--background-image {
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: var(--color-overlay);
    }

    img:not(.watermark__img) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
    }
  }

	.banner__cta--container {
		display: flex;
		flex-direction: row;
    justify-content: space-between;
    width: 100%;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: center;
		}

    &.align-center {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .banner__cta__content {
        width: 100%;
      }

      .banner__cta__content,
      .banner__cta__content__text {
        flex-direction: column;
      }

      .banner__cta__content__text,
      .banner__cta__content__icon {
        align-items: center;
        margin-right: 0;
      }

      .banner__cta__content__icon {
        margin-bottom: 20px;
      }

      .banner__cta__content__text {
        text-align: center;
      }

      .btn-container {
        margin-top: 30px;
        justify-content: center;
        width: 100%;
      }
    }

    .banner__cta__content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
			}

      @include media-breakpoint-up(md) {
        width: 50%;
			}
    }

		.banner__cta__content__icon {
			height: 70px;
			width: 70px;
      margin-right: 35px;
      font-size: 4.0625rem; // icon size
      position: relative;
      
			@include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-right: 0;
        position: relative;
			}

      img:not(.watermark__img),
      svg {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;
        height: auto;
        max-height: 65px;
      }
  
      img:not(.watermark__img) {
        transform: unset;
        object-fit: contain;
      }
		}

		.banner__cta__content__text {
      display: flex;
      flex-direction: column;
			max-width: 600px;
			margin-right: 30px;

			@include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-right: 0;
			}

			h2 {
				margin-bottom: 0;

				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}

      p {
        margin-top: 20px;
        margin-bottom: 0;
      }
		}
	}

  .btn-container {
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
      justify-content: center;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    .btn {
      margin-top: 0;
    }
  }
}
