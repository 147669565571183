/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Rich-text
 */

.rich-text {
  ul li::before,
  ul li::marker {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}

.rich-text {
  ul li::before {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}

.rich-text__text-box.framed {
  background-color: var(--darkmode-800) !important;
}

