/**
 * Third party
 * GIS
 */

.gis-map {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	& .gis-top,
	& .gis-bottom,
	& .gis-left,
	& .gis-right {
		height: 100%;
		flex: 1;
	}

	.map--gis {
		width: 100%;
		height: auto;
		padding: 20px 0;
		overflow: visible;
		//had padding:0 in inline styling, so let's see if it causes issues when removed (17 sep 2024)
	}

	ul {
		padding-left: 0;

		li:before {
			content: "";
		}
	}

	// Img tags in external GIS cannot be 100%, it messes up the map legend for some reason. This was added for Horsens subsite Erhvervsgrunde
	img {
		width: auto;
	} 

	// Img tags inside a widget inside a gis-map *must* be 100% (our default), this styling reverts the above styling in that case. Added for Naestved.
	.widget-map-popup img {
		width: 100%;
	}

	.minimap canvas {
		max-width: inherit;
	}

	//Horsens GIS - put into Horsens back office 19/7 2023 - can be deleted when we're sure there's no issues
	& .article-text {
		width: 100%;

		& tr {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	& .mmPanelHeader.selected {
		border: 1px solid #b1b1b1;
	}

	/*& {
		& .mmPanelContent {
			background-color: hsla(0, 0%, 91%, .7);
			color: #000;
		}

		& .mmPanelContent.mmPanelContentSelected.viewPopupContent,
		& .mmPanelHeader.viewPopupHeader {
			background-color: #fff !important;
		}

		& .mmPanelContent {
			background-color: hsla(0, 0%, 91%, .7);
			color: #000;
		}

		& .mmtbl,
		td {
			padding: 5px;
		}

		& .btn-erhv,
		& .btn-erhv:hover {
			border-color: #00a4a6 !important;
		}

		& .btn-erhv {
			color: #fff !important;
			background-color: #00a4a6 !important;
			margin: 20px !important;
			display: inline-block;
			padding: 20px 30px!important;
			background-color: #0b71ea;
			color: #fff !important;
			fill: #fff;
			-ms-flex-item-align: start;
			align-self: flex-start;
			border: 0;
			word-break: break-word;
			word-wrap: break-word;
			-webkit-hyphens: manual;
			-ms-hyphens: manual;
			hyphens: manual;
			box-shadow: inset 0 0 0 0 transparent
		}

		.popup_title {
			padding: 10px;
		}

		.poppins-medium,
		.popup_title {
			font-family: Poppins;
			font-weight: 500;
			line-height: 1.2;
		}

		& .mmPanelContent.mmPanelContentSelected.viewPopupContent,
		& .mmPanelHeader.viewPopupHeader {
			background-color: #fff !important;
		}

		.miniMapTheme>div {
			vertical-align: middle;
			display: inline-block;
		}

		.miniMapTheme>div.miniMapThemeLegend {
			display: inline;
		}

		table.article-text {
			table-layout: fixed;
		}

		.viewListHeader.mmPanelHeader.selected {
			transition: background-color .2s, color .2s;
			background-color: #0b71ea;
			color: #fff;
		}

		.viewListContent.mmPanelContent {
			background-color: hsla(0, 0%, 91%, .7);
			color: #000;
		}

		div#infodiv_grunde {
			display: flex;
			-ms-flex-direction: column-reverse;
			flex-direction: column-reverse;
		}

		table#grund_tabel {
			table-layout: fixed;
			background-color: #2d3438;
			color: #fff;
			padding: 10px;
		}

		table {
			width: 100% !important;
			height: auto !important;
		}

		.arrow {
			display: none !important;
			position: absolute;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			right: 10px;
		}


		.viewListHeader.mmPanelHeader {
			position: relative;
			background-color: #e7e7e7;
			color: #000;
			text-align: left;
			padding: 15px 10px;
			cursor: pointer;
			white-space: nowrap;
			margin-top: 4px;
		}
	}*/
}

.minimap-map {
	position: relative;

	& .arrow {
		&::before {
			content: none!important;
		}
	}
}

