.umbraco-forms-form {
  .btn {
    width: fit-content;
  }

	.btn-container {
		.btn {
			// padding: 0;

			.btn {
				padding: 15px;
				border: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

  legend {
    margin-bottom: 10px;
  }

	.umbraco-forms-page {
		.umbraco-forms-fieldset {
			padding: 0;
			border-top: none;
			border-bottom: none;
			border-left: none;
			border-right: none;
			margin-inline-start: 0;
			margin-inline-end: 0;

      legend.group-title {
        font-family: var(--font-primary);
        font-weight: var(--heading-font-weight);
        line-height: var(--font-primary-line-height);
        font-size: $h4-font-size-sm;
      }

			.umbraco-forms-container {
				form .form-group label,
				.form .form-group label,
				.form-group .umbraco-forms-field-wrapper {
					padding: 0 !important;
				}

				form .form-group:last-of-type,
				.form .form-group:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		.form-group {
			.select {
				select {
					&::-ms-expand {
						/* remove default arrow in IE 10 and 11 */
						display: none !important;
					}
				}
			}

			select {
				position: relative;

				&::-ms-expand {
					/* remove default arrow in IE 10 and 11 */
					display: none !important;
				}
			}

			input {
				width: 100%;
			}

			input[type=file] {
				background: $white;
			}

			input[type=checkbox] {
				-webkit-appearance: checkbox;
				width: 24px;
			}
		}

    fieldset.form-group {
      padding: 0;
      border: none;
      margin: 0;
    }

    label.help-block,
		p.help-block {
      font-size: 12px;
			margin-bottom: 0;
			margin-top: 5px;
		}

    .btn.btn-primary {
      width: fit-content;
    }
	}

  .form-group.umbraco-forms-field.date.hidden {
    margin-bottom: 0;
  }

  .form-group .form-check .checkboxlist {
    margin-bottom: 10px;

    label {
      margin-bottom: 10px;

      &:nth-child(3) {
        margin-top: 0;
      }
    }
    
    br {
      display: none;
      line-height: 15px;
    }
  }
}
