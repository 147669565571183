/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Cards
 */

.card {
  mark {
    background: var(--darkmode-hightlight-2-200);
    color: var(--darkmode-900);
  }

  .card__badge {
    background: var(--darkmode-hightlight-2-200);

    p,
    p.day {
      color: var(--darkmode-900) !important;
    }
  }

  .breadcrumb-container {
    ol li,
    ol li span,
    span {
      color: var(--darkmode-50);
    }
  }
}

.card:not(.card__list):not(.card__landscape) {
  &[class*="bg--"]:not(.bg--transparent) {
    background-color: var(--darkmode-800) !important;

    h3,
    p:not(.status) {
      color: var(--darkmode-50);
    }
    
    .arrow::before {
      color: var(--darkmode-hightlight-1-200);
    }
  }

  &.bg--transparent {
    background-color: transparent !important;
  }
}

.card__event {
  &.card__event--date--top-left,
  &.card__event--date--top-right {
    .card__event__image-content .card__event__image-content__date {
      background-color: var(--darkmode-600);

      p.day {
        color: var(--darkmode-50);
      }
    }
  }

  .card__event__image-content .card__event__image-content__date {
    background-color: var(--darkmode-700) !important;
  }

  .card__event__content ul li {
    border-color: var(--darkmode-600);
  }

  .card__tag {
    background: var(--darkmode-900);
  }

  svg.svg-date,
  svg.svg-time,
  svg.svg-location {
    path {
      fill: var(--darkmode-hightlight-1-200) !important;
    }
  }

  svg.svg-date {
    rect {
      fill: var(--darkmode-hightlight-1-200) !important;
    }
  }

  svg.svg-time {
    path {
      stroke: var(--darkmode-hightlight-1-200) !important;
    }

    circle {
      stroke: var(--darkmode-hightlight-1-200) !important;
    }
  }
}

.card__image .card__image__content {
  height: 100%;
}

.card__list {
  background-color: transparent;
  border-color: var(--darkmode-800) !important;
}

.card__landscape {
  border-color: var(--darkmode-800) !important;
}

.list__cards.list__cards--framed {
  background-color: var(--darkmode-700);

  .card__list,
  .card__list__contact {
    border-color: var(--darkmode-600) !important;
  }
}

.card__hours {
  background-color: var(--darkmode-700);
  color: var(--darkmode-50);

  .inactive {
    color: var(--darkmode-300) !important;
  }

  .card__hours__content__icon::after {
    opacity: 1;
    background-color: transparent;
    background-color: var(--darkmode-600);
  }

  .card__hours__image__icon {
    background-color: transparent;

    &::after {
      opacity: 1;
      background-color: var(--darkmode-700);
    }
  }

  .status {
    color: var(--darkmode-300);

    .closed,
    .opened {
      color: var(--darkmode-hightlight-1-200);
    }

    .closed-fill,
    .open-fill {
      background-color: var(--darkmode-hightlight-1-200);
    }
  }
}
