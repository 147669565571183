$sin45: 0.70710678;
$loader-size: 2em;
$symbol-thickness: 2px; // Check thickness
$check-height: $loader-size*0.5;
$check-width: $check-height*0.5;
$check-left: (math.div($loader-size, 7) + math.div($loader-size, 12)); // 7/12 is a nice centrepoint of the tick
$cross-offset: math.div($check-height, $sin45) - $check-height;

.btn--spinner {
  position: relative;
  padding: 5px 15px;

  span {
    margin-left: 15px;
  }

  .spinner {
    margin: 0;
    border: $symbol-thickness solid rgba(0, 0, 0, 0.2);
    border-left-color: $btn-primary-text-color;
    animation-name: loader-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
  
    &, &:after {
      border-radius: 50%;
      width: $loader-size;
      height: $loader-size;
    }
  }
  
  .success {
    -webkit-animation: none;
    animation: none;
    border-color: $btn-primary-text-color;
    transition: border opacity 500ms ease-out;
  
    .status {
      &.draw:after {
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
      }
  
      &:after {
        opacity: 1;
        height: $check-height;
        width: $check-width;
        transform-origin: left top;
        border-right: $symbol-thickness solid $btn-primary-text-color;
        border-top: $symbol-thickness solid $btn-primary-text-color;
        content: '';
        left: calc($check-left - 1px);
        top: calc($check-height - 1px);
        position: absolute;
      }
    }
  }
  
  .failed {
    -webkit-animation: none;
    animation: none;
    border-color: $btn-primary-text-color;
    transition: border opacity 500ms ease-out;
  
    .status {
      top: 50%;
      left: 50%;
      position: absolute;
  
      &.draw {
        &:before, &:after {
          animation-duration: 1.2s;
          animation-timing-function: ease;
          animation-name: crossmark;
        }
  
        &:before {
          transform: scaleX(-1) rotate(45deg);
        }
  
        &:after {
          transform: scaleX(-1) rotate(225deg);
        }
      }
  
      &:before, &:after {
        opacity: 1;
        height: $check-height * 0.5;
        width: $check-height * 0.5;
        transform-origin: left top;
        border-right: $symbol-thickness solid $btn-primary-text-color;
        border-top: $symbol-thickness solid $btn-primary-text-color;
        content: '';
        position: absolute;
      }
  
      &:before {
        // left: calc(#{$cross-offset} - #{($symbol-thickness*2)});
        // top: calc(#{$symbol-thickness * 2} - #{$cross-offset});
        left: calc(0.6em - 4px);
        top: calc(4px - 0.6em);
      }
  
      &:after {
        // left: calc(#{math.div($symbol-thickness * 2, $sin45)} - #{$cross-offset});
        // top: calc(#{$cross-offset} - #{math.div($symbol-thickness, $sin45)});
        left: calc(5.656854259px - 0.6em);
        top: calc(0.55em - 2.8284271295px);
      }
    }
  }  
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes crossmark {
  0% {
  height: 0;
  width: 0;
  opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-width;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-width;
    width: $check-width;
    opacity: 1;
  }
}
