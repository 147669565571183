/**
* Elements forms
*
* Input, select etc..
*
*/

// Global styling
input,
label,
select,
textarea,
.select-checkboxes button {
	font-family: $secondary-font;
	font-size: $form-font-size;
}

form,
.form {
	.form-group{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		// &:not(.honeypot):last-of-type {
		// 	margin-bottom: 40px;
		// }

		// Input and Textarea
		input,
		input[type="date"],
		textarea {
			padding: 14px 15px;
      border-style: solid;
      border-width: 1px;
      border-color: $grey-medium;
			-webkit-appearance: none;
			border-radius: 0;
			line-height: $line-height-secondary;
			width: 100%;
      border-radius: $border-radius-form-elements;
      
			&::-webkit-input-placeholder {
				color: $grey-dark;
				font-family: $secondary-font;
			}
		}

		// Label
		label {
			margin-bottom: 10px;

      // When input has two labels
      &:nth-child(3) {
        margin-top: 10px;
        margin-bottom: 0;
      }
		}

		img.form-group__img {
			height: 100px;
			width: 100px;
			border-radius: 50%;
		}

		input.form-upload {
			padding: 0;
			border: none;
			border: 1px solid;
			padding: 5px 10px;
			width: 100px;
		}

		.select select,
		.select-checkboxes {
			background-color: $white;
			border: 1px solid $grey-medium;
		}

    .form-check:last-of-type {
      margin-bottom: 0;
    }
	}
}

@import 'checkbox';
@import 'form-container';
@import 'form-subscribe';
@import 'form-validation';
@import 'input';
@import 'label';
@import 'radio';
@import 'select';
@import 'textarea';
@import 'umbraco-forms';
@import 'background-colors';
