/**
 * Footer 
 * Watermark
 */

 .footer__watermark {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;

  img {
    height: 100%;
    width: auto;
  }
}
