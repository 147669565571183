/**
 * Custom list styling
 *
 * Usage:
 * @include unordered-list-styled
 */

@mixin unordered-list-styled {
	list-style: none;
  list-style-type: disc;
  padding-left: 18px;
  padding-left: 20px;

	li {
		position: relative;

    &::marker {
			position: absolute;
			top: 0;
			color: $color-icons;
			font-weight: 600;
			display: inline-block;
			font-size: inherit;
			line-height: inherit;
      margin-top: -1px;
			padding-bottom: 0.5rem;
			margin-left: -20px;
      font-size: inherit;
      line-height: inherit;
    }
	}
}
