/*
** Variables
** Global
*/

:root {
  --article-wrapper-c-mb: 30px;
  --hyphens: ; 
  --hyphens-mobile: ;
}
