/**
 * Tables
 */

.rich-text {
	table {
		width: 100%;
		margin: 20px 0 0 0;
    border-color: $grey-medium;
	}

	tr {
		th,
		td,
		p {
			font-family: $secondary-font;
			font-size: $form-font-size;
		}

		th,
		td {
			text-align: left;
			padding: 5px 10px;
		}
	}
}
