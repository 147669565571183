/**
 * Media
 */

// Fluid images for responsive purposes.
img,
picture {
	vertical-align: middle;
	height: auto;
	transition: opacity 0.3s ease-out;
	transition-delay: 0.3s;
	background-size: cover;
	min-height: 1px;
	width: 100%;

	&.img--cover {
		object-fit: cover !important;
    height: 100% !important;
	}
}


// For hiding right-click save image option
.pointer-events-none {
	pointer-events: none;
}

.lazyload {
	opacity: 0;
}

.lazyloaded {
	opacity: 1 !important;
}

// Fake class for images that are positioned abosulte in frontend
// Only for display purposes in local frontend
img.fake-class__img {
	min-height: -webkit-fill-available;
	width: 100%;
}

// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.
img[width],
img[height] {
	max-width: none;
}

figure {
	margin: 0;
}

object,
iframe,
embed,
video,
audio {
	max-width: 100%;
}

.teaser.lazyload {
	opacity: 0;
	transform: scale(0.8);
}

.teaser.lazyloaded {
	opacity: 1 !important;
	transform: scale(1);
	transition: all 700ms;
}

.iframe-box {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;

	// 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
	// Then style the iframe to fit in the container div with full height and width
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}
