/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Overlays
 */

.framed--100-40 {
  background-color: var(--darkmode-900);
}

.hero__search-bar {
  background: var(--darkmode-overlay);
}

.hero__search-bar.has-overlay,
.hero__search-bar--has-title {
  .bg-image:after,
  .background-video:after {
    background: var(--darkmode-overlay);
  }
}

.header__intranet.has-burger-desktop .show-burger-desktop:after {
  background-color: var(--darkmode-overlay);
}

.page-not-found__overlay {
  background-color: var(--darkmode-overlay);
}

.card__image .card__image__content {
  background: var(--darkmode-overlay-0deg);
}

.banner__cta .banner__cta--background-image:after {
  background: var(--darkmode-overlay);
}

.bg-overlay {
  background-color: var(--darkmode-overlay);
}

.bg-overlay-45deg {
  background-image: var(--darkmode-overlay-45deg);
}

.has-overlay,
.has-overlay--md-up:not(.hero__text-box__bg-color) {
  .tag span,
  .btn__link {
    color: var(--darkmode-50) !important;
  }
}
