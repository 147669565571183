.btn__sound-input {
	position: absolute;
	z-index: 21;
	width: fit-content;
}

.search-bar--small {
	form {
		.form-group.has-sound-input {
			input {
				padding-left: 65px;
			}

			label {
				padding-left: 45px;
			}

			.btn__sound-input {
				position: absolute;
				left: 20px;
				z-index: 11;

				span, 
        span::before {
					color: $color-icons;
				}
			}
		}
	}
}

.search-bar {
	form.has-sound-input {
		input, label {
			padding-left: 65px;
		}

		.btn__sound-input {
			left: 0px;
			top: 16px;

			@include media-breakpoint-down(md) {
				top: 25px;
			}

			@include media-breakpoint-down(sm) {
				top: 0;

				span::before {
					font-size: 1.6rem;
				}
			}

			span {
				&::before {
					font-size: 2.5rem;

					@include media-breakpoint-down(md) {
						font-size: 2rem;
					}

					@include media-breakpoint-down(sm) {
						font-size: 1.6rem;
					}
				}
			}
		}
	}
}

.header__megamenu-extended .header__megamenu-extended__search-bar .header__megamenu-extended__search-bar__form{
	.form-group.has-sound-input {
		input {
			padding-left: 55px;
		}

		.btn__sound-input {
			position: absolute;
			left: -15px;
			top: 23px;
			border: none;

			span {
				color: var(--color-primary-text)
			}
		}
	}
}

.header--left-aligned, .header--double-logo {
	.mobile-actions-searchbar-form.has-sound-input {
		.mobile-actions-searchbar-label {
			padding-left: 35px;
		}

		.mobileSearchInput {
			padding-left: 35px;
		}

		.btn__sound-input {
			span {
				font-size: 1.5rem;
			}
		}
	}

	.desktop-actions-searchbar-form.has-sound-input {
		.desktop-searchbar-input.active {
			padding-left: 37px;
		}

		.btn__sound-input {
			left: 9px;
			top: 7px;

			span {
				font-size: 1.5rem;
			}
		}
	}

	.mobile-actions-searchbar-container {
		.btn__sound-input span {
			color: var(--color-header-text)
		}
	}
}

.header--tagged {
	.mobile-actions-searchbar-form.has-sound-input {
		.mobile-actions-searchbar-label {
			padding-left: 35px;
		}

		.mobileSearchInput {
			padding-left: 35px;
		}

		.btn__sound-input span {
			color: var(--color-header-text);
			font-size: 1.5rem;
		}
	}

	.desktop-actions-searchbar .has-sound-input { 
		.desktop-actions-searchbar-input.active{
			padding-left: 35px;
		}
		
		.btn__sound-input {
			left: 9px;
			top: 7px;

			span {
				font-size: 1.5rem;
			}
		}
	}
}

.header--transparent {
	.nav--transparent .search-form--transparent.has-sound-input .input-expandable.active {
		padding-left: 40px;
	}

	.btn__sound-input {
		top: 5px;
		left: 3px;

		span {
			font-size: 1.5rem;
		}
	}

	.sidemenu__search-bar.search-bar--small form.has-sound-input .form-group {
		.btn__sound-input {
			left: unset;
		}
	}
}

.header__intranet .navbar__megamenu-extended .navbar-nav__primary .nav-item__search-input {
	.form-group.has-sound-input {
		.btn__sound-input {
			top: 24px;
			left: 5px;

			span {
				font-size: 1.5rem;
			}
		}

		input {
			padding-left: 40px;
		}
	} 
} 

.hero__search-bar,
.header__megamenu-extended .header__megamenu-extended__search-bar.bg--white .header__megamenu-extended__search-bar__form .form-group.has-sound-input {
	.btn__sound-input span {
		color: var(--color-icons)
	}
}
