/**
 * Third party
 * Adgang med tegn
 */

span.cdh-video-tag {
  font-size: inherit;
  color: $black !important;
  font-family: inherit;
  line-height: inherit !important;
}

.service-menu .service-menu__item {
  span.cdh-video-tag {
    line-height: 20px !important;
  }
}

.side-menu {
  span.cdh-video-tag {
    display: unset;
  }
}

iframe[id^="cdh_iFrame"],
iframe[id*="cdh_iFrame"],
#cdh_iFrame_88618 {
  position: absolute;
}
