.login--screen {
	.search-bar--small form {
		.form-group {
			border: 1px solid $color-primary;

			input.login--input {
				width: 100%;
			}
      
			button.login--button {
				background: $color-primary;
				height: 100%;
				padding: 0 20px;
				color: $color-primary-text;
				right: 0;
				margin: 0;
			}
		}
	}
}

.error-msg {
  display: none;
  margin-top: 10px;
  color: red;
}
