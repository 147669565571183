.card__person {
  overflow: hidden;
  border-radius: var(--sleeve-component-border-radius);

  &.bg--transparent {
		background: none;
    border-radius: unset;

		.card__person__text {
			padding: 15px 0 0 0;
		}

    .tags-container--under {
      padding: 0;
    }

		.arrow {
			display: none;
		}
	}

  .arrow {
    position: absolute;
    bottom: 30px;
    right: 30px;
    transition: $transition;

    &:hover {
      right: 35px;
    }
  }

  .card__person__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;

    p {
      margin-bottom: 0;
    }

    .rich-text {
      p,
      .btn {
        margin-bottom: 20px;
      }
    }
    
    p.subtitle {
      font-weight: $font-weight-heading;
    }

    .card__person__text__content {
      &:last-of-type {
        margin-bottom: auto;
      }

      p:last-of-type {
        margin-bottom: 10px;
      }
    }

    .btn__link {
      margin-top: 15px;
    }
  }

  .card__person__image {
    img {
      width: 100%;
      height: 100%;
      transition: $transition;
    }

    img:not([class*="aspect-ratios--"]) {
      height: auto;
      aspect-ratio: 3 / 2;

      @include media-breakpoint-down(sm) {
        height: auto;
        aspect-ratio: 3 / 2;
      }
		}
  }
}

.card__person:not(.c-background):not(.bg--transparent):not(.card__person--no-image) {
  .card__person__text {
    .rich-text p {
      margin-bottom: 20px;
    }
  }
}

a.card__person {
  &:hover {
    .arrow {
      right: 35px;
    }
  }

  .card__person__text {
    margin-bottom: 30px;
  }
}

@import 'content--dividers';
