/**
 * Breadcrumb
 */

.breadcrumb {
  display: flex;
  padding-left: 0;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;

  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }

  .breadcrumb-item {
    padding-left: 0;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: $breadcrumb-font-size;
    color: $grey-dark;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }

    &.active {
      color: $grey-dark;
    }

    &:last-child {
      a {
        &::before {
          display: none;
        }
      }
    }

    & + & {
      position: relative;
      padding-left: 0;

      &::before {
        display: none; // Regular breadcrumb seperator
        font-weight: 600;
      }
    }

    &.active {
      a {
        color: $grey-dark;
        font-weight: 500;
      }
    }
    
    a,
    span {
      position: relative;
      color: $grey-dark;
      font-weight: $font-weight-body;
      font-size: inherit;
      margin-right: 25px;
    }

    a {
      transition: $transition;
      
      &:hover {
        span {
          color: $color-link;
        }
      }
    }
  }

  .arrow__back {
    width: fit-content;

    &::before {
      display: none;
      width: 20px;
      background-image: unset;
  
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
  }

  &.breadcrumb--divider-arrow,
  &.breadcrumb--divider-slash {
    display: -webkit-inline-box; // Don't change, words dont wrap correctly with other displays
    
    .breadcrumb-item {
      display: unset;
      margin-bottom: 0;

      a,
      span:not(.arrow__back) {
        font-size: inherit;
  
        &::before {
          display: none; // Arrow breadcrumb seperator
        }
      }
    }
  }

  &.breadcrumb--divider-arrow {
    .breadcrumb-item {
      a,
      span {
        margin-right: 5px;

        @include media-breakpoint-down(sm) {
          margin-right: 3px;
        }

        svg {
          height: 9px;
          width: 6px;
          margin-left: 5px;

          @include media-breakpoint-down(sm) {
            margin-left: 4px;
          }
        }
      }
    }

    .arrow__back::before {
      display: none;
    }
  }

  &.breadcrumb--divider-slash {
    .breadcrumb-item {
      a,
      span:last-of-type {
        margin-right: 4px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }

        span {
          margin-left: 5px;
        }
      }

      span:first-of-type {
        margin-right: 4px;
        margin-left: 0;
      }

      span.arrow__back {
        @include media-breakpoint-down(sm) {
          padding-left: 20px;
        }
      }

      span:not(.arrow__back) {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}
