/**
 * Checkbox
 */

.form-check {
	display: flex;
	align-items: center;
	min-height: 25px;
	margin-bottom: 15px;

	&:last-of-type {
		margin-bottom: 50px;
	}

	// Checkbox
	.checkbox {
    position: relative;
    display: flex;
    align-items: center;
		padding-left: 40px;
		cursor: pointer;
		margin-bottom: 0;
    min-height: 25px;

    p {
      margin-bottom: 0;
    }

		input {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
      padding: 0;
		}

		.checkbox__indicator {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			background: $white;
			border: 1px solid $grey-dark;
			transition: $transition;

			&:after {
				content: "";
				position: absolute;
				display: none;
				left: 9px;
				top: 3px;
				width: 6px;
				height: 14px;
				border: solid $color-primary-text;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
			}
		}

		& input:checked ~ .checkbox__indicator {
			border: 1px solid $color-primary-text;
			background: $color-primary;
			transition: $transition;

			&:after {
				display: block;
			}
		}

		& input:disabled ~ .checkbox__indicator {
			background: $grey-light;
			opacity: 0.6;
			pointer-events: none;

			&:after {
				border-color: $grey-dark;
			}
		}
	}
}

.checkbox-toggle {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 24px;
  margin-bottom: 15px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    min-width: 45px;
    height: 24px;

    &[disabled] {
      + label {
        > .status-switch {
          cursor: initial;
          background-color: $grey-dark !important;
        }
      }
    }

    // Unchecked State
    + label {
      height: 100%;
      width: 100%;

      > .status-switch {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $grey-medium;
        color: $white;
        transition: all 0.5s ease;
        padding: 1px;
        border-radius: 20px;

        &:before,
        &:after {
          border-radius: 2px;
          height: calc(100% - 2px);
          width: calc(50% - 1px);
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          transition: all 0.3s ease;
        }
        
        &:before {
          z-index: 10;
          content: '';
          left: 1px;
          background-color: $white;
          border-radius: 50%;
        }
        
        &:after {
          content: '';
          right: 0;
        }
      }
    }

    // Checked state
    &:checked + label > .status-switch {
      background-color: $color-primary;

      &:after {
        left: 0;
        content: '';
      }

      &:before {
        color: $color-primary;
        left: 50%;
        content: '';
      }
    }
  }
}