/**
 * Textarea
 */

button:disabled:hover {

}

.textarea {
  display: flex;
  flex-direction: column;
}

.textarea__meta {
	display: flex;
	justify-content: space-between;

.textarea-counter {
	display: flex;
	margin-top: 5px;
	align-self: flex-start;
	margin-left: auto;

	p {
		font-size: $small-font-size;
		margin-bottom: 0;
	}

	.textarea-counter__maxlength {
		position: relative;
		padding-left: 10px;

		&::after {
		  content: '/';
		  position: absolute;
		  left: 3px;
		  top: 50%;
		  transform: translateY(-50%);
		  font-size: $small-font-size;
		  line-height: 1.1;
		}
	}
}
}
