/**
 * Buttons
 * .btn__color-theme
 */

.btn__color-theme {
  position: relative;
  display: flex;
  align-items: center;
  width: 44px;
  height: 24px; // Web Accessibility
  border-radius: 25px;
  padding: 0;
  border: none;
  overflow: hidden;
  background-color: #dcdcdc;

  .btn__color-theme__elements {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .btn__color-theme__toggle {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    transition: all 0.25s ease;
    border-radius: 100px;
    background-color: $white;

    [class*="icon"] {
      font-size: 20px;
    }
  }
}

@import 'btn__color-theme--darkmode';
@import 'btn__color-theme--grayscale';
