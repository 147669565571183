/**
 * Label
 */

form {
	label,
  legend {
		font-family: $secondary-font;
		font-weight: $font-weight-body;
		color: $black;
	}
}
