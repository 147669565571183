.dark-theme {
	.header--tagged {
		.header--tagged-top-bar {
			background-color: var(--darkmode-800);
		}

		.header--tagged-sidemenu {
			background-color: var(--darkmode-600);
		}

		.sidemenu-list-item {
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);

			&:active {
				a {
					color: var(--darkmode-hightlight-1-200);
				}
			}

			a:focus {
				.list-item-content-heading {
					color: var(--darkmode-hightlight-1-200);
				}
			}

			@include media-breakpoint-up(md) {
				border-bottom: none;

				&:not(:first-child):before {
					background-color: rgba(255, 255, 255, 0.2);
				}

				a:after {
					background-color: var(--darkmode-hightlight-1-200);
				}

				&:hover {
					.list-item-content-heading {
						color:  var(--darkmode-hightlight-1-200);
					}
				}
			}
		}

    .mobile-actions-searchbar-container {
      background-color: var(--darkmode-800);
    }

    .desktop-actions-searchbar-btn {
      svg {
        background-color: var(--darkmode-800);
      }
    }
	}
}
