/**
 * Darkmode
 * @import into styles/darkmode.scss
 *
 * Intranet
 * Chat
 */

 .chat {
  .chat__post {
    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      background: var(--darkmode-500);
    }

    .ql-snow .ql-stroke {
      stroke: var(--darkmode-hightlight-1-200);
    }

    .ql-snow .ql-fill,
    .ql-snow .ql-stroke.ql-fill {
      fill: var(--darkmode-hightlight-1-200);
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
      color: var(--darkmode-hightlight-1-200);
    }
  }
}
