.card__votes {
  position: relative;
  width: 100%;
  border-bottom-left-radius: var(--sleeve-component-border-radius);
  border-bottom-right-radius: var(--sleeve-component-border-radius);

  @include media-breakpoint-down(sm) {
    min-height: auto;
  }

  &:hover {
    .arrow {
      right: 35px;
    }
  }

  &.archived {
    progress.progressbar__value {
      &::-webkit-progress-value {
        background-color: $grey-dark;
      }
      
      &::-moz-progress-bar {
        background-color: $grey-dark;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 280px;
    padding: 30px;
  }

  &__text {
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
    }

    &__tags {
      display: inline-flex;
      flex-direction: column;
      
      p {
        margin-bottom: 0;
      }
    }

    [class^="icon-"],
    [class*="icon-"] {
      position: absolute;
      top: 0;
      right: 0;
    }

    h3,
    .h3 {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .card__votes__content {
    .card__votes__content__amount {
      p {
        margin-bottom: 0;
        font-size: $component-font-size;
        font-weight: $font-weight-heading;
      }

      p.date {
        font-weight: $font-weight-body;
      }
    }
  }
  
  .arrow {
    position: absolute;
    bottom: 30px;
    right: 30px;
    transition: $transition;
  }
}
