// Hero article
.hero__article {
	position: relative;
	margin-bottom: 40px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}

	.hero__article__text {
		padding-top: 60px;

		@include media-breakpoint-down(sm) {
			padding-top: 30px;
		}

		h1 {
      hyphens: var(--hyphens);

			strong {
				font-weight: $font-weight-heading; // When pre-text is set
			}
			//Adgang med tegn span in h1 should preserve h1 font weight
			span:not(.cdh-video-tag) {
				font-family: $primary-font;
				font-weight: $font-weight-body;
			}
		}

		.hero__tags--over {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 15px;

			p,
			.ellipsis .dropdown-toggle {
				white-space: nowrap;
				margin-bottom: 0;
				margin-right: 7px;
				padding-right: 7px;
        min-height: 24px; // Web accessibility
        min-width: 24px; // Web accessibility
			}

			p {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(0, -50%);
					height: 12px;
					width: 1px;
					background: $grey-medium;
				}

				&:last-child {
					margin-right: 0;
					padding-right: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		// Tag-block
		h1 {
			margin-bottom: 10px;
		}

		.tag-block {
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 10px;

			.tag {
				margin-top: 0;

				.border-left {
					background-color: $color-icons;
				}

				.text {
					font-size: calc(0.0625em * var(--component-body-font-size));
				}

				&:hover {
					.border-left {
						width: 5px;
					}
				}
			}
		}

		p:not(.author):not(.caption) {
			margin-bottom: 0;
			@include responsive-font-size(
				$hero-article-p-font-size-sm,
				"",
				$hero-article-p-font-size-sm,
				"",
				$hero-article-p-font-size-lg
			);
			line-height: $line-height-secondary;
		}

		.hero__tags--under {
			display: flex;
			flex-direction: column;
			gap: 15px;

      .tags-container:first-of-type {
        margin-top: 25px;
      }
		}
	}

	img {
		width: 100%;
		height: 500px;
	}

	.article-wrapper__top-image {
		margin-top: 40px;
	}

  @include nestedArticleColors($themes-article) {
    background: themedArticle('background');
    padding-bottom: 40px;

    h1,
    p,
    .ellipsis .dropdown-toggle,
    .label,
    .tag {
      color: themedArticle('text');
    }
  }
}

// Hero article in article wrapper: Article image & info after article image
.hero__article--article-wrapper {
	.article-wrapper__top-image,
	.article-wrapper__top-image .video {
		margin-bottom: 0;
	}

	.hero__tags--under {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .tags-container:first-of-type {
      margin-top: 20px;
    }
	}
}
