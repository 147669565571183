
.banner__hours {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0 45px 0;
  border-radius: $border-radius-component;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
  }

  &.has-image {
    h2,
    .h2,
    p,
    [class*="icon-"] {
      color: var(--color-overlay-text);
    }
  }

  &.padding {
    padding: 50px;
  }

  .btn-container {
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }
  }

  .banner__hours__row {
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      align-items: center;
    }
  }

  .banner__hours__heading__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      margin-bottom: 30px;
      flex-wrap: wrap;
      gap: 30px;
    }

    &.has-icon {
      margin-top: 10px;
    }
  }

  .banner__hours__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 15px;
    }
    
    @include media-breakpoint-down(sm){
      flex-direction: column;
    }
  }

  .banner__hours__content__info {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      &:nth-of-type(3){
        min-width: 100%;
      }
    }

    h3,
    p {
      font-size: $component-font-size;
      line-height: $line-height-secondary;
      margin-bottom: 5px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-down(sm){
        flex: 1;
        justify-content: space-between;
        min-width: 100%;
      }
    }

    .info-close-dates .info-date {
      font-weight: 500;
    }

    .info-status.open {
      color: $success-green;
    }

    .info-status.busy {
      color: $warning-orange;
    }

    .info-status-indicator {
      display: inline-block;
      margin-right: 6px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }

    .hidden { 
      display: none;
    }

    .closed { 
      color: $grey-dark;
    }

    .info-status-indicator.open {
      background: $success-green;
    }

    .info-status-indicator.busy {
      background: $warning-orange;
    }

    .info-status-indicator.closed {
      background: $error-red;
    }

    .info-status-text.closed {
      color: $error-red;
    }
  }

  .banner__hours__content__icon {
    position: relative;
    height: 55px;
    min-width: 55px;
    margin-top: -10px;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    &[class*="icon-"] {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }
  
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        background: $color-icons;
        opacity: .1;
        border-radius: 30px;
      }
    }

    img,
    svg {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 55px;
      height: auto;
      max-height: 55px;
    }

    img {
      transform: unset;
      object-fit: contain;
    }
  }

  .banner__hours__content__text {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }

    h3 {
      margin-bottom: 0;
      white-space: nowrap;
    }

    a.h3 {
      color: $color-link;
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
  }
  
  .btn-container {
    flex: 1;
    align-items: center;
    margin-top: 0;

    @include media-breakpoint-down(md) {
      align-self: flex-start;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    .btn {
      margin-top: 0;
    }
  }

  .list__links {
    padding: 0;
  }
  
  &--divider {
    margin: 0 0.3em;
  }
}

// Grid
.container .banner__hours .container {
  padding-left: 30px;
  padding-right: 30px;
}
