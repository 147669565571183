.info-box-article {
  display: flex;
  border-top-width: var(--component-border-top-width);
  border-top-style: solid;
  border-top-color: $color-border-top--article-components-bg;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  height: auto;
  @include responsive-space('mb', var(--section-margin-bottom-sm--small), var(--section-margin-bottom-sm--small), var(--section-margin-bottom-md--small), '', '');

  &:last-child {
    margin-bottom: 0;
  }

  .tags-container {
    margin-bottom: 10px;
  }

  .info-box-article__icon {
    position: relative;
    height: 100%;
    width: auto;
    margin-right: 20px;
    font-size: inherit;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: em(30); // icon size
      color: $color-border-top--article-components-text;

      @include media-breakpoint-down(sm) {
        font-size: em(25); // icon size
      }
    }

    .info-box-article__icon__bg {
      height: 55px;
      width: 55px;
      border-radius: 30px;
      background: $color-border-top--article-components-bg;

      @include media-breakpoint-down(sm) {
        height: 45px;
        width: 45px;
      }
    }
  }

  .info-box-article__content {
    width: 100%;
    
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    h3,
    .h3 {
      margin-top: 15px;
      margin-bottom: 15px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    p {
      margin-bottom: 0;
    }

    .list__links {
      padding: 0;
    }

    ul {
      @include list-unstyled;
      margin-bottom: 0;
    }
  }

  .info-box-article__content__header {
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }

    &button {
      padding-right: 30px;
    }
  }

  .has-icon .info-box-article__content__body {
    @include media-breakpoint-up(md) {
      margin-left: calc(55px + 20px); // Icon width + icon margin
    }
  }

  // Content list__downloads
  .list__downloads {
    ul li .list__download{
      font-size: $component-font-size;
      line-height: $line-height-secondary;
    }
  }

  // Contendt: form
  .form-container {
    border: unset;
  }
  
  // Content references
  .rich-text-article__references {
    ul {
      li {
        font-size: $small-font-size;
        line-height: $line-height-primary;
      }
    }
  }

  // Content Contact
  .container--wide .row {
    margin-right: -13px;
    margin-left: -13px;

    .col-md-6 {
      &:nth-child(n + 2) {
        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
      }

      &:nth-child(n + 3) {
        @include media-breakpoint-up(md) {
          margin-top: 20px;
        }
      }
    }
  }

  .divider {
    position: relative;
    padding-top: 15px;
    margin-top: 15px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 40px;
      background-color: $grey-medium;
    }
  }

  .info-box-article__content-item {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0;
    }

    .info-box-article__content__image {
      max-width: 80px;
      margin-right: 15px;
      border-radius: $border-radius-image;
    }
  }

  .info-box-article__content__text {
    position: relative;

    h4,
    .h4 {
      @include responsive-font-size($component-font-size, "", $component-font-size, "", $h4-font-size-sm);
    }

    p:not(.category) {
      margin-bottom: 0;
    }

    .rich-text p {
      margin-bottom: 20px;
    }
  }

  .container {
    .col-md-6 {
      &:nth-child(n + 3) {
        @include media-breakpoint-up(md) {
          margin-top: 20px;
        }
      }

      &:nth-child(n + 2) {
        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
      }
    }
  }

  // Accordion
  &.js-accordion {
    .js-accordion-header {
      position: relative;
      width: 100%;
      text-align: left;
      margin-bottom: 15px;
      padding: 0;

      h2 {
        margin-bottom: 0;
      }
    }

    .js-accordion-body {
      display: none;
    }
  }
}
