.aarhusguiden {
	.aarhusguiden__filter-container {
		padding: var(--sleeve-component-padding);
		border-radius: var(--sleeve-component-border-radius);
		margin-bottom: 30px;
	}

	//calendar
	.aarhusguiden__dates-container {
		display: flex;
		gap: var(--col-padding);
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}

    .aarhusguiden__calendar {
			flex: 1;

			h3 {
				position: relative;

				.aarhusguiden__calendar-from-btn {
					transition: ease-in 200ms;

					&:focus::before{
						height: auto;
						overflow: visible;
					}

					&::before {
						transition: ease-in 200ms;
						height: 0;
						overflow: hidden;
						position: absolute;
						content: 'V';
					}
				}
			}
			.ui-datepicker-inline {
				width: 100%;
				border: none;
				background-color: transparent;
			}

			.ui-datepicker-next, .ui-datepicker-prev {
				&:hover {
					cursor: pointer;
					background-color: none;
				}
			}

			.ui-datepicker-header {
				background: transparent;
				border: none;
			}

			.ui-state-default {
				background: transparent;
				position: relative;

				&.ui-state-highlight {
					border: 1px solid var(--color-tag);
				}

				&.ui-state-active {
					border: 1px solid var(--color-tag);

					&::before {
						background-color: var(--color-tag);
						height: 100%;
						width: 100%;
						position: absolute;
						content: '';
						top: 0;
						left: 0;
						opacity: 0.15;
					}
				}
			}
    }

    //search bar 
    .aarhusguiden__search-container {
			margin-bottom: 30px;
    }

    // tags
    .aarhusguiden__tags-container {
			list-style: none;
			margin-bottom: 30px;
			display: grid;
			grid-template-columns: repeat(4, calc(25% - var(--col-padding)));
			grid-gap: var(--col-padding);

			@include media-breakpoint-down(sm){
				grid-template-columns: repeat(2, calc(50% - var(--col-padding)));
			}

			.aarhusguiden__tag-li {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				transform-origin: left;
				transition: ease-in 200ms;

				.tag--default::before {
					transition: ease-in 200ms;
					transform-origin: left;
					content: '';
					position: absolute;
					height: 2px;
					width: 0%;
					background-color: var(--color-tag);
					opacity: 1;
					bottom: 0;
					left:0;
				}

				&:hover, &.tag--underline {
					cursor: pointer;
					color: var(--color-tag);

					.tag--default {
						color: var(--color-tag)
					}

					.tag--default::before {
						width: 100%;
					}
				}
			}

			.tag {
				//width: 100%;
				font-size: var(--h4-font-size-lg);
				padding: 5px 10px;
				position: relative;
				border: 1px solid transparent;

				@include media-breakpoint-down(sm){
					min-width: 30%;
				}
			}
    }

	// buttons container
	.aarhusguiden__btn-container {
		display: flex;
		gap: var(--col-padding);
		justify-content: flex-end;
	}
}

//cards container
.aarhusguiden__cards-container {
	margin-bottom: 30px;
}

.aarhusguiden__load-more-btn-container {
	text-align: center;
}

//darkmode 
.dark-theme {
	.aarhusguiden__dates-container {
		span, a {
			color: var(--darkmode-50)
		}

		.ui-state-default.ui-state-active {
			border: 1px solid var(--darkmode-hightlight-1-200);

			&::before {
				background-color: var(--darkmode-hightlight-1-200);
			}
		}

		.ui-state-default.ui-state-highlight {
			border: 1px solid var(--darkmode-hightlight-1-200);
		}
	}

	.aarhusguiden .aarhusguiden__tags-container .aarhusguiden__tag-li .tag--default{
		&::before {
			background-color: var(--darkmode-hightlight-2-200);
		}
	}
}