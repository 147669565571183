.pagination {
	display: flex;
	justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3px 0; // .btn:focus top is cut off without

  .pagination__results {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: $small-details-font-size;
    color: $grey-dark;
  }

	ol {
		@include list-unstyled;
		display: flex;
		margin-bottom: 0;
	}

	&__item {
		display: flex;
    justify-content: center;
    align-items: center;
		display: none;
		margin-right: 2px;
		margin-left: 2px;
		margin-bottom: 0;
		width: 40px;
		height: 44px;

		@include media-breakpoint-down(sm) {
			width: 25px;
			height: 25px;
		}

    button,
    a {
      font-size: 1.875rem; // needs fixed size
      line-height: 2.625rem; // needs fixed size
      color: #666;
      transition: $transition;
      padding: 0;

			@include media-breakpoint-down(sm) {
        font-size: 1.25rem; // needs fixed size
        line-height: 1.625rem; // needs fixed size
			}
		}

		&:first-child,
		&:last-child {
			margin-right: 10px;
			margin-left: 10px;
			align-items: center;
		}

		&:last-child,
		&:nth-child(2),
		&:nth-last-child(2) {
			display: flex;
		}

		&:hover {
      button,
      a {
				color: $color-icons;
			}
		}

		.arrow {
			&--right,
			&--left {
				display: flex;
				align-items: center;
				height: 40px;

				@include media-breakpoint-down(sm) {
					height: auto;
				}

				svg {
					path {
						fill: $color-icons;
					}
				}
			}

			&--left {
				transform: rotate(180deg);
			}
		}
	}

	&:not([actpage="1"]) &__item:nth-child(1) {
		display: flex;
	}

  .btn.display-none--desktop {
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }
}

@for $i from 1 through 80 {
	.pagination[actpage="#{$i}"] {

		// 3 before
		.pagination__item:nth-child(#{$i - 2}):not(:first-child):not(:nth-child(2)),
		.pagination__item:nth-child(#{$i + 4}):not(:last-child):not(:nth-last-child(2)) {
			display: flex;
			pointer-events: none;
			width: 40px;

			@include media-breakpoint-down(sm) {
				width: 25px;
			}

      button,
      a {
				position: relative;
        width: 40px;
        font-size: 0; // needs fixed size

				@include media-breakpoint-down(sm) {
					width: 25px;
				}

				&:after {
          content: '...';
					position: absolute;
          left: 0;
          left: 50%;
          transform: translate(-50%, 0);
					color: #666;
          font-size: 1.875rem; // needs fixed size

					@include media-breakpoint-down(sm) {
            font-size: 1.25rem; // needs fixed size
					}
				}
			}
		}

		// 2 before
		.pagination__item:nth-child(#{$i - 1}):not(:first-child) {
			display: flex;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		// before
		.pagination__item:nth-child(#{$i}):not(:first-child) {
			display: flex;
		}

		// active
		.pagination__item:nth-child(#{$i + 1}) {
			display: flex;

			+.pagination__item:last-child {
				display: none !important;
			}

      button,
      a {
				color: $color-icons;
			}
		}

		// next
		.pagination__item:nth-child(#{$i + 2}):not(:last-child) {
			display: flex;
		}

		// 2 next
		.pagination__item:nth-child(#{$i + 3}):not(:last-child) {
			display: flex;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
}
