/*
Top nav items and search-bar.
*/

.nav--transparent {
	width: 100vw;
	z-index: 30;
	background-color: var(--navbar-bg-color);
	transition: background-color 0.15s ease, color 0.15s ease;
	min-height: 55px;
	display: flex;
	justify-content: center;
	align-items: center;

	&.absolute {
		position: absolute;
	}

	&.fixed {
		position: fixed;
	}

	.container--wide {
		height: 100%;
	}

	.header-items {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-family: Arial, Helvetica, sans-serif;
		transition: color 0.2s ease;

		.header-items__top-nav__list {
			white-space: nowrap;
			overflow: hidden;
		}

		.header-items__top-nav {
			max-width: var(--mid-width);
			padding: 0 10px;

			&__list {
				max-width: var(--mid-width);
				padding: 0 5px;
				list-style: none;
				display: flex;
				margin: 0;

				&:hover > .top-nav__list-item a::after {
					opacity: 0.5;
				}

				&:hover > .top-nav__list-item a:hover {
					&::after {
						opacity: 1;
					}
				}

				.top-nav__list-item {
					margin: 0;

					a {
            display: flex;
            justify-content: center;
						position: relative;
						transition: font-size 0.4s ease, height 0.4s ease, colors 0.2s ease;
						font-size: $header-nav-link-font-size;
            padding-top: 6px;
						padding-bottom: 6px;
            min-height: 24px; // Web accessibility
            min-width: 24px; // Web accessibility

						@include media-breakpoint-down(md) {
							font-size: $component-font-size;
						}

						&::after {
							content: '';
							display: flex;
							position: absolute;
							left: 0;
							bottom: 0;
							transition: width 0.2s ease-in-out;
							width: 0;
							height: 3px;
							border: none;
							margin: 0;
						}

						&:focus,
						&:focus-visible {
							outline: none;

							&::after {
								width: 100%;
							}
						}
					}

					&.active,
          &:hover {
						a::after {
							width: 100%;
						}
					}

					&:not(:first-child) {
						margin-left: 30px;
						margin-bottom: 0;
					}
				}
			}

      .list-item--cta {
        &:hover,
        &:focus {
          &::after {
            display: none;
          }
        }
      
        a { 
          background-color: var(--changing-text-color);
          color: var(--navbar-bg-color);
          border-width: $btn-primary-border-width;
          border-style: $btn-primary-border-style;
          border-color: var(--changing-text-color);
          border-radius: var(--sleeve-button-border-radius);
          font-weight: $btn-font-weight;
          width: fit-content;
          height: 42px;
          padding-left: 15px;
          padding-right: 15px;
          transition: $transition;
      
          &:hover,
          &:focus,
          &.active {
            background-color: transparent;
            color: var(--changing-text-color);
      
            &:after {
              display: none;
            }
      
            [class*="icon-"] {
              color: var(--navbar-bg-color);
            }
          }
      
          &:hover {
            &:before {
              display: none;
            }
          }
      
          &:focus {
            outline: -webkit-focus-ring-color auto 1px;
          }
      
          &::before,
          &::after {
            display: none;
          }
      
          [class*="icon-"] {
            color: $btn-primary-text-color;
          }
        }
      }
		}
	}

	.search-form--transparent {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 30px;
		padding-bottom: 6px;
    margin-bottom: 0;

		&.active {
			.input--transparent {
				width: 220px;
				height: 35px;
				padding: 0 10px;
				opacity: 1;
			}

			.submit-input {
				display: flex;
				position: absolute;
				padding-left: 10px;
				right: 0;
				color: $black;
			}
		}

		&.not-showing {
			display: none;
		}

		.input-expandable {
			border-radius: 25px;
			transition: all 0.2s ease-in;
			width: 0;
			padding: 0;
			border: none;

			&.active {
				padding: 10px 15px;
				margin-right: 6px;
				width: 250px;
			}
		}

		.expand-input-btn {
			&:hover {
				cursor: pointer;
			}
		}

		.btn-empty-input {
			position: absolute;
			right: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.w-6 {
			width: 1.5rem;
		}

		.h-6 {
			height: 1.5rem;
		}
	}
}
