// Media box full-width - has full width image as background
.media-box--full-width {
  &.has-overlay:not([class*="bg-"]) {  
    // For WCAG - Can't be seen because of image
    background-image: var(--color-overlay-45deg);

    .media-box__text {
      margin: 120px 0;
      background: transparent;
      padding: 0;
      
      @include media-breakpoint-down(md) {
        margin: 60px 0;
      }
    }
  }

  .media-box__media {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img:not(.watermark__img) {
      height: 100%;
      width: 100%;
    }
  }

  .media-box__text {
    margin: 100px 0;
    background: $white;
    border-radius: var(--sleeve-component-border-radius);
    
    @include media-breakpoint-down(md) {
      margin: 80px 0;
    }

    @include nestedComponentColors($themes) {
      background: themed('background');
    }
  }

  &.reverse {
    .row  {
      flex-direction: row-reverse;
    }
  }
}

