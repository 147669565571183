.info-box-article__comments {
  &.js-accordion {
    .info-box-article__content__body .info-box-article__content-item {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .info-box-article__content__body {
    .info-box-article__content-item {
      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      margin-top: 20px;
    }

    .card {
      h3 {
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }

  .has-icon .info-box-article__content__body {
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }
}
