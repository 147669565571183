// Media box equal-heights - text box and image are equal heigths
.media-box--equal-heights {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &:not(.bg--transparent) {
    &.has-searchbar {
      // When media-box is in full width container
      .container {
        .row {
          .media-box__text {
            padding: 100px 60px 60px 0;

            @include media-breakpoint-down(md) {
              padding: 100px 60px
            }

            @include media-breakpoint-down(sm) {
              padding: 40px calc(40px - var(--col-padding-md-down)) 40px calc(40px - var(--col-padding-md-down));
            }
          }
        }
      }
    }

    // When media-box is in full width container
    .container {
      .row {
        .media-box__text {
          padding: 100px 60px 100px 0;
          
          @include media-breakpoint-down(md) {
            padding: 100px 60px
          }

          @include media-breakpoint-down(sm) {
            padding: 40px calc(40px - var(--col-padding-md-down)) 40px calc(40px - var(--col-padding-md-down));
          }
        }
      }
    }
  }
  
  // Class used to reverse direction of content
  &.reverse {
    flex-direction: row-reverse;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .container {
      .row {
        flex-direction: row-reverse;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
      }
    }

    .media-box__media {
      left: 0;
    }
  }

  &.reverse:not(.bg--transparent) {
    .container {
      .row {
        .media-box__text {
          padding: 100px 0 100px 60px;

          @include media-breakpoint-down(md) {
            padding: 100px 60px
          }
          
          @include media-breakpoint-down(sm) {
            padding: 40px calc(40px - var(--col-padding-md-down)) 40px calc(40px - var(--col-padding-md-down));
          }
        }
      }
    }
  }

  // Media box__media
  .media-box__media {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      height: 400px;
    }

    @include media-breakpoint-down(sm) {
      height: 210px;
    }

    img:not(.watermark__img) {
      height: 100%;
      width: 100%;
    }
  }

  // Media box__text
  .media-box__text {
    width: 100%;
  }
}

// When media-box is in regular container
.container {
  .media-box:not(.bg--transparent) {
    .container {
      .row {
        .media-box__text {
          padding: 100px 60px;
          
          @include media-breakpoint-down(sm) {
            padding: 40px calc(40px - var(--col-padding-md-down)) 40px calc(40px - var(--col-padding-md-down));
          }
        }
      }
    }

    &.has-searchbar {
      .container {
        .row {
          .media-box__text {
            padding: 100px 60px 60px 60px;

            @include media-breakpoint-down(sm) {
              padding: 40px calc(40px - var(--col-padding-md-down)) 40px calc(40px - var(--col-padding-md-down));
            }
          }
        }
      }
    }
  }

	.media-box {
    &.media-box--equal-heights {
      border-radius: var(--sleeve-component-border-radius);

      .media-box__media {
        img:not(.watermark__img),
        .background-video {
          border-radius: var(--sleeve-component-border-radius);
  
          @include media-breakpoint-up(lg) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
  
          @include media-breakpoint-down(md) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: var(--sleeve-component-border-radius);
          }
        }

        .swiper .gradient {
          @include media-breakpoint-up(lg) {
            border-bottom-right-radius: var(--sleeve-component-border-radius);
            border-bottom-left-radius: 0;
          }
        }
      }

      &.reverse {
        .media-box__media {
          img:not(.watermark__img),
          .background-video {
            border-radius: var(--sleeve-component-border-radius);
  
            @include media-breakpoint-up(lg) {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: var(--sleeve-component-border-radius);
              border-top-left-radius: var(--sleeve-component-border-radius);
            }
  
            @include media-breakpoint-down(md) {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-left-radius: var(--sleeve-component-border-radius);
            }
          }

          .swiper .gradient {
            @include media-breakpoint-up(lg) {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: var(--sleeve-component-border-radius);
            }
          }
        }
      }
    }

    &.bg--transparent {
      &.media-box--equal-heights {
        .media-box__media {
          img:not(.watermark__img),
          .background-video {
            @include media-breakpoint-up(lg) {
              border-bottom-left-radius: var(--sleeve-component-border-radius);
              border-top-left-radius: var(--sleeve-component-border-radius);
            }
    
            @include media-breakpoint-down(md) {
              border-bottom-left-radius: var(--sleeve-component-border-radius);
              border-bottom-right-radius: var(--sleeve-component-border-radius);
            }
          }

          .swiper .gradient {
            border-bottom-left-radius: var(--sleeve-component-border-radius);
            border-bottom-right-radius: var(--sleeve-component-border-radius);
          }
        }
  
        &.reverse {
          .media-box__media {
            img:not(.watermark__img),
            .background-video {
              @include media-breakpoint-up(lg) {
                border-bottom-right-radius: var(--sleeve-component-border-radius);
                border-top-right-radius: var(--sleeve-component-border-radius);
              }
    
              @include media-breakpoint-down(md) {
                border-bottom-left-radius: var(--sleeve-component-border-radius);
                border-bottom-right-radius: var(--sleeve-component-border-radius);
              }
            }

            .swiper .gradient {
              border-bottom-left-radius: var(--sleeve-component-border-radius);
              border-bottom-right-radius: var(--sleeve-component-border-radius);
            }
          }
        }
      }
    }
	}
}

.media-box--equal-heights:not([class*="bg-"]) {
  background-color: $white;
}
  
.media-box--equal-heights.bg--transparent {
  &:not(.reverse) {
    .media-box__text {
      padding-left: 0 !important;
    }
  }

  &.reverse {
    .media-box__text {
      padding-right: 0 !important;
    }
  }
}
