.suggestions--autocomplete {
  z-index: 16;
  position: absolute;
  top: 70px;
  left: 0;
  box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.02);
  background: $white;
  margin-top: 5px;
  border-radius: $border-radius-component;
  //border: 1px solid $grey-light;
  transition: $transition;
  overflow: hidden;
  height: 0;
  min-width: 50%;
  display: none;

  &.active {
    height: auto;
    display: block;
  }

  ul {
    list-style-type: none;
		margin-bottom: 0;
		margin-bottom: auto;
    padding: 10px 0;
  }

  li {
    display: flex;
    margin-bottom: 0;

		&.highlighted {
			background-color: $grey-light;
		}

    a, 
    button {
      padding: 10px 20px;
      width: 100%;
      color: $black;
      text-align: left;

      &:hover, 
      &.highlighted {
        background-color: $grey-light;
      }
    }
  }
}

// Different positioning for autocomplete suggestions box inside megamenu
.header__megamenu-extended {
  .suggestions--autocomplete {
    top: 90px;
    width: 100%;

    @include media-breakpoint-down(md) {
      position: relative;
      top: 0;
    }

    ul {
      @include media-breakpoint-up(lg) {
        max-width: var(--container-width);
        margin: 0 auto;
      }
    }
  }
}

.header--left-aligned {
  &.collapsed-menu {
    .mobile-actions-searchbar-container {
      .suggestions--autocomplete {
        margin-top: 0;
        opacity: 1;
      }
    }
  }

  .desktop-searchbar {
    .suggestions--autocomplete {
      display: none;
      top: 50px;

      &.active {
        display: block;
      }
    }
  }
}

.suggestions-hidden {
  .header--left-aligned .desktop-searchbar .suggestions--autocomplete {
    display: none;
  }
}

//double logo menu
.header--double-logo {
  .suggestions--autocomplete {
    display: none;
    height: 0;
    top: 40px;
    background-color: $white;

    a {
      color: $black;
    }

    &.active {
      display: block;
      height: auto;
    }
  }
}

//header tagged
.header--tagged {
  .suggestions--autocomplete {
    display: none;
    height: 0;
    top: 50px;

    @include media-breakpoint-down(md) {
      top: 60px;
    }

    a {
      color: $black;
    }

    &.active {
      display: block;
      height: auto;
    }
  }
}

// header transparent
.header--transparent {
  .sidemenu__search-bar {
    .suggestions--autocomplete {
      top: 70px;
    }
  }

  .suggestions--autocomplete {
    display: none;
    height: 0;
    top: 50px;

    &.active {
      display: block;
      height: auto;
    }
  }
}

.search-bar {
  .form-group {
    margin-bottom: 0;
  }

  .suggestions--autocomplete {
    top: 78px;

    @include media-breakpoint-down(sm) {
      top: 44px;
    }
  }
}
