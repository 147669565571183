// Working on merging the intranet contact card and the one backend made
.card__list.card__list--contact {
  padding: 15px 0;

  .card__list__image {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      height: 55px;
      width: 55px;
      min-width: 55px;
      margin-right: 20px;
    }
  
    img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }

  .card__list__content {
    h3,
    .h3 {

      @include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
    }

    a {
      &:hover,
      &:focus {
        h3,
        .h3 {
          color: $color-link;
        }
      }

      h3,
      .h3 {
        transition: $transition;
      }
    }
  
    p.description {
      @include media-breakpoint-up(md) {
				margin-top: 15px;
			}

      @include media-breakpoint-down(lg) {
				margin-bottom: 15px;
			}
    }

    p strong {
      margin-right: 5px;
    }
  }

  .row {
    width: 100%;
    height: fit-content;
  }
}

.list__cards--framed {
  .card__list.card__list--contact {
    border-top: unset;
    border-bottom: 1px solid $grey-light;
  }
}
