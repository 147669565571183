.swiper.swiper--image {
  max-height: calc(100vh - #{var(--header-height-lg)});

  &.swiper-autoheight {
    img {
      height: auto;
    }
  }

  .image {
    margin-bottom: 0;

    picture {
      height: 100%;
    }
  }

  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 1));    
    padding: 50px 25px 35px 25px;
    box-sizing: border-box;

    @include media-breakpoint-down(md) {
      padding: 50px 25px 30px 25px;
    }

    p {
      color: $white;
    }
  }

  .swiper-pagination {
    bottom: 0;
    margin-top: 0;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .gradient {
    bottom: auto;
  }
}

// Live fix for full width slider with picture attribute
[data-view~="ncfullWidthslider"] {
  .swiper.swiper--image {  
    .image {
      picture {
        height: calc(100vh - #{var(--header-height-lg)});
      }
    }
  }
}

// Full width carousel
.container--wide {
  .swiper.swiper--image {
    .image {
      figcaption {
        max-width: 100%;

        p {
          padding: 0;
          margin: 0;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;
    }
  
    .gradient {
      bottom: 0;
    }
  }
}

// Carousel image in .article-wrapper
.article-wrapper {
  .swiper.swiper--image {
    max-height: 100%;

    .swiper-pagination {
      bottom: 0;
    }
  
    .gradient {
      bottom: 0;
    }
  }
}
