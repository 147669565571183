/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Filter list
 */

.filter-list {
  .card__list {
    border-color: var(--darkmode-700) !important;
  }
}

.filter-list__form {
  background: var(--darkmode-700);
}

.filter-list__form form .select select,
.filter-list__form .form .select select {
  background: var(--darkmode-600) !important;
}

.filter-list__form form .select span.border-left,
.filter-list__form .form .select span.border-left {
  background: var(--darkmode-hightlight-1-200) !important;
}

.filter-list ul.filter-search__filter__alphabet button.chosen {
  color: var(--darkmode-900);
  background-color: var(--darkmode-hightlight-2-200);
}

.filter-list ul.filter-search__filter__alphabet button {
  color: var(--darkmode-50);
}

.filter-list .filter-menu .filter-menu__items {
  .select.select-filter {
    border-color: transparent !important;
    background: transparent !important;

    .filtermenu_sort {
      border-color: var(--darkmode-600);
      background: var(--darkmode-700);
    }
  }
}

