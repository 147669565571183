// In text tooltip
.intext-tooltip {
  cursor: help;
	padding: 0;
	border-bottom: 1px $color-link dashed;
	font-family: $secondary-font;
	font-weight: $font-weight-body;
	background-color: transparent;
}

.tippy-box[data-theme~="custom"] {
	max-width: 385px;
	background-color: $color-primary;
	color: $color-primary-text;
	padding: 20px;
	text-align: left;
	border-radius: 0;
	font-family: $secondary-font;
	font-style: normal;
	font-weight: 500;
	font-size: $small-details-font-size;
	line-height: $line-height-secondary;

	&[data-placement^="top"] .tippy-arrow::before {
		border-top-color: $color-primary;
	}

  &[data-placement^='bottom'] .tippy-arrow::before {
    border-bottom-color: $color-primary;
  }

  &[data-placement^='left'] .tippy-arrow::before {
    border-left-color: $color-primary;
  }

  &[data-placement^='right'] .tippy-arrow::before {
    border-right-color: $color-primary;
  }
}

// Button tooltip
.btn-tooltip {
	position: relative;

	&.show {
		.btn-tooltip__text {
			visibility: visible;
			opacity: 1;
		}
	}

	&__text {
		position: absolute;
		top: 150%;
		left: -50%;
		visibility: hidden;
		max-width: 350px;
		background-color: $color-primary;
		color: $color-primary-text;
		padding: 20px;
		text-align: left;
		opacity: 0;
		transition: opacity 0.3s;
		border-radius: 0;
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		font-size: $small-details-font-size;
		line-height: $line-height-secondary;
		letter-spacing: 0.01em;
		white-space: nowrap;

		@include media-breakpoint-down(sm) {
			right: -50%;
			left: unset;
			max-width: 230px;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 33px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent $color-primary transparent;

			@include media-breakpoint-down(sm) {
				right: 33px;
				left: unset;
			}
		}
	}
}