.navbar {
  // component color theming: Primary container
  @include nestedComponentColors($themes) {
    background-color: themed('background');

    @include media-breakpoint-down(md) {
      background: $white !important;
    }

    p,
    span {
      @include media-breakpoint-down(md) {
        color: $black;
      }
    }
  
    a,
    li:not(.nav-item--cta) {
      @include media-breakpoint-down(md) {
        color: $black;
      }
    }
  
    .nav-item:not(.nav-item--cta) .nav-link {
      @include media-breakpoint-up(lg) {
        color: themed('text');
      }

      &:hover,
      &:focus {
        @include media-breakpoint-up(lg) {
          color: themed('text');
        }
      }
    }

    .dropdown-menu {
      p,
      li,
      span,
      a,
      .nav-link {
        color: $black !important;
      }

      .list__links ul li a::before {
        color: $color-arrows;
      }

      a,
      .nav-link:not(.dropdown-submenu__button):not(p),
      .list__link-image p:not(.nav-link) {
        &:hover {
          color: $color-link !important;
        }
      }
    }

    .navbar__search-toggle:before {
      background-color: themed('icon') !important;
    }

    .nav-item.nav-item--cta .nav-link {
      @include media-breakpoint-up(lg) {
        background-color: themed('btn-primary-background');
        color: themed('btn-primary-text');
        border-color: themed('btn-primary-border');
      }

      [class*=icon-]::before {
        @include media-breakpoint-up(lg) {
          color: themed('btn-primary-text');
        }
        
        @include media-breakpoint-down(md) {
          color: $btn-primary-text-color;
        }
      }

      &:hover,
      &:focus {
        @include media-breakpoint-up(lg) {
          background-color: transparent;
          color: themed('text') ;

          [class*=icon-]::before {
            color: themed('text');
          }
        }

        @include media-breakpoint-down(md) {
          color: $black;

          [class*=icon-]::before {
            color: $black;
          }
        }
      }
    }

    .navbar-nav__primary {
      .nav-link:after {
        background: themed('link') !important;
      }

      .nav-item__search-toggle {
        &::before {
          background-color: themed('text');
        }

        .navbar__search-toggle:after {
          background-color: themed('text') !important;
        }
      }
    }

    .navbar-nav__secondary {
      .nav-item:not(.nav-item--cta):not(.nav-item__social) {
        .nav-link {
          &:after {
            background: themed('link') !important;
          }
        }
      }
    }

    .navbar__search-toggle:before {
      background: themed('icon') !important;
    }

    .nav-item.nav-back-link .nav-link {
      @include media-breakpoint-down(md) {
        color: themed('text') !important;
      }
    }

    .navbar__megamenu__header {
      @include media-breakpoint-down(md) {
        background: themed('background') !important;
      }

      .navbar-toggler-icon span {
        background: themed('icon') !important;
      }
    }

    .navbar-brand {
      h1,
      .h1,
      strong {
        color: themed('text') !important;
      }
    }

    .navbar__search-toggle {
      svg path {
        fill: themed('icon') !important;
      }
    }
  }
}

.navbar-nav__secondary-container {
  // component color theming: Secondary container
  @include nestedComponentColors($themes) {
    .navbar-nav.navbar-nav__secondary {
      .nav-item:not(.nav-item--cta) .nav-link {
        color: themed('text') !important;
      }
      .nav-item.nav-item--cta {
        .nav-link {
          background-color: themed('btn-primary-background');
          color: themed('btn-primary-text');
          border-color: themed('btn-primary-border');
      
          &:hover,
          &:focus,
          &.active {
            background-color: themed('btn-primary-background-hover');
            color: themed('btn-primary-text-hover');

            [class*="icon-"]::before {
              color: themed('btn-primary-text-hover');
            }
          }
      
          [class*="icon-"]::before {
            color: themed('btn-primary-text');
          }
        }
      }

      .nav-item:not(.nav-item--cta):not(.nav-item__social) {
        .nav-link {
          &:after {
            background: themed('text') !important;
          }
        }
      }
    }
  }
}

.header__megamenu-extended__search-bar {
  @include nestedComponentColors($themes) {
    background-color: themed('background');

    .header__megamenu-extended__search-bar__form,
    form:not(#search-form).header__megamenu-extended__search-bar__form {
      .form-group {
        input,
        label,
        button {
          color: themed('text');
        }
  
        input {
          background-color: themed('background');
          border-color: themed('text');
  
          &::placeholder {
            color: themed('text');
          }
        }
  
        button[type="submit"] {
          background-color: themed('btn-primary-background');
          border-color: themed('btn-primary-border');
          color: themed('btn-primary-text');

          &:hover,
          &:focus {
            background-color: themed('btn-primary-background-hover');
            color: themed('btn-primary-text-hover');

            svg path {
              fill: themed('btn-primary-text-hover');
            }
          }
  
          svg path {
            fill: themed('btn-primary-text');
          }
        }
      }
    }
  }
}
