@include sectionColors($themes-section) {
  /***** Owl dots *****/
	.owl-carousel {
		.owl-dots {
			.owl-dot {
				//border: 2px solid themedSections('text');
				&::after {
					border: 2px solid themedSections('text');
				}
				&.active::after {
					background-color: themedSections('text');
				}
			}
		}
	}
}