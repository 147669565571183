/*
** Background color transparent for components
**
** .bg--transparent is used on the first level of the given component,
** to remove a background color
*/

.bg--transparent {
  background-color: transparent;
}
