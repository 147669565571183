/**
 * Tags
 */

// @TODO - clean this file once the new tags has been implemented.

.tags--inline,
.dates--inline {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&.with-divider {
		p:not(.tag--icon) {
			&:before {
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				height: 12px;
				width: 1px;
				background: $grey-medium;
			}

			&:last-child:before {
				display: none;
			}
		}

		p:not(.tag--icon) {
			padding-right: 10px;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	p {
		position: relative;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

.tag--border {
	border-style: solid;
	border-width: 1px;
	border-color: $error-red;
	color: $error-red;
	padding: 5px 9px;
	text-align: center;
	font-weight: $font-weight-tag;

	&::before {
		display: none;
	}
}

.tag--bg-color {
	padding: 5px 10px;
	text-align: center;
	// background-color: $color-tag-bg;
	// color: $color-tag-bg-text;
	border-radius: var(--sleeve-tag-border-radius);
}

.tag--with-icon {
	display: flex;

	[class*="icon-"] {
		display: flex;
		align-items: center;
		margin-right: 5px;
		font-size: em(16px); // icon size
		font-weight: $font-weight-tag;
	}
}

.article-wrapper {
	p.caption {
		margin-top: 7px;
		margin-bottom: 0;
		overflow: auto;
	}
}

.date,
.last-updated,
p.author {
	font-weight: 500;
}

.tag {
	font-family: $primary-font;
	font-weight: $font-weight-tag;
}

p.caption {
	text-transform: unset;
	margin-top: 10px;
}

p.date,
p.category,
p.last-updated,
p.month,
.ellipsis .dropdown-toggle,
p.author {
	font-size: $small-font-size;
	line-height: $line-height-secondary;
}

figcaption.caption,
p.caption,
.author {
	font-size: $small-details-font-size;
	line-height: $line-height-secondary;
}

p.tag,
p.date,
figcaption.caption,
p.caption,
p.last-updated,
p.month,
.ellipsis .dropdown-toggle {
	font-family: $secondary-font;
}

p.category,
.ellipsis .dropdown-toggle {
	font-family: $primary-font;
	font-weight: $font-weight-tag;
}

.date,
p.category,
figcaption.caption,
p.caption,
p.last-updated,
.author {
	color: $grey-dark;
}

.tag--with-icon:not(.color-red) {
	span:not([class*="icon-"]) {
		color: $grey-dark;
	}
}

p.category,
p.month,
.ellipsis .dropdown-toggle,
span.tag {
	color: $color-tag;
}

p.tag,
p.date,
p.author,
p.category,
.ellipsis .dropdown-toggle {
	text-transform: $small-text-transform;
	margin: 0;
}

p.category.category--framed {
	background-color: $grey-dark;
	color: $white;
	padding: 2px 5px;
	margin-bottom: 5px !important;
	text-align: center;
}

// ellipsis dropdown
.ellipsis.dropdown {
	display: flex;

	&:hover {
		.dropdown-menu {
			display: block;
		}
	}

	.dropdown-toggle {
		padding: 0;
		letter-spacing: 2px;
    min-height: 24px; // Web accessibility
    min-width: 24px; // Web accessibility

		&:after {
			display: none;
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border-color: $grey-light;
		padding: 10px 15px;
		box-shadow: rgb(0 0 0 / 25%) 0 2px 4px 0;

		ul {
			@include list-unstyled;
			margin-bottom: 0;

			li {
				font-size: $small-font-size;
				line-height: $line-height-secondary;
				font-weight: $font-weight-tag;
				color: $color-tag;
				font-family: $primary-font;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}
