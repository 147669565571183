:root {
  --swiper-pagination-bullet-inactive-color: $white;
  --swiper-pagination-bullet-inactive-opacity: 0.85;
  --swiper-pagination-bottom: 20px;
  --swiper-navigation-size: 50px;
}

.swiper {
  &.swiper--pb {
    padding-bottom: 52px; // pb 30px + pagination height

    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }
  }

  .swiper-wrapper {
    z-index: 2;
  }

  .swiper-slide {
    height: auto;
    cursor: grab;

    .card,
    .info-box {
      height: 100%;
    }
  }

  // Swiper pagination
  .swiper-pagination {
    padding-right: calc(var(--col-padding) * 2);
    padding-left: calc(var(--col-padding) * 2);

    @include media-breakpoint-down(md) {
      bottom: var(--col-padding) !important;
      padding-right: var(--col-padding);
      padding-left: var(--col-padding);
    }

		.swiper-pagination-bullet {
      position: relative;
      min-width: 24px; // Web accessibility
      min-height: 24px; // Web accessibility
      background: transparent;
      margin: 0 !important;

      button {
        padding: 0;
        min-width: 24px; // Web accessibility
        min-height: 24px; // Web accessibility
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 12px;
        background: $black;
        border: 2px solid $black;
      }

			@include media-breakpoint-down(md) {
        &::after {
          height: 10px;
          width: 10px;
        }
			}
		}

    &.swiper-pagination--overlay {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      margin: 40px auto 0;
  
      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
  
      .swiper-pagination-bullet {
        &::after {
          background: $white;
          border: 2px solid $white;
        }
      }
    }
	}

  // Pagination active state
  .swiper-pagination,
  .swiper-pagination.swiper-pagination--overlay {
    .swiper-pagination-bullet-active {
      &::after {
        background: none;
      }
    }
  }

  // Swiper navigation
  .swiper-navigation {
    z-index: 2;
    position: absolute;
    left: 0;
    top: var(--swiper-navigation-top-offset, 50%);
    left: 50%;
    transform: translateX(-50%);
    height: var(--swiper-navigation-size);
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      content: "";
      width: 44px; // Web accessibility
      height: 44px; // Web accessibility
      background: $black;
      opacity: 0.2;
      border-radius: 50%;
      border: 1px solid $white;
    }

    &.arrow {
      width: 44px; // Web accessibility
      height: 44px; // Web accessibility

      &::before {
        content: "\e200"; // Fixed arrow icon
        font-size: 30px; // Fixed arrow icon size
        z-index: 2;
        color: $white;
        top: 50%;
      }
    }
  }

  .swiper-button-prev {
    left: calc(var(--col-padding) * 2);

    @include media-breakpoint-down(md) {
      left: var(--col-padding);
    }

    &.arrow::before {
      rotate: 180deg;
      left: 50%;
      transform: translate(50%, 52%);
    }
  }

  .swiper-button-next {
    right: calc(var(--col-padding) * 2);

    @include media-breakpoint-down(md) {
      right: var(--col-padding);
    }

    &.arrow::before {
      right: 50%;
      transform: translate(42%, -50%);
    }
  }

  img {
    height: 100%;
  }

  figcaption {
    p.caption {
      margin: 0;
    }
  }

  .gradient {
    z-index: 2;
    position: absolute;
    bottom: 0;
    pointer-events: none;
    height: 100px;
    width: 100%;
    background: linear-gradient(transparent, rgba(#000, .3));
  }
}

@import 'swiper--image';
