.header--double-logo {
  // non collapsed menu media queries 
  .header--double-logo-sidemenu:not(.collapsed-menu){
    .list-item-left-aligned-toggle {
      @include media-breakpoint-up(lg){
        right: -18px;
        top: 10px;
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0;
      }
  
      &:focus {
        @include media-breakpoint-up(lg){
          opacity: 1;
          height: 11px;
          width: 11px;
          margin-right: -16px;
          margin-left: 5px;
          padding-bottom: 20px;
        }
      }
  
      p {
        @include media-breakpoint-up(lg){
          top: -300px;
          right: 0;
        }
      }
    }
  
    .list-item-megamenu-toggle-icon{
      @include media-breakpoint-up(lg){
        border-width: 2px 2px 0 0;
      }
    }
  
    .link-container {
      @include media-breakpoint-up(lg) {
        box-shadow: var(--header-box-shadow);
        padding: 15px 30px 15px 30px;
        position: absolute; 
        min-width: 250px;
        top: 55px ;
        background-color: inherit;
        z-index: -1;
      } 

      .link-container__link {
        @include media-breakpoint-up(lg) {
          transition: ease 200ms;
          font-size: $header-font-size-3;
        }   
      }
    }
  
    .sidemenu-list-item {
      @include media-breakpoint-up(lg) {
        position: relative;
      }
  
      &.visible {
        .link-container {
          @include media-breakpoint-up(lg) {
            display: block;
            z-index: 1;
          }
        }
      }

    }
  }
  //end

  .list-item-left-aligned-toggle {
    width: 55px;
    padding: 15px;

    p {
      position: absolute;
      top: -1000px;
      right: -35px;
      width: 20px;
    }

    &.active div {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .list-item-megamenu-toggle-icon{
    content: "";
    display: inline-block;
    transition: all .2s ease-in-out;
    width: 9px;
    height: 9px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(135deg);

     @include media-breakpoint-up(lg){
      border-width: 2px 2px 0 0;
    } 
  }

  .link-container {
    padding: 10px;
    width: 100%;
    z-index: 1;
    left: -30px;
    border-radius: var(--sleeve-component-border-radius);

    li {
      margin-bottom: 0.6em;
    }

    .link-container__links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .settings-menu {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        &:first-of-type {
          margin-top: .6em;
        }

        .btn__toggle {
          margin-top: 15px;
          margin: 0;
        }
      }
    }
  }

  .sidemenu-list-item {
    position: unset;

    .link-container {
      display: none;

      &.visible {
        display: block;
        z-index: 1;
      }
    }
  }

  .list-item-megamenu-toggle-icon {
    border-color: var(--color-arrows);
  }

  .link-container__link {
    color: var(--color-body-text);

    &:hover {
      color: var(--color-link);
    }
  }
  
  .header--double-logo-sidemenu {
  	// component color theming: link container
    @include nestedComponentColors($themes) {
      .sidemenu-list-item {
        border-color: themed('text');
      }

      .list-item-megamenu-toggle-icon {
        border-color: themed('text');
      }

      .link-container__link{
        color: themed('text');
      }
    }
  }

  &.header__collapsed {
    .header--double-logo-sidemenu-list{
      min-height: 100vh;
      justify-content: flex-start !important;
    }

    .sidemenu-list-item {
      position: unset;
    }

    .link-container {
      left: -334px;
      top: 0;

      &.visible {
        display: block;
      }
    }
  }
}