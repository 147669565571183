/*
** Background colors components
**
** .bg--***** is used on the first level of the given component,
** to give it a background color
*/

main,
body {
	&[class^="list-icon-"],
	&[class*="list-icon-"] {
    @include globalComponentColors($themes) {
      .intext-arrow-link:before {
        color: themed('text');
      }
    }
  }

  &[class^="cta-icon-"],
	&[class*="cta-icon-"] {
    @include globalComponentColors($themes) {
      .arrow:before {
        color: themed('text');
      }
    }
  }
}

@include globalComponentColors($themes) {
	background-color: themed('background');
	border: none;

  /***** Headlines *****/
	h1,
	.h1,
  h1 strong,
  .h1 strong,
	h2,
	.h2,
  .h2--large,
	h3,
	.h3,
	h4,
	.h4,
  p.author,
  [class*="icon-"] {
		color: themed('text');
	}

  [class*="icon-"]::before{
		color: themed('text');
	}

  /***** Text *****/
	p,
  p span,
  span,
	li,
	a,
  label,
  legend,
  .ellipsis .dropdown-toggle,
  .tag,
  .label,
  .tag--with-icon:not(.color-red) span:not([class*=icon-]) {
		color: themed('text');
	}

  .tag span {
		color: themed('tag');
	}

  .tag.tag--outlined {
    border-color: themed('tag');
  }

  .label.label--outlined,
  .tag.tag--outlined,
  .tag--free-color.tag--outlined {
    border-color: themed('text');
  }

  .icon--inline-tag {
    &::before {
      background-color: themed('background');
      opacity: .5;
    }

    [class*=icon-]::before {
      color: themed('text');
    }
  }

  .tag--bg-color::before,
  .tag--bg-color--system::before {
    background-color: themed('text');
  }

  .tag--icon {
    background-color: themed('background');
    
    &:after {
      background-color: themed('tag');
      // opacity: .5;
    }

    [class*=icon-]::before {
      color: themed('tag') !important;
    }
  }

  .tags-container.with-divider {
    & > *:not(:first-child)::after {
      background: themed('text');
    }
  }

  p a:not(.list__link):not(.btn):not(.btn__link) {
    background-image: linear-gradient(themed('text'), themed('text'));
    color: themed('text');
    background-size: 100% 1px;
  }

  &.rich-text,
	.rich-text {
		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4 {
			color: themed('text');
		}

		ul li {
			color: themed('text');

			&::marker {
				color: themed('text');
			}
		}
	}

  /***** List links *****/
	.list__links {
		ul li a {
			color:  themed('text');

			&::before {
				border-color:  themed('text');
				color:  themed('text');
			}
		}
	}

  /***** List news *****/
  .list__news {
    .list__news__item { 
      border-bottom: 1px solid themed('text');

      &:hover {
        h3,
        .h3 {
          color: themed('text');
        }
      }
    }
  }

	/***** Arrows *****/
	.arrow {
		svg {
			path {
				fill: themed('text') !important;
			}
		}
	}

  /***** Buttons *****/
  .btn {
    color: themed('text');
  }

  .btn__link {
    color: themed('text') !important;

    &-arrow--long,
    &-arrow--short {
      color: themed('text') !important;

      &:hover,
      &:focus {
        color: themed('text');
      }

      .arrow:before {
        color: themed('text') !important;
      }
    }
  }

  .btn__primary,
  .btn__primary-arrow--long {
    background: themed('btn-primary-background');
    color: themed('btn-primary-text');
    border-color: themed('btn-primary-border');
    
    &:hover,
    &:focus {
      background: transparent;
      color: themed('text');
    }
  }

  .btn__component--outline,
  .btn__primary--outline,
  .btn__primary-arrow--long--outline {
    border-color: themed('btn-primary-border');
    color: themed('text');

    &:hover,
    &:focus {
      background: themed('btn-primary-border');
      color: themed('btn-primary-text');
    }
  }

  .btn__primary-arrow--long {
    &:hover,
    &:focus {
      .arrow:before {
        color: themed('text');
      }
    }

    .arrow:before {
      color: themed('btn-primary-text');
    }
  }

  .btn__primary-arrow--long--outline {
    &:hover,
    &:focus {
      .arrow:before {
        color: themed('btn-primary-text');
      }
    }

    .arrow:before {
      color: themed('text');
    }
  }

  .btn__component {
    .btn__component__text,
    [class*="icon-"]::before,
    .arrow:before {
      color: themed('btn-primary-text');
    }
  
    &:hover,
    &:focus {
      .btn__component__text,
      [class*="icon-"]::before,
      .arrow:before {
        color: themed('text');
      }
    }
  }

  .btn__component--outline {
    .btn__component__text,
    [class*="icon-"]::before,
    .arrow:before {
      color: themed('text');
    }
  
    &:hover,
    &:focus {
      .btn__component__text,
      [class*="icon-"]::before,
      .arrow:before {
        color: themed('btn-primary-text');
      }
    }
  }

  .btn__toggle {
    background-color: themed('btn-primary-text');
    border-color: themed('btn-primary-background');

    .btn__toggle__toggle {
      background: themed('btn-primary-background');
    }

    .btn__toggle__elements {
      [class*="icon"]::before {
        color: themed('btn-primary-background');
      }
    }
  }

  .dropdown-settings {
    p {
      color: themed('text');
    }

    .dropdown-settings__container {
      background-color: themed('background');

      li {
        border-color: themed('text');
      }
    }

    .dropdown-settings__toggle::before,
    [class*=icon-]::before {
      color: themed('icon');
    }
  }

  &.navbar__megamenu-extended {
    .btn__color-theme {
      @include media-breakpoint-up(lg) {
        background-color: themed('text');
      }

      .btn__color-theme__elements {
        [class*=icon-]::before {
          @include media-breakpoint-up(lg) {
            color: themed('background');
          }
        }
      }

      .icon-darkmode-moon,
      .icon-lightmode-sun {
        @include media-breakpoint-down(md) {
          color: $black;
        }

        @include media-breakpoint-up(lg) {
          color: themed('background');
        }
      }
  
      &.btn__color-theme--icons--sm {
        .icon-darkmode-moon,
        .icon-lightmode-sun {
          @include media-breakpoint-down(md) {
            color: $black;
          }

          @include media-breakpoint-up(lg) {
            color: themed('background');
          }
        }

        .icon-lightmode-sun::before {
          @include media-breakpoint-down(md) {
            color: $black;
          }

          @include media-breakpoint-up(lg) {
            color: themed('background') !important;
          }
        }
  
        .btn__color-theme__toggle {
          @include media-breakpoint-up(lg) {
            background-color: themed('background');
          }
        }
      }

      .icon-lightmode-sun::before {
        @include media-breakpoint-down(md) {
          color: $black;
        }

        @include media-breakpoint-up(lg) {
          color: $color-primary !important;
        }
      }

      .btn__color-theme__toggle {
        @include media-breakpoint-up(lg) {
          background-color: $color-primary;
        }
      }
      
    }
  }

  &.navbar-nav__secondary-container {
    .btn__color-theme {
      background-color: themed('text');
  
      &.btn__color-theme--icons--sm {
        .icon-darkmode-moon,
        .icon-lightmode-sun {
          color: themed('background');

          @include media-breakpoint-up(lg) {
            color: themed('background') !important;
          }
        }

        .icon-lightmode-sun::before {
          @include media-breakpoint-down(md) {
            color: $black;
          }

          @include media-breakpoint-up(lg) {
            color: $color-primary !important;
          }
        }
  
        .btn__color-theme__toggle {
          background-color: themed('background');
        }
      }
    }
  }

	/***** Breadcrumb *****/
	.breadcrumb {
		.breadcrumb-item {
      &.active {
        color: themed('grey-text');
      }

      svg path {
        fill: themed('grey-text');
      }

			a {
				color: themed('grey-text');

				&::before {
					border: 2px solid themed('grey-text');
					border-width: 2px 2px 0 0;
				}

        &:hover,
        &:focus {
          color: themed('link');
        }
			}
		}
	}

  &.card .breadcrumb-container {
    p:not(.status),
    ol li {
      color: themed('text');
    }

    .breadcrumb-item span svg path {
      fill: themed('text');
    }
  }

	/***** Secvice menu *****/
	&.service-menu {
    background: unset !important;

		.service-menu__item__icon {
			background: themed('background') !important;

			svg {
				path {
					fill: $white !important;
				}
			}
		}
	}

	/***** Info boxes *****/
	&.info-box {
		&__publications {
			ul li {
				border-top: 1px solid themed('text');
			}
		}

		.list__links {
			.list__link {
				&:before {
					border-color: themed('text');
				}
			}
		}

    [class^="icon-"],
    [class*="icon-"] {
      &::before {
        color: themed('text');
      }
    }

		.btn__link {
			color: themed('text') !important;
		}
	}

  &.info-box__links__icon__item {
    .info-box__links__icon__item__header,
    .info-box__links__icon__item__body {
      background-color: transparent;
    }

    [class^="icon-"],
    [class*="icon-"] {
      &::before {
        color: themed('text');
      }
    }

    button.js-toggle:after {
      @include media-breakpoint-down(md) {
        border-color: themed('text');
      }
    }
  }

  	/***** Link boxes *****/
	&.link-box {
		color: themed('text');
	}

  	/***** Cards *****/
	.card {
		&__landscape {
			border-top: 3px solid themed('text') !important;
		}

		&__image__content {
			background: $primary-gradient;

			h3,
			p {
				color: themed('text');
			}

			.arrow {
				svg path {
					fill: themed('text');
				}
			}
		}

		&__event {
			&__image {
				&__date {
					background: themed('background');

					.day,
					.month {
						color: themed('text');
					}
				}
			}

			&__text {
				ul li {
					border-top: 1px solid themed('text');
				}
			}
		}
	}

	/***** Hero *****/
	&.hero-inner {
		h1 {
			color: themed('text') !important;
		}

		p {
			color: themed('text') !important;
		}
	}

  &.hero__article__displaced {
    background-color: unset !important;

    .hero__article__media.postion-absolute {
      background-color: themed('text') !important;
    }
  }

	/***** Banners *****/
  &.banner__cta {
    [class*="icon-"] {
      color: themed('text');
    }
  }

	&.banner__alert {
		.banner__alert__content__icon {
			color: themed('text');
		}

		p a {
			color: themed('text');
			background-size: 100% 1px;
			background-image: -webkit-gradient(linear, left top, left bottom, from(themed('text')), to(themed('text')));
			background-image: linear-gradient(themed('text'), themed('text'));
		}

		button.banner__alert__close:before,
		button.banner__alert__close:after {
			background-color: themed('text');
		}
	}

  &.banner__cta {
    .umbraco-forms-form {
      background-color: themed('background');
    }
  }
 

  /***** Accordion mobile *****/
  &.accordion--mobile .accordion--mobile__toggle:after {
    border-color: themed('text');
  }
}

svg {
  @include nestedComponentColors($themes) {
    path {
      fill: themed('background');
    }
  }
}

body:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {  
  @include globalComponentColors($themes) {
    .grid-dynamic {
      p a:not(.list__link) {
        color: themed('text');
        background-size: 100% 1px;
        background-image: -webkit-gradient(linear, left top, left bottom, from(themed('text')), to(themed('text')));
        background-image: linear-gradient(themed('text'), themed('text'));
  
        &:hover,
        &:focus {
          color: themed('text');
        }
      }
    }
  }

  .banner__alert,
  .media-box,
  .media-box__text {
    @include nestedComponentColors($themes) {
      p a:not(.list__link) {
        color: themed('text');
        background-size: 100% 1px;
        background-image: -webkit-gradient(linear, left top, left bottom, from(themed('text')), to(themed('text')));
        background-image: linear-gradient(themed('text'), themed('text'));
  
        &:hover,
        &:focus {
          color: themed('text');
        }
      }
    }
  }

  /***** Section dividers *****/
  .line {
    @include nestedComponentColors($themes) {
      background-color: themed('background');
    }
  }
}

.bg--header {
  .btn__toggle {
    background-color: var(--color-header) !important;
    border-color: var(--color-header-text) !important;

    .btn__toggle__toggle {
      background: var(--color-header-text) !important;
    }

    .btn__toggle__elements {
      [class*="icon"]::before {
        color: var(--color-header-text) !important;
      }
    }
  }
}
