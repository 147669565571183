.video {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
    margin-right: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
  }

  .video__overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white; //- If overlay is PNG

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      border-radius: $border-radius-image;
    }

    &.hide {
      display: none;
    }
  }

  .video__overlay__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 60px;
    width: 60px;
  }

  video,
  iframe {
    border-radius: $border-radius-image;
  }

  video {
    width: 100%;
  }

  video::cue {
    z-index: 1000;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  p {
    @include media-breakpoint-down(sm) {
      padding-right: var(--col-padding-md-down);
      padding-left: var(--col-padding-md-down);
    }
  }

  &__article {
    height: 100%;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.24999999296875%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  &__twentythree {
    height: 100%;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.24999999296875%;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__youtube {
    height: 100%;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.24999999296875%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__dreambroker {
    height: auto;
		// min-height: 300px;
    width: 100%;
    position: relative;
    display: flex;
    // padding-bottom: 56.24999999296875%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  &__html {
    position: relative;
    height: auto;
    width: 100%;
    // height: 0;
    // padding-bottom: 56.24999999296875%;

    video {
      width: 100%;
    }

    .video__overlay{
      top: 0;
    }
  }

  .video__azuremediaplayer {
    position: relative;
    height: auto;
    width: 100%;
    padding-bottom: 56.24999999296875%;
		font-size: 500px!important;
    &.amp-default-skin {
			&:hover {
				.vjs-big-play-button {
					transform: translate(-50%, -50%) scale(1.15);
        }
      }

      .vjs-big-play-button {
				top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.azuremediaplayer {
		position: relative;
    height: auto;
    width: 100%;
    padding-bottom: 56.24999999296875%;
		font-size: 500px!important;

    &.amp-default-skin {
      &:hover {
        .vjs-big-play-button {
          transform: translate(-50%, -50%) scale(1.15);
        }
      }

      .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@import 'video-background/video-background';
