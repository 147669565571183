.side-menu--list__links {
  .btn__link,
  .list__links {
    margin-bottom: 30px;
  }

  .btn__link {
    font-size: $component-font-size;
  }

  .list__links {
    padding: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
