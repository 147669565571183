.list__downloads {
  ul {
    list-style-type: none;

    li {
      margin-bottom: 15px;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      .list__link, 
      .list__download {
        display: flex;
        flex-direction: row;
        font-size: $component-font-size;
        line-height: $line-height-secondary;
        font-weight: $font-weight-heading;
        transition: $transition;

        &:hover {
          color: $color-link;
        }
 
        p {
          font-size: $small-font-size;
          line-height: $line-height-secondary;
        }

        [class*="icon-"] {
          margin-right: 8px;
          padding-top: 5px;
          font-size: initial;

          &:before {
            font-weight: $font-weight-heading;
            font-size: em(22px); // icon size
          }
        }

        .list__content {
          display: flex;
          flex-direction: column;
        }

        .list__link__tags,
        .list__download__tags {
          display: flex;

          p {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
