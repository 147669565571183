/**
 * Colors
 */

$white: #fff;
$black: #000;
$grey: #999;
$grey-light: #e5e9ec;
$grey-medium: #8a8a8a;
$transparent: transparent;

$success-green: #008a15;
$error-red: #e6142c;
$warning-orange: #c75000;
