/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Moduels: components & elements
 */

.info-box,
.info-box__links__icon__item,
.info-box__links__icon__item .info-box__links__icon__item__header,
.info-box__links__icon__item .info-box__links__icon__item__body,
.card:not(.card__list):not(.card__landscape),
.link-box__icon,
.link-box__arrow,
.link-box__article,
.media-box--equal-heights:not(.bg--transparent),
.media-box--displaced .media-box__text,
.media-box--full-width:not(.has-overlay) .media-box__text,
.media-box--text-box .media-box__text,
.events,
.chat .chat__post,
.chat .chat__feed-container,
.tab-list--scrollable,
.profile,
.profile-text {
  background-color: var(--darkmode-700) !important;
}
