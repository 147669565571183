/*
** Article anchor menu
*/

.article-anchor-menu {
	display: none;
  position: relative;
  padding-top: 15px;
  padding-bottom: 25px;
  border-top: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;
  
  @include media-breakpoint-down(sm) {
    padding-top: 20px;
  }

	h2 {
    margin-bottom: 15px;
    @include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
    line-height: $line-height-primary;
	}

	ol {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    color: $color-link;
    font-weight: 600;

		li,
		a {
      font-size: $article-font-size-lg;
      line-height: $line-height-article-body;

			&:last-child {
				margin-bottom: 0;
			}
    }

    li {
      position: relative;
      display: flex;
      margin-bottom: 5px;
      padding-left: 25px;

      &::before {
        content: counter(list-item) '.';
        counter-increment: list-item;
        position: absolute;
        left: 0;
        top: 0;
        font-weight: $font-weight-heading;
      }
      
      a {
        // Why is the a tag display flex? Commented out 19/1 2024 because of conflicts with adgang med tegn. Can be removed if no conflicts after deploy
        /* display: flex; */
        color: $color-link;
        font-weight: 500;
        
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
	}
}
