.link-boxes .link-box__icon, 
.link-boxes .link-box__arrow {
  @include nestedComponentColors($themes) {
    background-color: ('background');
    color: themed('text');

    .link-box__icon__image:before,
    .arrow:before,
    .icon-download:before {
      color: themed('text');
    }
  }

  @include nestedArticleColors($themes-article) {
    background-color: themedArticle('background');
    color: themedArticle('text');

    .link-box__icon__image:before,
    .arrow:before,
    .icon-download:before,
    .icon-new-window-arrow:before {
      color: themedArticle('text');
    }
  }
}
