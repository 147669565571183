.link-boxes__medicindk {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &.below-accordion-medicindk {
    margin-top: -17px;
  }

  .link-box__medicin:first-child {
    margin-right: 2%;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }

  .link-box__medicin {
    background-color: $grey-light;
    border-top: 1px solid $divider-color;
    width: 49%;
    display: flex;
    align-items: center;
    padding: 6px;
    height: 60px;
    margin-bottom: 13px;
  
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 15px;
    }
  }
}
