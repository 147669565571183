section.banners__alert {
  z-index: 1;
  position: absolute;
  width: 100%;
}

section.banner__alert {
  position: sticky;
}

.banner__alert {
  z-index: 1;
  display: none;
  width: 100%;
  background-color: #ffdc60;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);

  .banner__alert__content {
    display: flex;
    justify-content: center;
    padding: 15px 40px 15px 0;
  }

  .banner__alert__content__icon {
    margin-right: 20px;
    

    &[class*="icon-"] {
      font-size: calc($component-font-size + em(16px)); // icon size

      &::before {
        color: $black;
      }
    }

    img,
    svg {
      width: 35px;
      height: auto;
      max-height: 35px;
    }

    img {
      transform: unset;
      object-fit: contain;
    }
  }

  .banner__alert__content__text {
    display: flex;
  }

  p {
    margin: auto 0; //- delete after rich-text is wrapped around text
    font-size: $component-font-size;
    line-height: $line-height-secondary;

    &.h2,
    &.h3,
    &.h4 {
      margin-bottom: 10px;
      line-height: $line-height-primary;
    }

    &.h2 {
      @include responsive-font-size($h2-font-size-sm, "", $h2-font-size-sm, "", $h2-font-size-lg);
    }

    &.h3 {
      @include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
    }

    &.h4 {
      @include responsive-font-size($h4-font-size-sm, "", $h4-font-size-sm, "", $h4-font-size-lg);
    }

  }

  .rich-text {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      margin: 0; //- delete after rich-text is wrapped around text
    }
  }

  button.banner__alert__close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 24px;
    width: 24px;

    &:before, 
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 11px;
      height: 16px;
      width: 2px;
      background-color: $black;
      transform: translateY(-50%);
    }

    &:before {
      transform:  translateY(-50%) rotate(45deg);
    }

    &:after {
      transform:  translateY(-50%) rotate(-45deg);
    }
  }

  @include nestedComponentColors($themes) {
    .banner__alert__content__icon {
      &[class*="icon-"] {
        &::before {
          color: themed('icon');
        }
      }
    }
	}
}
