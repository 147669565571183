/**
 * Document defaults (html, body)
 */

html {
	font-family: $secondary-font;
	font-size: $font-size-base;
	background: $color-body;
	line-height: $line-height-base;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
	transition: opacity 1s ease;
}
