.medicindk {
  padding: 45px 30px 25px 30px;
  background-color: $white;

  @include media-breakpoint-down(md) {
    padding: 30px 15px;
  }

  h2 {
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  .medicindk__filtering {
    .search-bar--small form .form-group input,
    .select {
      border: 1px solid $grey-dark;
    }
  
    .select {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
  
      select {
        background: transparent;
      }
    }
  
    ul.filter-search__filter__alphabet {
      @include list-unstyled;
      display: flex;
      justify-content: space-between;
      padding: 20px 0 20px 0;
      
      @include media-breakpoint-down(md) {
        position: relative;
        flex-wrap: wrap;
        justify-content: inherit;
        padding: 20px 0 20px 60px;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
  
      button {
        padding: 4px 6px;
        color: $black;
        background-color: transparent;
        opacity: 0.2;
        cursor: auto;
        font-weight: $font-weight-heading;
        transition: $transition;
  
        &.active,
        &.chosen {
          opacity: 1;
          cursor: pointer;
        }

        &.active {
          &:hover {
            color: $color-primary;
          }
        }
  
        &.chosen {
          color: $color-primary-text;
          background-color: $color-primary;
        }
      }
    }
  }
}

.article-wrapper,
[data-creative~="article-content"] {
  .medicindk {
    padding: 0;

    .medicindk__filtering {
      background-color: $grey-light;
      padding: 30px;
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        padding: 20px 15px;
        margin-bottom: 30px;
      }

      select {
        background: $white;
      }

      .filter-search__filter__alphabet {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
