.list__links,
.list__arrows {
	height: auto;

	@include media-breakpoint-down(md) {
		min-height: auto;
	}

  h2,
	h3,
  .h3,
  h4,
  .h4 {
		padding-bottom: 15px;
		margin-bottom: 0;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;

    &.ul--flex-wrap {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    
		li {
			position: relative;
      display: flex;
      margin-bottom: 15px;
			padding-left: 10px;
			margin-left: 15px;
			line-height: $line-height-secondary;
			text-align: left;

			&:last-of-type {
				margin-bottom: 0;
			}

			&.list__btn {
				padding-left: 0;
				margin-left: 0;
			}

      // Remove when - Single arrow link has been outfased
      a.list__link {
        cursor: pointer;
        position: unset;
        padding-left: 0;
        margin-left: 0;
      }
		}
	}
}
