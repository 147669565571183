/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Heros
 */

.hero .hero-inner,
.hero .hero-inner[class*="bg-"],
.hero__article[class*="bg-"] {
  background: var(--darkmode-800);
  color: var(--darkmode-50);
}

.hero__search-bar .search-bar--small form .form-group .button-highlighted {
  background-color: var(--darkmode-hightlight-1-200);

  svg path {
    fill: var(--darkmode-900);
  }
}

.hero__text-box {
  .container {
    @media only screen and (max-width: 991.98px) {
      background: var(--darkmode-800);
    }
  }
}

.hero__multi-boxes
  .hero__multi-boxes__box
  .hero__multi-boxes__box__text {
    h2,
    h2 p,
    p {
      color: var(--darkmode-50) !important;
    }

    .hero__multi-boxes__box__overlay {
      background-color: var(--darkmode-800) !important;
    
      &.article-element-bg--0,
      &.article-element-bg--1,
      &.article-element-bg--2,
      &.article-element-bg--3,
      &.article-element-bg--4,
      &.article-element-bg--5 {
        background-color: var(--darkmode-900) !important;
      }
    }
  }

.hero__event {
  background-color: var(--darkmode-900);
}

.hero__article {
  &[class*="article-element-bg--"] {
    p {
      color: var(--darkmode-50);
    }
  }
}
