.hero__filter {
	height: 450px;
	align-items: flex-end !important;
	@include responsive-space('mb', var(--section-margin-bottom-sm--large) !important, var(--section-margin-bottom-sm--large) !important, var(--section-margin-bottom-md--large), '', '');

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		height: auto;
		background: $white;
	}

	.bg-image {
		@include media-breakpoint-down(md) {
			position: relative !important;
			height: 160px !important;
		}

		img {
			@include media-breakpoint-down(md) {
				position: relative !important;
			}
		}
	}

	.hero-inner {
		&.hero__filter__inner {
			max-width: 100%;
			border-radius: var(--sleeve-component-border-radius);

			@include media-breakpoint-up(lg) {
				padding: 25px 30px;
				margin-bottom: -40px;
				max-width: 100%;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: -140px;
			}

			@include media-breakpoint-down(md) {
				padding: 25px 0;
				margin-bottom: 0;
			}

			.hero__filter__inner__content {
				display: flex;

				@include media-breakpoint-down(md) {
					flex-wrap: wrap;
				}

				&:first-of-type {
					margin-bottom: 30px;

					@include media-breakpoint-down(md) {
						flex-direction: column;
					}
				}

				&:last-of-type {
					justify-content: space-between;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
					}
				}
			}

			.hero__filter__form-groups {
				display: flex;

				@include media-breakpoint-down(md) {
					width: 100%;
					margin-bottom: 30px;
				}

				.form-date {
					position: relative;
					display: flex;
					flex-direction: column;
					margin-right: 20px;
					width: 50%;

					@include media-breakpoint-down(sm) {
						margin-right: 0;
					}

					&:first-of-type {
						@include media-breakpoint-down(sm) {
							padding-right: 10px;
						}
					}

					&:last-of-type {
						@include media-breakpoint-down(md) {
							margin-right: 0;
						}

						@include media-breakpoint-down(sm) {
							padding-left: 10px;
						}
					}

					input {
						border-radius: var(--sleeve-component-border-radius);
					}
				}
			}

			.search-bar--small {
				height: 100%;
				width: 100%;

				@include media-breakpoint-down(md) {
					width: 100%;
				}

				form .form-group {

					input,
					label {
						cursor: inherit;
						color: $grey-medium;
					}

					input {
						padding: 0 0 0 25px;
						background-color: $grey-light;
						border-radius: $border-radius-search-bar-small;
					}

					label {
						left: 25px;
					}
				}

				button {
					display: flex;
					justify-content: center;
					right: 5px;
					height: 60px;
					width: 60px;
          color: var(--color-btn-primary-text);
					background-color: var(--color-btn-primary);
					border-radius: $border-radius-search-bar-small;

					svg path {
						fill: var(--color-btn-primary-text);
					}
				}
			}

			.hero__filter__checkboxes {
				display: flex;
				flex-wrap: wrap;
				width: 60%;

				@include media-breakpoint-down(lg) {
					width: 100%;
				}

				@include media-breakpoint-down(lg) {
					margin-bottom: 20px;
				}

				.form-check {
					padding-right: 40px;
					margin-bottom: 10px;

					@include media-breakpoint-down(sm) {
						flex: 1 1 50%;
					}

					@include media-breakpoint-up(md) {
						width: calc(100%/3 - 10px);
					}

					@include media-breakpoint-up(lg) {
						width: calc(100%/4 - 10px);
					}

					&:nth-child(4n) {
						@include media-breakpoint-up(md) {
							padding-right: 0;
						}
					}

					&:nth-child(2n) {
						@include media-breakpoint-down(md) {
							padding-right: 0;
						}
					}

					.checkbox {
						padding-left: 30px;
						font-size: $small-details-font-size;

						input {
							height: 15px;
							width: 15px;
							top: 2px;
							left: 2px;

							&:disabled~.checkbox__indicator {
								&:after {
									border-color: $grey-medium;
								}
							}
						}

						.checkbox__indicator {
							height: 20px;
							width: 20px;
							border: 1px solid $grey-medium;
							border-radius: var(--sleeve-checkbox-border-radius);

							&:after {
								left: 7px;
								top: 3px;
								width: 6px;
								height: 10px;
							}
						}
					}
				}
			}

			.btn-container {
				margin-top: 0;

				@include media-breakpoint-up(lg) {
					flex-direction: column;
				}

				.btn {
					margin-right: 0;
          font-size: $small-details-font-size;

					@include media-breakpoint-down(md) {
						width: auto;
            margin-right: 20px;
					}

					@include media-breakpoint-down(sm) {
						margin: 0;
						width: calc(50% - 10px);
					}

					&:first-of-type {
						@include media-breakpoint-up(lg) {
							margin-bottom: 10px;
						}
					}

					&:last-of-type {
						@include media-breakpoint-down(md) {
							margin-right: 0;
						}
					}

					&.btn__primary__icon {
						min-height: 40px;
						padding-top: 10px;
						padding-bottom: 10px;

						object {
							width: 19px;
						}

						object,
						svg {
							path {
								fill: $grey-medium;
							}

							rect {
								fill: $grey-medium;
              }
              
              &.svg-time {
                path {
                  stroke: $grey-medium;
                }
  
                circle {
                  stroke: $grey-medium;
                }
              }
						}
					}
				}
			}
		}
	}
}
