/*
** Accordion form
*/

.accordion__form { 
	.accordion__item {
		&.active {
			.accordion-header {
				padding-left: 40px;
				background: $white;

				@include media-breakpoint-down(sm) {
					padding-left: 15px;
				}

				&:after {
					top: 40px;
					transform: rotate(-45deg);
				}
			}
		}

		&:last-child {
			border-bottom: unset;
		}

    button.accordion-header {
      text-align: left;
    }

		.accordion-header {
      display: flex;
      flex-direction: column;
      align-items: unset;
			padding: 30px 60px 30px 40px;
			background: $white;
      border-top-width: var(--component-border-top-width);
      border-top-style: solid;
      border-top-color: var(--color-border-top--article-components-bg);

			@include media-breakpoint-down(sm) {
				padding: 30px 35px 30px 15px;
			}

			&::before {
				display: none;
			}

			&:after {
				position: absolute;
				content: "";
				display: flex;
				right: 42px;
				top: 35px;
				transition: all .2s ease-in-out;
				width: 9px;
				height: 9px;
				border-style: solid;
				border-color: $color-icons;
				border-width: 2px 2px 0 0;
				transform: rotate(135deg);

				@include media-breakpoint-down(sm) {
					right: 17px;
				}
      }
      
			&.success {
				&:after {
					border-color: transparent;
				}
      }
      
      h3,
      .h3 {
				margin-bottom: 0;
        @include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
        line-height: $headings-line-height;
      }
      
      p {
        font-size: $component-font-size;
        line-height: $line-height-secondary;

        &:first-of-type {
          margin-top: 10px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
		}

		.accordion-body__contents {
      background: $white;
      padding: 0 40px 55px 40px;

      @include media-breakpoint-down(sm) {
        padding: 0 15px 30px 15px;
      }

      &::after {
        display: none;
      }
		}

		form {
			background: $white;

      h3,
      .h3 {
				margin-bottom: 30px;
			}

      .form-group input,
      .form-group textarea,
			.form-group select {
				background: $grey-light;
				border: none;
			}

      .btn__submit:not(input) {
        color: var(--color-btn-bg--grey-light-text);

        &:after {
          background: var(--color-btn-bg--grey-light);
        }
      }
		}
	}
}
