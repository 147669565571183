/*
 * Side boxes
 */

.article-wrapper,
[data-creative~="article-content"] {
  .card__cta.side-box {
    background: $grey-light;
    border: none;
  }
}

.article-wrapper,
[data-creative~="article-content"] {
  .side-box .rich-text {
    p.intext-arrow-link {
      @include responsive-font-size($component-font-size, "", $component-font-size, "", $component-font-size);
      line-height: $line-height-secondary;
    }
  }
}
