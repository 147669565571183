/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Loader
 */

.loader-screen {
  background-color: var(--darkmode-900);
}

.lds-default div {
  background: var(--darkmode-hightlight-1-200);
}
