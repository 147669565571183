.filter-menu.filter-menu__search {
  .filter-menu__items {
    width: 100%;

    .select {
      margin-bottom: 0;
      
      @include media-breakpoint-up(md) {
        height: 60px;
      }

      select {
        @include media-breakpoint-up(md) {
          height: 100%;
        }
      }
    }
  }

  &.row-reverse {
    .row {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .filter-menu__items {
    .search-bar--small form .form-group {
      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }

      input {
        height: 60px;
        padding: 0 20px 0 25px;
      }

      label,
      input {
        font-size: $component-font-size;
        line-height: $line-height-secondary;
      }
    }
    
    .select {
      width: 100%;

      &:after {
        right: 25px;
      }

      select {
        padding: 5px 55px 5px 20px;
      }
    }
  }
}
