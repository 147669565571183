/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Search bars
 */


.search-bar--small form .form-group {
  input {
    border: 1px solid var(--darkmode-600);
    background: var(--darkmode-700);
    color: var(--darkmode-50);

    &::placeholder {
      color: var(--darkmode-50);
    }
  }

  button {
    color: var(--darkmode-50);

    &.btn--filled {
      background-color: var(--darkmode-hightlight-1-200);
    }

    svg path {
      fill: var(--darkmode-hightlight-1-200);
    }
  }
}

.media-box.has-searchbar:not([class*="bg-"]) .search-bar--small form .form-group input {
  border: 1px solid var(--darkmode-500) !important;
  background: var(--darkmode-600) !important;
}

.suggestions--autocomplete {
  background-color: var(--darkmode-700);
  
  li a:hover, 
  .suggestions--autocomplete li a.highlighted {
    background-color: var(--darkmode-600);
  }

  li button,
  li a {
    color: var(--darkmode-50);
  }
}