/**
 * Input
 */

input {
  color: $grey-dark;

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder {
    color: $grey-dark;
  }
}

.form-date {
	label {
		position: absolute;
		padding: 10px 10px 6px 10px;
		font-size: $small-font-size;
	}

  input[type="date"] {
    height: 70px;
    min-height: 70px;
    border: 1px $grey-medium solid;
    padding: 10px;
    padding-top: 30px;
    -webkit-appearance: none;
  }
}

input[type="date"] {
  -webkit-appearance: none;
  background-color: $white;

  &::-webkit-input-placeholder {
    color: $grey-dark;
    font-family: $secondary-font;
  }

  &::-webkit-datetime-edit {
    padding: 14px 10px 15px 0;
  }

  &::-webkit-datetime-edit-text,
  &::-webkit-date-and-time-value,
  &::-webkit-datetime-placeholder,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-year-field {
    color: $grey-dark;
  }

  &::-webkit-calendar-picker-indicator {
    color: $grey-dark;
    padding: 14px 10px 15px 0;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &:not(.has-value):before {
    @include media-breakpoint-down(sm) {
      color: $grey-dark;
      content: attr(placeholder);
      width: 100%;
    }
  }
}

.form-group input[type="date"] {
  position: relative;
  border: none;
  padding-left: 15px;
  height: 50px;

  &::-webkit-datetime-edit { 
    padding: 5px 35px 5px 0;
    cursor: pointer;
    font-weight: $font-weight-body;
  }

  &::-webkit-calendar-picker-indicator { 
    z-index: 1;
    padding: 5px 16px 5px 5px;
    opacity: 0;
    cursor: pointer;
  }

  &:after {
    z-index: 0;
    position: absolute;
    content: "";
    right: 15px;
    top: 49%;
    width: 9px;
    height: 9px;
    border: 2px solid #000;
    border-width: 2px 2px 0 0;
    -webkit-transform: translate(0, -50%) rotate(135deg);
    transform: translate(0, -50%) rotate(135deg);
  }
}

//- input[type=file]
input[type=file] {
	font-size: $small-font-size;

  &:hover {
    &::-webkit-file-upload-button {
      background-color: transparent;
      color: $black;
    }
  }

	&::-webkit-file-upload-button {
    border-color:var(--color-btn-bg--grey-light);
    border-width: var(--btn-border-width);
    border-style: solid;
    background-color: var(--color-btn-bg--grey-light);
    color: var(--color-btn-bg--grey-light-text);
    padding: calc(var(--sleeve-btn-padding-x)/2) calc(var(--sleeve-btn-padding-y)/2);
    font-size: $small-font-size;
    font-weight: var(--btn-font-weight);
    font-family: var(--font-primary);
    border-radius: var(--sleeve-button-border-radius);
    transition: $transition;
    cursor: pointer;
	}
}

.file-upload {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: fit-content;
	padding: var(--sleeve-btn-padding-y) var(--sleeve-btn-padding-x);
	background: $color-secondary;
	color: $btn-secondary-text-color;
	font-family: $btn-font-family;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	border: none;
	border-radius: var(--sleeve-button-border-radius);
	transition: $transition;

	&:hover {
		opacity: 0.8;
	}
}

.form-file {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
  }
  
  input[type=file] {
    width: 220px;
    // padding: 7px 15px 5px 15px;
    // border-color: var(--color-btn-primary);
    // border-width: var(--btn-border-width);
    // border-style: solid;
  }

  .thumbs {
    display: flex;
    flex-wrap: wrap;

    .thumb {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .thumb {
    position: relative;
    margin-top: 10px;
    width: 100px;

    button {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: $white;
      color: $black;
      padding: 2px;
      border-radius: 100%;
      transition: $transition;
      font-size: $small-font-size;
      font-weight: 900;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }
}
