//Bypass Block for web accessibility - Skip navigation links
.bypass-block {
  left: -999px;
  position: absolute;
  top: auto;
  width: auto;
  height: auto;
  min-width: 44px; // Web accessibility
  min-height: 44px; // Web accessibility
  overflow: hidden;
  z-index: -999;

  &:focus,
  &:active {
    top: 0;
    left: auto;
    color: $black;
    background-color: $white;
    width: auto;
    height: auto;
    overflow: auto;
    padding: 10px 50px;
    border: 2px solid $color-primary;
    text-align: center;
    z-index: 999;
  }
}
