/**
 * Footer sub
 */

.footer__sub {
  padding: 40px 0;

  .footer__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    p, 
    ul, 
    ul li,
    ul:last-child {
      margin-bottom: 0;
    }

    .list__icons ul li {
      padding-bottom: 0;
    }

    p {
      margin-bottom: 0 !important;
    }

    .content {
      margin-right: 25px;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.footer .footer__sub {
  .footer__content {
    flex-direction: row !important;

    .rich-text p,
    ul li {
      margin-bottom: 0 !important;
    }
  }
}
