.events {
	background-color: $white;
	border-radius: var(--sleeve-component-border-radius);

	.events__image {
		height: 100%;

		img {
			max-width: 100%;
			height: 100%;
			border-top-left-radius: var(--sleeve-component-border-radius);
			border-bottom-left-radius: var(--sleeve-component-border-radius);

			@include media-breakpoint-down(sm) {
				border-top-right-radius: var(--sleeve-component-border-radius);
				border-bottom-left-radius: 0;
			}
		}
	}

	.events__list {
		padding: 30px 30px 30px 0;

		@include media-breakpoint-down(sm) {
			padding: 30px 30px 0 30px;
		}

		.headline {
			h3 {
				@include media-breakpoint-down(sm) {
					margin-bottom: 20px !important;
				}
			}
		}

		.list {
			ul li {
				position: relative;
				margin-bottom: 30px;
				padding-right: 40px;

				&:hover {
					.arrow {
						margin-right: 10px;
					}
				}

				.label {
					margin: 5px 0;
				}

				h4 {
					margin-top: 10px;
					margin-bottom: 10px;
				}

				p {
					margin-bottom: 10px;
					font-size: $component-font-size;
					line-height: $line-height-secondary;
				}

				.arrow {
					position: absolute;
					right: 0;
					bottom: 0;
					transition: $transition;
				}
			}
		}
	}
}
