/**
	* Padding
	* Classes that can be used to create padding
	*/

.pt--100 {
  padding-top: 100px;
}

.pt--40 {
  padding-top: 40px;
}

.pb--100 {
  padding-bottom: 100px;
}

//- Padding top - Section padding
.pt--section-padding {
	@include responsive-space("pt", var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), "", "");
}

//- Padding bottom - Section padding
.pb--section-padding {
	@include responsive-space("pb", var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), "", "");
}

//- Section padding - used for section with background-colors
.section-padding {
	@include responsive-space("pt", var(--bg-section-padding-sm-top), var(--bg-section-padding-sm-top), var(--bg-section-padding-md-top), "", "");
	@include responsive-space("pb", var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), "", "");
}

//- Component padding
.c-padding {
  padding: var(--sleeve-component-padding);
}

//- Padding top - small
.pt--small {
	@include responsive-space("pt", 30px, 30px, 60px, "", "");
}

// Frames
.framed--components {
  border-radius: var(--sleeve-component-border-radius);
  padding: var(--sleeve-component-padding);

  @include media-breakpoint-down(sm) {
    padding: var(--col-padding-md-down);
  }
}

.framed--100-40 {
  padding: 100px 40px;
  background-color: $white;
  border-radius: var(--sleeve-component-border-radius);

  &.container {
    width: calc(100% - 80px);

    @include media-breakpoint-down(sm) {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}
