&.form-container {
  .form-sub-response {
    .checkmark-icon svg {
      height: 50px;
      width: 50px;
  
      circle {
        fill: $white;
      }
    }
  
    .modal-title {
      font-size: calc(.0625em*var(--h3-font-size-lg));
      margin-bottom: 10px;
    }
  }
}
