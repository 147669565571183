.fact-box {
	position: relative;

	&.fact-box__no-title {
		.fact-box__content:after {
			border-radius: var(--sleeve-component-border-radius);
		}

		.fact-box__text {
			&:after {
				border-radius: var(--sleeve-component-border-radius);
			}
		}

    @include nestedArticleColors($themes-article) {
      .fact-box__content {
        &:after {
          opacity: 1;
        }

        .rich-text {
          h2,
          h3,
          h4,
          h5,
          p,
          ul li,
          ol li,
          ul li::marker,
          .intext-arrow-link:before  {
            color: themedArticle('text');
          }
        }
      }

      .btn__component {
        &:focus,
        &:hover {
          color: themedArticle('text');
      
          [class*="icon-"]:before {
            color: themedArticle('text');
          }
          
          &[class*="article-element-bg--"] {
            color: themedArticle('text');
      
            [class*="icon-"]:before {
              color: themedArticle('text');
            }
          }
        }
      }

      .read-more-button {
        .read-more-button__text {
          color: themedArticle('text');
        }
  
        .read-more-button__icon {
          stroke: themedArticle('text');
        }
      }
    }
	}

	&:not(.fact-box--collapsible):not(.fact-box__no-title){
		.fact-box__content::after {
			border-radius: 0 0 var(--sleeve-component-border-radius) var(--sleeve-component-border-radius);
		}
	}

	.fact-box__title__icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 45px;
		width: 45px;
		min-width: 45px;
		background-color: $white;
		border-radius: 50%;
		transition: $transition;
		margin-right: 20px;

		&[class^='icon-'],
		&[class*='icon-'] {
			color: $black;
			font-size: em(25); // Icon size
		}

		&::before {
			z-index: 1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $article-element-bg--default;
			opacity: 0.5;
			border-radius: 50%;
		}
	}

	.fact-box__title {
		display: flex;
		width: 100%;
		padding: 20px 30px;
		background: $article-element-bg--default;
		align-items: center;
		border-radius: var(--sleeve-component-border-radius) var(--sleeve-component-border-radius) 0 0;

		@include media-breakpoint-down(sm) {
			padding: 20px 15px;
		}

		h2 {
			margin-bottom: 0;
		}
	}

	.fact-box__content {
		margin-bottom: 30px;
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 30px;

		@include media-breakpoint-down(sm) {
			padding: 15px;
		}

		// background color
		&:after {
			z-index: 0;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0.5;
			background: $article-element-bg--default;
			width: 100%;
			height: 100%;

			@include media-breakpoint-up(sm) {
				border-radius: 0 0 var(--sleeve-component-border-radius) var(--sleeve-component-border-radius);
			}
		}

    .content,
		.rich-text,
		.btn,
		.btn__component-container {
			z-index: 1;
      position: relative;
			margin: 0;
			overflow: hidden;
			transition: max-height 0.5s ease-out;
		}

    .btn.btn__component {
      transition: $transition-base;
    }

		.rich-text {
			p,
			ul li,
			ol li {
				font-size: $component-font-size;
				line-height: $line-height-secondary;
			}

      ul,
      ol {
        font-size: initial;
      }
		}
	}

  @include nestedArticleColors($themes-article) {
    &.fact-box--collapsible.fact-box__no-title {
      .read-more-button {
        &:after {
          opacity: 1;
        }
      }
    }

    .fact-box__title__icon {
      &:after {
        display: flex;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        transition: $transition;
        border-radius: 50%;
        background: themedArticle('background');
      }
    }

    .fact-box__title {
      background: themedArticle('background');
  
      h2 {
        color: themedArticle('text');
      }
    }
  
    .fact-box__content,
    .read-more-button {
      &:after {
        background: themedArticle('background');
      }
    }

    .table-hours {
      .status-indicator-open, 
      .status-indicator-closed, 
      tr.inactive th, 
      tr.inactive td,
      .status-text {
        color: $black;
      }
    
      .status-indicator {
        border: 2px solid $black;
      }
    }

    &.fact-box__no-title {
      .table-hours {
        th, 
        tr, 
        td, 
        .status-indicator-open, 
        .status-indicator-closed, 
        tr.inactive th, 
        tr.inactive td,
        .status-text {
          color: themedArticle('text');
        }
      
        .status-indicator {
          border: 2px solid themedArticle('text');
        }
      }
    }
  }
}

/* Fact box styles in mobile only collapsible */
.fact-box--no-collapse-desktop {
	@include media-breakpoint-up(md){
		&.fact-box__no-title {
			.fact-box__content::after {
				border-radius: var(--sleeve-component-border-radius);
			}
		}

		&:not(.fact-box__no-title){
			.fact-box__content::after {
				border-radius: 0 0 var(--sleeve-component-border-radius) var(--sleeve-component-border-radius);
			}
		}
	}
}

/* Fact styles box in collapsible version */
.fact-box--collapsible:not(.fact-box--no-collapse-desktop){
	@include media-breakpoint-up(md){
    margin-bottom: calc(var(--article-wrapper-c-mb) + 36px) !important;
	}
  
	.fact-box__content:after {
		border-radius: 0px;
	}
}

.fact-box--collapsible {
  &.fact-box__no-title {
		.fact-box__content:after {
			border-radius: var(--sleeve-component-border-radius);

      @include media-breakpoint-down(sm) {
				border-radius: var(--sleeve-component-border-radius) var(--sleeve-component-border-radius) 0 0;
			}
		}

		.fact-box__text {
			&:after {
				border-radius: var(--sleeve-component-border-radius);
			}
		}
	}

  &[x-data="collapseBox"]:not(.fact-box--no-collapse-desktop) {
    .fact-box__content:after {
			border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
		}

    .read-more-button:after {
      border-radius: 0 0 var(--sleeve-component-border-radius) var(--sleeve-component-border-radius);
    }
  }

	.fact-box__content {
		@include media-breakpoint-down(sm) {
			margin-bottom: 64px;
		}

    &::after {
      @include media-breakpoint-down(sm) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

		.closed {
      z-index: 1;
			-webkit-mask-image: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 0.9)),
				to(rgba(0, 0, 0, 0))
			);
		}
	}

	// Button element
	.read-more-button {
		//display: none;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 100%;
		cursor: pointer;
		padding-bottom: 15px;
		width: 100%;
    min-height: 44px;
		text-align: center;
		color: $black;

		&:focus {
			-webkit-tap-highlight-color: transparent;
			outline: none;
			-ms-touch-action: manipulation;
			touch-action: manipulation;
		}

		// Background-color
		&:after {
			z-index: 0;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0.5;
			background: $article-element-bg--default;
			width: 100%;
			height: 100%;

			@include media-breakpoint-down(sm) {
				border-radius: 0 0 var(--sleeve-component-border-radius)
					var(--sleeve-component-border-radius);
			}
		}

		.read-more-button__icon {
			z-index: 1;
			width: 20px;
			transition: transform 0.5s ease;
		}

		&.arrowUp {
			.read-more-button__icon {
				transform: rotate(180deg);
				transition: transform 0.5s ease;
			}
		}

		.read-more-button__text {
			z-index: 1;
			font-weight: $btn-font-weight;
			margin-right: 10px;
		}
	}
}
