/**
 * Titles
 */

 .title {
  display: flex;
  align-items: center;
	width: 100%;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  .title--large {
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  h1,
  h2,
  .h2,
  h3,
  .h3 {
    width: 100%;
    margin-bottom: 0;
    font-weight: $font-weight-heading;
  }

  div:not(.tag) {
    font-size: $base-font-size; // Reset font-size
  }

  p {
    margin-bottom: 0;
  }

  .tag {
    margin-bottom: 5px;
  }
}

.mb--medium .title {
  margin-bottom: 0;
}

// Link
.title.title__link {
  h2 {
    width: auto;
  }
}

.title__link {
  flex-wrap: wrap;
	justify-content: space-between;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 10px;
    margin-right: 20px;
  }

  .btn__link-arrow--short {
    padding-right: 20px;
  }

  .btn__link {
    margin-bottom: 10px;
    white-space: nowrap;
    color: $black;
  }

  .btn__link:not(.btn__link-arrow--short) {
    &:hover {
      color: $color-link;
    }
  }
}

// Icon
.title {
  .title__icon {
    display: flex;
    align-items: center;

    .title__icon__bg {
      position: relative;
      height: 55px;
      width: 55px;
      margin-right: 15px;

      &[class*="icon-"] {
        border-radius: 30px;
        background: $white;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.875rem; // icon size
          color: $color-icons;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: $color-icons;
          opacity: .1;
          border-radius: 50px;
        }
      }

      img,
      svg {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 55px;
        height: auto;
        max-height: 55px;
      }
  
      img {
        transform: unset;
        object-fit: contain;
      }
    }

    h2,
    .h2,
    h3,
    .h3 {
      margin: 0;
    }
  }
}

@include sectionColors($themes-section) {
  .title__link {  
    .btn__link:not(.btn__link-arrow--short) {
      &:hover {
        color: themedSections('text');
        text-decoration: underline;
      }
    }
  }
}
