/*
** Background colors sections
**
** .bg--***** is used on the first level of the given section,
** to give it a background color
*/

@include sectionColors($themes-section) {
  background: themedSections('background');
 
  h1 {
    color: themedSections('text');
  }

  /***** Title *****/
  .title {
    h2,
    .btn__link,
    .btn__link-arrow--short,
    .btn__link.btn__link-arrow--short::before {
      color: themedSections('text');
    }
  }

  .bg--tranparent,
  .card.bg--transparent,
  .media-box.bg--transparent,
  .media-box__text.bg--transparent,
  &.banner,
  &.subscribe {
    .btn__primary,
    .btn__primary.loadmore,
    .btn__primary-arrow--long {
      background: themedSections('text');
      color: themedSections('background');
      border-color: themedSections('text');
      
      &:hover,
      &:focus {
        background: transparent;
        color: themedSections('text');
      }
    }
  
    .btn__primary--outline,
    .btn__primary-arrow--long--outline {
      border-color: themedSections('text');
      color: themedSections('text');
  
      &:hover,
      &:focus {
        background: themedSections('text');
        color: themedSections('background');
      }
    }
  
    a.btn__primary-arrow--long {
      &:hover,
      &:focus {
        .arrow:before {
          color: themedSections('text');
        }
      }
  
      .arrow:before {
        color: themedSections('background');
      }
    }
  
    a.btn__primary-arrow--long--outline {
      &:hover,
      &:focus {
        .arrow:before {
          color: themedSections('background');
        }
      }
  
      .arrow:before {
        color: themedSections('text');
      }
    }

    .tag span {
      color: themedSections('text');
    }
  }

  /***** Heros *****/
  &.hero__article__displaced {
    background: unset;

    .hero__article__media.postion-absolute {
      background: themedSections('background');
    }
  }

  &.hero__article .hero__article__text {
    h1,
    .h1,
    h2,
    .h2,
    p,
    p span,
    .tag,
    .label {
      color: themedSections('text');
    }
  }

  /***** Banner cta *****/
  &.banner__cta {
    h2,
    .h2--large,
    p,
    p a,
    [class*="icon-"] {
			color: themedSections('text');
		}
  }

  /***** Component background transparent *****/
  .card.bg--transparent,
  .media-box.bg--transparent {
    h2,
    .h2,
    h3,
    p:not(.tag--icon),
    p span,
    p span:not([class*=icon-]),
    .tag,
    .tag span,
    .tag--with-icon:not(.color-red) span:not([class*=icon-]),
    .label,
    .label span,
    .arrow:before {
      color: themedSections('text');
    }

    .icon--inline-tag {
      &::before {
        background-color: themedSections('background');
        opacity: .5;
      }
  
      [class*=icon-]::before {
        color: themedSections('text');
      }
    }
  
    .tag--bg-color::before,
    .tag--bg-color--system::before {
      background-color: themedSections('text');
    }

    .tags-container.with-divider {
      & > *:not(:first-child)::after {
        background: themedSections('text');
      }
    }
  }

  .media-box.bg--transparent {
    .btn__link-arrow--long {
      span {
        color: themedSections('text');
      }

      .arrow:before {
        color: themedSections('text') !important;
      }
    }
  }

  .media-box--displaced .media-box__text.bg--transparent {
    background-color: themedSections('background');
  }

  /***** Grid dynamic *****/
  .grid-dynamic {
    .rich-text {
      h2, 
      h3, 
      h4,
      p {
        color: themedSections('text');
      }
    }

    .tag,
    .label,
    p.caption,
    .btn__link-arrow--long {
      color: themedSections('text');
    }
  }

  /***** Spot box *****/
  .spot-box {
    &:not(.c-background):not([class*="bg-"]) {
      p.h1,
      p {
        color: themedSections('text');
      }

      .imagery-text::before {
        background-color: themedSections('text');
      }
    }
  }

  /***** Section dividers *****/
  &.line {
    background-color: themedSections('background');
  }

  /***** SVG styling *****/
  &svg {
    path {
      fill: themedSections('text');
    }
  }
}

// Link styling
body {
  &:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {
    .media-box.has-overlay .media-box__text.bg--transparent {
      p a:not(.list__link) {
        color: var(--color-overlay-text);
        background-size: 100% 1px;
        background-image: unset;
  
        &:hover,
        &:focus {
          color: var(--color-overlay-text);
        }
      }
    }
  

    @include sectionColors($themes-section) {
      .media-box.bg--transparent .media-box__text,
      .media-box .media-box__text.bg--transparent,
      .grid-dynamic {
        p a:not(.list__link) {
          color: themedSections('text');
          background-size: 100% 1px;
          background-image: linear-gradient(themedSections('text'), themedSections('text'));
    
          &:hover,
          &:focus {
            color: themedSections('text');
          }
        }
      }
    }
  }

  &.link--underline--lr {
    .media-box.has-overlay .media-box__text.bg--transparent {
      p {
        a:not(.list__link) {
          color: var(--color-overlay-text);
          background-size: 100% 1px;
          // background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
    
          &:hover,
          &:focus {
            color: var(--color-overlay-text);
          }
        }
      }
    }

    @include sectionColors($themes-section) {
      .media-box.bg--transparent .media-box__text,
      .media-box .media-box__text.bg--transparent,
      .grid-dynamic {
        p {
          a:not(.list__link) {
            color: themedSections('text');
            background-size: 100% 1px;
            background-image: linear-gradient(themedSections('text'), themedSections('text'));
      
            &:hover,
            &:focus {
              color: themedSections('text');
            }
          }
        }
      }
    }
  }

  &.link--underline-fat-lr {
    .media-box.has-overlay .media-box__text.bg--transparent {
      p {
        a:not(.list__link) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
          border-bottom: 1px solid var(--color-overlay-text);

          &:hover,
          &:focus {
            color: var(--color-overlay-text);
          }
        }
      }
    }

    @include sectionColors($themes-section) {
      .media-box.bg--transparent .media-box__text,
      .media-box .media-box__text.bg--transparent,
      .grid-dynamic {
        p {
          a:not(.list__link) {
            color: themedSections('text');
            background-image: linear-gradient(themedSections('text'), themedSections('text'));
            border-bottom: 1px solid themedSections('text');

            &:hover,
            &:focus {
              color: themedSections('text');
            }
          }
        }
      }
    }
  }

  &.link--underline-bg-bt {
    .media-box.has-overlay .media-box__text.bg--transparent {
      p {
        a:not(.list__link) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));

          &:hover,
          &:focus {
            color: var(--color-overlay-start);
          }
        }
      }
    }

    @include sectionColors($themes-section) {
      .media-box.bg--transparent .media-box__text,
      .media-box .media-box__text.bg--transparent,
      .grid-dynamic {
        p {
          a:not(.list__link) {
            color: themedSections('text');
            background-image: linear-gradient(themedSections('text'), themedSections('text'));

            &:hover,
            &:focus {
              color: themedSections('background');
            }
          }
        }
      }
    }
  }

  &.link--underline-bg-gradient-bt {
    .media-box.has-overlay .media-box__text.bg--transparent {
      p {
        a:not(.list__link) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));

          &:hover,
          &:focus {
            color: var(--color-overlay-start);
          }
        }
      }
    }

    @include sectionColors($themes-section) {
      .media-box.bg--transparent .media-box__text,
      .media-box .media-box__text.bg--transparent,
      .grid-dynamic {
        p {
          a:not(.list__link) {
            color: themedSections('text');
            background-image: linear-gradient(themedSections('text'), themedSections('text'));

            &:hover,
            &:focus {
              color: themedSections('background');
            }
          }
        }
      }
    }
  }
}
