//- Delete after rich-text text box implementation
.rich-text__text-box {
  &.framed {
    padding: 40px 30px 20px 30px;
    background-color: #fff;

    .rich-text__text-box__content {
      @include media-breakpoint-up(lg) {
        column-count: 2;
        column-gap: 40px;
      }
    }
  }
}

