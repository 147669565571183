.card__event.card__event--list {
  padding-top: var(--sleeve-component-padding);
	padding-bottom: var(--sleeve-component-padding);
	border-top: 1px solid $divider-color;
  background-color: transparent;

  &.card__event--date--top-left {
    .card__event__date {
      padding: 12px 15px;
      background-color: var(--color-btn-primary);
      border-radius: var(--sleeve-component-border-radius);
      margin-bottom: 0;

      p.day,
			p.month {
        color: var(--color-btn-primary-text);
			}
    }
  }

  &.card__event.card__event--date--top-right {
    .card__event__date  {
      align-items: center;
      justify-content: center;
      width: 80px;
      min-width: 80px;
      height: 80px;
      padding: 10px;
      border-radius: 50%;
      margin-bottom: 0;
      overflow: hidden;
      
      p.day,
      p.month {
        color: $color-tag;
      }

      p.month {
        font-weight: 400;
      }
    }
  }

  .card__event__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 20px 25px;
    background: $white;
    border-top-right-radius: var(--sleeve-component-border-radius);
    margin-right: 30px;

    @include media-breakpoint-down(sm) {
      margin-right: 20px;
    }

    p {
      font-weight: $font-weight-heading;
      margin-bottom: 0;

      &.day {
        font-size: calc($component-font-size + em(8px));
      }

      &.month {
        color: $black;
      }
    }
  }

  .card__event__content {
    justify-content: flex-start;
    padding: 0;
  }

  .card__event__image-content {
    margin: 0;
    padding: 0 0 30px 0;

    img:not([class*="aspect-ratios--"]) {
      height: auto;
      aspect-ratio: 3 / 2;

      @include media-breakpoint-down(sm) {
        height: auto;
        aspect-ratio: 3 / 2;
      }
		}
  }

  .card__tag {
    top: 10px;
    right: 10px;
    left: unset;
  }

  .card__badge {
    right: 10px;
  }

  // Grid
  .row {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }

  .col-md-8.col-lg-9.col-xl-8 {
    display: flex;
    flex-direction: row;
  }
}