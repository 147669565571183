// Media box displaced - has displaced textbox
.media-box--displaced {
  padding-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }

  .media-box__media {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
    width: 60%;
    height: 100%;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      height: 400px;
      transform: unset;
      right: unset;
      top: unset;
    }
    
    @include media-breakpoint-down(sm) {
      height: 222px;
    }

    img,
    .background-video {
      border-radius: var(--sleeve-component-border-radius);
    }

    .swiper .gradient {
      border-bottom-left-radius: var(--sleeve-component-border-radius);
      border-bottom-right-radius: var(--sleeve-component-border-radius);
    }

    > img {
      height: 100%;
      width: 100%;

      @include media-breakpoint-down(md) {
        height: 400px;
      }

      @include media-breakpoint-down(sm) {
        height: 222px;
      }
    }

    .video {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        margin: 0 !important;
      }    
    }
  }

  .media-box__text {
    border-radius: var(--sleeve-component-border-radius);
    width: 50%;
    background: $white;

    @include media-breakpoint-down(sm) {
      padding: 40px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include nestedComponentColors($themes) {
      background: themed('background');
    }

    // bg--transparent inverts bg main for transparent look
    &.bg--transparent {
      background: $color-body;
    }
  }

  &.has-searchbar {
    .media-box__text {
      @include nestedComponentColors($themes) {
        .search-bar--small {
          form .form-group input {
            background: $white !important;
          }
        }
      }

      &.bg--transparent {
        .search-bar--small {
          form .form-group input {
            background: $white;
          }
        }
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .media-box__media {
      left: 0;
      
      @include media-breakpoint-down(md) {
        left: unset;
      }

      .background-video button.background-video__btn {
        @include media-breakpoint-up(lg) {
          right: unset;
          left: 30px;
        }
      }
    }
  }
}

// Grid
.container--wide {
  .media-box--displaced:not(.reverse) {
    .media-box__media,
    .media-box__media img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @include media-breakpoint-down(md) {
        border-radius: 0;
      }
    }

    .media-box__text {
      @include media-breakpoint-down(md) {
        border-radius: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 30px;
      }
    }
  }

  .media-box--displaced.reverse {
    .media-box__media img {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      @include media-breakpoint-down(md) {
        border-radius: 0;
      }
    }

    .media-box__text {
      @include media-breakpoint-down(md) {
        border-radius: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 30px;
      }
    }
  }

  .container {
    .media-box--displaced:not(.reverse) {
      .media-box__media {
        img,
        .background-video {
          border-radius: var(--sleeve-component-border-radius);
    
          @include media-breakpoint-down(md) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .media-box__text {
        border-radius: var(--sleeve-component-border-radius);

        @include media-breakpoint-down(md) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }
    }
  
    .media-box--displaced.reverse {
      .media-box__media {
        img,
        .background-video {
          border-radius: var(--sleeve-component-border-radius);
    
          @include media-breakpoint-down(md) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .media-box__text {
        border-radius: var(--sleeve-component-border-radius);

        @include media-breakpoint-down(md) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }
    }
  }
}
