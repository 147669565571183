/**
 * Card carousel
 */

@import '../../../../node_modules/owl.carousel/src/scss/owl.carousel.scss';

.owl-card-carousel {
  .owl-stage {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .item {
    height: 100%;
    margin-right: 25px;
    border: none;

    &:last-child {
      margin-right: 0;
      margin-bottom: 1px;
    }

    p {
      margin-bottom: 30px
    }

    &.link-box__arrow {
      .arrow {
        bottom: 20px;
      }
    }
  }
}
