.ql-editor {
  p, 
  ol, 
  pre, 
  blockquote, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    color: $grey-dark;
    font-size: $form-font-size;
  }
}

//- Quill: .ql-editor result
.rich-text--ql {
  p {
    margin-bottom: 5px;
  }
}

//- Quill in: Intranet chat
.chat {
	.chat__post__form--container {
		&.collapsed {
			.ql-toolbar {
				border-bottom: none;
			}
		}
	}

	.chat__post {
		.ql-toolbar.ql-snow {
			padding-right: 20px;
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
			border-top: none;
			border-left: none;
			border-right: none;
			background: $color-body;
		}

		.ql-container.ql-snow {
			font-size: var(--body-font-size);
			background: $color-body;
			border: none;
			height: auto;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			margin-bottom: 15px;
      
			@include media-breakpoint-down(md){
				font-size: 16px;
			}
		}

		.ql-editor p {
			&:focus {
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}
}

//- Quill in: Borgerdialog
.proposal {
  .ql-toolbar,
  .ql-editor {
    background: $white;
  }

  .ql-editor {
    height: 250px;
  }
}
