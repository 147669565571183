progress {
	/* Positioning */
	position: fixed;
	z-index: 100;
	left: 0;
  top: 0;
  transition: $transition;

	/* Dimensions */
	width: 100%;
	height: 5px;

	/* Reset the appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	/* Get rid of the default border in Firefox/Opera. */
	border: none;

	/* Progress bar container for Firefox/IE10+ */
	background-color: transparent;

	/* Progress bar value for IE10+ */
  color: $color-primary;
  
	&::-webkit-progress-bar {
		background-color: transparent;
	}
	&::-webkit-progress-value {
		background-color: $color-secondary;
	}
	&::-moz-progress-bar {
		background-color: $color-secondary;
	}
}
