.filter-menu.filter-menu__events {
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }

  p {
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }
  }

  form {
    .form-group:last-of-type {
      margin-bottom: 0;
    }

    .select {
      margin-bottom: 0;
    }
  }

  .filter-menu__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .container {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .form-group input,
    .select,
    .select select,
    .btn__primary {
      width: 100%;
      height: 60px;
    }

    .form-group input,
    .form-group input[type=date],
    .select {
      border: unset;
    }

    .select-checkboxes .form-check .checkbox input {
      height: 25px;
      width: 25px;
    }

    .btn__primary {
      min-width: auto;

      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }
    }

    form .form-group input[type="date"],
    .form-group input[type="date"],
    .select {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }
    }

    input {
      color: $black;
    }

    .filter-menu__items__display {
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-top: 25px;
      }

      p {
        min-width: auto;
        margin-top: 0;
        margin-right: 30px;
        margin-bottom: 0;
      }

      button {
        display: flex;
        padding: 0;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        svg {        
          &.svg-grid,
          &.svg-list {
            rect {
              fill:  $grey-medium;
            }

            &.active {
              rect {
                fill: $color-icons;
              }
            }
          }

          &.svg-location {
            path {
              fill: $grey-medium;
            }

            &.active {
              path {
                fill: $color-icons;
              }
            }
          }
        }
      }
    }
  }
}
