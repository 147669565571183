// Accordion--mobile
.accordion--mobile {
  border-radius: var(--sleeve-component-border-radius);
  
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0;
  }
  
  &.active {
    .accordion--mobile__toggle {
      &:after {
        transform: translateY(-50%) translateX(-15px) rotate(-45deg);
      }
    }
  }

  // Toggle container - if title is link
  .accordion--mobile--toggle-container {
    display: flex;

    @include media-breakpoint-down(sm) {
      padding: 15px 0 15px 30px;
    }

    .accordion--mobile__toggle {
      margin-left: auto;
      padding: 10px 43px 10px 10px;
    }

    a {
      display: flex;
      
      @include media-breakpoint-down(sm) {
        align-items: center;
      }

      .info-box__links__icon--large__bg {
        margin-right: 20px;
      }
    }
  }

  // Header - title, icon & arrow
  .accordion--mobile__toggle {
    text-align: left;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }
    
    @include media-breakpoint-down(sm) {
      position: relative;
      padding: 15px 43px 15px 30px;
    }

    &[disabled] {
      cursor: auto;
    }

    &:after {
      @include media-breakpoint-down(sm) {
        content: '';
        display: inline-block;
        position: absolute;
        right: 7px;
        top: 50%;
        transition: all 0.2s ease-in-out;
        width: 9px;
        height: 9px;
        border-style: solid;
        border-color: $color-arrows;
        border-width: 2px 2px 0 0;
        transform: translateY(-50%) translateX(-15px) rotate(135deg);
      }
    }

    h2,
    h3,
    .h3 {
      @include media-breakpoint-down(sm) {
        max-width: 90%;
        margin-bottom: 0;
      }
    }
  }

  // Body - Hidden content
  .accordion--mobile__body {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      display: flex !important;
      height: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
      height: 100%;
      padding: 0 30px 15px 30px;
    }
  }
}

// Accordion--mobile
.accordion--mobile {
  &.info-box.info-box__links,
  &.info-box.info-box__links__icon--large,
  &.card {
    @include media-breakpoint-down(sm) {
      border-radius: $border-radius-component;
      background-color: $white;

      .card__portrait {
        border-radius: unset;
      }
    }

    @include nestedComponentColors($themes) {
      @include media-breakpoint-down(sm) {
        color: themed('text');
        background-color: themed('background');
      }
    }
    
    &.bg--transparent {
      @include media-breakpoint-down(sm) {
        background-color: $white;
      }
    }
  }

  &.card {
    @include media-breakpoint-up(md) {
      background-color: transparent;
    }

    &.card__event {
      &.no-image {
        .card__event__image-content {
          padding-left: 15px;
        }
      }

      &.no-image.card__event--date--top-left,
      &.no-image.card__event--date--top-right {
        .card__event__image-content {
          padding-left: 30px;
        }
      }
    }

    .accordion--mobile__toggle {
      display: flex;
      align-items: center;

      div[class*="icon-"] {
        margin-right: 20px;
      }

      img, 
      svg {
        margin-right: 20px;
        width: 30px;
        height: auto;
        max-height: 30px;
      }
    }

    .card {
      height: 100%;

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }
  }
} 

// Info-box in Accordion--mobile
.info-box.info-box__links,
.info-box.info-box__links__icon--large {
  &.accordion--mobile {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    
    h3,
    .h3 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

    // Header - title, icon & arrow
    .accordion--mobile__toggle,
    .accordion--mobile--toggle-container {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        flex-direction: row;
      }

      .info-box__links__icon--large__bg {
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }

        &::before {
          @include media-breakpoint-down(sm) {
            font-size: 1.875rem; // icon size
          }
        }

        img,
        svg {
          @include media-breakpoint-down(sm) {
            width: 30px;
            height: auto;
            max-height: 30px;
          }
        }
      }

      a {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}

.info-box.info-box__links__icon--large {
  &.accordion--mobile,
  &.accordion--mobile--toggle-container {
    .info-box__links__icon--large__bg {
      @include media-breakpoint-down(sm) {
        margin-right: 20px;
      }
    }
  }

  &.accordion--mobile {
    .accordion--mobile__toggle {
      display: flex;

      @include media-breakpoint-up(md) {
        flex-direction: column;
      }
     
      @include media-breakpoint-down(sm) {
        align-items: center;
      }

      .info-box__links__icon--large__bg {
        @include media-breakpoint-up(md) {
          width: 100%;
        }
        
        @include media-breakpoint-down(sm) {
          margin-right: 20px;
        }
      }

      a,
      h2,
      h3 {
        width: 100%;
      }
    }
  }
}

// Card in Accordion--mobile
.card.accordion--mobile {
  // Body - Hidden content
  .accordion--mobile__body {
    @include media-breakpoint-down(sm) {
      padding: 0 30px 0 30px;
    }

    // Card potrait
    a.card__portrait {
      &:hover {
        .arrow {
          @include media-breakpoint-down(sm) {
            right: 5px;
          }
        }
      }

      .card__portrait__text {
        @include media-breakpoint-down(sm) {
          padding: 30px 0 30px 0;
        }
        
        h3,
        .h3 {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      .arrow {
        @include media-breakpoint-down(sm) {
          right: 0; 
        }
      }

      &.card__portrait--no-image {
        .card__portrait__text {
          @include media-breakpoint-down(sm) {
            padding: 0 0 30px 0;
          }
        }
      }
    }

    // Card event
    .card__event {
      .card__event__content {
        @include media-breakpoint-down(sm) {
          padding: 5px 0 30px 0;
        }
      }
    }

    .card__event.card__event--date--top-left {
      .card__event__image-content {
        @include media-breakpoint-down(sm) {
          margin-top: 15px;
        }
      }
    }

    // Card CTA
    .card__cta {
      .card__cta__content {
        @include media-breakpoint-down(sm) {
          padding: 30px 0;
        }
      }
    }
  }
}

// Row accordion mobile
.row.row--grid.row-accordion--mobile {
  @include media-breakpoint-down(sm) {
    margin-top: -15px;
  }
  
  [class*="col-"],
  .col-lg-4 {
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }
  }
}

//Link boxes in accordion mobile
.accordion--mobile.link-boxes--framed {
  .accordion--mobile__body {
    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-top: var(--col-padding-md-down);
    }
  }

  .accordion--mobile__toggle {
    display: flex;
    padding: 0;

    @include media-breakpoint-up(md){
      display: none;
    }

    &:after{
      right: 0;
    }
  }

  &.no-icon .link-boxes--framed__toggle {
    padding-left: 5px;
  }
}
 