.breadcrumb__page-title {
  background-color: $color-body;
  margin: 0 -60px;
  padding: 15px 60px 0 60px;

  h1,
  .h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 60px;
    @include responsive-font-size($hero-article-p-font-size-sm, "", $hero-article-p-font-size-sm, "", $hero-article-p-font-size-lg);
    line-height: $line-height-secondary;
  }
}
