.progressbar__breadcrumb {
  display: flex;
  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .progressbar__breadcrumb__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 10px 15px 10px 10px;
    background: $grey-light;
    font-size: $breadcrumb-font-size;

    @include media-breakpoint-down(md) {
      width: calc(50% - 1px);
      margin-bottom: 1px;
      margin-right: 1px;
    }

    @include media-breakpoint-down(sm) {
      justify-content: unset;
      padding: 10px;
    }

    &:last-of-type {
      .list-icon {
        opacity: 0;
      }
    }

    .progressbar__breadcrumb__item__number {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 1px solid $grey-medium;

      @include media-breakpoint-down(sm) {
        margin-right: 8px;
      }

      // Add this icon to icon libary
      svg path {
        stroke: $color-secondary;
      }
    }

    .list-icon {
      position: relative;
      height: 100%;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      &::before {
        top: 50%;
        transform: translateY(-50%);
        font-size: em(20px); // icon size
        color: $grey-medium;
      }
    }

    &.active {
      border-bottom: 2px solid $color-secondary;

      .progressbar__breadcrumb__item__number {
        border-color: $color-secondary;
        background-color: $color-secondary;
        color: $color-secondary-text;
      }
    }

    &.done {
      .progressbar__breadcrumb__item__number {
        background-color: $white;
        border-color: $white;
      }
    }
  }
}
