/**
* Sizes
*/

.height-100vh,
.height-80vh,
.height-50vh {
  .image img {
    height: 100%;
  }
}

.height-100vh:not(.banner),
.height-80vh {
	display: flex;
	align-items: center;
	justify-content: center;
}

.height-100vh {
  min-height: calc(100vh - #{var(--header-height-md)});
}

.height-80vh {
  min-height: calc(80vh - #{var(--header-height-md)});
}

.height-50vh {
  min-height: calc(50vh - #{var(--header-height-md)});
}

.height-auto {
  min-height: auto;
}
