.links-download {
	display: flex;
	flex-direction: column;
  margin-left: 30px;

  a.link-download {
    position: relative;
    width: fit-content;
    font-size: $component-font-size;
    font-weight: $font-weight-body;
    line-height: $line-height-secondary;
    color: $color-link;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;

      .icon-download {
        top: 15px;
      }
    }

    .icon-download {
      position: absolute;
      left: -30px;
      top: 10px;
      transform: translateY(-50%);
      transition: $transition;
      color: $color-arrows;
      font-size: em(20px); // icon size
      font-weight: $font-weight-heading;
      margin: 0;
    }
  }
}
