/*
** Background color Grey Light for components
**
** .bg--grey-light is used on the first level of the given component,
** to give it a background color
*/

main.bg-main--white,
.bg-main--white {
  .bg--grey-light {
    background-color: $grey-light;
  }
  
  // Components with grey light background
  .side-box:not(.bg--primary):not(.bg--secondary):not(.bg--border-topform),
  .form-container,
  .umbraco-forms-form, 
  .bg--grey-light {
    .btn__primary, 
    .btn__submit {
      border-color: $btn-bg--grey-light-bg-color;
      background: $btn-bg--grey-light-bg-color;
      color: $btn-bg--grey-light-text-color;

      &:hover:enabled,
      &:focus:enabled {
        background: transparent;
        color: $black;
      } 

      &:hover:disabled,
      &:focus:disabled {
        background: $btn-bg--grey-light-bg-color;
        color: $btn-bg--grey-light-text-color;
      } 
    }
  
    .btn__primary--outline {
      border-color: $btn-bg--grey-light-bg-color;

      &:hover,
      &:focus {
        background: $btn-bg--grey-light-bg-color;
        color: $btn-bg--grey-light-text-color;
      }
    }
  }

  
  
  .form-container.bg--white form:not(#search-form):not(#subscribForm) .form-group input, .search-bar--small form:not(#search-form) .form-group input {
    background-color: $grey-light;
  }
  
  form:not(#search-form) .form-group input, .hero .search-bar--small form:not(#search-form) .form-group input {
    background-color: $white;
  }

  .form-file input[type=file] {
    border-color: $btn-bg--grey-light-bg-color;
  }
}

main:not(.bg-main--white) {
  .bg--grey-light {
    background-color: $bg--white-color;

    form,
    form:not(#search-form) {
      .form-group input {
        background-color: $grey-light;
      }
    }
  }
}
