/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Cludo
 */

.cludo-search .cludo__filter-list {
  background-color: var(--darkmode-700);
  
  .cludo__filter-list__title {
    background-color: var(--darkmode-800);
  }
}