/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Links default
 */

p,
h2,
h3,
h4,
.article-wrapper .rich-text li,
.accordion li,
.article-wrapper table,
[data-creative~="article-content"] .rich-text li,
[data-creative~="article-content"] .accordion .rich-text li,
[data-creative~="article-content"] table,
.suggestions p,
.info-box-article .rich-text-article__references ul li,
.side-box li {
  a:not(.list__link):not(.btn):not(.btn__link) {
    color: var(--darkmode-50);
    background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

    &:hover,
    &:focus {
      color: var(--darkmode-50);
    }
  }
}

[class*="bg-"].media-box,
[class*="bg-"].media-box .media-box__text {
  a:not(.btn__link):not(.list__link):not(.btn) {
    color: var(--darkmode-50);
    background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

    &:hover,
    &:focus {
      color: var(--darkmode-50);
    }
  }
}

.banner__alert:not([class*="bg-"]) p {
  a:not(.list__link):not(.btn):not(.btn__link) {
    color: var(--darkmode-900);
    background-image: linear-gradient(var(--darkmode-900), var(--darkmode-900));

    &:hover,
    &:focus {
      color: var(--darkmode-900);
    }
  }
}

.accordion.accordion__colored-backgrounds,
.fact-box {
  &[class^="article-element-bg"],
  &[class*="article-element-bg"] {
    .rich-text {
      a:not(.list__link):not(.btn):not(.btn__link),
      li a:not(.list__link):not(.btn):not(.btn__link) {
        color: var(--darkmode-50);
        background-image: linear-gradient(var(--darkmode-50), var(--darkmode-50));

        &:hover,
        &:focus {
          color: var(--darkmode-50);
        }
      }
    }
  }
}
