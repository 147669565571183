.table,
.dataTables_wrapper {
  width: 100% !important;
  opacity: 0;
  transition: $transition-base;
  margin: 20px 0;

  th,
  td p,
  .dtr-data p,
  .dtr-data a {
    font-family: $secondary-font;
    font-size: $form-font-size;
  }

  th,
  .dtr-title {
    font-family: $primary-font;
    font-size: $form-font-size;
  }
}

.table {
  padding-top: 30px !important;
}

.dataTables_length {
  label {
    position: relative;
    margin-bottom: 0;

    &::before {
      position: absolute;
      content: '';
      right: 15px;
      top: 49%;    
      z-index: 2;
      width: 9px;
      height: 9px;
      border: 2px solid #000;
      border-width: 2px 2px 0 0;
      transform: translate(0, -50%) rotate(135deg);
    }
  }

  select {
    font-family: $secondary-font;
    height: 45px;
    width: 150px;
    padding: 5px 35px 5px 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    
    &:focus {
      box-shadow: unset;
    }
  }
}

.dataTables_filter {
  label,
  input {
    padding: 5px 35px 5px 15px;
    font-size: $form-font-size;
    line-height: $line-height-secondary;
  }

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 45px;
    width: 260px;
    margin-bottom: 0;
    border: 1px solid $grey-medium;
    
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      margin-left: 0 !important;
      border: none;
      background-color: transparent;
      font-family: $secondary-font;
      transition: $transition-base;

      &:focus,
      &:visited,
      &:valid {
        background-color: $white;
      }

      &::after {
        content: "\e904";
        position: absolute;
        top: 3px;
        font-size: 1.7em; // icon size (Loop icon)
        transition: $transition-base;
        font-weight: 500;
        padding: 0;
        margin-right: 20px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.dataTables_info {
  font-family: $secondary-font;
  font-size: $small-details-font-size;
}

// Wrap text in table accordions
table.dataTable.nowrap {
  tbody {
    td {
      p {
        margin-bottom: 0;
      }
    }
  }

  td.child {
    white-space: pre-wrap;
  }
}

.dataTable {
  p {
    margin-bottom: 0;
  }

  tbody tr, 
  thead tr th{
    vertical-align: baseline;
  }

  thead {
    tr {
      th {
        font-family: $primary-font;
        border-top: 2px solid $color-secondary !important;
        border-bottom: none !important;
        text-align: left;
        padding: 10px 30px 10px 10px;
        white-space: nowrap;

        &.control {
          &:after {
            display: none;
          }
        }

        &.sorting,
        &.sorting_desc,
        &.sorting_asc {
          background-image: none;
          position: relative;
          padding: 10px 25px 10px 10px;

          &:after {
            content: "";
            position: absolute;
            top: 45%;
            right: 12px;
            padding: 3px;
            margin: 0 0 0 15px;
            border-color: $color-arrows;
            border-style: solid;
            border-width: 0 2px 2px 0;
            transition: $transition;
          }
        }

        &.sorting,
        &.sorting_desc {
          &:after {
            transform: rotate(45deg) translate(0, -50%);
          }
        }

        &.sorting_asc {
          &:after {
            transform: rotate(-135deg);
          }
        }

        &.no-sort::after {
          display: none !important;
        }

        &.no-sort {
          pointer-events: none !important;
          cursor: default !important;
        }
      }
    }
  }

  tbody {
    td.control:before, 
    th.control:before {
      top: 20px !important; 
    }

    td {
      padding: 10px !important;
    }
  }

  tfoot {
    th {
      select {
        width: 100%;
      }
    }
  }

  .control.control.sorting_1,
  .control.sorting_asc {
    display: table-cell;
    margin: 0;
    min-width: 40px;
  }

  .control.control.sorting_1 {
    padding: 0;
  }

  ul {
    width: 100%;
    padding-left: 30px;

    li {
      &::before {
        content: '';
      }
    }
  }

  &.no-footer {
    border-bottom: 2px solid $color-secondary !important;
  }
}

.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      padding: 10px 0;
      color: $grey-dark !important;
      font-size: calc($btn-font-size + em(2px));
      width: 1.2em;
      border: 0 !important;

      &:hover {
        background: transparent;
        color: $color-arrows !important;
        border: 0 !important;
      }

      &.current {
        background: none !important;
        border: none;
        font-weight: $font-weight-heading;

        &:hover {
          background: none;
        }
      }

      &.previous,
      &.next {
        position: relative;
        margin-left: 10px;
        padding: 0;
        height: 23px;
        width: 18px;

        &:after {
          font-family: 'icomoon';
          content: "\e938";
          color: $color-arrows;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 1.3em; // icon size (arrow)
          transition: $transition-base;
          font-weight: 500;
          padding: 0;
        }

        &:hover {
          color: transparent !important;
        }

        &.disabled {
          display: none;
        }
      }

      &.previous {
        margin-left: 0;
        margin-right: 10px;

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.table--collapse {
  .control.control.sorting_1,
  .control.sorting_asc {
    width: 40px !important;
  }
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  width: 20px;
  height: 20px;
  margin-top: -9px;
  margin-left: -10px;
  font-family: $secondary-font;
  font-size: 20px; // icon size (+ icon)
  line-height: 21px; // icon line height
  font-weight: 600;
  box-shadow: none;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: $color-secondary;
  border: 1px solid $white;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  color: $color-secondary;
  background-color: transparent;
  border: 1px solid $color-secondary;
}

// KLUB TABLE col style
table.dataTable > tbody > tr.child {
  ul.dtr-details {
    li {
      padding: 5px 0;
      margin-bottom: 0;

      .dtr-title {
        margin-right: 15px;
      }
    }
  }
}
