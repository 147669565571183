/**
 * Modal popup
 */

.modal {
  .tags-container {
		margin-bottom: 15px;
	}

  .modal__item {
    position: relative;
    display: flex;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    h3,
    h4 {
      margin-bottom: 0;
      font-size: $component-font-size;
    }

    p {
      color: $grey-dark;
      margin-bottom: 0;
      font-size: $small-details-font-size;
    }

    &.modal__item--togglers {
      .modal__item__content {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .modal__item__content__text {
          width: 60%;
          margin-right: 0;
        }
      }
    }
  }
}

.modal-popup {
	.modal-dialog {
		max-width: 600px;
	}

	.modal-content {
		border-radius: 0;
		border: unset;

    .divider {
      border-bottom: 1px solid $grey-light;
      margin-bottom: 20px;
    }

		.modal-header {
			border-bottom: unset;
			padding: 20px 20px 0 30px;

			h2 {
        color: $black;
				margin-bottom: 0;
			}
		}

    .modal-body.modal-body--rich-text {
      h2,
      h3,
      h4,
      p {
        color: $black;
      }
    }

    .modal-body:not(.modal-body--rich-text) {
			h3 {
				margin-bottom: 0;
				font-size: $component-font-size;
			}

			p {
				color: $grey-dark;
				margin-bottom: 10px;
				font-size: $small-details-font-size;
			}
    }

		.modal-body {
			padding: 0;
			margin-top: 30px;
			padding: 10px 30px 30px 30px;

			.btn {
				margin-top: 0;
			}

      .modal__item {
        position: relative;
      }

			.modal__btn-delete {
				cursor: pointer;
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 30px;
				width: 22px;
				padding: 0;
				transition: $transition;

				&:hover {
					svg path {
						fill: red;
					}
				}
			}

			.search-bar--small input {
				background: $grey-light !important;
			}
		}
	}
}

.modal-popup__list {
  .btn__modal-popup {
    margin-bottom: 15px;

    div:not(.tag) {
      .tag {
        margin-bottom: 10px;
      }
    }
  }
}

button.card {
  padding: 0;
  text-align: left;
}
