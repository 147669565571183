/**
 * Grid
 */

// Containers
.container {
  // max-width of the grid
	@include media-breakpoint-up(xl) {
		max-width: var(--container-width) !important;
	}
}
// Container fluid is bootstraps full width container. Always use a container inside a container-fluid, never a row directly inside a container-fluid
.container-fluid {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.container--wide {
	width: 100%;
  height: auto;
  overflow: hidden; // Do not remove: use class .overflow--unset if you want to overide
}

.container--wide__important {
  @include media-breakpoint-down(md) {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    overflow: hidden !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .three-column-carousel .owl-stage,
  .owl-card-carousel .owl-stage {
    @include media-breakpoint-down(md) {
      padding-left: 15px !important;
      margin: 0 20px;
    }

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
}

article {
  overflow: hidden;
}

// sections
section {
	width: 100%;
}

//row
.equalizer {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(sm) {
		display: block !important;
	}

	[class*="col-"] {
		display: flex;

		[class*="col-"] {
			flex-direction: column;
		}

		.card__image {
			height: 100%;
		}

    .video {
      width: 100%;
    }

    .list__timeline {
      .card {
        height: auto;
      }
    }

		.col-lg-8 {
			.card__image {
				height: 100%;
			}
		}
	}

	.equalizer {
		height: 100%;

		[class*="col-"] {
			display: flex;
			flex-flow: inherit;
		}
	}
}

.row {
  margin-right: calc(var(--col-padding) - (2 * var(--col-padding)));
  margin-left: calc(var(--col-padding) - (2 * var(--col-padding)));

	@include media-breakpoint-down(md) {
    margin-right: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
    margin-left: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
	}
}

.row-reverse {
	flex-direction: row-reverse;
}
