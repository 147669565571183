//- megamenu left aligned - button color styles
.navbar__megamenu-extended-left-aligned {
  .search-form--integrated {
    @include media-breakpoint-down(md){
      padding-bottom: 0;
    }
		
    .submit-input-btn:after, .expand-input-btn:after {
      position: absolute;
      z-index: -1;
      content: '';
      height: 39px;
      width: 39px;
      top: -5px;
      right: -2px;
      border-radius: 50%;
      opacity: 0.2;

      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }

	@include nestedComponentColors($themes) {
    .search-form--integrated {
      .submit-input-btn, .expand-input-btn {
        color: themed('icon');

        &:after {
          background-color: themed('icon');
          opacity: 0.1;
        }
      }
    }
	}

  &.bg--white {
    .search-form--integrated {
      .submit-input-btn, .expand-input-btn {
        color: var(--color-icons);
        &:after {
          background-color: var(--color-icons);
          opacity: 0.1;
        }
      }
    }
  }
}