/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Anchor links
 */

.article-anchor-menu {
  border-color: var(--darkmode-800);
}

.top-link {
	.top-link__icon {
		&::before {
      color: var(--darkmode-900) !important;
		}

		&:after {
			background-color: var(--darkmode-hightlight-1-200);
      opacity: 0.75;
		}

		&:hover:after {
      background-color: var(--darkmode-hightlight-1-200);
      opacity: 0.95;
		}
	}
}

.top-link__container {
  a.top-link {
    &.inverted {
      &::after {
        background-color: var(--darkmode-hightlight-1-200);
        border-color: var(--darkmode-800);
      }

      .top-link__icon.arrow {
        &::before {
          color: var(--darkmode-hightlight-1-200);
        }
      }
    }
  }
}
