$theme-article-map: null;

$themes-article: (
  "bg--0": (
    background: var(--color-article-element--0),
    text: var(--color-article-element-title--0),
    link: var(--color-article-element-title--0),
    icon: var(--color-article-element-title--0),
    btn-primary-background: var(--color-article-element--0),
    btn-primary-border: var(--color-article-element--0),
    btn-primary-text: var(--color-article-element-title--0),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--0),
  ),
  "bg--1": (
    background: var(--color-article-element--1),
    text: var(--color-article-element-title--1),
    link: var(--color-article-element-title--1),
    icon: var(--color-article-element-title--1),
    btn-primary-background: var(--color-article-element--1),
    btn-primary-border: var(--color-article-element--1),
    btn-primary-text: var(--color-article-element-title--1),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--1),
  ),
  "bg--2": (
    background: var(--color-article-element--2),
    text: var(--color-article-element-title--2),
    link: var(--color-article-element-title--2),
    icon: var(--color-article-element-title--2),
    btn-primary-background: var(--color-article-element--2),
    btn-primary-border: var(--color-article-element--2),
    btn-primary-text: var(--color-article-element-title--2),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--2),
  ),
  "bg--3": (
    background: var(--color-article-element--3),
    text: var(--color-article-element-title--3),
    link: var(--color-article-element-title--3),
    icon: var(--color-article-element-title--3),
    btn-primary-background: var(--color-article-element--3),
    btn-primary-border: var(--color-article-element--3),
    btn-primary-text: var(--color-article-element-title--3),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--3),
  ),
  "bg--4": (
    background: var(--color-article-element--4),
    text: var(--color-article-element-title--4),
    link: var(--color-article-element-title--4),
    icon: var(--color-article-element-title--4),
    btn-primary-background: var(--color-article-element--4),
    btn-primary-border: var(--color-article-element--4),
    btn-primary-text: var(--color-article-element-title--4),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--4),
  ),
  "bg--5": (
    background: var(--color-article-element--5),
    text: var(--color-article-element-title--5),
    link: var(--color-article-element-title--5),
    icon: var(--color-article-element-title--5),
    btn-primary-background: var(--color-article-element--5),
    btn-primary-border: var(--color-article-element--5),
    btn-primary-text: var(--color-article-element-title--5),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-article-element-title--5),
  ),
);

@mixin globalArticleColors($themes-article) {
  @each $theme, $map in $themes-article {
    .article-element-#{$theme}   {
      $theme-article-map: map-get($themes-article, $theme) !global;
      @content;
    }
  }
}

@mixin nestedArticleColors($themes-article) {
  @each $theme, $map in $themes-article {
    &.article-element-#{$theme}   {
      $theme-article-map: map-get($themes-article, $theme) !global;
      @content;
    }
  }
}

@function themedArticle($property) {
  $property: unquote($property);
  @return map-get($theme-article-map, $property);
}
