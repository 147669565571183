/**
 * Link animation
 *
 * Usage: <body class="link--underline--lr">
 * Will overide default link animations
 *
 * Link animations don't work with display: flex;
 */

// Default
body:not(.link--underline--lr):not(.link--underline-fat-lr):not(.link--underline-bg-bt):not(.link--underline-bg-gradient-bt) {
  p,
  h2,
  h3,
  h4,
  .list__bullets li,
  .rich-text li,
  .article-wrapper .rich-text li,
  .accordion .rich-text li,
  .article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
  .info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .label {
    a:not(.list__link):not(.btn) {
      color: $color-link;
      text-decoration: none;
      background-image: linear-gradient($color-link, $color-link);
      background-position: 0% 99%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: $transition;
      font-weight: $font-weight-body;
  
      &:hover,
      &:focus {
        background-size: 100% 1px;
        color: $color-link;
      }
    }
  }

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
        background-image: none;
        border-bottom: 1px solid var(--color-overlay-text);
    
        &:hover,
        &:focus {
          background-size: 0% 1px;
          color: var(--color-overlay-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
          background-image: none;
          border-bottom: 1px solid var(--color-overlay-text);
      
          &:hover,
          &:focus {
            background-size: 0% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
          background-image: none;
          border-bottom: 1px solid var(--color-overlay-text);
        }
    
        &:hover,
        &:focus {
          @include media-breakpoint-up(md) {
            background-size: 0% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }
  
  @include globalComponentColors($themes) {
    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn) {
        background-size: 100% 1px;
        color: themed('link');
        background-image: linear-gradient(themed('link'), themed('link'));
    
        &:hover,
        &:focus {
          color: themed('link');
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          background-size: unset;
          background-image: unset;

          &:hover,
          &:focus {
            color: var(--color-footer-arrows);
          }
        }
      }
    }

    &.bg--default {
      p,
      .rich-text li,
      .list__icons li,
      .label {
        a:not(.list__link):not(.btn) {
          background-size: 0% 1px;
          color: $color-link;
          background-image: linear-gradient($color-link, $color-link);
      
          &:hover,
          &:focus {
            background-size: 100% 1px;
            color: $color-link;
          }
        }
      }
    }
  }

  @include globalArticleColors($themes-article) {
    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn) {
        background-size: 100% 1px;
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
    
        &:hover,
        &:focus {
          color: themedArticle('text');
        }
      }
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--color-overlay-text);

      a:not(.list__link) {
        color: var(--color-overlay-text);
        background-image: none;
        border-bottom: 1px solid var(--color-overlay-text);
    
        &:hover,
        &:focus {
          color: var(--color-overlay-text);
        }
      }
    }
  }

  .hero {
    @include nestedComponentColors($themes) {
      .suggestions p {
        color: themed('text');
  
        a:not(.list__link) {
          color: themed('text');
          background-image: none;
          border-bottom: 1px solid themed('text');
      
          &:hover,
          &:focus {
            color: themed('text');
          }
        }
      }
    }
  }

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    @include nestedArticleColors($themes-article) {
      .rich-text a:not(.list__link):not(.btn) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
    
        &:hover,
        &:focus {
          color: themedArticle('text');
        }
      }
    }
  }

  .footer.hightlight-links p,
	.footer.hightlight-links li {
		a:not(.list__link):not(.btn) {
      color: var(--color-footer-text);
      background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
      background-size: 100% 1px;

			&:hover,
			&:focus {
				color: var(--color-footer-text);
			}
		}
	}
}

// All link animations
.link--underline--lr,
.link--underline-fat-lr,
.link--underline-bg-bt,
.link--underline-bg-gradient-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .label {
		a:not(.list__link):not(.btn) {
      color: $color-link;
      text-decoration: none;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
        }
      }
    }
  }

  @include nestedComponentColors($themes) {
    p,
    .label {
      a:not(.list__link) {
        color: themed('text');
  
        &:hover,
        &:focus {
          color: themed('text');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('text');
  
        &:hover,
        &:focus {
          color: themed('text');
        }
      }
    }
  }
}

// Underline on hover - left to right
.link--underline--lr {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
			color: $color-link;
			text-decoration: none;
			background-image: linear-gradient($color-link, $color-link);
      background-position: 0% 99%;
			background-repeat: no-repeat;
			background-size: 0% 1px;
			padding: 0;

      &:hover,
      &:focus {
        background-size: 100% 1px;
        color: $color-link;
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
        border-bottom: 1px solid var(--color-overlay-text);

        &:hover,
        &:focus {
          background-size: 0% 1px;
          color: var(--color-overlay-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
          border-bottom: 1px solid var(--color-overlay-text);
  
          &:hover,
          &:focus {
            background-size: 0% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
          border-bottom: 1px solid var(--color-overlay-text);
        }

        &:hover,
        &:focus {
          @include media-breakpoint-up(md) {
            background-size: 0% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }

  @include globalComponentColors($themes) {
    p,
    .label {
      a:not(.list__link) {
        color: themed('link');
        background-image: linear-gradient(themed('link'), themed('link'));
        background-size: 100% 1px;

        &:hover,
        &:focus {
          color: themed('link');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('text');
        background-image: linear-gradient(themed('text'), themed('text'));
        background-size: 100% 1px;

        &:hover,
        &:focus {
          color: themed('text');
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          background-size: unset;
          background-image: unset;

          &:hover,
          &:focus {
            color: var(--color-footer-arrows);
          }
        }
      }
    }
  }

  @include globalArticleColors($themes-article) {
    p,
    .label {
      a:not(.list__link) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
        background-size: 100% 1px;

        &:hover,
        &:focus {
          color: themedArticle('text');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
        background-size: 100% 1px;

        &:hover,
        &:focus {
          color: themedArticle('text');
        }
      }
    }
  }

  @include sectionColors($themes-section) {
    .feedback-banner,
    .feedback-response,
    .feedback-success {
      p {
        a:not(.list__link) {
          color: themedSections('link');
          background-image: linear-gradient(themedSections('link'), themedSections('link'));
          background-size: 100% 1px;
  
          &:hover,
          &:focus {
            color: themedSections('link');
          }
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: $black;
      background-image: linear-gradient($black, $black);
      background-size: 100% 1px;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }

  .suggestions p {
    a:not(.list__link) {
      text-decoration: none;
      background-position: 0% 99%;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
      padding: 0;
      background-size: 100% 1px;
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--color-overlay-text);

      a:not(.list__link) {
        color: var(--color-overlay-text);
        background-image: unset;
      }
    }
  }

  .hero {
    @include nestedComponentColors($themes) {
      .suggestions p {
        color: themed('text');
  
        a:not(.list__link) {
          color: themed('text');
          background-image: unset;
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: $black;
          background-size: 100% 1px;
          background-image: linear-gradient($black, $black);

          &:hover,
          &:focus {
            background-size: 100% 1px;
            color: $black;
          }
        }
      }
    } 
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
			background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
			color: var(--color-footer-text);
      padding: 0;
      background-size: 0% 1px;

      &:hover,
      &:focus {
        background-size: 100% 1px;
        color: var(--color-footer-text);
      }
		}
	}

	.footer.hightlight-links {
    p a,
    li a {
      background-size: 100% 1px;
    }
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: themedArticle('text');
          background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
          &:hover,
          &:focus {
            color: themedArticle('text');
          }
        }
      }
    }  
  }
}

// Underline - fat on hover - left to right
.link--underline-fat-lr {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li
  .form-richtext,
  .list__icons li,
  .label {
		a:not(.list__link):not(.btn) {
			color: $color-link;
			text-decoration: none;
			background-image: linear-gradient($color-link, $color-link);
			background-position: 0% 100%;
			border-bottom: 1px solid $color-link;
			background-size: 0% 1px;
			padding: 0;

			&:hover,
			&:focus {
				background-size: 100% 1px;
				color: $color-link;
			}
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
        border-bottom: 1px solid var(--color-overlay-text);
        background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));

        &:hover,
        &:focus {
          background-size: 100% 1px;
          color: var(--color-overlay-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
          border-bottom: 1px solid var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
  
          &:hover,
          &:focus {
            background-size: 100% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
          border-bottom: 1px solid var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
        }

        &:hover,
        &:focus {
          @include media-breakpoint-up(md) {
            background-size: 100% 1px;
            color: var(--color-overlay-text);
          }
        }
      }
    }
  }

  @include globalComponentColors($themes) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('link');
        background-image: linear-gradient(themed('link'), themed('link'));
        border-bottom: 1px solid themed('link');
        background-size: 0% 1px;
  
        &:hover,
        &:focus {
          background-size: 100% 1px;
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('text');
        background-image: linear-gradient(themed('text'), themed('text'));
        border-bottom: 1px solid themed('text');
        background-size: 0% 1px;
  
        &:hover,
        &:focus {
          background-size: 100% 1px;
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          background-size: unset;
          background-image: unset;
          border-bottom: none;

          &:hover,
          &:focus {
            background-size: unset;
            color: var(--color-footer-arrows);
          }
        }
      }
    }
  }

  @include globalArticleColors($themes-article) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
        border-bottom: 1px solid themedArticle('text');
        background-size: 0% 1px;
  
        &:hover,
        &:focus {
          background-size: 100% 1px;
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
        border-bottom: 1px solid themedArticle('text');
        background-size: 0% 1px;
  
        &:hover,
        &:focus {
          background-size: 100% 1px;
        }
      }
    }
  }

  @include sectionColors($themes-section) {
    .feedback-banner,
    .feedback-response,
    .feedback-success {
      p {
        a:not(.list__link) {
          color: themedSections('text');
          background-image: linear-gradient(themedSections('text'), themedSections('text'));
          border-bottom: 1px solid themedSections('text');
          background-size: 0% 1px;
    
          &:hover,
          &:focus {
            background-size: 100% 1px;
          }
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: $black;
      background-image: linear-gradient( $black,  $black);
      border-bottom: 1px solid $black;
      background-size: 0% 1px;

      &:hover,
      &:focus {
        background-size: 100% 1px;
        color: $black;
      }
    }
  }

  .suggestions p {
    a:not(.list__link) {
      background-size: 0% 1px;
      background-position: 0% 100%;
      background-repeat: no-repeat;
			transition: $transition;

			&:hover,
			&:focus {
				background-size: 100% 1px;
			}
    }
  }

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--color-overlay-text);

      a:not(.list__link) {
        color: var(--color-overlay-text);
        background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
        border-bottom: 1px solid var(--color-overlay-text);
      }
    }
  }

  .hero {
    @include nestedComponentColors($themes) {
      .suggestions p {
        color: themed('text');
  
        a:not(.list__link) {
          color: themed('text');
          background-image: linear-gradient(themed('text'), themed('text'));
          border-bottom: 1px solid themed('text');
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: $black;
          background-size: 0% 1px;
          border-bottom: 1px solid $black;
          background-image: linear-gradient($black, $black);

          &:hover,
          &:focus {
            background-size: 100% 1px;
            color: $black;
          }
        }
      }
    } 
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
      color: var(--color-footer-text);
			border-bottom: none;

      &:hover,
      &:focus {
        background-size: 100% 2px;
        background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
        background-position: 0% 99%;
        color: var(--color-footer-text);
        border-bottom: none;
      } 
		}
	}

	.footer.hightlight-links p,
	.footer.hightlight-links li {
		a:not(.list__link):not(.btn) {
			background-position: 0% 100%;
			background-image: linear-gradient(var(--color-footer-text), var(--color-footer-text));
			border-color: var(--color-footer-text);
			border-bottom: 1px solid var(--color-footer-text);
			transition: $transition;
			background-size: 0% 1px;

			&:hover,
			&:focus {
				background-size: 100% 1px;
			}
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: themedArticle('text');
          border-bottom: 1px solid themedArticle('text');
          background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
          &:hover,
          &:focus {
            color: themedArticle('text');
          }
        }
      }
    }  
  }
}

// Underline - background color on hover - bottom to top
.link--underline-bg-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
      color: $color-link;
      background-image: linear-gradient($color-link, $color-link);
      text-decoration: none;
      background-position: 0% 99%;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
			background-size: 100% 2px;
			padding: 3px 3px;
			border-bottom: none;

      &:hover,
      &:focus {
        background-size: 100% 100%;
        color: var(--color-link-colored-bg);
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
        background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));

        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: var(--color-overlay-button-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));
  
          &:hover,
          &:focus {
            background-size: 100% 100%;
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(var(--color-overlay-button), var(--color-overlay-button));
        }

        &:hover,
        &:focus {
          @include media-breakpoint-up(md) {
            background-size: 100% 100%;
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  @include globalComponentColors($themes) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-size: 100% 2px;
        background-image: linear-gradient(themed('link'), themed('link'));
        color: themed('link');
  
        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: themed('link-background-text');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('text');
        background-image: linear-gradient(themed('text'), themed('text'));
  
        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: themed('background');
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p,
      li {
        a:not(.list__link):not(.btn):not(.btn__link) {
          padding: 0;
        } 
      }

      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          background-size: unset;
          background-image: unset;
          padding: 0;
    
          &:hover,
          &:focus {
            background-size: unset;
            color: var(--color-footer-arrows);
          }
        }
      }
    }
  }

  @include globalArticleColors($themes-article) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-size: 100% 2px;
        color: themedArticle('text');
  
        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: themedArticle('background');
        }
      }
    }

    .rich-text li,
    .list__icons li {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themedArticle('text');
        background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: themedArticle('background');
        }
      }
    }
  }

  @include sectionColors($themes-section) {
    .feedback-banner,
    .feedback-response,
    .feedback-success {
      p {
        a:not(.list__link) {
          background-size: 100% 2px;
          color: themedSections('text');
    
          &:hover,
          &:focus {
            background-size: 100% 100%;
            color: themedSections('background');
          }
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: $black;
      background-image: linear-gradient($black, $black);

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  .suggestions p {
		a:not(.list__link) {
      text-decoration: none;
      background-position: 0% 99%;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
			background-size: 100% 2px;
			padding: 3px 3px;
			border-bottom: none;

			&:hover,
			&:focus {
        background-size: 100% 100%;
			}
		}
	}

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--color-overlay-text);

      a:not(.list__link) {
        color: var(--color-overlay-text);
        background-image: linear-gradient(var(--color-overlay-text), var(--color-overlay-text));
  
        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }

  .hero {
    @include nestedComponentColors($themes) {
      .suggestions p {
        color: themed('text');
  
        a:not(.list__link) {
          color: themed('text');
          background-image: linear-gradient(themed('text'), themed('text'));
    
          &:hover,
          &:focus {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          background-image: linear-gradient($black, $black);
          color: $black;
          background-size: 100% 2px;
          padding: 3px 3px;
          border-bottom: none;

          &:hover,
          &:focus {
            background-size: 100% 100%;
            color: $white;
          }
        }
      }
    }
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
			background-size: 100% 0;
			padding: 3px 3px;
			border-bottom: none;
			color: var(--color-footer-text);

      &:hover,
      &:focus {
        color: var(--color-footer);
        background-size: 100% 100%;

        &::before {
          color: var(--color-footer-text);
        }
      }
		}
	}

	.footer.hightlight-links {
		p,
		li {
			a:not(.list__link):not(.btn) {
				background-size: 100% 2px;

        &:hover,
        &:focus {
          background-size: 100% 100%;
          color: var(--color-footer);
        }
			}
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: themedArticle('text');
          background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
          &:hover,
          &:focus {
            color: themedArticle('background');
          }
        }
      }
    }  
  }
}

.link--underline-bg-gradient-bt {
	p,
	h2,
	h3,
	h4,
  .list__bullets li,
  .rich-text li,
	.article-wrapper .rich-text li,
	.accordion .rich-text li,
	.article-wrapper table,
  [data-creative~="article-content"] .rich-text li,
  [data-creative~="article-content"] .accordion .rich-text li,
  [data-creative~="article-content"] table,
	.info-box-article__references .rich-text-article__references ul li,
  .list__icons li,
  .form-richtext,
  .label {
		a:not(.list__link):not(.btn) {
      color: $color-link;
      text-decoration: none;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
			background-image: linear-gradient(120deg, $black 0%, var(--color-link) 100%);
			background-size: 100% 2px;
      background-position: 0% 90%;
			padding: 3px 3px;
			border-bottom: none;

      &:hover,
      &:focus {
        background-size: 100% 88%;
        color: var(--color-link-colored-bg);
      }
		}
	}

  .has-overlay {
    p,
    .label {
      a:not(.list__link):not(.btn) {
        color: var(--color-overlay-text);
        background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);

        &:hover,
        &:focus {
          background-size: 100% 88%;
          color: var(--color-overlay-button-text);
        }
      }
    }

    .rich-text {
      h2,
      h3,
      h4,
      h5 {
        a:not(.list__link):not(.btn) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);
  
          &:hover,
          &:focus {
            background-size: 100% 88%;
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  .has-overlay--md-up {
    p {
      a:not(.list__link):not(.btn) {
        @include media-breakpoint-up(md) {
          color: var(--color-overlay-text);
          background-image: linear-gradient(120deg, var(--color-overlay-button) 0%, var(--color-overlay-button) 100%);
        }

        &:hover,
        &:focus {
          @include media-breakpoint-up(md) {
            background-size: 100% 88%;
            color: var(--color-overlay-button-text);
          }
        }
      }
    }
  }

  .suggestions p {
		a:not(.list__link) {
      text-decoration: none;
      background-position: 0% 99%;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;
			background-size: 100% 2px;
			padding: 3px 3px;
			border-bottom: none;

			&:hover,
			&:focus {
        background-size: 100% 100%;
			}
		}
	}

  @include globalComponentColors($themes) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('link');
        background-size: 100% 2px;
        background-image: linear-gradient(120deg, themed('link') 0%, themed('link') 100%);
      
        &:hover,
        &:focus {
          color: themed('link-background-text');
          background-size: 100% 88%;
        }
      }
    }

    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        color: themed('link');
        background-size: 100% 2px;
        background-image: linear-gradient(120deg, themed('link') 0%, themed('link') 100%);
      
        &:hover,
        &:focus {
          color: themed('link-background-text');
          background-size: 100% 88%;
        }
      }
    }

    &.footer.hightlight-links--arrow {
      p,
      li {
        a:not(.list__link):not(.btn):not(.btn__link) {
          padding: 0;
        } 
      }

      p {
        a:not(.list__link):not(.btn):not(.btn__link) {
          background-size: unset;
          background-image: unset;
    
          &:hover,
          &:focus {
            background-size: unset;
            color: var(--color-footer-arrows);
          }
        }
      }
    }
  }

  @include globalArticleColors($themes-article) {
    p,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-size: 100% 2px;
        background-image: linear-gradient(120deg, themedArticle('text') 0%, themedArticle('text') 100%);
      
        &:hover,
        &:focus {
          color: themedArticle('background');
          background-size: 100% 88%;
        }
      }
    }

    p,
    .rich-text li,
    .list__icons li,
    .label {
      a:not(.list__link):not(.btn):not(.btn__link) {
        background-size: 100% 2px;
        background-image: linear-gradient(120deg, themedArticle('text') 0%, themedArticle('text') 100%);
      
        &:hover,
        &:focus {
          color: themedArticle('background');
          background-size: 100% 88%;
        }
      }
    }
  }

  @include sectionColors($themes-section) {
    .feedback-banner,
    .feedback-response,
    .feedback-success {
      p {
        a:not(.list__link) {
          background-size: 100% 2px;
          background-image: linear-gradient(120deg, themedSections('text') 0%, themedSections('text') 100%);
        
          &:hover,
          &:focus {
            color: themedSections('background');
            background-size: 100% 88%;
          }
        }
      }
    }
  }

  .banner__alert:not([class*="bg-"]) {
    a:not(.list__link):not(.btn) {
      color: $black;
      background-image: linear-gradient(120deg, $black 0%, $black 100%);

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  .suggestions p {
    a:not(.list__link):not(.btn) {
			background-size: 100% 2px;
      background-position: 0% 90%;
			padding: 3px 3px;
			border-bottom: none;
      text-decoration: none;
      background-repeat: no-repeat;
      transition: $transition;
      font-weight: $font-weight-body;

			&:hover,
			&:focus {
        background-size: 100% 88%;
			}
		}
	}

  .hero:not([class*="bg-"]) {
    .suggestions p {
      color: var(--color-overlay-text);

      a:not(.list__link) {
        color: var(--color-overlay-text);
        background-image: linear-gradient(120deg, var(--color-overlay-text) 0%, var(--color-overlay-text) 100%);
  
        &:hover,
        &:focus {
          color: var(--color-overlay-button-text);
        }
      }
    }
  }

  .hero {
    @include nestedComponentColors($themes) {
      .suggestions p {
        color: themed('text');
  
        a:not(.list__link) {
          color: themed('text');
          background-image: linear-gradient(120deg, themed('text') 0%, themed('text') 100%);
    
          &:hover,
          &:focus {
            color: themed('background');
          }
        }
      }
    }
  }

  .accordion.accordion__colored-backgrounds,
  .fact-box {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: $black;
          background-image: linear-gradient(120deg, $black 0%, $black 100%);
          background-size: 100% 2px;
          background-position: 0% 90%;
          padding: 3px 3px;
          border-bottom: none;

          &:hover,
          &:focus {
            background-size: 100% 90%;
            color: var(--color-link-colored-bg);
          }
        }
      }
    }
  }

	.footer p,
	.footer li {
		a:not(.list__link):not(.btn) {
      color: var(--color-footer-text);
			background-size: 100% 0;
			background-image: linear-gradient(120deg, var(--color-footer-text) 0%, var(--color-footer-text) 100%);

      &:hover,
      &:focus {
        background-size: 100% 90%;
        color: var(--color-footer);
  
        &::before {
          color: var(--color-footer-text);
        }
      }
		}
	}

	.footer.hightlight-links {
		p,
		li {
			a:not(.list__link):not(.btn) {
				background-size: 100% 2px;

        &:hover,
        &:focus {
          background-size: 100% 90%;
          color: var(--color-footer);
        }
			}
		}
	}

  // Fact boxes - no title
  .fact-box.fact-box__no-title {
    @include nestedArticleColors($themes-article) {
      .rich-text {
        a:not(.list__link):not(.btn):not(.btn__link),
        li a {
          color: themedArticle('text');
          background-image: linear-gradient(themedArticle('text'), themedArticle('text'));
  
          &:hover,
          &:focus {
            color: themedArticle('background');
          }
        }
      }
    }  
  }
}
