.three-column-carousel {
  width: 100%;

  @include media-breakpoint-up(lg) {
    display: flex !important;
    flex-wrap: wrap;
  }

  &.owl-carousel {
    .owl-dots {
      display: none;

      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
  }

  .owl-item [class*="col-"] {
    @include media-breakpoint-down(md) {
      height: 100%;
      max-width: 100%;
      margin-top: 0;
    }
  }

  .owl-stage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .owl-item {
    display: flex;
  }

  .item {
    height: 100%;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  .owl-nav.disabled {
    display: none;
  }
}

.row.row--grid .three-column-carousel {
  [class*=col-] {
    @include media-breakpoint-down(md) {
      margin-top: 0;
      padding-top: 30px;
    }
  }
}
