/**
 * Intranet
 * Login page
 */

.login-page {
  padding-top: 0;

  .login {
    position: relative;
    min-height: 100vh;

    .login__bg-image {
      img {
        width: 33.33%;
        height: 100vh;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0
    }

    .logo {
      height: 70px;
      width: auto;

      img {
        z-index: 10;
        position: relative;
        width: auto;
        height: 100%;
        object-fit: contain;
        background-size: contain !important;
        opacity: 0;
      }
    }

    .login__form {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 90vh;

      .form-container {
        padding: 50px 50px 35px 50px;
        border-top: none;
        background: $white;
        width: 100%;

        @include media-breakpoint-down(md) {
          padding: var(--sleeve-component-padding);
        }
    
        .form-group__img {
          margin-bottom: 15px;
        }
    
        input {
          &::-webkit-input-placeholder {
            color: $grey-dark;
          }
    
          &:disabled {
            &::-webkit-input-placeholder {
              color: $grey-dark;
            }
          }
        }

        .btn-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 40px;

          .btn__submit {
            width: 100%;
            margin: 0 0 30px 0;
          }
        }
      }
    }
  }
}

// Move to form scss
.form-title {
  margin-bottom: 40px;
  text-align: center;
}

