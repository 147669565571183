/**
 * Side menu - no dropdowns
 * Is used on: Sleeve light
 */

.side-menu__no-dropdowns {
  // This is a duplicate
  margin-top: 65px;

  @include media-breakpoint-down(md) {
    z-index: 200;
    margin-top: 0;
    background: transparent;

    // Hide sidemenu, that is not cloned on mobile
    //display: none;
  }

  // Mobile toggle button
  .side-menu__toggle-mobile {
    display: none;
    background-color: $white;
    color: $black;

    @include media-breakpoint-down(md) {
      //z-index: 1000;
      background-color: transparent;
      position: relative;
      left: 0;
      top: auto;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 55px;
      padding: 10px 55px 10px 15px;
      font-size: $component-title-font-size;
      font-weight: $font-weight-heading;
      //box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
      text-align: left;
      border-bottom: 1px solid #e3e3e3;
    }

    &::before {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: #e3e3e3;
      }
    }

    &::after {
      @include media-breakpoint-down(md) {
        position: absolute;
        display: flex;
        content: "";
        right: 18px;
        top: 50%;
        transition: $transition;
        width: 9px;
        height: 9px;
        border-style: solid;
        border-color: $color-arrows;
        border-width: 2px 2px 0 0;
        transform: translate(0, -50%) rotate(135deg);
        top: 49%;
      }
    }

    &.show {
      &:after {
        @include media-breakpoint-down(md) {
          top: 55%;
          transform: translate(0, -50%) rotate(-45deg);
        }
      }
    }
  }

  .side-menu__title {
    font-size: $component-title-font-size;
    line-height: $line-height-primary;
    
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

	ul {
		@include list-unstyled();

		li {
			padding-left: 0 !important;
		}
  }

  li.side-menu__level-one {
    margin-bottom: 15px;
  }

  // This is a duplicate END




  // Go back button
  button.side-menu__btn-back {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;
    color: $black;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .arrow__back {
      position: relative;
      width: 36px;
      height: 36px;
      margin-right: 10px;
      border-radius: 50%;

      &:hover {
        // Override global arrow effect - Remove effect gloabaly fron icon-list file
        &:before {
          left: 50%;
        }
      }

      &:before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(180deg);
      }

      &:after {
        z-index: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $color-arrows;
        opacity: 0.1;
        border-radius: 50px;
      }
    }
  }

  &.show {
    ul.sidemenu__dropdown {
      transform: translateY(0);
      display: block;
    }
  }

  // Side menu title
  .side-menu__title {
    margin-bottom: 30px;
  }

  ul.sidemenu__dropdown {
    @include media-breakpoint-down(md) {
      display: none;
      //z-index: 200;
      //position: absolute;
      //top: initial;
      //left: 0;
      transform: translateY(100%);
      //height: 100vh;
      //overflow-y: scroll;
      background: transparent;
      width: 100%;
      //padding-bottom: 400px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  li a {
    display: flex; // Web accessibility
    min-width: 24px; // Web accessibility
    min-height: 25px; // Web accessibility
  }

  li > ul {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  li:not(.side-menu__level-one) {
    font-size: $component-font-size;
    line-height: $line-height-secondary;
    margin-bottom: 10px;

    ul {
      margin-top: 15px;
      margin-bottom: 15px;

      li {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  a.active,
  a:hover {
    color: $color-link;
  }

  a.active {
    font-weight: 600;
  }
}
