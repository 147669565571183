.map-container {
    width: 100%;
    margin-bottom: 30px;
    height: 500px;
    &.height-small {
        height: 200px
    }
    &.height-medium {
        height: 500px
    }
    &.height-large {
        height: 700px
    }
    
}