.table-hours {
	border-radius: var(--sleeve-component-border-radius);
    
	&:not(.has-image) {
		border-radius: var(--sleeve-component-border-radius);
    }

    .hidden {
			display: none !important;
    }

    .subtitle {
			margin-top: 30px;
    }

    table {
			tr {
				&.inactive th, 
        &.inactive td{
					color: $grey-dark;
				}
				
				&:last-of-type {
					th,
					td {
						padding-bottom: 0;
					}
				}
			}
        
			th,
			td {
				font-family: $primary-font;
				font-size: var(--component-body-font-size);
				line-height: $line-height-secondary;
				padding-bottom: 5px;
				padding-left: 0;
				font-weight: $font-weight-body;
				text-align: left;
				vertical-align: top;
        		color: $black;
			}
			
			th {
				padding-right: 25px;
			}
    }
    
	.btn__link {
		margin-top: 20px;
	}
	
	.status {
		margin-top: 15px;
		margin-bottom: 0;
		display: block;
		color: $grey-dark;
		font-weight: 500;
		font-family: var(--font-primary);
		position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		
		.opened { 
			color: $success-green; 
		}

		.open-fill { 
			background-color: $success-green;
		}

		.closed { 
			color: $error-red;
		}

		.busy-fill {
			background-color: $warning-orange;
		}

		.busy {
			color: $warning-orange;
		}

		.closed-fill { 
			background-color: $error-red;
		}

		span:not(.status-indicator), span:not(.info-close-dates){
			font-size: $small-details-font-size;
		}
	
		.status-indicator {
			display: inline-block;
			margin-right: 5px;
			height: 10px;
			width: 10px;
			border-radius: 50%;
		}

		.status-text-holiday::before, .status-text::before {
			margin: 0 0.5em;
			content: '|';
		}
	}
}

//table hours in side-box
.side-box .table-hours {
  margin-bottom: 15px;
}

// Color options
@include globalComponentColors($themes) {
	.table-hours {
    th, 
    tr, 
    td, 
    .status-indicator-open, 
    .status-indicator-closed, 
    tr.inactive th, 
    tr.inactive td {
			color: themed('text');
		}
	
		.status-indicator {
			border: 2px solid themed('text');
		}
	}
}

.side-box {
  @include nestedComponentColors($themes) {
    .table-hours {
      th, tr, td, .status-indicator-open, .status-indicator-closed, tr.inactive th, tr.inactive td {
        color: themed('text');
      }
    
      .status-indicator {
        border: 2px solid $white;
      }
    }
  }
}
