/*Styling on image heros*/

.media__container {
	height: 100%;
}

.hero--transparent-header {
	.media__container {
		&:before {
			background: linear-gradient(
				to top,
				rgba(0, 0, 0, 0) 70%,
				rgba(0, 0, 0, 0.75) 100%
			);
			height: 100%;
			width: 100%;
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			z-index: 1;
		}

		.bg-overlay {
			display: none;
		}

		.bg-overlay-0deg {
			display: none;
		}
	}

	.container {
		z-index: 2;
	}

  &.section-padding {
    @include media-breakpoint-down(md) {
      padding-top: 100px;
    }
  }
}

.umb-block-list ~ main,
.header--transparent ~ main {
	padding-top: 0px;
}
