#cludo-404 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: $white;
  padding: var(--sleeve-component-padding);
  box-shadow: unset;
  border-radius: var(--sleeve-component-border-radius);

  .cludo-result-item-title {
    @include responsive-font-size($h3-font-size-sm, "", $h3-font-size-sm, "", $h3-font-size-lg);
  }

  a {
    transition: $transition;
    cursor: pointer;
  }

  ul {
    li.cludo-result-item {
      width: 100%;
      margin-bottom: 15px;
      border-top: 1px solid var(--color-grey-medium);
      padding-top: 18px;
  
      &:nth-child(n + 2) {
        margin-top: 15px;
      }

      .cludo-result-item-title {
        font-size: $component-font-size;
        font-weight: 500;

        a.cludo-result-item-path {
          font-size: 100%;
          color: $black;
          background-image: none; //- Unset link animations
          border-bottom: unset; //- Unset link animations

          &:hover {
            background-size: 0; //- Unset link animations
          }
        }
      }

      a.cludo-result-item-path {
        margin-bottom: 0;
        font-size: 87.5%;
        color: $grey-dark;

        &:hover,
        &:focus {
          color: $color-link;
        }
      }
    }
  }
}
