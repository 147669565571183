.form-subscribe {
  .flex-row  {
    .form-group {
      width: 100%;
    }

    .btn-container {
      align-items: center;
      margin-top: 15px;
      margin-left: 20px;

      .btn {
        min-height: 55px;
        margin-top: 0;
      }
    }
  }

  .form-check:last-of-type {
    margin-bottom: 0;
  }

  .form-sub-response {
    h2 {
      margin-bottom: 10px;
    }
    
    .checkmark-icon,
    .error-icon {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
