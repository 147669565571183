/**
 * Text
 */

p,
span,
a,
button {
	font-family: $secondary-font;
  hyphens: var(--hyphens);
}

p,
li {
  color: $black;
}

p {
	font-size: $component-font-size;
	font-weight: $font-weight-body;
  line-height: $line-height-secondary;
	margin-top: 0;
	margin-bottom: 20px;

	strong {
		font-weight: 600;
	}

  span {
    font-size: inherit;
    line-height: inherit;
  }

	.intext-tooltip {
		color: inherit;
		@include responsive-font-size(1em, "", 1em, "", 1em);
	}
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $grey-medium;
}

.rich-text--1-5x,
.rich-text--x1-5,
p.p--x1-5 {
  font-size: calc($component-font-size * 1.5);
}

.rich-text--2x,
.rich-text--x2,
p.p--x2 {
  font-size: calc($component-font-size * 2);
}

.c-font-size {
  font-size: $component-font-size;
}

.c-font-size--x2 {
  &.footer__content {
    h2.h3 {
      font-size: calc($component-font-size * 2) !important;
    }
  }

  li a {
    font-size: calc($component-font-size * 2) !important;
  }

  h2,
  .h2,
  h3,
  .h3,
  p,
  li a,
  [class*="icon-"] {
    font-size: calc($component-font-size * 2) !important;
  }

  .rich-text {
    h2,
    h2.c-font-size,
    h3,
    h4,
    h5,
    p,
    li {
      font-size: calc($component-font-size * 2) !important;
    }
  }
}

.c-font-size--x1-5 {
  &.footer__content {
    h2.h3 {
      font-size: calc($component-font-size * 1.5) !important;
    }
  }

  li a {
    font-size: calc($component-font-size * 1.5) !important;
  }

  .rich-text {
    h2,
    h2.c-font-size,
    h3,
    h4,
    h5,
    p,
    li {
      font-size: calc($component-font-size * 1.5) !important;
    }
  }
}

.article-wrapper,
[data-creative~="article-content"] {
	.rich-text {
		p,
		ul li,
		ol li {
			@include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
      line-height: $line-height-article-body;
		}

    ul li ul li,
    ul li p,
    ol li ol li,
    ol li p {
      font-size: inherit;
      line-height: inherit;
    }
	}

  // Components in article-wrapper 
  .grid-dynamic {
    .rich-text {
      p,
      ul li,
      ol li {
        font-size: $component-font-size;
      }
    }
  }

  // Enlarge text in article-wrapper 
  .rich-text--1-5x,
  .rich-text--x1-5,
  p.p--x1-5 {
    font-size: calc($component-font-size * 1.5);
  }

  .rich-text--2x,
  .rich-text--x2,
  p.p--x2 {
    font-size: calc($component-font-size * 2);
  }

  .list__downloads {
    ul {
      li {    
        .list__download {
          @include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
          line-height: $line-height-article-body;
        }
      }
    }
  }
}

a,
li a {
	@include responsive-font-size(0.875em, "", 0.875em, "", 1em);
}

a {
	color: $black;
	font-family: $secondary-font;
	font-weight: 500;

	&:hover {
		text-decoration: none;
		color: $black;
	}
}

li a {
	font-weight: $font-weight-body;
}
