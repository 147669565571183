.btn__like {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 24px; // Web accessibility
  min-height: 24px; // Web accessibility

  &:hover,
  &:focus {
    .btn__like__icon {
      svg.btn__like__icon--outline {
        transform: scale(0.33);
        opacity: 0;
      }
      
      svg.btn__like__icon--filled {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .btn__like__icon {
    position: relative;
    width: 18px;
    height: 18px;
  
    &.liked {
      svg.btn__like__icon--outline {
        transform: scale(0.33);
        opacity: 0;
      }
      
      svg.btn__like__icon--filled {
        transform: scale(1);
        opacity: 1;
      }
    }
  
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease, opacity 0.3s ease;

      path {
        fill: $color-icons;
      }
    }
  
    svg.btn__like__icon--outline {
      transform: scale(1);
      opacity: 1;
    }
  
    svg.btn__like__icon--filled {
      transform: scale(0.33);
      opacity: 0;
    }
  }

  span {
    margin-left: 6px;
    margin-top: 2px;
    font-size: $small-font-size;
    line-height: 1.5;
  }
}

