/**
 * Tab panel
 */

 .tab-panel--container {
  .tab-panel--desktop,
  .tab-panel--desktop.active {
    @include media-breakpoint-down(md) {
      //display: none;
    }
  }
  .tab-panel--desktop {
    display: none;
    &.active {
      display: block;
    }
  }

  .tab-controls {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .tab-control--container {
      width: 100%;

      @include media-breakpoint-up(lg) {
        border: 1px solid $grey-medium;
        border-right: none;
      }

      &:first-of-type {
        @include media-breakpoint-up(lg) {
          border-top-left-radius: var(--sleeve-component-border-radius);
          border-bottom-left-radius: var(--sleeve-component-border-radius);
        }

        .tab-control {
          @include media-breakpoint-up(lg) {
            border-top-left-radius: var(--sleeve-component-border-radius);
            border-bottom-left-radius: var(--sleeve-component-border-radius);
          }

          @include media-breakpoint-down(md) {
            border-top-left-radius: var(--sleeve-component-border-radius);
            border-top-right-radius: var(--sleeve-component-border-radius);
          }
        }
      }

      &:last-of-type {
        @include media-breakpoint-up(lg) {
          border-right: 1px solid $grey-medium;
          border-top-right-radius: var(--sleeve-component-border-radius);
          border-bottom-right-radius: var(--sleeve-component-border-radius);
        }

        .tab-control {
          @include media-breakpoint-up(lg) {
            border-top-right-radius: var(--sleeve-component-border-radius);
            border-bottom-right-radius: var(--sleeve-component-border-radius);
          }

          @include media-breakpoint-down(md) {
            border-bottom-left-radius: var(--sleeve-component-border-radius);
            border-bottom-right-radius: var(--sleeve-component-border-radius);
          }
        }
      }
    }

    .tab-control {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: calc(30px + var(--component-border-top-width));
      background-color: $color-body;
      transition: background-color 0.2s ease-in-out 0s;

      &:focus-visible {
        z-index: 5;
        outline: 2px solid #0000EE;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
        align-items: center;
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
        background-color: $white;
        border-bottom: none;
        padding: 15px 43px 15px 15px;
        outline: 1px solid $grey-medium;
      }

      &:after {
        @include media-breakpoint-down(md) {
          content: '';
          display: inline-block;
          position: absolute;
          right: 7px;
          top: 50%;
          transition: all 0.2s ease-in-out;
          width: 9px;
          height: 9px;
          border-style: solid;
          border-color: $color-arrows;
          border-width: 2px 2px 0 0;
          transform: translateY(-50%) translateX(-15px) rotate(135deg);
        }
      }

      p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: $component-font-size;
        line-height: $line-height-secondary;
        font-weight: $font-weight-heading;
        text-align: left;
  
        @include media-breakpoint-up(lg) {
          text-align: center;
        }

        @include media-breakpoint-down(md) {
          margin: auto 0;
        }
      }
    }

    .tab-control.active,
    .tab-control--container.active .tab-control {
      background-color: $white;
      border-bottom-width: var(--component-border-top-width);
      border-bottom-style: solid;
      border-bottom-color: $color-icons;
      padding-bottom: 30px;
      @include media-breakpoint-down(md) {
        padding-bottom: 15px;
      }

      &:after {
        @include media-breakpoint-down(md) {
          transform: translateY(-15%) translateX(-15px) rotate(-45deg);
        }
      }
    }

    // hover border styling
    @include media-breakpoint-up(lg){
      .tab-control--container .tab-control:not(.active) {
        span:not(.icon){
          transition: all 0.3s ease-in-out 0s;
        }

        &::after {
          transition: all 0.3s ease-in-out 0s;
          content: '';
          position: absolute;
          width: 100%;
          height: var(--component-border-top-width);
          bottom: 0;
          width: 0;
          left: 0;
          background-color: $color-icons;
        }

        &:hover {
          &::after{
            width: 100%; 
          }

          span:not(.icon) {
            color: $color-link;
          }
        }
      }
    }
    .tab-panel--mobile {
      display: none;
      &.active {
        display: block;
      }
    }

    .tab-panel--mobile,
    .tab-panel--mobile.active {
      // margin-top: 20px;
      margin-bottom: 30px;

      @include media-breakpoint-up(lg) {
        //display: none;
      }
    }

    [class*="icon-"] {
      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
        text-align: center;
      }

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }

      //Icon
      &:before {
        font-size: 2.75rem; // icon size

        @include media-breakpoint-down(md) {
          font-size: 1.875rem; // icon size
        }
      }
    }

    // When using an SVG, height corresponds to icon size
    //!important is necessary to overwrite default svg styling.
    svg {
      height: 2.75rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }

      @include media-breakpoint-down(md) {
        height: 1.875rem; // icon size
        margin-right: 20px;
      }
    }
  }

  .tab-panel {
    .card {
      margin: 2px; // for focus outline
    }
  }
}
