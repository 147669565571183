/**
 * Buttons
 * .btn__color-theme
 * Theme darkmode 
 * Add class .dark-theme to body
 */

.btn__color-theme--darkmode {
  .icon-darkmode-moon,
  .icon-lightmode-sun {
    font-size: 20px;
  }
}
