.header--tagged-desktop-actions {
	justify-content: center;
	align-items: center;
	margin-left: auto;
	display: none;

	@include media-breakpoint-up(lg) {
		display: flex;
	}

	.desktop-actions-searchbar-close-btn {
		position: absolute;
		height: 24px;
		width: 24px;
		right: 32px;
		top: 7px;
		z-index: 5;

		span {
			display: block;
			position: absolute;
			width: 80%;
			height: 2px;
			background-color: $color-icons;

			&:nth-of-type(1) {
				transform: rotate(45deg);
			}

			&:nth-of-type(2) {
				transform: rotate(-45deg);
			}
		}
	}

	li {
		&:not(:first-child) {
			margin-left: 15px;
		}
	}

	.suggestions--autocomplete li {
		margin-left: unset;
	}
}

.desktop-actions-searchbar {
	margin-left: 15px;
	border-radius: 25px;

	.desktop-actions-searchbar-form {
		display: flex;
		position: relative;
		min-width: 40px;
		min-height: 40px;
		border-radius: 25px;
    margin-bottom: 0; // Override initial form margin

    &::after {
      content: "";
      height: 100%;
      width: 100%;
      background-color: var(--color-icons);
      opacity: .1;
      position: absolute;
      border-radius: 25px;
      z-index: 0;
    }
	}

	.desktop-actions-searchbar-btn,
	.desktop-actions-searchbar-submit {
    z-index: 1;
		width: 40px;
		height: 40px;
		border-radius: 999px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 7px;
		position: absolute;
		right: 0;
		color: $color-icons;
	}

  .desktop-actions-searchbar-btn {
    svg {
      width: auto;
      height: 26px;
    }
  }

	.desktop-actions-searchbar-label {
		color: $black;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 18px;
	}

	.desktop-actions-searchbar-input {
		width: 40px;
		border-radius: 25px;
		height: 40px;
		color: $black;
		padding: 0 15px;
		transition: width 0.25s ease-in-out;

		&.active {
			width: 400px;

			&::placeholder {
				visibility: visible;
			}
		}

		&::placeholder {
			color: $black;
			visibility: hidden;
		}
	}
}

.header--tagged-mobile-actions {
	display: flex;
	margin-left: auto;
  padding: 15px 0;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.mobile-actions-search-icon {
		display: flex;
		border-right: rgba(255, 255, 255, 90) 1px solid;
		padding-right: 15px;

		&:not(:first-child) {
			margin-left: 10px;
		}
	}

	.mobile-actions-search-btn {
		display: flex;
		width: 30px;
		height: 30px;
	}

	.mobile-actions-burger {
		display: flex;
		padding-left: 15px;

		.mobile-actions-burger-btn {
			position: relative;
		}

		.mobile-actions-burger-icon {
			position: relative;
			display: flex;
			overflow: hidden;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			transition: all 0.2s ease;
		}

		.burger-icon-elmnt {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: all 0.2s;
			transform-origin: center;
			overflow: hidden;
			width: 30px;
			height: 20px;

			.burger-icon-elmnt-dash {
				background-color: $color-icons;
				transition: all 0.3s ease;
				transform-origin: left;
				animation-delay: 100ms;
				height: 2px;
				width: 30px;

				&.translate-y-6 {
					transform: translateY(24px);
				}
			}
		}

		.burger-cross-icon {
			position: absolute;
			display: flex;
			justify-content: space-between;
			transition: all 0.5s ease;
			top: 15px;

			&.translate-x-0 {
				transform: translateX(0);
				width: 48px;
			}

			&.-translate-x-10 {
				transform: translateX(-40px);
				width: 0;
			}

			.burger-cross-icon-right,
			.burger-cross-icon-left {
				position: absolute;
				background-color: $color-icons;
				transition: all 0.3s ease;
				transition-delay: 300ms;
				height: 2px;
				width: 30px;
			}

			.burger-cross-icon-right {
				transform: rotate(0);

				&.rotate-45 {
					transform: rotate(45deg);
				}
			}

			.burger-cross-icon-left {
				transform: rotate(0);

				&.-rotate-45 {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.mobile-actions-searchbar-container {
	background-color: var(--color-header);
	position: relative;
	opacity: 0.98;
	width: 100%;
	z-index: 2;
	padding: 12px 15px;

	&.alpine-transition-enter {
		transition: all 0.2s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateY(-100%);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave {
		transition: all 0.2s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateY(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateY(-100%);
	}
}

.mobile-actions-searchbar-form {
	display: flex;
	align-items: center;
	width: 100%;
	border: none;

	&:focus-within {
		outline: none;
	}
}

.mobile-actions-searchbar-input {
	position: relative;
	width: 100%;
	height: 26px;
	margin-right: 24px;
}

.mobile-actions-searchbar-label {
	color: var(--color-header-text);
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	left: 0;
	display: flex;
	align-items: center;
	margin-top: 2px;
	margin-left: 1px;

	&.alpine-transition-enter {
		transition: all 0.1s ease-out;
	}

	&.alpine-transition-enter-start {
		opacity: 0;
		transform: translateX(16px);
	}

	&.alpine-transition-enter-end {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave {
		transition: all 0.1s ease-in;
	}

	&.alpine-transition-leave-start {
		opacity: 0.9;
		transform: translateX(0);
	}

	&.alpine-transition-leave-end {
		opacity: 0;
		transform: translateX(16px);
	}
}

#input-mobile {
	background-color: transparent;
	color: var(--color-header-text);
	margin-right: 24px;
	transition: all 0.2s ease;
	width: 0;
	border: none;
	border-radius: 0;
	height: 100%;
	-webkit-appearance: none;

	&:focus {
		outline: none;
		width: 100%;
		box-shadow: none;
		border-bottom: solid 1px var(--color-header-text);
	}

	&.w-full.border-b {
		width: 100%;
		border-bottom: solid 1px var(--color-header-text);
	}
}

.mobile-actions-searchbar-submit {
	color: var(--color-header-text);
	border: var(--color-header-text) 2px solid;
	border-radius: 9999px;
	margin-left: auto;
	min-height: 46px;
	min-width: 46px;
}