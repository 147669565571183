.timeline {
  width: 100%;
}

ul.list__timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border-left-style: solid;
  border-width: 1px;
  border-color: $grey-medium;
  border-inline-start-width: 1px;
  box-sizing: border-box;

  li {
    margin-inline-start: 25px;
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.has-title-bg {
      .card {
        &:not([class*="bg-"]):not([class*="card__"]) {
          padding: 0 var(--sleeve-component-padding);
        }
      }
    }
  }

  .list__timeline__header {
    // margin-bottom: 30px;
    border-radius: 50px;

    &[class*="bg-"] {
     &:not(.has-icon) {
        padding: 15px 25px;
      }
    }

    @include nestedComponentColors($themes) {
      background-color: themed('background');

      .list__timeline__dot {
        border-color: themed('background');
      }

      h2,
      h3 {
        color: themed('text');
      }
    }

    @include nestedArticleColors($themes-article) {
      background-color: themedArticle('background');

      .list__timeline__dot {
        border-color: themedArticle('background');
      }

      h2,
      h3 {
        color: themedArticle('text');
      }
    }

    &.has-icon {
      @include nestedComponentColors($themes) {
        .title__icon__bg {
          background: themed('background');

          &::before {
            color: themed('text');
          }

          &::after {
            background-color: themed('text');
          }
        }
      }

      @include nestedArticleColors($themes-article) {
        .title__icon__bg {
          background: themedArticle('background');

          &::before {
            color: themedArticle('text');
          }

          &::after {
            background-color: themedArticle('text');
          }
        }
      }

      .list__timeline__dot {
        margin-top: 1.275rem;
      }
    }

    .list__timeline__dot {
      position: absolute;
      background-color: var(--color-body);
      border-radius: 50px;
      border-width: 5px;
      border-style: solid;
      border-color: var(--color-icons);
      width: 17px;
      height: 17px;
      margin-top: .375rem;
      inset-inline-start: -0.500rem;
    }
  
    .title {
      margin-bottom: 0;
    }
  }

  .card {
    border-radius: $border-radius-component;
    margin-top: 30px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:not([class*="card__"]) {
      div:not(.tags-container):not(.tag) {
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .image {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .btn-container {
        margin-top: 0;
      }
    }
    
    &:not([class*="bg-"]):not([class*="card__"]) {
      background-color: transparent;
    }
  
    &[class*="bg-"] {
      padding: var(--sleeve-component-padding);
    }
  }
}

@import 'list__timeline--split-column';
