.feedback-banner {
  .feedback-banner__buttons {
    display: flex;
  }
}

.feedback-banner,
.feedback-success {
	display: flex;
	align-items: center;
	padding: 30px 0;
	min-height: 120px;

	@include media-breakpoint-down(sm) {
		padding: 60px 0;
	}

	[class*="col-"] {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		margin-top: 0 !important;

		@include media-breakpoint-down(sm) {
			justify-content: center;
			margin-bottom: 30px;
		}

		&:last-of-type {
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
	}

	&__icon {
		& .feedback-banner__content__icon {
			font-size: 70px; // icon size
		}
	}

	&__text {
		h2 {
			margin-bottom: 0;
			font-size: $h3-font-size-lg;
			line-height: $line-height-primary;
			font-weight: 600;

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

    p {
      font-size: $component-font-size;
      line-height: $line-height-secondary;
    }
	}

	&__message {
		& .yes-response,
		& .no-response {
			display: none;
		}

		& .show {
			display: inline-block;
		}

		.btn__secondary--outline {
			color: $color-primary-text;

			&::after {
				border-color: $color-primary-text;
			}
		}
	}
}

.feedback-success {
	display: none;

	h2 {
		margin-bottom: 15px;
	}

	&__icon {
		svg {
			width: 70px;
		}
	}

	@include media-breakpoint-down(sm) {
		.feedback-banner__text {
			text-align: center;
		}
	}
}

.feedback-response {
	display: none;

	.feedback-banner__message {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 0 0 40px 0;

    h2, 
    h3,
    h4,
    h5,
    p,
		.disclaimer,
    .invalid-feedback,
    label {
			color: $color-primary-text;
      line-height: $line-height-secondary;
		}

    p#feedback-message {
      font-size: $form-font-size;
    }

    .disclaimer,
    .invalid-feedback {
			font-size: $small-details-font-size;
      margin-top: 5px;
		}

		textarea {
			height: 65px;
			width: 100%;
			padding: 10px;
		}

		.invalid-feedback {
			color: $red !important;
		}

		a {
			text-decoration: underline;
		}

		.btn {
			align-self: flex-start;
			margin-top: 20px;
		}
	}
}

@include globalComponentColors($themes) {
  .feedback-banner,
  .feedback-response,
  .feedback-success {
    &__icon {
      .feedback-banner__content__icon {
        &::before {
          color: themed('icon');
        }
      }
    }

    h2,
    h3,
    h4,
    h5,
    label {
      color: themed('text');
    }

    .textarea-counter .textarea-counter__maxlength::after {
      color: themed('text');
    }

    &__message {
      p {
        a {
          color: themed('text');
        }
      }

      button.btn__secondary--outline {
        color: themed('text');

        &:after {
          border-color: themed('text');
        }

        &:hover:disabled {
          background-color: transparent;
        }

        &:hover:enabled,
        &:focus:enabled {
          background-color: themed('text');
          color: themed('background');
        }
      }
    }

    p,
    p a {
      color: themed('text');
    }
  }
}

@include sectionColors($themes-section) {
  .feedback-banner,
  .feedback-response,
  .feedback-success {
    &__icon {
      .feedback-banner__content__icon {
        &::before {
          color: themedSections('icon');
        }
      }
    }

    h2,
    h3,
    h4,
    h5,
    label {
      color: themedSections('text');
    }

    .textarea-counter .textarea-counter__maxlength::after {
      color: themedSections('text');
    }

    &__message {
      p {
        a {
          color: themedSections('text');
        }
      }

      button.btn__secondary--outline {
        color: themedSections('text');

        &:after {
          border-color: themedSections('text');
        }

        &:hover:disabled {
          background-color: transparent;
        }

        &:hover:enabled,
        &:focus:enabled {
          background-color: themedSections('text');
          color: themedSections('background');
        }
      }
    }

    p,
    p a {
      color: themedSections('text');
    }
  }
}
