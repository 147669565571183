// Viewer.js overide
.viewer-backdrop {
  background-color: var(--color-overlay) !important;
}

.viewer-title {
  color: $white !important;
  margin: 0 5% 10px !important; 
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.5);
  font-family: $secondary-font;
  font-weight: $font-weight-heading;
  font-size: 14px !important;
  line-height: $line-height-secondary !important;
  opacity: 1 !important;
}

.viewer-container {
  .viewer-toolbar > ul {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .viewer-toolbar > ul > li {
    position: relative;
    width: 44px;
    height: 44px;
  }
  
  .viewer-prev::before, 
  .viewer-next::before {
    position: absolute;
    font-family: 'ctaicons', sans-serif !important;
    content: "\e200";
    font-size: 26px;
    line-height: 1;
    color: $white;
    background-image: none; //- Remove default arrows
    width: 26px;
    height: 26px;
  }
  
  .viewer-prev:before {
    top: -15%;
    left: -14%;
    transform: translate(-50%, -50%);
    rotate: 180deg;
  }

  .viewer-next::before {
    top: 46%;
    left: 46%;
    transform: translate(-50%, -50%);
  }
}
