&.bg--gray,
&:not(.bg--border-top):not(.bg--primary):not(.bg--secondary) {
  background: $white;

  .side-box__media__icon {
    background: $btn-bg--grey-light-bg-color;
    color: $btn-bg--grey-light-text-color;

    .logo {
      svg path {
        fill: $btn-bg--grey-light-text-color;
      }
    }

    [class*="icon-"]::before {
			color: $btn-bg--grey-light-text-color;
    }

    .tag {
      color: $btn-bg--grey-light-text-color;
    }
  }

  .side-box__content {
    padding: var(--sleeve-component-padding);
  }
}

&.bg--border-top {
  .side-box__media__icon {
    background: $color-border-top--article-components-bg;
    color: $color-border-top--article-components-text;

    .logo {
      svg path {
        fill: $color-border-top--article-components-text;
      }
    }

    [class*="icon-"]::before {
			color: $color-border-top--article-components-text;
    }

    .tag {
      color: $color-border-top--article-components-text;
    }
  }

  .side-box__content{
    border-top-width: var(--component-border-top-width);
    border-top-style: solid;
    border-top-color: $color-border-top--article-components-bg;
    padding: 15px 0 0 0;
  }
}

@include nestedComponentColors($themes) {
  background-color: themed('background');

  .side-box__media__icon {
    background: var(--color-btn-primary);
    color: var(--color-btn-primary-text);

    .logo {
      svg path {
        fill: var(--color-btn-primary-text);
      }
    }

    [class*="icon-"]::before {
			color: var(--color-btn-primary-text);
    }

    .tag {
      color: var(--color-btn-primary-text);
    }
  }

  .side-box__content {
    padding: var(--sleeve-component-padding);
  }
}