/**
 * Intext arrow link
 */

.article-wrapper,
[data-creative~="article-content"] {
  .rich-text p {
    &.intext-arrow-link {
      @include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
      line-height: $line-height-article-body;
    }
  }
  
  p .intext-arrow-link {
		font-size: inherit;
		line-height: inherit;
	}

  .intext-arrow-link {
		position: relative;
		display: block;
		margin-left: 22px;
	}

  div.intext-arrow-link {
    &::before {
      @include responsive-font-size($article-font-size-sm, "", $article-font-size-sm, "", $article-font-size-lg);
      line-height: $line-height-article-body;
    }
  }
}

.intext-arrow-link {
  position: relative;
}
