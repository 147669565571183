.search-bar-raffle {
  position: relative;
  height: auto;
  min-height: 50px;
  z-index: 2;
  width: 100%;
  
  &__iframe {
    border: none;
    height: 0;
    width: 100%;
    height: 50px;
  }

  //overwrite raffle styling to avoid search bar shifting position
  .raffle__Element--ilkeag {
    position: absolute;
  }
  .relative.raffle__Element--19gz6sr {
    position: absolute !important;
  }

  .suggestions {
    padding-top: 50px;
  }
}

.hero-inner, .hero__multi-boxes__box {
  .search-bar-raffle {
    height: 60px;
  }
}