/*
** Global variables  
** Buttons
*/

//- Button
$btn-font-family: $primary-font;
$btn-font-size: 1rem;
$btn-link-font-size: 0.8125rem;
$btn-font-weight: var(--btn-font-weight);

//- Button primary
$btn-primary-bg-color: var(--color-btn-primary);
$btn-primary-text-color: var(--color-btn-primary-text);
$btn-primary-border-width: var(--btn-border-width);
$btn-primary-border-style: solid;
$btn-primary-border-color: var(--color-btn-primary);
$btn-primary-outline-text-color: #000;

//- Button secondary
$btn-secondary-bg-color: var(--color-btn-secondary);
$btn-secondary-text-color: var(--color-btn-secondary-text);
$btn-secondary-border-width: var(--btn-border-width);
$btn-secondary-border-style: solid;
$btn-secondary-border-color: var(--color-btn-secondary);
$btn-secondary-outline-text-color: #000;

//- Button on  class bg--primary
$btn-bg--primary-bg-color: var(--color-btn-bg--primary);
$btn-bg--primary-text-color: var(--color-btn-bg--primary-text);
$btn-bg--primary-border-color: var(--color-btn-bg--primary-border-color);
$btn-bg--primary-outline-text-color: #000;

//- Button on class bg--secondary
$btn-bg--secondary-bg-color: var(--color-btn-bg--secondary);
$btn-bg--secondary-text-color: var(--color-btn-bg--secondary-text);
$btn-bg--secondary-border-color: var(--color-btn-bg--secondary-border-color);
$btn-bg--secondary-outline-text-color: #000;

//- Button on class bg--header
$btn-bg--header-border-color: var(--color-btn-bg--header-border-color);

//- Button on grey light background: Forms, Side-boxes & some article components
$btn-bg--grey-light-bg-color: var(--color-btn-bg--grey-light);
$btn-bg--grey-light-text-color: var(--color-btn-bg--grey-light-text);

//- Button link
$btn-link-after-border-width: 2px 2px 0 0;

//- Button or link with icon
$btn-icon-color: $color-link;
