/**
 * Lists
 */

ol,
ul,
dl {
	list-style-position: inside;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 1rem;
}

li > ul, 
li > ol {
  margin-left: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

ol {
	list-style-position: outside;
	// padding-left: 20px;
  padding-left: 30px;

	li {
		padding-left: 6px;

		&::marker {
			font-weight: 600;
		}

		p,
		a {
			font-size: inherit !important;
			line-height: inherit !important;
		}
	}
}

li {
	margin-bottom: 0.5rem;
	font-family: $secondary-font;
	font-weight: $font-weight-body;

	> ul,
	> ol {
		margin-bottom: 0;
		margin-left: 1rem;
		margin-top: 0.5rem;
	}
}

dt {
	font-weight: 600;
}

dd {
	margin-bottom: 1rem;
	margin-left: 1rem;
}

ul.column-count-2 {
  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: 40px;
  }
}

// Lists
.list {
	ul {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;

		li {
			cursor: pointer;
			border-top: 1px solid $grey-medium;

			&:last-child {
				border-bottom: none;
			}

			a {
        .label,
				p.date {
					margin: 10px 0 10px 0;
				}

				h4 {
					margin-bottom: 20px;
				}
			}
		}
	}
}

.list__link {
	color: $black;
	font-family: $secondary-font;
	font-size: $component-font-size;
	line-height: $line-height-secondary;

	&:hover {
		text-decoration: none;
	}

  &.active {
    color: $color-link;
  }
}

.list--dividers {
  ul {
    li {
      border-top: 1px solid $grey-medium;
      padding-top: 18px;

      ul li {
        border-top: unset;
        padding-top: 0;
      }
    }
  }
}

button.list__link {
  padding: 0;
  text-align: left;
}
