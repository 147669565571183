ul.list__timeline.spilt-column {
  @include media-breakpoint-up(md) {
    --line-w: 1px;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-template-columns: 1fr var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-padding);
    border: none;
  }

  /* line */
  &::before {
    @include media-breakpoint-up(md) {
      content: "";
      grid-column: 1;
      grid-row: 1 / span 20;
      background: $grey-medium;
      border-radius: calc(var(--line-w) / 2);
      grid-column: 2;
    }
  }

  /* item header */
  .list__timeline__header {
    @include media-breakpoint-up(md) {
      position: relative;
    }
    
    &[class*="bg-"] {
      .list__timeline__dot {
        @include media-breakpoint-up(md) {
          top: 15px;
        }
      }
    }
  }

  /* item dot */
  .list__timeline__dot {
    @include media-breakpoint-up(md) {
      left: 102.5%;
      top: 8px;
    }
  }
  
  /* items */
  li {
    @include media-breakpoint-up(md) {
      grid-row: span 2;
      margin-inline: 0;
    }

    /* left items */
    &:nth-child(odd) {
      @include media-breakpoint-up(md) {
        grid-column: 1;
        margin-right: var(--col-padding);
      }
    }

    /* right items */
    &:nth-child(even) {
      @include media-breakpoint-up(md) {
        grid-column: 3;
        margin-left: var(--col-padding);
      }
      

      .list__timeline__header .list__timeline__dot {
        @include media-breakpoint-up(md) {
          left: -35px;
        }
      }
    }

    /* start second iten */
    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        grid-row: 2/4;
      }
    }
  }
}
