/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Carousels
 */

.owl-carousel {
  .owl-dots {
    .owl-dot {
      &::after {
        border: 2px solid var(--darkmode-hightlight-1-200);
      }

      &.active {
        &::after {
          background: var(--darkmode-hightlight-1-200);
        }
      }
    }
  }
}
