// Megamenu extended search bar
.header__megamenu-extended__search-bar {
  z-index: 1;
  display: none;
  width: 100%;
  background-color: $header-search-bar-background-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  position: relative;

  .header__megamenu-extended__search-bar__form {
    margin-bottom: 0;

    input[type="search"] {
      position: relative;

      &::-webkit-search-cancel-button {
        cursor: pointer;
        -webkit-appearance: none;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='black'/%3E%3Crect x='7.53125' y='5.66675' width='15.2763' height='2.5' transform='rotate(45 7.53125 5.66675)' fill='white'/%3E%3Crect x='18.333' y='7.53082' width='15.2763' height='2.5' transform='rotate(135 18.333 7.53082)' fill='white'/%3E%3C/svg%3E ");
        background-size: contain;
        opacity: 0;
      }

      &:focus::-webkit-search-cancel-button {
        opacity: 1;
        pointer-events: all;
      }
    }

    .form-group {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px 0;
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      input,
      label,
      button {
        color: $color-primary-text;
        font-size: calc($form-font-size + em(2px));
      }

      input {
        z-index: 10;
        width: 100%;
        transition: all 0.2s;
        border: none;
        background: transparent;
        font-weight: $font-weight-body;
        padding: 15px 15px;
        border: 1px solid $color-primary-text;

        &::placeholder {
          font-weight: $font-weight-body;
          color: $color-primary-text;
        }

        &:required {
          // Removes red border when invalid IE, firefox
          box-shadow: none;
          border-radius: $border-radius-search-bar-small;
        }

        & + label,
        &:invalid + label {
          opacity: 1;
        }

        &:focus + label,
        &:visited + label,
        &:valid + label {
          opacity: 0;
          display: none;
        }
      }

      label {
        z-index: 5;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 15px;
        margin-bottom: 0;
        transition: 0.25s;
        font-weight: $font-weight-body;
      }

      button {
        font-weight: 600;
        margin: 0 0 0 15px;
        padding: 10px 15px;
        border: 1px solid $color-primary-text;
        font-size: $btn-font-size;
        border-radius: var(--sleeve-button-border-radius);
        transition: $transition;

        svg path {
          fill: $color-primary-text;
        }
      }
    }
  }
}
