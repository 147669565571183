/**
 * Footer col 3 offset-3 6
 */

.footer__col-3-offset3-6 {
  // Footer grid
  .col-md-12.col-lg-6 {
    .col-md-6 {
      &:nth-child(n + 3) {
        @include media-breakpoint-up(md) {
          margin-top: 80px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
      }

      p,
      ul {
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.footer__col-3-offset3-6,
.footer__extended {
  padding-top: var(--footer-pt-lg);
  padding-bottom: var(--footer-pb-lg);

  @include media-breakpoint-down(sm) {
    padding-top: var(--footer-pt-md);
    padding-bottom: var(--footer-pb-md);
  }
  
  .footer__content {
    .footer__content__logo {
      display: flex;

      &:last-of-type {
        margin-bottom: 80px;
        
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }

      @include media-breakpoint-down(sm) {
			  margin-top: 30px;
      }
    }
  }

  // Remove global col-margin bottom
  [class*='col-'] {
    [class*='col-'] {
      padding: 0 !important;

      &:nth-child(2n+1) {
        padding-right: 30px !important;

        @include media-breakpoint-down(sm) {
          padding-right: 0 !important;
        }
      }

      &:nth-child(2n+2) {
        padding-left: 30px !important;

        @include media-breakpoint-down(sm) {
          padding-left: 0 !important;
        }
      }
    }
  }

  // Footer grid 
  .col-lg-6 .col-md-6:nth-child(n+3) {
    @include media-breakpoint-up(md) {
      margin-top: 80px;
    }
  }
  
  .col-md-12.col-lg-3 {
    @include media-breakpoint-down(md) {
      margin-bottom: 80px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }
}
