/*
** Heros article
*/

// Global .hero__article
.hero__article {
  p:not(.caption):not(.author),
  p.p--introductory {
    margin-bottom: 0;
    @include responsive-font-size($hero-article-p-font-size-sm, "", $hero-article-p-font-size-sm, "", $hero-article-p-font-size-lg);
    line-height: $line-height-article-body;
  }
  
  .ellipsis .dropdown-toggle, 
  p.author {
    margin-bottom: 0;
  }

  &:not(.hero__article__displaced):not(.hero__article__centered) {
    @include nestedComponentColors($themes) {
      padding-bottom: 40px;
    }
  }
}

// Global .hero__article__centered & .hero__article__displaced
.hero__article__centered,
.hero__article__displaced {
  .hero__article__text {
    .hero__tags--over  {
			margin-bottom: 20px;
		}

    p.p--introductory {
			margin-top: 10px;
		}

    .hero__tags--under {
			margin-top: 20px;
		}
  }
}

@import 'hero__article/hero__article';
@import 'hero__article__centered/hero__article__centered';
@import 'hero__article__displaced/hero__article__displaced';
