/*
** Accordion icon
*/

.accordion-header.has-icon {
  .accordion-header__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    min-width: 45px;
    background-color: $color-secondary;
    border-radius: 50%;
    transition: $transition;
    margin-right: 20px;

    &[class^="icon-"],
    &[class*="icon-"] {
      color: $color-secondary-text;
      font-size: em(25); // icon size
    }

    //- SVG icon
    img {
      border-radius: 50%;
    }
  }
}
