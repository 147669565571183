/**
 * Watermark
 */

 .watermark {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  //- AM: Fjern efter markup rettelse er lavet i backend
  // &.position-right {
  //   img {
  //     right: 0;
  //     left: auto !important;
  //   }
  // }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    width: auto;

    &.p-top-left {
      top: 0;
      bottom: auto;
      left: 0;
      right: auto;
    }
  
    &.p-top-right {
      top: 0;
      bottom: auto;
      left: auto;
      right: 0;
    }

    &.p-top-center {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    &.p-bottom-left {
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
    }
  
    &.p-bottom-right {
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
    }

    &.p-bottom-center {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    &.p-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.p-left-center {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &.p-right-center {
      top: 50%;
      left: auto;
      right: 0;
      transform: translateY(-50%);
    }
  
    &.w-100 {
      &img {
        height: auto;
        width: 100%;
      }
    }

    &.w-auto {
      &img {
        width: auto;
      }
    }
  
    &.h-100 {
      img {
        height: 100%;
        width: auto;
      }
    }

    &.h-auto {
      img {
        height: auto;
      }
    }
  
    &.h-100.w-100 {
      img {
        max-height: auto;
        height: 100%;
        width: 100%;
      }
    }

    &.h-auto.w-auto {
      img {
        height: auto;
        width: auto;
      }
    }
  }

  .reverse {
		transform: scaleX(-1);
	}

  .rotate {
    transform: rotate(180deg);
    margin-top: -1px;
    margin-bottom: 0;
  }
}

.card {
  .watermark {
    z-index: 1;

    // img.watermark__img {
    //   height: auto;
    // }
  } 
}
