// Header megamenu extended
.header__megamenu-extended {
  z-index: 200;
  // position is only 'relative' until the height has been calculated and padding bottom set via JS. Then position is switched to 'fixed' via JS function.
  position: relative;
  top: 0;
  left: 0;
  transition: $transition;
  width: 100%;

  @include media-breakpoint-down(md) {
    z-index: 100;
    width: 100%;
    max-width: 100vw;
    min-height: 70px;
    position: relative;
    top: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: var(--header-box-shadow)
  }

  // Btn CTA
  .navbar .nav-item.nav-item--cta {
    .nav-link {
      background-color: $btn-primary-bg-color;
      color: $btn-primary-text-color;
      border-width: $btn-primary-border-width;
      border-style: $btn-primary-border-style;
      border-color: $btn-primary-border-color;
      border-radius: var(--sleeve-button-border-radius);
      font-weight: $btn-font-weight;
  
      &:hover,
      &:focus,
      &.active {
        background-color: transparent;
        color: $black;
  
        &:after {
          display: none;
        }
  
        [class*="icon-"] {
          color: $color-icons;
        }
      }
  
      &:hover {
        &:before {
          display: none;
        }
      }
  
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }
  
      &::before,
      &::after {
        display: none;
      }
  
      [class*="icon-"] {
        color: $btn-primary-text-color;
      }
    }
  }

  .navbar__megamenu-extended {
    z-index: 100;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    transition: $transition;
    background: $white;
    box-shadow: var(--header-box-shadow);

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      min-height: var(--header-height-md);
      z-index: 100;
      min-height: 70px;
      padding-top: 0;
      padding-bottom: 0;
      background: transparent;
      box-shadow: none;
    }

    &.show-mobile-menu {
      height: 100vh;
      width: 100%;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      background: $white;

      .navbar__megamenu__header__togglers .navbar__search-toggle {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .container {
      padding-right: var(--col-padding);
      padding-left: var(--col-padding);

      @include media-breakpoint-down(md) {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          max-width: 100% !important;
        }

        @include media-breakpoint-down(lg) {
          flex-wrap: wrap;
        }
      }

      .row {
        [class*="col-"] {
          margin-bottom: 0;
        }
      }
    }

  //Logo no whitespace
    &.brand-whitespace-0 {
      .header__container {
        padding-left: 0;
      }
      .navbar__megamenu__header {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        .navbar-brand img {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }


    // No styling on unorded lists
    ul {
      @include list-unstyled;
    }

    .navbar__megamenu__header {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include media-breakpoint-up(lg) {
        margin-top: calc(var(--header-brand-height-lg) / 3);
        margin-bottom: calc(var(--header-brand-height-lg) / 3);
      }

      @include media-breakpoint-down(md) {
        z-index: 110;
        width: 100%;
        min-height: var(--header-height-md);
        justify-content: space-between;
        padding: 0 var(--col-padding-md-down);
        box-shadow: var(--header-box-shadow);
        background: $white;
      }

      .navbar-brand {
        position: relative;
        display: block;
        z-index: 110;
        padding: 0;
        height: 100%;
        width: auto;
        font-size: inherit;

        h1,
        .h1 {
          font-family: $primary-font;
          font-size: calc($header-nav-link-font-size + em(6px));
          line-height: 1.125;
          font-weight: $font-weight-heading-menu;
          color: $grey-dark;
          margin-bottom: 0;

          @include media-breakpoint-down(md) {
            font-size: $header-nav-link-font-size;
          }
  
          strong {
            font-family: $primary-font;
            color: $black;
          }
        }

        img {
          z-index: 10;
          width: auto;
          height: var(--header-brand-height-lg);
          // object-fit: contain;
          // background-size: contain !important; // IE

          @include media-breakpoint-down(md) {
            height: var(--header-brand-height-md);
            margin-top: calc(var(--header-brand-height-md) / 3);
            margin-bottom: calc(var(--header-brand-height-md) / 3);
          }
        }
      }
    }

    .navbar__megamenu__header__togglers {
      display: flex;

      .navbar__search-toggle {
        display: none;
        padding: 5px;
        margin-right: 30px;

        @include media-breakpoint-up(md) {
          display: none;
        }

        @include media-breakpoint-down(md) {
          position: relative;
          display: flex;
        }

        &:before {
          @include media-breakpoint-down(md) {
            content: "";
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translate(0, -50%);
            width: 1px;
            height: 35px;
            background: #e3e3e3;
          }
        }

        svg path {
          fill: $color-icons;
        }
      }

      .navbar-toggler {
        padding: 5px;
        position: absolute;
        z-index: 110;
        right: var(--col-padding);

        @include media-breakpoint-down(md) {
          position: relative;
          right: 0 !important;
        }

        &-icon {
          width: 30px;
          height: 18px;
          position: relative;
          cursor: pointer;
          background-image: none;
          transition: $transition;

          span {
            background-color: $color-icons;
            position: absolute;
            height: 2px;
            width: 100%;
            left: 0;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
              top: 0;
              transform-origin: left center;
            }

            &:nth-child(2) {
              top: 7.5px;
              transform-origin: left center;
            }

            &:nth-child(3) {
              top: 15px;
              transform-origin: left center;
            }
          }

          &.open {
            span {
              &:nth-child(1) {
                transform: rotate(45deg);
                top: -3px;
                left: 4px;
              }

              &:nth-child(2) {
                width: 0%;
                opacity: 0;
              }

              &:nth-child(3) {
                transform: rotate(-45deg);
                top: 18px;
                left: 4px;
              }
            }
          }
        }
      }
    }

    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      transition: $transition;
      flex-basis: unset;
      margin-top: auto;

      @include media-breakpoint-up(lg) {
        padding-top: 20px;
      }

      @include media-breakpoint-down(md) {
        position: relative;
        width: 100%;
        height: auto;
        transform: translateX(100%);
        background-color: $white;
        visibility: hidden;       
      }

      &.show {
        @include media-breakpoint-down(md) {
          transform: translateX(0);
          visibility: visible;
          padding-bottom: 100%;
        }
      }

      &:not(.show) {
        @include media-breakpoint-down(md) {
          position: absolute;
        }
      }

      .navbar-nav {
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
          align-items: flex-end;
        }

        li {
          margin-bottom: 0;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .nav-link {
          @include media-breakpoint-down(md) {
            padding-left: 0;
          }
        }
      }

      .dropdown {
        .dropdown-menu {
          border-radius: 0;

          &.show {
            top: unset;
            left: unset;
            border: 1px solid $grey-medium;
            background: $grey-light;

            @include media-breakpoint-down(md) {
              border: none;
            }
          }
        }

        .dropdown-menu__megamenu {
          width: 100%;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          border: none;
          box-shadow: var(--header-box-shadow);

          @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            transition: transform 0.25s ease-in-out;
            transform: translateX(100%);
            visibility: hidden;
          }

          &.show {
            top: 100%;
            left: 0;
            background: $white;
            visibility: visible;

            @include media-breakpoint-down(md) {
              display: flex;
              align-items: flex-start;
              top: 0;
              transform: translateX(0);
              border: none;
              background: $grey-light;
            }
          }

          .container {
            @include media-breakpoint-down(md) {
              padding-right: 0 !important;
              padding-left: 0 !important;
            }
          }

          &__links {
            padding: 15px 0 35px 0;

            @include media-breakpoint-down(md) {
              padding: 0 0 40px 0;
              background: $white;
            }

            .container {
              @include media-breakpoint-down(md) {
                padding-right: 0 !important;
                padding-left: 0 !important;
              }
            }
          }

          .row {
            width: 100%;
            margin-right: 0 !important;
            margin-left: 0 !important;

            [class*="col-"] {
              padding-right: 0 !important;
              padding-left: 0 !important;
            }

            .dropdown-submenu {
              padding-top: 15px;
              padding-bottom: 15px;
              padding-right: 15px;
              transition: $transition;

              @include media-breakpoint-down(md) {
                margin-bottom: 0;
                padding-right: var(--col-padding-md-down);
                padding-left: var(--col-padding-md-down);
                padding-top: 0;
                padding-bottom: 0;
              }

              .dropdown-submenu__toggle-mobile {
                display: flex;

                .dropdown-submenu__link {
                  position: relative;
                  cursor: pointer;
                  justify-content: left;

                  &:hover,
                  &:focus {
                    color: $color-link;
                  }

                  &::before {
                    display: none;
                  }

                  &.show {
                    &:after {
                      @include media-breakpoint-down(md) {
                        z-index: -1;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -15px;
                        width: calc(100% + 30px);
                        height: 100%;
                        background-color: $grey-light;
                      }
                    }
                  }
                }
  
                .dropdown-submenu__button {
                  width: 44px; // Web accessibility
                }
              }

              .dropdown-submenu__ul {
                display: flex;
                flex-direction: column;
                position: relative;
                border: none;
                background-color: transparent;
                margin-top: 0;
                padding: 0;

                @include media-breakpoint-down(md) {
                  display: none;
                  margin: 0;
                  padding: 0;
                }

                &.show {
                  @include media-breakpoint-down(md) {
                    display: flex;
                  }
                }

                li {
                  margin-bottom: 5px;
  
                  @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-bottom: 0;
                    padding: 15px 0;
                    border-bottom: 1px solid $grey-medium;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }

                  a.list__link {
                    align-items: center;
                    min-height: 24px; // Web accessibility
                    min-width: 24px; // Web accessibility
                    font-size: $megamenu-link-list-font-size;
                    line-height: $line-height-secondary;
  
                    @include media-breakpoint-down(md) {
                      display: flex;
                    }

                    &:hover {
                      color: $color-link;
                    }

                    &::before {
                      @include media-breakpoint-down(md) {
                        display: none;
                        padding-left: 0;
                      }
                    }
                  }
                }
              }

              .nav-link {
                color: $black;
                position: relative;
                padding: 0;
                padding-top: 0;
                margin-bottom: 15px;
                cursor: default;
                height: unset;
  
                @include media-breakpoint-down(md) {
                  margin-bottom: 0;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  font-size: $component-font-size;
                }

                &.show {
                  @include media-breakpoint-down(md) {
                    z-index: 0;
                    position: relative;
                  }

                  &:before {
                    @include media-breakpoint-down(md) {
                      top: 55%;
                      transform: translate(0, -50%) rotate(-45deg);
                    }
                  }

                  &:after {
                    @include media-breakpoint-down(md) {
                      z-index: -1;
                      content: "";
                      position: absolute;
                      top: 0;
                      left: -15px;
                      width: calc(100% + 30px);
                      height: 100%;
                      background-color: $grey-light;
                    }
                  }
                }

                &:before {
                  @include media-breakpoint-up(lg) {
                    display: none;
                  }

                  @include media-breakpoint-down(md) {
                    position: absolute;
                    display: flex;
                    content: "";
                    right: 2px;
                    top: 45%;
                    transition: $transition;
                    width: 9px;
                    height: 9px;
                    border-style: solid;
                    border-color: $color-arrows;
                    border-width: 2px 2px 0 0;
                    transform: translate(0, -50%) rotate(135deg);
                  }
                }

                &:after {
                  display: none;

                  @include media-breakpoint-down(md) {
                    display: flex;
                  }
                }
              }
            }

            .list__links:not(.dropdown-submenu) {
              @include media-breakpoint-down(md) {
                // margin-bottom: 0;
                padding-right: var(--col-padding-md-down);
                padding-left: var(--col-padding-md-down);
                padding-top: 0;
                padding-bottom: 0;
              }

              ul:not(.dropdown-menu) {
                li {
                  @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-bottom: 0;
                    padding: 15px 0;
                    border-bottom: 1px solid var(--color-grey-medium);
                  }

                  &:last-child {
                    @include media-breakpoint-down(md) {
                      margin-bottom: 0;
                    }
                  }

                  .list__link {
                    @include media-breakpoint-down(md) {
                      font-size: $megamenu-link-list-font-size;
                      line-height: $line-height-secondary;
                    }

                    &::before {
                      @include media-breakpoint-down(md) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }

          h4 {
            margin-bottom: 0;
          }
        }

        .megamenu__extended-links {
          padding-top: 30px;
          padding-bottom: 50px;
          padding-left: 30px;

          @include media-breakpoint-down(md) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
          }

          // Colored background
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: #f3f6f9;
            height: 100%;
            width: 100vw;

            @include media-breakpoint-down(md) {
              height: auto;
              width: auto;
            }
          }

          .nav-link {
            z-index: 1;
          }

          .list__links-image {
            display: flex;
            flex-direction: column;
            padding-top: 0;
            padding-bottom: 0;
            height: auto;            
  
            @include media-breakpoint-down(md) {
              padding-right: var(--col-padding-md-down);
              padding-left: var(--col-padding-md-down);
              padding-top: 25px;
              background-color: $grey-light;
              padding-bottom: 100px;
            }

            .nav-link {
              margin-bottom: 20px;
              cursor: default;
              justify-content: left;

              @include media-breakpoint-down(md) {
                margin-bottom: 0;
                border-bottom: none;
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: $component-font-size;
              }

              &:after {
                display: none;
              }
            }

            ul {
              display: flex;
              flex-direction: column;
              position: relative;
              z-index: 1000;
              padding: 0;
              margin: 0;

              li {
                margin-bottom: 20px;

                .list__link-image {
                  display: flex;
                  align-items: center;
                  font-size: $base-font-size;

                  @include media-breakpoint-down(md) {
                    margin-right: 0;
                  }

                  &:hover,
                  &:focus {
                    p {
                      color: $color-link;
                    }
                  }
  
                  img {
                    height: 45px;
                    width: 60px;
                    min-width: 60px;
                    margin-right: 15px;
                  }

                  p {
                    transition: $transition;
                    margin-bottom: 0;
                    font-size: $megamenu-link-list-font-size;
                    line-height: $line-height-secondary;
                  }
                }
              }
            }
          }
        }
      }

      .nav-item {
        position: static !important;

        &.visible {
          .nav-link:after {
            width: 100%;
          }

          .dropdown-menu__megamenu {
            display: flex;
          }
        }
      }
    }

    // Megamenu extended primary menu
    .navbar-nav__primary {
      margin-top: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: var(--col-padding-md-down);
        padding-left: var(--col-padding-md-down);
      }

      // Set opacity on active menu item, when hovering on different menu item
      &:hover > .nav-item .nav-link.active:after {
        @include media-breakpoint-up(lg) {
          background: $color-icons;
          opacity: 0.5;
        }
      }

      &:hover > .nav-item.visible .nav-link,
      &:hover > .nav-item.sfHover .nav-link,
      &:hover > .nav-item .nav-link:hover {
        &:after {
          opacity: 1;
        }
      }

      .dropdown {
        position: relative;

        .dropdown-menu__button {
          width: 100%;
        }

        &.visible,
        &.sfHover {
          .megamenu {
            display: block;
            border: 1px solid $grey-medium;
          }
        }

        .dropdown-toggle {
          position: relative;

          &:after {
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        button.dropdown-toggle__mobile {
          @include media-breakpoint-up(lg) {
            z-index: -999;
            position: absolute;
            bottom: auto;
            left: -999px;
            min-width: 24px; // Web accessibility
            min-height: 25px; // Web accessibility
            width: auto;
            height: auto;
            overflow: hidden;
          }

          &:focus {
            @include media-breakpoint-up(lg) {
              outline: -webkit-focus-ring-color auto 1px;
              bottom: -24px;
              left: auto;
              z-index: 1;
            }
          }

          span {
            display: none;
            
            @include media-breakpoint-up(lg) {
              display: none;
              background-color: $white;
              color: $black;
              padding: 5px;
              font-family: $primary-font;
              font-size: $small-font-size;
              font-weight: $font-weight-heading;
            }
          }

          &:focus {
            span {
              @include media-breakpoint-up(lg) {
                display: flex;
              }
            }
          }

          @include media-breakpoint-down(md) {
            content: '';
            display: flex;
            width: 61px;
            border-bottom: 1px solid $grey-medium;
            border-left: 1px solid $grey-medium;
            background-color: $grey-light;
          }

          // Mobile - toggle arrow
          &:after {
            display: none;

            @include media-breakpoint-down(md) {
              content: "";
              display: flex;
              position: absolute;
              right: 50%;
              top: 50%;
              margin: 0;
              width: 9px;
              height: 9px;
              border-style: solid;
              border-color: $color-arrows;
              border-width: 2px 2px 0 0;
              transform: translate(50%, -50%) rotate(45deg);
            }
          }
        }
      }

      .nav-item {
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          height: 75px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 0;
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;

          @include media-breakpoint-down(md) {
            margin-bottom: 15px;
          }
        }

        &.nav-back-link {
          display: none;

          @include media-breakpoint-down(md) {
            position: relative;
            display: flex;
            width: 100%;
            color: $color-primary-text;
            background-color: $color-primary;
          }

          .nav-link {
            width: 100%;
            border-bottom: none;
            position: relative;
            padding-right: var(--col-padding);
            padding-left: 38px;

            @include media-breakpoint-down(md) {
              padding-right: var(--col-padding-md-down);
              color: $color-primary-text;
            }

            &:before {
              @include media-breakpoint-down(md) {
                position: absolute;
                content: "";
                left: 18px;
                top: 50%;
                width: 9px;
                height: 9px;
                border-style: solid;
                border-color: $color-primary-text !important;
                border-width: 2px 2px 0 0;
                transform: translate(0, -50%) rotate(225deg);
              }
            }
          }
        }

        &.sfHover {
          .nav-link {
            position: relative;

            &:after {
              @include media-breakpoint-up(lg) {
                content: "";
                display: flex;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: $transition;
                width: 100%;
                height: 6px;
                background: $color-icons;
                border: none;
                margin: 0;
              }
            }
          }

          .dropdown-menu__megamenu {
            .nav-link {
              color: $black;
            }
          }
        }
      }

      .nav-item__togglers {
        height: 100%;

        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: row;
        }
      }

      .nav-item--cta {
        @include media-breakpoint-up(lg) {
          padding-bottom: 10px;
          margin-top: 5px;
        }

        @include media-breakpoint-down(md) {
          margin-top: 30px;
          text-align: center;
        }

        &.has-icon {
          .nav-link {
            @include media-breakpoint-up(lg) {
              padding-left: 25px;
            }
          }
        }

        .nav-link {
          font-size: $btn-font-size;

          @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            height: 50px;
            padding-left: 30px;
            padding-right: 30px;
          }

          @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
          }

          [class*="icon-"] {
            margin-right: 15px;
            font-size: em(25); // icon size
          }
        }
      }

      .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        min-width: 44px; // Web accessibility: AAA
        padding: 0 0 25px 0;
        padding: 0 0 10px 0;
        font-family: $primary-font;
        font-size: $header-nav-link-font-size;
        line-height: 1.5;
        font-weight: $font-weight-heading-menu;
        transition: $transition;
        text-align: left;

        &:not(.dropdown-submenu__button) {
          @include media-breakpoint-up(lg) {
            justify-content: center;
          }
        }

        &:focus {
          @include media-breakpoint-up(lg) {
            outline: transparent;
          }

          &::before {
            @include media-breakpoint-up(lg) {
              content: "";
              outline: -webkit-focus-ring-color auto 1px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -60%);
              height: 40px;
              width: calc(100% + 20px);
            }
          }
        }

        @include media-breakpoint-down(md) {
          padding-bottom: 25px;
          padding-top: 25px;
          border-bottom: 1px solid $grey-medium;
        }

        &:after {
          @include media-breakpoint-up(lg) {
            content: "";
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: $transition;
            width: 0;
            height: 6px;
            background: $color-icons;
            border: none;
            margin: 0;
          }
        }

        &:hover,
        &:focus,
        &.active {
          &:after {
            @include media-breakpoint-up(md) {
              width: 100%;
            }
          }
        }
      }

      .nav-item__search-toggle {
        @include media-breakpoint-up(lg) {
          position: relative;
          display: flex;
          align-items: center;
          height: 45px;
          padding-left: 10px;
          padding-right: 0;
          margin-left: 5px;
          margin-bottom: 22px !important;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }

        &:before {
          @include media-breakpoint-up(lg) {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 1px;
            height: 35px;
            background: #e3e3e3;
          }
        }
      }

      .navbar__search-toggle {
        position: relative;
        display: flex;
        padding: 10px;
        margin-right: -10px;
        background-color: transparent;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          display: none;
        }

        svg path {
          fill: $color-icons;
        }
      }
    }

    .navbar-nav__secondary-container,
    .navbar-nav__secondary {
      // Nav-links secondary menu
      .nav-link,
      .btn__toggle,
      .btn__color-theme {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: $small-details-font-size;
        line-height: $line-height-secondary;
        font-weight: $font-weight-body;
        transition: $transition;
      }

      .nav-link {
        transition: $transition;
        
        @include media-breakpoint-down(md) {
          padding-bottom: 15px;
          padding-top: 15px;
        }
      }

      .btn__toggle {
        @include media-breakpoint-down(md) {
          // margin-bottom: 15px;
          margin-top: 15px;
          margin-left: 0;
        }
      }

      .nav-item:not(.nav-item--cta):not(.nav-item__social) {
        .nav-link {
          @include media-breakpoint-up(lg) {
            position: relative;
          }
          
          &:after {
            @include media-breakpoint-up(lg) {
              content: "";
              display: flex;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: all .2s ease-in-out;
              width: 0;
              height: 2px;
              background: $color-icons;
              border: none;
              margin: 0;
            }
          }
  
          &:hover {
            &:after {
              @include media-breakpoint-up(lg) {
                width: 100%;
              }
            }
          }
  
          &.active {
            &:after {
              @include media-breakpoint-up(lg) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .navbar-nav__secondary-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @include media-breakpoint-up(lg) {
        align-items: center;
      }
      
      @include media-breakpoint-down(md) {
        order: 2;
        flex-direction: column;
      }

      .btn__toggle,
      .btn__color-theme {
				// margin-left: 25px;

        @include media-breakpoint-down(md) {
          margin-top: 15px;
          // margin-left: 15px;
        }
			}

      &[class*="bg"] {
        .btn__toggle,
        .btn__color-theme {
          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
        }
      }
    }
  
    // Megamenu extended secondary menu
    .navbar-nav__secondary {
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center !important;
      }

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        flex-direction: row-reverse;
        width: 100%;
        padding-right: var(--col-padding-md-down);
        padding-left: var(--col-padding-md-down);
        padding-top: 20px;
      }

      // Set opacity on active menu item, when hovering on different menu item
      &:hover > .nav-item .nav-link.active:after {
        @include media-breakpoint-up(lg) {
          background: $color-icons;
          opacity: 0.5;
        }
      }

      .dropdown {
        position: relative !important;

        &.show {
          .dropdown-toggle {
            &:after {
              top: 25px;
              transform: rotate(-45deg);
            }

            &__megamenu {
              &:after {
                top: 22px;
              }
            }
          }
        }

        .dropdown-toggle {
          position: relative;
          margin-right: 24px;

          @include media-breakpoint-down(md) {
            margin-right: 0;
          }

          &:after {
            position: absolute;
            content: "";
            right: -22px;
            top: 20px;
            transition: $transition;
            width: 9px;
            height: 9px;
            border: 2px solid $color-primary;
            border-width: 2px 2px 0 0;
            transform: rotate(135deg);

            @include media-breakpoint-down(md) {
              right: 14px;
            }
          }

          &__megamenu {
            &:after {
              top: 18px;
            }
          }
        }
      }

      .btn__toggle {
        margin-left: 20px;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }

      .nav-item {
        padding-left: 20px;

        @include media-breakpoint-down(md) {
          padding-left: 0;
        }

        &:first-child {
          padding-left: 0;
        }
        
        .btn__toggle {
          margin-left: 0;
        }

        a.nav-link,
        button.btn__toggle,
        button.btn__color-theme  {
          @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            min-height: 44px; // Web accessibility: AAA
            min-width: 44px; // Web accessibility: AAA
          }
        }

        &.nav-item--cta {
          &.has-icon {
            .nav-link {
              @include media-breakpoint-up(lg) {
                padding-left: 10px;
              }
            }
          }

          .nav-link {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 35px;
            padding-left: 15px;
            padding-right: 15px;

            @include media-breakpoint-down(md) {
              margin-top: 15px;
              margin-bottom: 15px;
            }

            [class*="icon-"] {
              margin-right: 5px;
              font-size: em(20); // icon size
              line-height: var(--font-secondary-line-height);
            }
          }
        }

        &.nav-item__social {
          padding-top: 0;
          padding-bottom: 0;

          @include media-breakpoint-down(md) {
            width: fit-content;
            padding-top: 15px;
            padding-right: 15px;
          }

          .nav-link {
            align-items: center;
            justify-content: center;
            background: $grey-light;
            border-radius: 50%;
            padding: 5px;
            width: 38px;
            height: 38px;
          }
        }
      }
    }
  }

  .navbar-nav__primary-container {
    width: 100%;

    .navbar__megamenu__header {
      display: none !important;
    }
  }

  &:not(.bg-color__nav__secondary) .bg--primary {
    .header__container.container {
      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }
    }
  }

  // Colored secondary navigation
  &.bg-color__nav__secondary {
    .header__container.container {
      padding: 0 !important;
    }

    .navbar__megamenu__header {
      display: none !important;

      @include media-breakpoint-down(md) {
        display: flex !important;
      }
    }

    .navbar-collapse {
      padding-top: 0 !important;
    }

    #color-theme-selector {
      @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
      }
    }

    .navbar-nav__secondary-container {
      &[class*="bg--"] {
        width: 100%;

        @include media-breakpoint-down(md) {
          margin-top: 30px;
          padding-right: var(--col-padding-md-down);
          padding-left: var(--col-padding-md-down);
        }

        .nav-item.nav-item--cta {
          @include media-breakpoint-down(md) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      .navbar-nav__secondary {
        padding-left: 15px;
        padding-right: 15px;
        width: 100% !important;
        max-width: 1410px !important;
        margin: 0 auto;

        .nav-item {
          .nav-link {
            min-height: 44px; // Web accessibility: AAA
            min-width: 44px; // Web accessibility: AAA
          }
        }
      }

      .nav-item.nav-item--cta,
      .btn__toggle,
      .btn__color-theme {
        margin-top: 12px;
        margin-bottom: 12px;

        @include media-breakpoint-down(md) {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      .dropdown-settings {
        .btn__toggle {
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .navbar-nav__secondary {
        padding-left: 0;
        padding-right: 0;
      }

      .navbar-nav__secondary-container__content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-left: 15px;
        padding-right: 15px;
        width: 100% !important;
        max-width: 1410px !important;
        margin: 0 auto !important;

        @include media-breakpoint-up(lg) {
          align-items: center;
        }

        @include media-breakpoint-down(md) {
          flex-direction: column;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .navbar-nav__primary-container {
      padding-top: 0 !important;
      width: 100% !important;
      max-width: 1410px !important;
      margin: 0 auto !important;
      align-items: center;

      @include media-breakpoint-up(lg) {
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: revert !important;
        justify-content: space-between !important;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media-breakpoint-down(md) {
        order: 1;
      }

      .navbar__megamenu__header {
        display: flex !important;

        @include media-breakpoint-down(md) {
          display: none !important;
        }
      }
    }
  }

  // Header full width
  &.header--full-width {
    .container.header__container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .navbar-nav__secondary-container {
      max-width: 100% !important;

      &[class*="bg--"] {
        padding-right: 0;
        padding-left: 0;

        @include media-breakpoint-down(md) {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }

      .navbar-nav__secondary-container__content,
      .navbar-nav__secondary {
        max-width: 100% !important;
      }
    }

    .navbar-nav__primary-container {
      max-width: 100% !important;
    }

    .navbar-nav__primary {
      margin-left: auto;
    }

    //Logo no whitespace
    .navbar__megamenu-extended.brand-whitespace-0 {
      .navbar-nav__primary-container, .header__container.container {
        padding-left: 0 !important;
      }
    }
  }

  &.bg-color__nav__secondary,
  &.header--full-width {
    .header__container.container {
      max-width: 100% !important;
    }
  }

  &.bg-color__nav__secondary {
    &.header--full-width {
      .header__container.container {
        padding: 0 !important;
      }
    }
  }

  &.header--full-width {
    .header__container.container {
      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }
    }
  }

  // When menu has no primary navigation
  .primary-nav--none {
    .navbar-collapse {
      @include media-breakpoint-up(lg) {
        padding-bottom: 20px;
        margin-bottom: auto;
      }
    }
  }
}

[class^="list-icon-"],
[class*="list-icon-"] {
	.header__megamenu-extended
		.navbar__megamenu-extended
		.navbar-collapse
		.dropdown
		.dropdown-menu__megamenu
		.row
		.dropdown-submenu
		.dropdown-menu {
		li {
			.list__link {
				&::before {
					top: 0;
				}
			}
		}
	}
}
