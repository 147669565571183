.accordion__like {
  margin-bottom: 0 !important;

  .accordion-header  {
    flex-direction: column;
    align-items: flex-start;

    .accordion-header__icon {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: $base-font-size; // Reset font-size

      &[class*="icon-"]:before {
        font-size: calc($form-font-size + em(4px)); // icon size (initial 20px)
        line-height: $line-height-secondary;
      }
  
      p {
        margin-left: 5px;
        margin-bottom: 0;
        font-size: $form-font-size;
        line-height: $line-height-secondary;
      }
    }
  }

  .accordion-body__contents {
    &:after {
      display: none;
    }
    
    .accordion__form {
      margin-top: 30px;
      
      .accordion-header {
        border-top: unset;

        .h3 {
          margin-bottom: 0;
        }
      }

      form {
        background-color: transparent;
      }
    }
  }
}

// .accordion in .form-container
.form-container {
  .accordion {
		@include media-breakpoint-down(sm) {
			margin-bottom: 0 !important;
		}

		.accordion__item {
			border-bottom: 1px solid $divider-color;

			&.active {
				.accordion-header {
					background: $white;
				}

        .accordion-body__contents {
          background: $white;
        }
			}

			.accordion-header {
				&::before {
					border-bottom: 1px solid $divider-color;
				}

				&:hover {
					background: $white;
				}
			}
		}
	}
}
