/**
 * Buttons
 * .btn__link-arrow--short
 */

.btn__link-arrow--short {
  position: relative;
  color: $black;
  width: fit-content;

  &:hover,
  &:focus {
    .arrow {
      right: -13px;
    }
  }

  .arrow {
    position: absolute;
    right: -18px;
    bottom: 3px;
    display: flex;
    transition: $transition;
  }
}
