/**
 * Third party
 * Kultunaut
 */

@import 'pagination-kultunaut/pagination-kultunaut';

.nautdata {
  .filter-menu form .form-group {
    input,
    .select-checkboxes {
      border: none;
    }
  }

  .search-bar--small {
    margin-bottom: 15px;
  }
}

main.bg-main--white {
  .nautdata {
    .search-bar--small {
      form:not(#search-form) .form-group input {
        background: $grey-light;
      }
    }
  }
}
