/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Buttons
 */

// Buttons
.btn__primary,
.has-overlay .btn__primary,
.form-container .btn__primary,
span.btn__primary a:not(.list__link),
span.btn__selfservice a,
.btn__secondary,
.btn__selfservice,
.btn__selfservice a,
.btn__primary-arrow--long,
.has-image .btn__primary-arrow--long,
.btn__feedback.btn__feedback--filled,
.file-upload,
.btn__submit,
.form-container .btn__submit,
.btn--filled,
.btn__component {
  color: var(--darkmode-900);
  border-color: var(--darkmode-hightlight-1-200);
  background-color: var(--darkmode-hightlight-1-200);

  .btn__component__text {
    color: var(--darkmode-900);
  }

  .arrow::before {
    color: var(--darkmode-900) !important;
  }

  &:focus,
  &:hover {
    background: var(--darkmode-900);
    color: var(--darkmode-hightlight-1-200);

    span,
    span[class*="icon-"]::before {
      color: var(--darkmode-hightlight-1-200) !important;
    }

    .arrow::before,
    .btn__component__icon::before {
      color: var(--darkmode-hightlight-1-200);
    }

    .arrow::before {
      color: var(--darkmode-hightlight-1-200) !important;
    }
  }

  &:disabled {
    &:hover {
      color: var(--darkmode-900);
      background-color: var(--darkmode-hightlight-1-200);
    }
  }

  span,
  span[class*="icon-"]::before {
    color: var(--darkmode-900) !important;
  }

  .arrow::before,
  .btn__component__icon::before {
    color: var(--darkmode-900);
  }
}

.btn__component {
  &:focus,
  &:hover {
    .btn__component__icon::before {
      color: var(--darkmode-hightlight-1-200) !important;
    }
  }

  .btn__component__icon::before {
    color: var(--darkmode-900) !important;
  }
}

.btn__link-arrow--short::before,
.bg-section--secondary-decorative .title .btn__link-arrow--short::before {
  color: var(--darkmode-hightlight-1-100) !important;
}

.btn__primary-arrow--long--outline,
.has-image .btn__primary-arrow--long--outline,
.btn__feedback--border,
.btn__component--outline,
.btn__primary--outline,
.form-container .btn__primary--outline,
.btn__component.btn__component--outline,
.btn__secondary--outline {
  color: var(--darkmode-50);
  border-color: var(--darkmode-hightlight-1-200);
  background-color: transparent;

  .btn__component__text {
    color: var(--darkmode-50);
  }

  &:focus,
  &:hover {
    color: var(--darkmode-hightlight-1-100); 
    background: var(--darkmode-900);

    span,
    span[class*="icon-"]::before {
      color: var(--darkmode-hightlight-1-100) !important;
    }

    .btn__component__text,
    .arrow::before,
    .btn__component__icon::before {
      color: var(--darkmode-900) !important;
    }
  }

  &:disabled {
    &:hover {
      color: var(--darkmode-900);
      background: var(--darkmode-hightlight-1-100);
    }
  }

  .arrow::before,
  .btn__component__icon::before {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}

.arrow::before,
[class*="bg-"] .arrow::before {
  color: var(--darkmode-hightlight-1-200);
}

[class*="bg-"] {
  .btn__feedback--border {
    color: var(--darkmode-50);
  }
}

.btn__primary--outline,
.btn__secondary--outline {
  border: 1px solid var(--darkmode-hightlight-1-200);
}

.btn__primary-arrow--long--outline:before {
  background-color: var(--darkmode-hightlight-1-200);
} 

.btn__secondary--outline:after {
  display: none;
}

.btn.btn__primary a,
.btn.btn__selfservice a {
  background-image: none !important;
}

.btn__link,
.span__link {
  color: var(--darkmode-hightlight-1-200) !important;
}

[class*="bg-"] {
  .info-box .btn__link {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}

[class*="bg-"]:not(svg),
[class*="bg-section--"]:not(svg),
.has-overlay,
.has-overlay:not([class*="bg-"]),
.has-overlay--md-up:not(.hero__text-box__bg-color) {
  .btn__primary,
  .btn__primary-arrow--long {
    color: var(--darkmode-900);
    border-color: var(--darkmode-hightlight-1-200);
    background-color: var(--darkmode-hightlight-1-200);

    &:focus,
    &:hover {
      color: var(--darkmode-hightlight-1-200);
      background: var(--darkmode-900);

      .arrow::before {
        color: var(--darkmode-hightlight-1-200);
      }
    }

    .arrow::before {
      color: var(--darkmode-800);
    }
  }

  .btn__primary--outline,
  .btn__primary-arrow--long--outline {
    color: var(--darkmode-50);
    border-color: var(--darkmode-hightlight-1-200);
  
    &:focus,
    &:hover {
      color: var(--darkmode-900);
      background: var(--darkmode-hightlight-1-100);
  
      .arrow::before {
        color: var(--darkmode-900) !important;
      }
    }
  
    .arrow {
      &::before {
        color: var(--darkmode-hightlight-1-200) !important;
      }
    }
  }
}

.btn__component:not(.btn__component--outline) {
  &:focus,
  &:hover {
    color: var(--darkmode-50);
    background-color: transparent;

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      color: var(--darkmode-50);
    }
  }

  &[class*="article-element-bg-"] {
    border-color: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-900);
    background-color: var(--darkmode-hightlight-1-100);

    .btn__component__text,
    [class*="icon-"]:before,
    .arrow:before {
      color: var(--darkmode-900);
    }

    &:focus,
    &:hover {
      color: var(--darkmode-50);

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: var(--darkmode-50);
      }
    }
  }
}

.btn__component--outline {
  &:focus,
  &:hover {
    background: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-50);

    [class*="icon-"]:before,
    .arrow:before {
      color: var(--darkmode-50);
    }
  }

  &:focus,
  &:hover {
    color: var(--darkmode-hightlight-1-100);
    background-color: var(--darkmode-900);

    [class*="icon-"]:before,
    .arrow:before {
      color: var(--darkmode-hightlight-1-100);
    }
  }
}


[class*="bg-"] {
  .btn__component:not(.btn__component--outline) {
    border-color: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-900);
    background-color: var(--darkmode-hightlight-1-100);

    &.has-arrow .arrow:before
    [class*="icon-"]::before,
    .arrow:before {
      color: var(--darkmode-900);
    }

    &:focus,
    &:hover {
      color: var(--darkmode-50);
      background-color: transparent;

      [class*="icon-"]::before,
      .arrow:before {
        color: var(--darkmode-50);
      }
    } 
  }

  .btn__component--outline {
    border-color: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-50);

    &.has-arrow {
      .arrow:before {
        color: var(--darkmode-50);
      }
    }

    [class*="icon-"]::before,
    .arrow:before {
      color: var(--darkmode-50);
    }
    
    &:focus,
    &:hover {
      color: var(--darkmode-900);
      background-color: var(--darkmode-hightlight-1-100);

      [class*="icon-"]:before,
      .arrow:before {
        color: var(--darkmode-900);
      }
    }
  } 
}

[class*="bg-section-"] {
  .btn__component:not(.btn__component--outline) {
    &:focus,
    &:hover {
      color: var(--darkmode-50);
      background-color: transparent;

      [class*="icon-"]:before,
      .arrow:before {
        color: var(--darkmode-50);
      }
    }
  }

  .btn__component--outline {
    border-color: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-50);

    [class*="icon-"]:before,
    .arrow:before {
      color: var(--darkmode-50);
    }
    
    &:focus,
    &:hover {
      [class*="bg-"] {
        color: var(--darkmode-900);
        background-color: var(--darkmode-hightlight-1-100);

        [class*="icon-"]:before,
        .arrow:before {
          color: var(--darkmode-900);
        }
      }
    }
  }
}

[class*="bg-section-"] {
  .bg--transparent .btn__component:not(.btn__component--outline),
  .btn__component:not(.btn__component--outline) {
    border-color: ar(--darkmode-hightlight-1-100);
    background-color: ar(--darkmode-hightlight-1-100);
    color: var(--darkmode-900);

    .btn__component__text,
    [class*="icon-"]:before,
    &.has-arrow .arrow:before {
      color: var(--darkmode-900);
    }

    &:focus,
    &:hover {
      color: var(--darkmode-50);
      background-color: transparent;

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: var(--darkmode-50);
      }
    }
  }

  .bg--transparent .btn__component--outline, 
  .btn__component--outline {
    border-color: var(--darkmode-hightlight-1-100);
    color: var(--darkmode-50);

    .btn__component__text,
    [class*="icon-"]:before,
    &.has-arrow .arrow:before {
      color: var(--darkmode-50);
    }
    
    &:focus,
    &:hover {
      color: var(--darkmode-900);
      background-color: var(--darkmode-hightlight-1-100);

      .btn__component__text,
      [class*="icon-"]:before,
      .arrow:before {
        color: var(--darkmode-900);
      }
    }
  }

  .bg--tranparent,
  .card.bg--transparent,
  .media-box.bg--transparent,
  .media-box__text.bg--transparent,
  &.banner,
  &.subscribe {
    .btn__primary,
    .btn__primary.loadmore,
    .btn__primary-arrow--long {
      color: var(--darkmode-900);
      background-color: var(--darkmode-hightlight-1-100);
      border-color: var(--darkmode-hightlight-1-100);
      
      &:hover,
      &:focus {
        background: transparent;
        color: var(--darkmode-50);
      }
    }
  }
}
