.page-not-found {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 127px);
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include responsive-space('pb', var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), 0, 0);
  @include responsive-space('pt', var(--bg-section-padding-sm-top), var(--bg-section-padding-sm-top), var(--bg-section-padding-md-top), 0, 0);
  background-color: rgba($black, .55);

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 70px);
  }

  &--suggestions {
    flex-direction: column;
    @include responsive-space("pt", var(--bg-section-padding-sm-top), var(--bg-section-padding-sm-top), var(--bg-section-padding-md-top), "", "");
    @include responsive-space("pb", var(--bg-section-padding-sm-bottom), var(--bg-section-padding-sm-bottom), var(--bg-section-padding-md-bottom), "", "");
  }

  img,
  picture {
    z-index: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .page-not-found__overlay {
    z-index: 0;
    background-color: var(--color-overlay);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .page-not-found__text {
    margin-bottom: 55px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 20px;
      text-align: center;
      color: $white;
    }

    p {
      margin-bottom: 0;
      text-align: center;
      color: $white;
      font-size: $component-font-size;
      line-height: $line-height-secondary;
    }
  }

  .search-bar--small {
    margin-bottom: 20px;
  }

  .list__links {
    ul {
      li {
        margin-right: 15px;
        
        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }

        a.list__link {
          color: $white;

          &::before {
            color: $white;
          }
        }
      }
    }
  }

  .list__links {
    padding: 0;

    ul {
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      li {
        margin-bottom: 7px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
