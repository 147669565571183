/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Modals
 */

.modal {
  .modal-content .modal-header button svg path {
    fill: var(--darkmode-50);
  }
}

.modal__subscription .modal-content,
.modal__login .modal-content {
  background-color: var(--darkmode-800);
  border-top-color: var(--darkmode-hightlight-2-200);
}
