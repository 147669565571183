/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Icons & arrows
 */

// List icons
&[class*="list-icon-"] {
  a.list__link:before,
  [class*="bg-"] .intext-arrow-link:before,
  .intext-arrow-link:before {
    color: var(--darkmode-hightlight-1-200);
  }
}

// Icons & Arrows
[class*="icon-"]::before,
.tag--icon [class*=icon-]::before,
.arrow__back::before {
  color: var(--darkmode-hightlight-1-200) !important;
}

main[class^="cta-icon-"] .arrow,
main[class*="cta-icon-"] .arrow,
body[class^="cta-icon-"] .arrow,
body[class*="cta-icon-"] .arrow {
  &::before {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}

.svg-time {
  path {
    stroke: var(--darkmode-hightlight-1-200) !important;
  }

  circle {
    stroke: var(--darkmode-hightlight-1-200) !important;
  }
}

.svg-price path {
  stroke: var(--darkmode-hightlight-1-200) !important;
}

.svg-date {
  rect {
    fill: var(--darkmode-hightlight-1-200) !important;
  }

  path {
    fill: var(--darkmode-hightlight-1-200) !important;
  }
}

.svg-link path {
  fill: var(--darkmode-hightlight-1-200) !important;
}

.svg-location path {
  fill: var(--darkmode-hightlight-1-200) !important;
}
