.banner {
  z-index: 0;
	position: relative;
	overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
	width: 100%;
  min-height: calc(var(--bg-section-padding-md-top) + var(--bg-section-padding-md-bottom));

  &.has-title {
    padding: 60px 0;

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }
  }

  &.has-overlay {
    @include nestedComponentColors($themes) {
      &.has-title {
        padding: 90px calc(var(--sleeve-component-padding) + var(--col-padding)) 60px calc(var(--sleeve-component-padding) + var(--col-padding));
      
        @include media-breakpoint-down(sm) {
          padding: var(--sleeve-component-padding) var(--col-padding) var(--sleeve-component-padding) var(--col-padding);
        }
      }

      .banner__image {
        top: var(--sleeve-component-padding);
        left: var(--sleeve-component-padding);
        height: calc(100% - var(--sleeve-component-padding));
        width: calc(100% - (var(--sleeve-component-padding) * 2));

        @include media-breakpoint-down(sm) {
          top: var(--col-padding);
          left: var(--col-padding);
          height: calc(100% - var(--col-padding));
          width: calc(100% - (var(--col-padding) * 2));
        }
      }
    }
    
    .breadcrumb li a {
      color: var(--color-overlay-text);
    }
  }

  .banner__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  // Start - Made for Intranet, needs to be outfased
  .banner-image--overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-primary-decorative;
    opacity: 0.8;
  }
  // End

  .bg-overlay,
  .bg-overlay-0deg {
    z-index: -1;
  }

  .title {
    margin-bottom: 0;
  }

  .breadcrumb {
    padding-top: 10px;
    padding-bottom: 0;
    padding-right: 0;
  }

	
  :not([class^="christmas-lights"]) img:not(.watermark__img) {
    z-index: -2;
		position: absolute; // Images are positioned absolute to avoid the browser scaling the images
		transform: translate(-50%, -50%);
		top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
  &.has-christmas-lights {
    padding-top: 150px;
    @include media-breakpoint-down(sm) {
      padding-top: 75px;
    }
  }

  &.height-100vh {
    h2.h2--large {
      @include responsive-font-size(calc($h1-font-size-sm * 1.375), "", calc($h1-font-size-sm * 1.375), "", calc($h1-font-size-lg * 1.375));
    }
  }
}

// Position banner absolute, so other components can overlap
.banner--absolute,
.banner--absolute--height50vh,
.banner--absolute--height100vh {
  .container--wide {
    position: absolute; 
  }

  .banner {
    &.has-title {
      padding: 60px 0 100px 0;

      @include media-breakpoint-down(sm) {
        padding: 60px 0 70px 0;
      }
    }

    &.has-breadcrumb {
      padding: 60px 0 80px 0;
    }
  }
}

.banner--absolute {
  height: calc(var(--bg-section-padding-md-top) + var(--bg-section-padding-md-bottom));

  .banner {
    height: calc(var(--bg-section-padding-md-top) + var(--bg-section-padding-md-bottom) + 40px);
  }
}

.banner--absolute--height50vh {
  height: calc(50vh - #{var(--header-height-md)});

  .banner {
    height: calc((50vh - #{var(--header-height-md)}) + 40px);
  }
}

.banner--absolute--height100vh {
  height: calc(100vh - #{var(--header-height-md)});

  .banner {
    height: calc((100vh - #{var(--header-height-md)}) + 40px);
  }
}
