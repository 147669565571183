/**
 * Spacing
 */

/**
	* Column spacing (padding right/left)
	* - overiding bootstraps defalut padding right/left
	*/
[class*="col-"]:not([class*="__col-"]) {
	padding-right: var(--col-padding);
	padding-left: var(--col-padding);

	@include media-breakpoint-down(md) {
		padding-right: var(--col-padding-md-down);
		padding-left: var(--col-padding-md-down);
	}
}

/**
	* Column spacing - Article with. sidemenu (padding right/left, width etc.)
	*/
.article-with-sidemenu {
	//- Side menu, main content & info box article
	.article-wrapper .col-md-12.col-lg-12.col-xl-8,
  //- .article-wrapper .col-md-12.col-lg-12.col-xl-4,
	.article-wrapper .col-md-12.col-lg-12.col-xl-12,
	.article-wrapper .col-sm-12.col-lg-8.col-xl-8.offset-xl-1 .col-md-12.col-lg-12,
  .col-md-12.col-lg-8.offset-lg-4.col-xl-8.offset-xl-3,
	.col-md-12.col-lg-8.offset-lg-4.col-xl-6.offset-xl-3 {
		@include media-breakpoint-up(xl) {
			padding-left: 40px !important;
			padding-right: 40px !important;
		}

		@include media-breakpoint-up(lg) {
			padding-left: 40px !important;
		}
	}

	// .article-wrapper .col-md-12.col-lg-12.col-xl-8,
	.article-wrapper .col-sm-12.col-lg-8.col-xl-8.offset-xl-1 .col-md-12.col-lg-12,
	.article-wrapper .col-md-12.col-lg-12.col-xl-12,
  .col-md-12.col-lg-8.offset-lg-4.col-xl-8.offset-xl-4,
  .article-with-sidebar .col-md-12.col-lg-7.offset-xl-1,
  .article-with-sidemenu.article-with-sidebar .article-wrapper .col-md-12.col-lg-12.col-xl-8,
  .article-with-sidemenu.article-with-sidebar .article-wrapper .col-md-12.col-lg-12.col-xl-12,
  .article-with-sidemenu.article-with-sidebar .col-md-12.col-lg-8.offset-lg-4.col-xl-6.offset-xl-3 {
		max-width: var(--article-wrapper-max-width);
	}

	// Info box article
	.col-md-12.col-lg-8.offset-lg-4.col-xl-8.offset-xl-4 {
		@include media-breakpoint-up(xl) {
			padding-left: 40px !important;
			padding-right: 40px !important;
		}

		@include media-breakpoint-up(lg) {
			padding-left: 40px !important;
		}
	}

	// Article with. sidebar and colored hero
	&.article-colored-hero {
		.side-menu {
			margin-top: 0;
		}

    .hero__article {
      @include nestedComponentColors($themes) {
        .hero__article__text {
          @include media-breakpoint-up(xl) {
            padding-left: 40px !important;
            padding-right: 40px !important;
          }
  
          @include media-breakpoint-up(lg) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }
    }
	}

  .article-wrapper .col-sm-12.col-lg-8.col-xl-9,
  .article-wrapper .col-sm-12.col-lg-8.col-xl-8 {
    .container--wide {
      overflow: visible;
    }
  }
}

/**
	* Column spacing - Article with. sidebar (padding right/left, width etc.)
	*/
.article-with-sidebar {
	// Side menu, Side bar & main content
	.article-wrapper .col-md-12.col-lg-7.offset-xl-1 {
		margin-right: auto !important;
	}
}

/**
	* Column spacing - Article with. sidemenu & sidebar (padding right/left, width etc.)
	*/
.article-with-sidemenu.article-with-sidebar {
	.article-wrapper .col-md-12.col-lg-12.col-xl-4 {
		@include media-breakpoint-down(lg) {
			padding-left: 40px;
		}

		@include media-breakpoint-down(md) {
			padding-left: var(--col-padding-md-down);
		}
	}

	// Info box article
	.col-md-12.col-lg-8.offset-lg-4.col-xl-6.offset-xl-3 {
		@include media-breakpoint-up(lg) {
			padding-left: 40px !important;
		}

		@include media-breakpoint-up(xl) {
			margin: 0 auto;
		}
	}
}

/**
	* Column spacing (margin bottom/margin top)
	*/
.row.row--grid {
  margin-top: -30px;

  @include media-breakpoint-down(sm) {
		margin-top: 0;
	}

  [class*="col-"] {
    margin-top: 30px;

    .row:not(.row--grid) {
      margin-top: 0;

      [class*="col-"] {
        margin-top: 0;
      }
    }
  }
}

//- Grid 8-4-4-4-4
.row.row--grid__col-8-4-4-4-4:not(.row--grid) {
	padding-right: 0 !important;
	padding-left: 0 !important;

	[class*="col-"] {
		//- Mobile
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}

		&:last-child {
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}

		// Desktop: All children from the third child has margin-top
		&:nth-child(n + 2) {
			@include media-breakpoint-up(md) {
				margin-top: 30px;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 0;
			}
		}

		// Desktop: All children from the third child has margin-top
		&:nth-child(n + 3) {
			@include media-breakpoint-up(lg) {
				margin-top: 30px;
			}

			@include media-breakpoint-down(sm) {
				margin-top: 0;
			}
		}
	}

  .col-md-6.col-lg-4 {
    &:nth-child(n + 3) {
      display: flex;

			.card {
        height: 100%;
      }
		}
  }

  .col-sm-12.col-md-12.col-lg-8 {
    display: flex;
    
    .card {
      height: 100%;
    }
  }
}

//- Grid 6-6
.row.row--grid__col-6-6:not(.row--grid) {
	[class*="col-"] {

		//- Mobile
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}

		&:last-child {
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
	}
}

//- Grid 8-4
.row--grid__col-8-4 {
  &.flex-row-reverse { //- .flex-row-reverse - Probebly used from bootstrap
    @include media-breakpoint-down(md) {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  .col-sm-12.col-md-12.col-lg-8 {
    .card__portrait:not(.card__portrait--no-image):not(.bg--transparent):not(.c-background) .card__portrait__image,
    .card__portrait__image {
      img:not([class*="aspect-ratios--"]) {
        @include media-breakpoint-up(lg) {
          height: 100%;
        }
      }
    }
  }

  .col-md-12.col-lg-4 {
    .row.row--grid  {
      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        height: calc(100% + 30px);
      }
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;

  .btn {
    height: fit-content;
  }
}
 