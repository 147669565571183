/**
 * Global
 *
 * !default Flag
 * When building a library, a framework, a grid system or any piece of Sass
 * that is intended to be distributed and used by external developers,
 * all configuration variables should be defined with the !default flag
 * so they can be overwritten.
 */

//- fonts
$font-object-fit-cover: "object-fit: cover;";

$font-size-base: var(--base-font-size) !default;
$line-height-base: 1.5 !default;

//- box shadow
$shadow: 4px 8px 20px rgba(0, 0, 0, 0.03);
$shadow-hover: 4px 8px 20px rgba(0, 0, 0, 0.1);
$shadow--lg: 0 20px 60px rgba(0, 0, 0, 0.12);
