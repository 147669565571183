// Darkmode
.btn__color-theme--darkmode {
  .btn__color-theme__toggle {
    right: 3px;
    left: auto;
  }
}

.btn__color-theme {
  background-color: var(--darkmode-700);

  .btn__color-theme__toggle {
    background: linear-gradient(to bottom, var(--darkmode-500), var(--darkmode-600));
  }
}

[class*="bg-"]:not(svg) {  
  .btn__color-theme {
    background-color: var(--darkmode-500);
  
    .btn__color-theme__toggle {
      background: linear-gradient(to bottom, var(--darkmode-300), var(--darkmode-400));
    }
  }
}
