.header--tagged {
  z-index: 12;
  position: relative;
	width: 100vw;

	ul {
		list-style: none;
		margin: 0;
	}

	li {
		margin-bottom: 0;
	}

  h1,
  .h1 {
    font-family: $primary-font;
    font-size: calc($header-nav-link-font-size + em(6px));
    line-height: 1.125;
    font-weight: $font-weight-heading-menu;
    color: $grey-dark;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: $header-nav-link-font-size;
    }

    strong {
      font-family: $primary-font;
      color: $black;
    }
  }

	button {
		padding: 0;
	}

	// This class should be removed from SVG
	svg,
	.text-white {
		width: auto;
		height: 30px;
		color: $color-icons !important;
	}

	input {
		padding: 0;
	}
}

.header--tagged-top-bar {
	position: relative;
	background-color: $white;
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	padding: 0 15px;
	border-bottom: 1px solid $grey-medium;

	@include media-breakpoint-up(lg) {
		padding: 0 40px;
    min-height: 70px;
	}

  @include nestedComponentColors($themes) {
		border-bottom: unset;
    background-color: themed('background');

    a {
      color: themed('text');
    }

    .desktop-secondary-list {
      .desktop-secondary-list-item {
        &:after {
          @include media-breakpoint-up(lg) {
            background-color: themed('text');
          }
        }
      }
    }

    .desktop-actions-searchbar {
      .desktop-actions-searchbar-btn,
      .desktop-actions-searchbar-submit {
        color: themed('text');

				// This class should be removed from SVG
				svg,
				.text-white {
					color: themed('text') !important;
				}
      }
    
      .desktop-actions-searchbar-label {
        color: themed('text');
      }
    
      .desktop-actions-searchbar-input {
				border-width: 2px;
			 	border-style: solid;
        border-color: themed('text');
        color: themed('text');
				background: transparent;
    
        &::placeholder {
          color: themed('text');
        }
				
      }

			.desktop-actions-searchbar-close-btn {
				span {
					background-color: themed('text');
				}
			}
		
      .desktop-actions-searchbar-form {
        &::after {
          background-color: themed('text');
        }
      }
    }

    .header--tagged-mobile-actions {
      .burger-icon-elmnt {
        .burger-icon-elmnt-dash {
          background-color: themed('text');
        }
      }

      .burger-cross-icon {
        .burger-cross-icon-right,
        .burger-cross-icon-left {
          background-color: themed('text');
        }
      }
    }
  }

	.nav-brand {
    display: flex;
    align-items: center;
    margin: calc(var(--header-brand-height-md) / 3) 0;

		img {
			height: var(--header-brand-height-md);
      width: auto;

      @include media-breakpoint-up(lg) {
        height: var(--header-brand-height-lg);
      }
		}
	}

  @import 'background-colors';
}

.desktop-secondary-list {
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(:first-child) {
		margin-left: 15px;
	}

  .desktop-secondary-list-item {
    @include media-breakpoint-up(lg) {
			position: relative;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        content: "";
        position: absolute;
        transform-origin: 100% 50%;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        width: 100%;
        background-color: $color-icons;
        height: 2px;
        transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
      }
    }

    &:hover {
      &:after {
        @include media-breakpoint-up(lg) {
          transform: scaleX(100%);
          transform-origin: 0 100%;
        }
      }
    }

    a {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 25px; // Web accessibility
        min-width: 24px; // Web accessibility
        font-size: $header-font-size-2;
      }
    }
  }
}

.mobile-secondary-nav {
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
		display: none;
	}

	.mobile-secondary-list-item {
		padding: 15px 0;

    a {
      display: flex;
      align-items: center;
      min-height: 25px; // Web accessibility
      min-width: 24px; // Web accessibility
    }
	}

  .btn__toggle {
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
}

.list-item--cta {
  &:hover,
  &:focus {
    &::after {
      display: none;
    }
  }

  a {
    background-color: $btn-primary-bg-color;
    color: $btn-primary-text-color;
    border-width: $btn-primary-border-width;
    border-style: $btn-primary-border-style;
    border-color: $btn-primary-border-color;
    border-radius: var(--sleeve-button-border-radius);
    font-weight: $btn-font-weight;
    width: fit-content;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    transition: $transition;

    &:hover,
    &:focus,
    &.active {
      background-color: transparent;
      color: $black;

      &:after {
        display: none;
      }

      [class*="icon-"] {
        color: $color-icons;
      }
    }

    &:hover {
      &:before {
        display: none;
      }
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &::before,
    &::after {
      display: none;
    }

    [class*="icon-"] {
      color: $btn-primary-text-color;
    }
  }
}

@import 'sidemenu';
@import 'searchbar';
@import 'darkmode';
