/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Titles
 */

.title .title__icon .title__icon__bg {
  background-color: transparent;
  
  &:after {
    background-color: var(--darkmode-hightlight-1-200);
  }
}
