&.has-carousel {
  z-index: 0;

  .swiper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100%;

    img {
      height: 100%;

      @include media-breakpoint-up(xl) {
        height: auto;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }
  }

  // .swiper-pagination {
  //   @include media-breakpoint-down(md) {
  //     top: 0;
  //     bottom: auto !important;
  //     margin-top: var(--col-padding);
  //   }
  // }

  // .gradient {
  //   @include media-breakpoint-down(md) {
  //     top: 0;
  //     transform: rotate(180deg);
  //   }
  // }

  .container {
    z-index: 2;
  }
}