.card__person__text__content,
.card__business__text__content,
.info-box-article__content__text {
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.content--dividers {
  .card__person__text__content,
  .card__business__text__content,
  .info-box-article__content__text {
    &:nth-child(n + 2) {
      position: relative;
      padding-top: 15px;
      margin-top: 5px;
      margin-bottom: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 40px;
        background-color: var(--color-grey-medium);
      }
    }
  }
}