/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Background colors
 */

// White & body
.bg-main--white,
.body--white,
main:not(.bg-main--white) .bg--inherit-bg-main,
.bg-main--white .bg--inherit-bg-main {
  background-color: var(--darkmode-900);
}

.bg-main--white,
.body--white {
  .accordion--mobile {
    &.info-box.info-box__links,
    &.info-box.info-box__links__icon--large,
    &.card {
      @media (min-width: 767.98px) {
        border: var(--darkmode-card-border);
      }
    }
  }

  .side-box:not(.bg--primary):not(.bg--secondary):not(.bg--border-topform) .btn__primary {
    border-color: var(--darkmode-hightlight-1-200);
    background: var(--darkmode-hightlight-1-200);
    color: var(--darkmode-900);
  
    &:focus,
    &:hover {
      color: var(--darkmode-hightlight-1-200);
      background: var(--darkmode-900);
    }
  }
}

main:not(.bg-main--white) .bg-section--invert-bg-main,
.bg-section--white,
.bg-section--invert-bg-main {
  background-color: var(--darkmode-800);
}

.bg--body:not(svg),
.bg--footer:not(svg),
.bg--grey-light,
.bg--invert-bg-main {
  background-color: var(--darkmode-800) !important;
}

body,
.bg--white:not(svg) {
  background-color: var(--darkmode-900) !important;
}

.bg--white {
  .info-box__links,
  .info-box__links__icon--large,
  .info-box__links__icon .info-box__links__icon__item,
  .owl-card-carousel .card__portrait,
  .card__portrait--no-image,
  .card__meeting,
  .card__cta,
  .card__event,
  .link-box:not(.link-box__article):not(.article-element-bg--0):not(.article-element-bg--1):not(.article-element-bg--2):not(.article-element-bg--3):not(.article-element-bg--4):not(.article-element-bg--5),
  .media-box--displaced .media-box__text,
  .media-box--equal-heights:not([class*="bg-"]),
  .rich-text__text-box.framed {
    border: var(--darkmode-card-border);
  }
}

.bg-main--white,
.body--white,
.bg-section--white,
.bg--white {
  .info-box__links,
  .info-box__links__icon--large,
  .info-box__links__icon .info-box__links__icon__item,
  .owl-card-carousel .card__portrait,
  .card__portrait--no-image,
  .card__meeting,
  .card__cta,
  .card__event,
  .link-box:not(.link-box__article):not([class*="article-element-bg--"]),
  .media-box--displaced .media-box__text,
  .media-box--equal-heights:not([class*="bg-"]),
  .rich-text__text-box.framed {
    border: none !important;
  }
}

.bg-main--white .breadcrumb__page-title,
.breadcrumb__page-title {
  background-color: var(--darkmode-900) !important;
}

main:not(.bg-main--white) {
  .bg--grey-light {
    form,
    form:not(#search-form) {
      .form-group input {
        background-color: var(--darkmode-700);
      }
    }
  }
}

[class*="bg-"]:not(svg) {
  background-color: var(--darkmode-800);
}

.bg-main--white:not(svg) {
  background-color: var(--darkmode-900);
}

.bg--border-top:not(svg),
.bg--transparent:not(svg) {
  background-color: transparent
}

[class*="bg-"]:not(svg) {
  h1,
  .h1, 
  h2, 
  .h2, 
  .h2--large, 
  h3, 
  .h3, 
  h4, 
  .h4,
  span,
  ul li,
  ol li,
  [class*=icon-] {
    color: var(--darkmode-50);
  }

  &.info-box__links__icon__item {
    .info-box__links__icon__item__header,
    .info-box__links__icon__item__body {
      background-color: var(--darkmode-800) !important;
    }
  }

  [class*=bg-section--] .media-box.bg--transparent .btn__link-arrow--long .arrow:before,
  .btn__link-arrow--long .arrow:before {
    color: var(--darkmode-hightlight-1-200) !important;
  }

  .list__news .list__news__item {
    border-color: var(--darkmode-600);
  }
}

[class*="bg-section--"]:not(svg) {
  background-color: var(--darkmode-800);

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  p {
    color: var(--darkmode-50) !important;
  }

  .title {
    h2, 
    .btn__link-arrow--short, 
    .btn__link-arrow--short::before {
      color: var(--darkmode-50) !important;
    }
  }

  .grid-dynamic {
    .rich-text {
      h1,
      .h1,
      h2,
      .h2,
      h3,
      .h3,
      p,
      a:not(.btn) {
        color: var(--darkmode-50);
      }
    }

    .btn__primary {
      background: var(--darkmode-hightlight-1-200);

      &::after {
        background: var(--darkmode-hightlight-1-200);
      }
    }
  }

  &:not(svg).hero__article__displaced {
    background-color: transparent;

    .hero__article__media.postion-absolute {
      background-color: var(--darkmode-800);
    }
  }
}

// SVG
svg {
  &[class*="bg-"],
  &[class*="bg-section--"] {
    path {
      fill: var(--darkmode-800) !important;
    }
  }
}

[class*="bg-section--"]:not(svg) {
  .media-box.bg--transparent  {
    h2,
    .h2,
    h3,
    p,
    p span,
    .tag,
    .label,
    .arrow:before,
    .btn__link-arrow--long,
    .btn__link-arrow--long span {
      color: var(--darkmode-50);
    }
  }
}

.navbar-nav__secondary-container.bg--secondary .navbar-nav.navbar-nav__secondary .dropdown-settings:not(.tablet-secondary-nav) .dropdown-settings__toggle,
.navbar-nav__secondary-container.bg--body .navbar-nav.navbar-nav__secondary .dropdown-settings.settings-menu:not(.tablet-secondary-nav),
.dropdown-settings.settings-menu:not(.tablet-secondary-nav) {
  .dropdown-settings__toggle {
    color: var(--darkmode-50) !important;

    &::before {
      border-color: var(--darkmode-50) !important;
    }
  }

  .dropdown-settings__toggle::before,
  [class*=icon-]::before {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}
