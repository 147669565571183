.card.card__list__contact {
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  background: transparent;
  border-bottom: 1px solid $grey-medium;

  @include media-breakpoint-down(md) {
    padding: 20px 0 15px 0;
  }

  &:first-of-type {
    padding-top: 0;
  }

  .row {
    width: 100%;
  }

  .card__list__contact__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    h3,
    .h3 {
      margin-bottom: 0 !important;

      @include media-breakpoint-down(md) {
        margin-bottom: 10px !important;
      }
    }
  }

  .card__list__contact__image {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin-right: 30px;
  
    img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
}

.list__cards--framed {
  .card.card__list__contact {
    border-bottom: 1px solid $grey-light;

    &:first-of-type {
      padding-top: 0;
    }
  }
}
