.list__cards {
	&.list__cards--framed {
		background: $white;
		padding: var(--sleeve-component-padding-top)
			var(--sleeve-component-padding-right) 30px
			var(--sleeve-component-padding-left);

		h3,
		.h3 {
			margin-bottom: 20px;
		}

		.card__list {
			border-radius: 0 !important;

			&:first-of-type {
				border-top: none !important;
			}

			.card__list__image {
				margin-bottom: 0;
			}
		}

		.btn-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&.list__cards--grid {
		.container {
			padding: 0;
		}
	}

  &.list__cards--sm {
    width: 100%;
    
    .container--wide .row.equalizer {
      margin-top: 0 ;

      [class*=col-] {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .col-12 {
      &:nth-child(1) {
        .card__list--sm {
          border-top: none;
          padding-top: 0;
        }
      }
    }

		.col-12,
		.col-md-12.col-lg-6 {
			.card__list--sm {
				.card__list__image {
					@include media-breakpoint-down(lg) {
						width: 100px;
						min-width: 100px;
						height: 60px;
					}

					@include media-breakpoint-down(md) {
						width: 160px;
						min-width: 160px;
						height: 100px;
					}

					@include media-breakpoint-down(sm) {
						width: 100px;
						min-width: 100px;
						height: 60px;
					}
				}
			}
		}

		.col-md-12.col-lg-6 {
			&:nth-child(1),
			&:nth-child(2) {
				.card__list--sm {
					@include media-breakpoint-up(lg) {
						border-top: none;
						padding-top: 0;
					}
				}
			}

			&:nth-child(1) {
				.card__list--sm {
					@include media-breakpoint-down(md) {
						border-top: none;
						padding-top: 0;
					}
				}
			}
		}

		.col-sm-12.col-md-6.col-lg-6 {
			&:nth-child(1),
			&:nth-child(2) {
				.card__list--sm {
					@include media-breakpoint-up(md) {
						border-top: none;
						padding-top: 0;
					}
				}
			}

			&:nth-child(1) {
				.card__list--sm {
					@include media-breakpoint-down(sm) {
						border-top: none;
						padding-top: 0;
					}
				}
			}

			.card__list--sm {
				.card__list__image {
					@include media-breakpoint-down(md) {
						width: 100px;
						min-width: 100px;
						height: 60px;
					}
				}
			}
		}
	}
}

.card__list {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;
	border-top: 1px solid $divider-color;
	background: transparent;
	// cursor: pointer;

	@include media-breakpoint-down(sm) {
		padding-bottom: 20px;
	}

	&:hover {
		.arrow {
			margin-right: 10px;

			&:before {
				right: 10px;
			}
		}

		[class^="cta-icon-"],
		[class*="cta-icon-"] {
			&.arrow {
				&:before {
					right: 10px;
				}
			}
		}
	}

	// highlighted
	&[class*="bg--"] {
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 30px;
		border-radius: var(--sleeve-component-border-radius);
	}

	&.pt--0 {
		border-top: unset !important;
	}

	.card__list__content {
		max-width: var(--article-wrapper-max-width);
	}

	h3,
	.h3 {
		font-weight: $font-weight-heading;
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 0;
	}

	.tags-container {
		margin-bottom: 15px;
	}

	.labels--bottom {
		margin-top: 10px;
	}

	ul.card__list__event-list {
		@include list-unstyled();
		margin-bottom: 18px;

		li {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			font-size: $small-details-font-size;
			line-height: $line-height-secondary;

			svg {
				margin-right: 15px;

				&.svg-date,
				&.svg-time,
				&.svg-location {
					path {
						fill: $color-primary;
					}
				}

				&.svg-date {
					rect {
						fill: $color-primary;
					}
				}

				&.svg-time {
					path {
						stroke: $color-primary;
					}

					circle {
						stroke: $color-primary;
					}
				}
			}
		}
	}

	.card__list__image {
		img {
			height: auto;
			width: 100%;
			border-radius: $border-radius-component-image;
		}

		img:not([class*="aspect-ratios--"]) {
			height: 180px;

			@include media-breakpoint-down(sm) {
				height: 120px;
			}
		}
	}

	.card__list__badge {
		&.card__badge {
			top: unset;
			bottom: 0;
		}

		p {
			margin-top: 0;
		}
	}

	.arrow {
		display: flex;
		justify-content: flex-end;
		transition: $transition;
		margin-right: 20px;
		right: 0;
		top: 0;
	}

	[class^="cta-icon-"],
	[class*="cta-icon-"] {
		&.arrow {
			width: calc(100% - 20px);

			&:before {
				right: 0;
				left: unset;
			}
		}
	}

	&.card__list--arrow-bottom {
		&:hover {
			.arrow {
				@include media-breakpoint-down(sm) {
					right: 10px;
				}

				&:before {
					left: 5px;

					@include media-breakpoint-down(sm) {
						left: auto;
						right: 10px;
					}
				}
			}
		}

		.row {
			[class*="col-"] {
				@include media-breakpoint-down(sm) {
					margin-bottom: 15px;
				}

				&:last-of-type {
					@include media-breakpoint-down(sm) {
						margin-bottom: 0;
					}
				}
			}

			.col-md-6.col-lg-7 {
				@include media-breakpoint-down(sm) {
					order: 1;
				}
			}

			.col-md-3 {
				@include media-breakpoint-down(sm) {
					order: 2;
				}
			}
		}

		h3,
		.h3 {
			@include media-breakpoint-down(sm) {
				width: calc(100% - 40px);
			}
		}

		.arrow {
			position: relative;
			justify-content: flex-start;
			margin-right: 0;
			margin-top: 15px;

			@include media-breakpoint-down(sm) {
				position: absolute;
				top: 5px;
				right: 0;
				margin-top: 0;
			}

			&:before {
				left: 0;
			}
		}
	}

	// When in 3 column grid
	.col-md-12 {
		.card__list__image img {
			height: 260px;
			min-height: 260px;
			margin-bottom: 20px;

			@include media-breakpoint-down(sm) {
				height: 195px;
			}
		}
	}

	//card list title link
	&.card__list--title-link {
		cursor: unset;
		h3 {
			display: inline;
		}
		.card__list__title-container {
			display: flex;
			align-items: flex-end;
			padding-bottom: 15px;
		}
	}

	&.card__list--sm {
		.card__list__content {
			margin-right: 30px;
		}

		.card__list__image {
      width: auto;
      min-width: auto;
      height: auto;

			img:not([class*="aspect-ratios--"]) {
				width: 160px;
				min-width: 160px;
				height: 100px;

				@include media-breakpoint-down(sm) {
					width: 100px;
					min-width: 100px;
					height: 60px;
				}
			}
		}
	}
}

.card__list:not(.card__list--contact) {
  // Grid styling
	.row {
		display: flex;
		justify-content: space-between;

		[class*="col-"] {
			padding-right: 0 !important;
			padding-left: 0 !important;

			@include media-breakpoint-down(sm) {
				margin-bottom: 15px;
			}
		}

		.col-md-6.col-lg-7 {
			@include media-breakpoint-down(sm) {
				order: 2;
			}
		}

		.col-md-3 {
			@include media-breakpoint-down(sm) {
				order: 1;
			}
		}

		.col-md-1 {
			@include media-breakpoint-down(sm) {
				order: 3;
				margin-bottom: 0;
				height: 24px;
			}
		}
	}
}

//unsave button
.card__list_unsave-button {
	padding: 0;
	margin-left: 15px;
	&:hover {
		svg path {
			fill: none;
		}
	}
	svg path {
		transition: ease 200ms;
		fill: var(--color-link);
	}
}

// Grid
.col-md-4 {
	.list__cards {
		.card__list {
			&:nth-child(1) {
				@include media-breakpoint-up(md) {
					border-top: none;
					padding-top: 0;
				}
			}
		}
	}
}
