/**
 * Flex order
 *
 * Usage
 * @include order(1);
*/

@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}

@for $i from 1 through 12 { 
  .order-mobile-#{$i} {
    @include media-breakpoint-down(sm) {
      @include order($i);
    }
  }
}
