// Darkmode
.btn__toggle.jsDarkmodeToggle {
 .btn__toggle__toggle {
    right: 3px;
    left: auto;
  }
}

.btn__toggle,
.footer .footer__content .btn__toggle {
  background-color: var(--darkmode-700);
  border-color: var(--darkmode-600);

  .btn__toggle__toggle {
    background: linear-gradient(to bottom, var(--darkmode-500), var(--darkmode-600));
  }
}

[class*="bg-"]:not(svg) {
  .btn__toggle {
    background-color: var(--darkmode-500);
  
    .btn__toggle__toggle {
      background: linear-gradient(to bottom, var(--darkmode-300), var(--darkmode-400));
    }
  }
}

.bg--header {
  .btn__toggle {
    background-color: var(--darkmode-500) !important;
    border-color: var(--darkmode-500) !important;

    .btn__toggle__toggle {
      background: linear-gradient(to bottom, var(--darkmode-300), var(--darkmode-400)) !important;
    }

    .btn__toggle__elements {
      [class*="icon"]::before {
        color: var(--darkmode-hightlight-1-200) !important;
      }
    }
  }
}
