.editorButton {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	background-color: #3498db;
	right: 0;
	top: 40vh;
	width: 200px;
	height: 50px;
	transition: 0.2s all ease-out;
	transform: translateX(110px);
	z-index: 2000;
	padding: 10px 10px 10px 20px;

	& a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			opacity: 0;
			color: $white;
			margin-left: 12px;
			transition: 0.5s opacity ease-out;
		}
	}

	&:hover {
		transform: translateX(0);

		& a span {
			opacity: 1;
		}
	}

	svg {
		height: 20px;
	}
}
