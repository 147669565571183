/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Side boxes
 */

.side-box {
  &:not(.bg--border-top) {
    background-color: var(--darkmode-700) !important;
  }

  &[class*="bg--"]:not(.bg--border-top):not(.bg--default) {
    background-color: var(--darkmode-800) !important;
  }

  &.bg--border-top .side-box__content {
    border-top-color: var(--darkmode-hightlight-1-200);
  }

  .side-box__media__icon {
    background: var(--darkmode-600) !important;
    color: var(--darkmode-hightlight-1-200) !important;
  }

  .umbraco-forms-form {
    background: unset !important;

    form .form-group {
      input,
      textarea, 
      .select,
      .select select {
        border-color: var(--darkmode-700) !important;
        background: var(--darkmode-800) !important;
      }

      .form-check .checkbox .checkbox__indicator {
        border-color: var(--darkmode-800);
      }
    }
  }
}

.side-boxes__most-popular {
  background: var(--darkmode-800);

  li::before {
    background-color: var(--darkmode-hightlight-2-200);
    color: var(--darkmode-50);
  }

  li::after {
    background: var(--darkmode-hightlight-1-200) !important;
  }
}

[class*="bg-"]:not(svg) {
  .rich-text ul li::marker {
    color: var(--darkmode-hightlight-1-200) !important;
  }
}
