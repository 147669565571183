/*
** Creative
** Grid dynamic
*/

.grid-dynamic {
	z-index: 0;
	position: relative;

	&.has-image-background {
		@include responsive-space("pt",
			var(--bg-section-padding-sm-top),
			var(--bg-section-padding-sm-top),
			var(--bg-section-padding-md-top),
			"",
			""
		);
		@include responsive-space(
			"pb",
			var(--bg-section-padding-sm-bottom),
			var(--bg-section-padding-sm-bottom),
			var(--bg-section-padding-md-bottom),
			"",
			""
		);
	}

  &.text-center {
    .tag.tag--default {
      width: 100%;
    }
  }

	.bg-overlay {
		z-index: -1;
	}

	.title {
		margin-bottom: 30px;
	}

	.tag {
		margin-bottom: 10px;
	}

	.image {
		height: 100%;

		img,
		picture {
			height: 100%;
		}
	}

  .grid-dynamic__content {
    .title,
    .rich-text,
    .image,
    .btn {
      margin-bottom: 0;
    }
  }

	// Grid spacing - set by class in HTML
	.title {
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}

	[class*="col-"]:not(.offset):nth-child(n + 2) {
		@include media-breakpoint-down(sm) {
			margin-top: var(--section-margin-bottom--xsmall);
		}
	}
}

// If backgorund color & background image
[class*="bg-"] {
	.grid-dynamic.has-image-background {
		margin-left: var(--bg-section-padding-md-top);
		margin-right: var(--bg-section-padding-md-top);

		@include media-breakpoint-down(sm) {
			margin-left: var(--bg-section-padding-sm-top);
			margin-right: var(--bg-section-padding-sm-top);
		}
	}
}
