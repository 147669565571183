/**
 * Visibility
 */

// .display-none {
// 	display: none !important;
// }

.display-none, .umbraco-forms-hidden {
	display: none !important;
}

.display-none--desktop {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}

.display-none--mobile {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
