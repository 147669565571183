/**
 * Classes
 * Color
 */

.color-tag {
  color: $color-tag;
}

.color-grey-dark {
  color: $grey-dark;
}

.color-red {
  color: $error-red;
}

.color-orange {
  color: $warning-orange;
}

.color-green {
  color: $success-green;
}
