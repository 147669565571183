/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Accordions
 */

// Default Accordion
.accordion {
  h2,
  .h2,
  h3,
  .h3 {
    color: var(--darkmode-50);
  }

  .icon-plus:before,
  .icon-plus:after {
    background: var(--darkmode-hightlight-1-200) !important;
  }

  .accordion__toggle-btn button span {
    color: var(--darkmode-hightlight-1-200);
  }

  .accordion__item.active .accordion-header,
  .accordion__item .accordion-header:hover {
    background-color: var(--darkmode-600) !important;
  }

  .accordion-header {
    &::before {
      background-color: var(--darkmode-600);
    }

    &:hover {
      h2,
      h3 {
        color: var(--darkmode-50);
      }
    }

    &.has-icon .accordion-header__icon {
      background: var(--darkmode-hightlight-2-200);

      &[class^="icon-"],
      &[class*="icon-"] {
        &:before {
          color: var(--darkmode-900) !important;
        }
      }
    }

    h2,
    .h2,
    h3,
    .h3 {
      color: var(--darkmode-50);
    }
  }

  .accordion-body .accordion-body__contents .rich-text ul li::marker {
    color: var(--darkmode-hightlight-1-200);
  }
}

// Accordion like
.accordion__like .accordion-header .accordion-header__icon {
  color: var(--darkmode-hightlight-1-200);
}

// Accordion in form container
.form-container .accordion {
  .accordion__item.active .accordion-body__contents {
    background: var(--darkmode-600) !important;
  }

  .accordion__item.active .accordion-header,
  .accordion__item .accordion-header:hover {
    background-color: var(--darkmode-700) !important;
  }

  .accordion .accordion__item.active .accordion-header,
  .accordion .accordion__item .accordion-header:hover,
  .accordion .accordion__item.active .accordion-body__contents,
  .accordion .accordion__item .accordion-body__contents {
    background-color: var(--darkmode-500) !important;
  }
}

// Accordion colored backgrounds

.accordion__colored-backgrounds:not(.accordion__colored-backgrounds--1-item) {
  background: var(--darkmode-700);
}

.accordion__colored-backgrounds {
  &[class*="article-element-bg"],
  &[class^="article-element-bg"] {
    .rich-text .intext-arrow-link:before {
      color: var(--darkmode-hightlight-1-200) !important;
    }

    .accordion__title h2,
    .accordion__title .h2 {
      color: var(--darkmode-50);
    }
  }

  &.accordion__no-title {
    background: var(--darkmode-700) !important;

    &:before {
      background: var(--darkmode-800) !important;
      opacity: 0.5;
    }

    .accordion__toggle-btn::before {
      border-bottom: 1px solid var(--darkmode-600);
    }
  }

  .accordion__title,
  .accordion__sub-title:after,
  .accordion-header:after,
  &:after {
    background: var(--darkmode-800) !important;
  }

  .accordion__sub-title:after,
  .accordion-header:after,
  &:after {
    opacity: 0.5;
  }

  .accordion-body {
    background: var(--darkmode-700) !important;

    &:after {
      background: transparent;
    }
  }

  .accordion__items::before {
    border-top-color: var(--darkmode-600);
  }

  .accordion__item {
    background: var(--darkmode-700);
    &::before {
      border-bottom-color: var(--darkmode-600);
      bottom: 0px;
    }
  }

  .accordion-header,
  .accordion__sub-title {
    background: var(--darkmode-700) !important;
  }

  .accordion-header.has-icon .accordion-header__icon {
    background: var(--darkmode-hightlight-2-200) !important;
  }
}

.accordion__colored-backgrounds.accordion__colored-backgrounds--1-item .accordion__item{
  border-top-color: var(--darkmode-700);

  &:last-child {
    border-bottom-color: var(--darkmode-700);
  }
}

// Accordion form
.accordion__form .accordion__item {
  .accordion-header {
    background: var(--darkmode-800) !important;
    border-top: 3px solid var(--darkmode-hightlight-2-200);

    &:after {
      border-color: var(--darkmode-hightlight-1-200);
    }
  }

  .accordion-body__contents {
    background: var(--darkmode-800);
  }
}

// Accordion--mobile
.accordion--mobile .accordion--mobile__toggle:after {
  @media only screen and (max-width: 991.98px) {
    border-color: var(--darkmode-hightlight-1-200) !important;
  }
}
