	/*
	** Form validation
	*/

form {
  label.error {
    font-size: $small-details-font-size;
    color: $error-red;
  }

  .input-validation-error {
    margin-bottom: 5px;
  }

  .field-validation-error {
    color: $error-red;
    font-size: $small-details-font-size;
    font-weight: $font-weight-body;
  }
  
  .has-error {
    input,
    select,
    .checkbox {
      border: 1px solid $red !important;
    }
  
    .with-errors {
      margin-top: 5px;
  
      ul {
        margin-bottom: 0 !important;
  
        li {
          color: $red;
          margin-bottom: 0 !important;
          line-height: $line-height-secondary;
        }
      }
    }
  }
}
