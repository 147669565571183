/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Cookie information
 */

#coiOverlay {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  ul li,
  ol li,
  label,
  input::placeholder,
  select,
  select option {
    color: #000;
  }
}
