.hero__search-bar.hero__search-bar--variant-right {
  align-items: center;
  
	.container {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.row {
		justify-content: flex-end;
		flex-direction: row;
		align-items: start;
		flex-grow: 1;
    width: 100%;

    @include media-breakpoint-down(md) {
      justify-content: center;
			flex-grow: 0;
    }
	}

	.search-bar--small {
		margin-bottom: 0px;
	}

	h1,
  h2,
	.h2 {
		margin: 10px 0px;
		padding-right: 15px;

    @include media-breakpoint-down(lg) {
      margin: 19px 0px;
    }

    @include media-breakpoint-down(md) {
      margin: 0px 0px 20px 0px;
			padding-right: 0px;
    }
	}

}
