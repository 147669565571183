/*
** Video background
*/

.background-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&:hover {
		button.background-video__btn {
			opacity: 1;
		}
	}

	video {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		width: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		cursor: pointer;
		object-fit: cover;

		&::-webkit-media-controls {
			display: none !important;
		}

		&::-webkit-media-controls-panel {
			display: none !important;
			-webkit-appearance: none;
		}

		&::-webkit-media-controls-play-button {
			display: none !important;
			-webkit-appearance: none;
		}

		&::-webkit-media-controls-start-playback-button {
			display: none !important;
			-webkit-appearance: none;
		}

    &::-internal-media-controls {
      display: none;
    }
  
    &::-webkit-media-controls {
      display: none;
    }
	}

	button.background-video__btn {
		z-index: 1;
		position: absolute;
		top: 30px;
		right: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		opacity: 0;
		transition: $transition;

		@include media-breakpoint-down(md) {
			top: 15px;
			right: 15px;
		}

		&:after {
			z-index: -1;
			content: '';
			background-color: $white;
			height: 100%;
			width: 100%;
			opacity: 0.8;
			top: 0;
			left: 0;
			position: absolute;
			border-radius: 50%;
		}

		&.paused {
			@include media-breakpoint-down(md) {
				opacity: 1;
			}

			.play-icon .icon {
				opacity: 1;
			}

			.pause-icon {
				opacity: 0;
			}
		}

		svg {
			z-index: 2;
			height: 38px;
			width: 38px;

			&.play-icon .icon {
				opacity: 0;
			}

			&.pause-icon {
				height: 12px;
				width: 12px;

				path {
					fill: black;
				}
			}
		}
	}
}

// Third party video players
.background-video {
  .video__azuremediaplayer {
    height: 100%;
    width: 100%;

    &.azuremediaplayer .vjs-tech {
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }

  .video__dreambrokermediaplayer {
    height: 100%;
    width: 100%;

    &.dreambrokermediaplayer {
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
}
