/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Header burger menu
 */

.burger-menu {
	.burger-menu__nav {
		background: var(--darkmode-800);
	}

	.icon--burger-button {
		span {
			background-color: var(--darkmode-hightlight-1-200);
		}
	}

	.burger-button {
		color: var(--darkmode-50);
	}

	.nav-brand {
		h1,
		.h1 {
      color: var(--darkmode-50);

      strong {
        color: var(--darkmode-50);
      }
    }
	}

	.search-form__btn,
	.search-form__input {
		background-color: var(--darkmode-600);
	}

	.search-container-mobile {
		.search-input--mobile,
		.search-btn--mobile {
			background-color: var(--darkmode-600);
		}
	}
}

.sidemenu {
	background-color: var(--darkmode-600);

	&__primary,
	&__secondary {
		color: var(--darkmode-50);
		border-color: var(--darkmode-50);
	}

	&__close {
		background-color: transparent;

		@media (min-width: 768px) {
			background-color: var(--darkmode-700);
		}

		&::after,
		&::before {
			background: var(--darkmode-hightlight-1-200);
		}
	}
}
