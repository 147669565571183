$theme-map: null;

$themes: (
  "default": (
    background: var(--color-white),
    text: var(--color-black),
    grey-text: var(--color-grey-dark),
    tag: var(--color-tag),
    link: var(--color-link),
    link-background-text: var(--color-link-colored-bg),
    icon: var(--color-icons),
    btn-primary-background: var(--color-btn-primary),
    btn-primary-border: var(--color-btn-primary),
    btn-primary-text: var(--color-btn-primary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-black),
  ),
  "body": (
    background: var(--color-body),
    text: var(--color-black),
    grey-text: var(--color-grey-dark),
    tag: var(--color-tag),
    link: var(--color-link),
    link-background-text: var(--color-link-colored-bg),
    icon: var(--color-icons),
    btn-primary-background: var(--color-btn-primary),
    btn-primary-border: var(--color-btn-primary),
    btn-primary-text: var(--color-btn-primary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-black),
  ),
  "gray": (
    background: var(--color-grey-light),
    text: var(--color-black),
    grey-text: var(--color-grey-dark),
    tag: var(--color-tag),
    link: var(--color-link),
    link-background-text: var(--color-link-colored-bg),
    icon: var(--color-icons),
    btn-primary-background: var(--color-btn-bg--grey-light),
    btn-primary-border: var(--color-btn-bg--grey-light),
    btn-primary-text: var(--color-btn-bg--grey-light-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-black),
  ),
  "transparent": (
    background: transparent,
    text: var(--color-black),
    grey-text: var(--color-grey-dark),
    tag: var(--color-tag),
    link: var(--color-link),
    link-background-text: var(--color-link-colored-bg),
    icon: var(--color-icons),
    btn-primary-background: var(--color-btn-primary),
    btn-primary-border: var(--color-btn-primary),
    btn-primary-text: var(--color-btn-primary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-black),
  ),
  "invert-bg-main": (
    background: var(--color-white),
    text: var(--color-black),
    grey-text: var(--color-grey-dark),
    tag: var(--color-tag),
    link: var(--color-link),
    link-background-text: var(--color-link-colored-bg),
    icon: var(--color-icons),
    btn-primary-background: var(--color-btn-primary),
    btn-primary-border: var(--color-btn-primary),
    btn-primary-text: var(--color-btn-primary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-black),
  ),
  "primary": (
    background: var(--color-primary),
    text: var(--color-primary-text),
    grey-text: var(--color-primary-text),
    tag: var(--color-primary-text),
    link: var(--color-primary-text),
    link-background-text: var(--color-primary),
    icon: var(--color-primary-text),
    btn-primary-background: var(--color-btn-bg--primary),
    btn-primary-border: var(--color-btn-bg--primary),
    btn-primary-text: var(--color-btn-bg--primary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-primary-text),
  ),
  "secondary": (
    background: var(--color-secondary),
    text: var(--color-secondary-text),
    grey-text: var(--color-secondary-text),
    tag: var(--color-secondary-text),
    link: var(--color-secondary-text),
    link-background-text: var(--color-secondary),
    icon: var(--color-secondary-text),
    btn-primary-background: var(--color-btn-bg--secondary),
    btn-primary-border: var(--color-btn-bg--secondary),
    btn-primary-text: var(--color-btn-bg--secondary-text),
    btn-primary-background-hover: transparent,
    btn-primary-text-hover: var(--color-secondary-text),
  ),
  "header": (
    background: var(--color-header),
    text: var(--color-header-text),
    link: var(--color-header-link-underline),
    icon: var(--color-header-icon),
    btn-primary-background: transparent,
    btn-primary-border: var(--color-btn-bg--header-border-color),
    btn-primary-text: var(--color-btn-bg--header-text),
    btn-primary-background-hover: var(--color-header-text),
    btn-primary-text-hover: var(--color-header),
  ),
  "footer": (
    background: var(--color-footer),
    text: var(--color-footer-text),
    link: var(--color-footer-text),
    link-background-text: var(--color-footer),
    icon: var(--color-footer-text),
    btn-primary-background: var(--color-footer-text),
    btn-primary-border: var(--color-footer-text),
    btn-primary-text: var(--color-footer),
    btn-primary-background-hover: var(--color-footer-text),
    btn-primary-text-hover: var(--color-footer),
  ),
);

@mixin globalComponentColors($themes) {
  @each $theme, $map in $themes {
    .bg--#{$theme}:not(svg) {
      $theme-map: map-get($themes, $theme) !global;
      @content;
    }
  }
}

@mixin nestedComponentColors($themes) {
  @each $theme, $map in $themes {
    &.bg--#{$theme} {
      $theme-map: map-get($themes, $theme) !global;
      @content;
    }
  }
}

@function themed($property) {
  $property: unquote($property);
  @return map-get($theme-map, $property);
}
