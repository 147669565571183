/**
 * Cards
 */

.card {
  background-color: $white;
  border: none;
  width: 100%;
  border-radius: 0;

  @include nestedArticleColors($themes-article) {
    background-color: themedArticle('background');
    color: themedArticle('text');
  }

  .tags-container {
    margin-bottom: 10px;

    .tag,
    .label,
    .icon--inline-tag {
      margin-bottom: 0;
    }

    button.tag {
      padding: 0;
    }
  }

  .tags-container--under {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .tag,
  .label,
  .icon--inline-tag {
    margin-bottom: 10px;
  }

  // When pre-text is set
  h3,
  .h3 {
    strong {
      font-weight: $font-weight-heading;
    }
    
    span {
      font-family: $primary-font;
      font-weight: $font-weight-body;
    }
  }

  p:not(.status),
  table tbody {
    font-size: $component-font-size;
    line-height: $line-height-secondary;
  }

  mark {
    background: $color-highlight-bg;
    color: $color-highlight-text;
  }
  
  .card__badge,
  .card__tag {
    z-index: 1;
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-secondary;

    p:not(.status) {
      font-size: $small-details-font-size;
      line-height: $line-height-secondary;
      color: $color-secondary-text;
      margin-bottom: 0;
      font-weight: 600;
      text-transform: $small-text-transform;
    }
  }

  .card__badge {
    height: 80px;
    min-width: 80px;
    border-radius: 50px;
    padding: 12px;

    p {
      display: -webkit-box;
      max-width: 100px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  
  .card__tag {
    top: 15px;
    height: fit-content;
    border-radius: var(--sleeve-component-border-radius);
    background: $white;

    p {
      margin-bottom: 0;
      font-weight: 600;
      text-transform: $small-text-transform;
    }
  }

  .divider {
    position: relative;
    padding-top: 15px;
    margin-top: 5px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 40px;
      background-color: $grey-medium;
    }
  }
}
