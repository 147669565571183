/*
** <main>
** Background color white
*/

.bg-main--white,
.body--white {
	// background: var(--section-white-bg);
	background-color: $white;

  section {
    &:not(.bg-section--primary-decorative):not(.bg-section--secondary-decorative):not(.bg-section--invert-bg-main):not(.bg--body):not(.article-wrapper):not(.bg--grey-light) {
      .accordion--mobile:not(.link-boxes--framed):not([class*="bg-"]),
      .c-background,
      .bg--default,
      .info-box__links:not([class*="bg-"]),
      .info-box__links__icon--large:not([class*="bg-"]),
      .info-box__links__icon .info-box__links__icon__item:not([class*="bg-"]),
      // .framed--components,
      .info-box__links__icon--framed,
      .owl-card-carousel .card__portrait,
      .card__portrait:not(.card__portrait--no-image):not([class*="bg-"]),
      .card__portrait--no-image:not([class*="bg-"]),
      .card__meeting,
      .card__cta:not([class*="bg-"]),
      .card__event,
      .card__business,
      .card__person:not([class*="bg-"]),
      .card__hours,
      .events,
      .media-box.media-box--text-box:not([class*="bg-"]),
      .media-box--displaced .media-box__text:not([class*="bg-"]),
      // .media-box--displaced .media-box__text.bg--inherit-bg-main,
      .media-box--equal-heights:not([class*="bg-"]),
      .rich-text__text-box.framed,
      .link-box:not(.link-box__article):not([class*="article-element-bg--"]) {
        border: var(--main-white-bg-component-border);
        background-color: var(--main-white-bg-component-bg);
      }

      .info-box__links__icon .info-box__links__icon__item {
        .info-box__links__icon__item__header,
        .info-box__links__icon__item__body {
          background-color: transparent;
        }
      }

      .accordion--mobile {
        .info-box,
        .card {
          border: none;
          background-color: unset;
        }
      }

      .card__portrait.bg--transparent,
      .info-box__links__icon--framed .info-box__links__icon .info-box__links__icon__item:not([class*="bg-"]) {
        border: none;
        background-color: unset;
      }

      .card__event.card__event--date--bottom-left .card__event__image-content__date {
        background-color: var(--main-white-bg-component-bg);
      }

      .link-boxes--framed .link-box:not(.link-box__article):not([class*="article-element-bg--"]) {
        border: none;
        background-color: $white;
      }
    }
  }

  .article-wrapper {
    .side-box:not(.bg--border-top):not(.bg--primary):not(.bg--secondary) {
      background: $grey-light
    }

    .list__timeline .list__timeline__header.bg--default {
      background-color: $grey-light;

      .list__timeline__dot {
        border-color: $grey-light;
        background-color: $white;
      }
    }
  }

  &[data-creative~="article-content"] {
    .card.card__business {
      background-color: $grey-light;
      border: none;
    }
  }
  
  .bg-section--invert-bg-main {
    .form-container {
      background-color: var(--main-white-bg-component-bg);
    }
  }

  section:not(.bg--grey-light):not(.bg--body) {
    .search-bar--container form:not(#search-form) .form-group input {
      background: $grey-light
    }
  }

  section {
    &.bg--grey-light,
    &.bg--body {
      .search-bar--container form:not(#search-form) .form-group input {
        background: $white;
      }
    }
  }

  section {
    &.bg-section--invert-bg-main {
      .bg--transparent {
        .search-bar--small form:not(#search-form) .form-group input {
          background: $white;
        }
      }
    }

    @include globalComponentColors($themes) {
      .search-bar--small form:not(#search-form) .form-group input {
        //background: $white;
      }
    }

    @include nestedSectionColors($themes-section) {
      .search-bar--small form:not(#search-form) .form-group input {
        background: $white;
      }
    }

    .bg--default {
      .search-bar--small form:not(#search-form) .form-group input {
        background: $white;
        border: 2px solid $color-body;
      }
    }
  }

  .media-box--text-box:not(.bg--primary), 
  .media-box--text-box:not(.bg--secondary) {
    .search-bar--small form:not(#search-form) .form-group input {
      background: $white;
    }
  }
 
  .bg-section--primary-decorative, .bg-section--secondary-decorative {
    .media-box--text-box {
      .search-bar--small form:not(#search-form) .form-group input {
        background: $grey-light;
      }
    }
  }

  article {
    .card__portrait:not(.card__portrait--no-image) {
      border: var(--main-white-bg-component-border);
      background-color: var(--main-white-bg-component-bg);
    }

    .list__cards.list__cards--framed {
      background: var(--color-grey-light);
    }
  }

  .list__cards.list__cards--framed .card.card__list__contact {
    border-bottom: 1px solid var(--color-grey-medium) !important;
  }

  .bg-section--invert-bg-main,
  .bg--invert-bg-main,
  .list__cards.list__cards--framed,
  .media-box--displaced .media-box__text.bg--transparent
  .framed--components {
    background: $color-body;
  }

  // Outfase in back-end and set the correct color
  .banner__hours.bg--white {
    background: $color-body !important;
  }

  .bg--inherit-bg-main {
    background-color: $white;
  }

  .filter-list .search-bar--small .form-control{
    border: 1px solid $grey-dark;
  }

  .form-container,
  .umbraco-forms-form,
  .filter-list__form {
    background: var(--color-grey-light);
  }

  .form-container form:not(#search-form):not(#subscribForm),
  .umbraco-forms-form,
  .filter-list__form {
    .form-group {
      //- Input and Textarea
      input,
      input[type="date"],
      textarea {
        background-color: $white;
      }

      .select select,
      .select-checkboxes {
        background: $white;
      }
    }

    .form .form-group {
      .select select {
        background: $white;
      }
    }
  }

  .select select {
    background: $grey-light;
    color: $black;
  }

  .article-wrapper {
    .select select {
      background: $white;
      color: $black;
      border: 1px solid $grey-medium;
    }

    .c-background {
      background: $grey-light;
    }
  }

  .filter-menu {
    .select select,
    form:not(#search-form) .form-group input[type=date],
    input[type="date"],
    .select-checkboxes,
    .select-checkboxes ul {
      background: $grey-light;
      color: $black;
      border: none;
    }

    .select button {
      color: $black;
    }

    .select select,
    input[type="date"]
    input,
    select.select-checkboxes.dropdown {
      &::placeholder,
      &::-webkit-input-placeholder,
      button {
        color: $black;
      }
    }

    input[type="date"] {
      &::-webkit-datetime-edit-text,
      &::-webkit-date-and-time-value,
      &::-webkit-date-and-time-placeholder,
      &::-webkit-calendar-picker-indicator,
      &::-webkit-datetime-placeholder,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        color: $black;
      }
    }

    input[type="date"]:not(.has-value):before{
      color: $black;
    }
  }

	.breadcrumb__page-title {
		background-color: $white;
	}

  .accordion__form {  
    .accordion__item {
      &.active {
        .accordion-header {
          background: $grey-light;
        }
      }
  
      .accordion-header {
        background: $grey-light;
      }
  
      .accordion-body__contents {
        background: $grey-light;
      }

      form:not(#search-form) {
        background: $grey-light;

        .form-group input,
        .form-group textarea,
        .form-group select {
          background: $white;
          border: 1px solid $grey-medium;
        }
      }
    }
  }

  .accordion__like .accordion__form .accordion__item form:not(#search-form) {
    background: transparent;
  }

  .info-box__links__icon--framed .info-box__links__icon .info-box__links__icon__item:not([class*="bg-"]),
  .link-boxes--framed .link-box:not([class*="bg-"]) {
    border: none;
    background-color: unset;
  }

  .title__introductory .bg--invert-bg-main{
    background: $color-body !important;
  }
}

.bg--white:not(svg) {
	background: $bg--white-color !important;

	&.navbar-nav__secondary-container .navbar-nav.navbar-nav__secondary {
		.nav-item:not(.nav-item--cta) .nav-link {
			color: $black !important;
		}

    .nav-item.nav-item--cta {
      .nav-link {
        background-color: $btn-primary-bg-color;
        color: $btn-primary-text-color;
        border-color: $btn-primary-border-color;

        &:hover,
        &:focus,
        &.active {
          background-color: transparent;
          color: $black;

          [class*="icon-"]::before {
            color: $black;
          }
        }
      }
    }
	}

  &.header__megamenu-extended__search-bar {
    background-color: themed('background');
  
    .header__megamenu-extended__search-bar__form,
    form:not(#search-form).header__megamenu-extended__search-bar__form {
      .form-group {
        input,
        label,
        button {
          color: $black;
        }
  
        input {
          background-color: $white;
          border-color: $black;
  
          &::placeholder {
            color: $black;
          }
        }
  
        button[type="submit"] {
          background-color: $btn-primary-bg-color;
          border-color: $btn-primary-bg-color;
          color: $btn-primary-text-color;

          &:hover,
          &:focus {
            background-color: transparent;
            color: $black;

            svg path {
              fill: $black;
            }
          }
  
          svg path {
            fill: $btn-primary-text-color;
          }
        }
      }
    }
  }

  .dropdown-settings.settings-menu:not(.tablet-secondary-nav) {
		.dropdown-settings__toggle {
			color: $black;

			&::before {
				border-color: $black;
			}
		}
	}

  .dropdown-settings {
    p {
      color: $black;
    }

    .dropdown-settings__container {
      background-color: $white;

      li {
        border-color: $black;
      }
    }

    .dropdown-settings__toggle::before,
    [class*=icon-]::before {
      color: $color-icons
    }
  }

  /***** Buttons *****/
  .btn__color-theme {
    background-color: var(--color-btn-primary);

    .icon-darkmode-moon,
    .icon-lightmode-sun {
      color: var(--color-btn-primary-text);
    }

    .btn__color-theme__toggle {
      background-color: var(--color-btn-primary-text);
    }
  }

  /***** Banners *****/
  &.banner__cta {
    .btn__primary-arrow--long {
      .arrow::before {
        color: $btn-primary-text-color;
      }
    }
  }

	&.banner__alert {
		background-color: $white !important;

		p a {
			color: $color-link;
			background-size: 0% 1px;
			background-image: linear-gradient($color-link, $color-link);

			&:hover {
				color: $color-link;
				background-size: 100% 1px;
			}
		}
	}
}

.bg-main--white,
.bg-section--white,
.bg--white {
  section.article-wrapper,
  &.article-wrapper {
    .link-box:not(.link-box__article):not([class*="article-element-bg--"]) {
      border: none;
    }
  }

  .card__portrait .tag-block .tag,
  .card__votes {
    background: $grey-light;
  }

  .tag-block .tag {
    background-color: $grey-light;
  }

	.owl-card-carousel {
		.item.card.card__portrait:not(.card__portrait--no-image) {
			border: none;
			background: var(--color-body);
		}
	}

	section {
		&.mb--large:not(.section-padding) {
			&:last-of-type {
				@include responsive-space("mb", 0, 0, 0, "", "");
				@include responsive-space("pb", var(--section-margin-bottom-sm--large), var(--section-margin-bottom-sm--large), var(--section-margin-bottom-md--large),	"", "");
			}
		}

    &.bg-section--primary-decorative,
    &.bg-section--secondary-decorative {
      .media-box.media-box--text-box:not([class*="bg-"]),
      .media-box--displaced .media-box__text,
      .media-box--equal-heights:not([class*="bg-"]) {
        border: unset;
      }
    }
	}

  .service-menu:not(.service-menu--bg-transparent) .service-menu__item .service-menu__item__icon {
    background: $grey-light;
  }

  .filter-menu {
    .service-menu:not(.service-menu--bg-transparent) .service-menu__item .service-menu__item__icon {
      background: $grey-light;
    }
  }
}

// Creative article
[data-pagetype~="creative"].bg-main--white {
  section {
    &:not(.bg-section--primary-decorative):not(.bg-section--secondary-decorative):not(.bg-section--invert-bg-main):not(.bg--body):not(.article-wrapper):not(.bg--grey-light) {
      .card__business {
        background-color: $grey-light;
        border: none;
      }
    }
  }

  .side-box:not(.bg--border-top):not(.bg--primary):not(.bg--secondary) {
    background-color: $grey-light;
  }
}