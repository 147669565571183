/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Fact boxes
 */

.fact-box {
  &[class*="article-element-bg"],
  &[class^="article-element-bg"] {
    .rich-text .intext-arrow-link:before {
      color: var(--darkmode-hightlight-1-200);
    }
  }

  .fact-box__title {
    background: var(--darkmode-800) !important;
  }

  .fact-box__title__icon {
    background-color: var(--darkmode-700);

    &::after {
      display: none;
    }
  }

  .fact-box__content,
  .read-more-button {
    background: var(--darkmode-700) !important;
    color: var(--darkmode-50);
    border-radius: 0 0 var(--sleeve-component-border-radius) var(--sleeve-component-border-radius);
  }

  .fact-box__content,
  .read-more-button {
    &:after {
      background: var(--darkmode-800) !important;
      border-radius: 0 0 var(--sleeve-component-border-radius)
        var(--sleeve-component-border-radius);
    }
  }

  .fact-box__title--icon {
    &::after {
      background-color: var(--darkmode-800);
    }

    &::before {
      color: var(--darkmode-50) !important;
      z-index: 1;
    }
  }

  &.article-element-bg--0,
  &.article-element-bg--1,
  &.article-element-bg--2,
  &.article-element-bg--3,
  &.article-element-bg--4,
  &.article-element-bg--5 {
    .fact-box__title h2 {
      color: var(--darkmode-50);
    }

    .fact-box__content {
      .rich-text {
        h2,
        h3,
        h4,
        h5,
        p,
        ul li,
        ol li,
        ul li::marker,
        .intext-arrow-link:before  {
          color: var(--darkmode-50);
        }
      }
    }
  }
}

// Delete after implementaion of new fact-box collapsible
.fact-box.accordion--mobile {
  .accordion--mobile__body {
    padding: 0;
    background: var(--darkmode-700) !important;
  }

  .fact-box__text:after {
    background: var(--darkmode-800);
  }
}
