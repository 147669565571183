.breadcrumb-container {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .service-menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 0;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      flex-wrap: wrap;
    }

    .service-menu__item {
      .service-menu__item__icon {
        display: flex;
        align-items: center;
        margin-right: 10px;
        height: auto;
        width: auto;
        border-radius: unset;
        background: transparent !important;
      }
    }
  }
}

@include media-breakpoint-down(sm){
  .dynamic-service-menu {
    .service-menu {
      display: none;
    }
  }
}
