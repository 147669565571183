/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Tabel hours
 */

.table-hours table th,
.table-hours table td,
.table-hours table tr:last-of-type td,
.table-hours tr.inactive td,
.table-hours .status-indicator-open,
.table-hours .status span:not(.info-close-dates),
.table-hours .status .status-text::before {
  color: var(--darkmode-50) !important;
}

.table-hours .status .opened {
  color: var(--darkmode-hightlight-1-200) !important;
}

.table-hours .status-indicator {
  border-color: var(--darkmode-hightlight-1-200) !important;
}

.table-hours .status .open-fill {
  background-color: var(--darkmode-hightlight-1-200) !important;
}