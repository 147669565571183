// side menu default
.stop-scroll {
	overflow: hidden;
}

.sidemenu {
	height: 100vh;
	width: 380px;
	background-color: $white;
	position: absolute;
	top: 0;
	z-index: 1;
	overflow-y: auto;

	@include media-breakpoint-up(md) {
		width: 780px;
	}

	&__container {
		padding: 20px 30px;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		@include media-breakpoint-up(md) {
			padding: 55px 60px;
		}
	}

	&__primary {
		border-bottom: 1px solid $grey-light;

		&.list__links {
			padding: 0 0 30px 0;
		}

		&__list {
			display: flex;
			flex-direction: column;

			&-item {
				font-size: 18px;
				display: flex;
				margin-right: 15px;
				margin-bottom: 20px;
				color: $black;

				@include media-breakpoint-up(md) {
					font-size: $header-nav-link-font-size;
				}
			}
		}
	}

	&__close {
		margin: 20px 0 0 22px;
		height: 25px;
		width: 20px;
		border-radius: 50%;
		background: $grey-light;
		padding: 20px;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			height: 2px;
			background: $color-icons;
			width: 18px;
			display: block;
			position: absolute;
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		@include media-breakpoint-up(md) {
			background: var(--color-grey-light);
		}
	}

	&__secondary {
		display: flex;
		flex-direction: column;
		padding: 20px 0;

		&__list {
			list-style: none;

			&-item {
				font-size: $megamenu-link-list-font-size;

				&:hover {
					a {
						color: $color-arrows;
					}
				}

				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: 150px;
		}

		&__quickactions {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
			align-items: center;

			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
}

// Sidemenu when active
.sidemenu.active {
	visibility: visible;
	animation: menuFadeIn 0.65s ease forwards;
}

.sidemenu.inactive {
	visibility: hidden;
	animation: overlayFadeOut 0.5s ease forwards;
	transition: visibility 0.5s;
}

// Overlay
.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	background-color: var(--color-overlay-start);
}

.menu-overlay.active {
	visibility: visible;
	animation: overlayFadeIn 0.65s ease forwards;
}

.menu-overlay.inactive {
	animation: overlayFadeOut 1s ease forwards;
	visibility: hidden;

	// hide after fade animiation finishes
	transition: visibility 1s linear;
}

// positioned left
.burger-menu--left {
	.sidemenu {
		left: 0;

		&__close {
			margin-right: 22px;
			margin-left: auto;
		}
	}
}

// positioned right
.burger-menu--right {
	.sidemenu {
		right: 0;

		&__close {
			margin-left: auto;
			margin-right: 22px;

			@include media-breakpoint-up(md) {
				margin-left: 22px;
				margin-right: auto;
			}
		}
	}
}

// animations

@keyframes delayedShow {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 100;
	}
}

@keyframes itemFadeInRight {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}
	75% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes itemFadeOutRight {
	0% {
		opacity: 1;
		transform: translateX(0%);
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
		transform: translateX(100px);
	}
}

@keyframes itemFadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}
	75% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes itemFadeOutLeft {
	0% {
		opacity: 1;
		transform: translateX(0%);
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
		transform: translateX(-100px);
	}
}

@keyframes overlayFadeIn {
	0% {
		opacity: 0.35;
	}
	// sync transformation with menu
	25% {
		opacity: 0.35;
	}
	100% {
		opacity: 0.9;
	}
}

@keyframes overlayFadeOut {
	from {
		opacity: 0.9;
	}
	to {
		opacity: 0;
	}
}

@keyframes menuFadeIn {
	0% {
		opacity: 0;
	}
	// sync transformation with overlay
	25% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
