/*
Backgrounds (nav and header)
Logo
Burger icon
*/

:root {
	--navbar-height: '';
	--mid-width: 0px;
	--navbar-bg-color: '';
	--changing-text-color: ''
}

/* Alpine */
[x-cloak] {
	display: none !important;
}

.header--transparent {
	.container--wide {
		padding-left: 35px;
		padding-right: 35px;

    @include media-breakpoint-down(md) {
      padding-right: var(--col-padding-md-down);
      padding-left: var(--col-padding-md-down);
    }
	}

	.burger-button {
		display: flex;
		justify-content: flex-end;

		.icon--burger-button {
			width: 28px;
			transition: width 0.4s ease, height 0.4s ease;
			height: 18px;

			span {
				transition: position 0.4s ease, backgroundColor 0.2s ease,
					width 0.4s ease;

				&:nth-child(2) {
					width: 24px;
					left: auto;
					right: 0;
					top: 8px;
				}

				&:nth-child(3) {
					top: 16px;
				}
			}
		}
	}

	.header__background-color {
		width: 100vw;
		height: 130px;
	}

	.burger-button {
		visibility: hidden;
		margin-left: auto;
		padding-left: 20px;

		.burger-button-wrapper {
			padding: 5px;
		}

		&.active {
			visibility: visible;
		}

		&:focus,
		&:focus-visible{
			outline: none !important;

			.burger-button-wrapper {
				border: 2px solid var(--changing-text-color);
				border-radius: 5px;
			}
		}
	}

	.navbar-brand {
		margin-right: auto;
		padding-right: 20px;

		#logo-id {
			color: var(--changing-text-color);
		}

		#inverse-logo-id {
			color: var(--changing-text-color);
		}

		h1,
    .h1 {
			font-family: var(--font-primary);
			font-size: calc(0.0625em * var(--header-nav-link-font-size) + 0.375em);
			line-height: 1.125;
			font-weight: var(--heading-font-weight);
			margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: calc(0.0625em * var(--header-nav-link-font-size));
      }

			strong {
				font-family: var(--font-primary);
			}
		}

		img {
			width: auto;
			height: var(--header-brand-height-lg);

      @include media-breakpoint-down(md) {
        height: var(--header-brand-height-md);
      }
		}

		#logo-id,
		#inverse-logo-id {
			z-index: 10;
			object-fit: contain;
			transition: height 0.4s ease, font-size 0.4s ease, margin 0.4s ease;
			margin-top: calc(var(--header-brand-height-lg) / 3);
			margin-bottom: calc(var(--header-brand-height-lg) / 3);
			background-size: contain !important;

      @include media-breakpoint-down(md) {
        margin-top: calc(var(--header-brand-height-md) / 3);
        margin-bottom: calc(var(--header-brand-height-md) / 3);
      }
		}
	}
}
