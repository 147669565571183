.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  
  @include media-breakpoint-down(xs) {
    width: calc(100% + (2 * var(--col-padding-md-down)));
    margin-left: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
    margin-right: calc(var(--col-padding-md-down) - (2 * var(--col-padding-md-down)));
  }

  img {
    min-height: 1px;
    width: 100%;
    background-size: contain;
  }

  p {
    @include media-breakpoint-down(xs) {
      margin-right: var(--col-padding-md-down);
      margin-left: var(--col-padding-md-down);
    }
  }
}

figure.image {
  img {
    border-radius: $border-radius-image;
  } 
}

.container--wide {
  .image {
    img {
      border-radius: 0;
    }
  }
}

.container--wide .container,
.container {
  .image {
    img {
      border-radius: $border-radius-image;
    }
  }
}

// Images in grid
.equalizer {
  .image {
    img {
      height: 100%;
    }
  }
}

// Image in conatiner--wide
.container--wide {
  figure.image figcaption {
    width: 100%;
    margin: auto;

    // max-width of the grid
    @include media-breakpoint-up(xl) {
      max-width: var(--container-width);
    }

    @include media-breakpoint-down(lg) {
      max-width: 960px;
    }

    @include media-breakpoint-down(md) {
      max-width: 720px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 540px;
    }

    //Commented out 16/4 2024 due to double padding on figcaptions on mobile in container wide (it is also set on the p tag). 
    //Keeping it to see if there's any unforeseen issues after deploy
    @include media-breakpoint-down(xs) {
      //padding: 0 var(--col-padding);
    }
  }
}
