/**
 * Blockquote
 */

blockquote,
.blockquote {
  display: flex;
  font-size: var(--base-font-size);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .blockquote__image {
    width: 30px;
    height: 24px;
    margin-right: 15px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    svg {
      display: flex;
      width: 30px;
      height: auto;
      object-fit: contain;

      path {
        fill: $color-icons;
      }
    }
  }

  .blockquote__text {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;

    p {
      @include responsive-font-size($hero-article-p-font-size-sm, '', $hero-article-p-font-size-sm, '', $hero-article-p-font-size-lg);
      line-height: $line-height-article-body;
      margin-bottom: 18px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }
}
