.info-box.info-box__links__icon--large {
  border-radius: var(--sleeve-component-border-radius);

  .info-box__links__icon--large__bg {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &:before {
      font-size: 4.4rem; // icon size
      color: $color-icons;
    }

    &.flex-left {
      justify-content: left;
      margin-bottom: 10px;
    }

    img,
    svg {
      width: 70px;
      height: auto;
      max-height: 70px;
    }
  
    img {
      object-fit: contain;
    }
  }

  h3, 
  .h3 {
    margin-bottom: 20px;
  }

  p {
    padding: 20px 0;
    margin-bottom: 20px;
    font-size: $component-font-size;
	  line-height: $line-height-secondary;
  }
}
